import React, { Component }
from "react";
import { LinkContainer }
from "react-router-bootstrap";
import "./FormTemplate.css";
import { toast } from 'react-toastify';
import $ from 'jquery';

import Header from "../Common/Header/Header";
class FormTemplate extends Component {
	
	
	constructor(props){
		super(props);
		this.state={
			isLoading:false,
			formtemplates:[],
			fakePath:'',
            userTypeForRoleManagement:localStorage.getItem('userType')
		}
		if(this.state.userTypeForRoleManagement === 'Admin'){
            this.props.history.push("/dashboard");
        }
		localStorage.removeItem('ftGuid');
	}
	
	componentDidMount = async () => {	
		$(".modal").css('display', 'none');
		this.setState({isLoading:true});
		try{
          var response = await this.props.postRequest("/form-template/get-list", {});
          if(response.status){
          	this.setState({isLoading:false, formtemplates: response.result});
          }else{
          	this.setState({isLoading:false});
          	toast.error(response.error, {autoClose: 5000});
          }
          console.log("/form-template/get-list=>", response);
    }catch(err){
          this.setState({isLoading:false});
          toast.error(err.message, {autoClose: 5000});
          console.log("/form-template/get-list error=>", err);
    }
        //return false;
		//this.get_all_form_template();
	}

	
	edit = (e,id) => {
		localStorage.setItem('ftGuid',id);
		this.props.history.push('/form-template-detail');
	}

	delete = async (e,id) => {
		var result = window.confirm('Are you sure you want to delete?');
		if(result){
			this.setState({ isLoading:true});
			try{
                var response = await this.props.postRequest("/form-template/delete-template", {Guid: id});
                if(response.status){
                  toast.success("Success! Record has deleted successfully." , { autoClose: 5000 });
                  this.componentDidMount();
                  this.setState({ isLoading: false });
                }else{
                    toast.error(response.error , { autoClose: 5000 });
                    this.setState({ isLoading: false });
                }
            }catch(err){
                toast.error(err.message , { autoClose: 5000 });
                this.setState({ isLoading: false });
           	}
		}	
	}

	
    render() {
        return (
                <div className="FormTemplate">
   <Header pageHeading="Form Template"  props={this.props} isLoading={this.state.isLoading}/>
   <div className="clearfix"></div>
   <div className="col-sm-12 p0 top_mrg_general top_mrg_general_custom">
      <div className="">
         <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
            <div className="col-xs-12 col-sm-12 col-md-12">
               <div className="clear10"></div>
               <LinkContainer to="/form-template-detail">
                  <a href={() => false} className="CursorPointer">
                  <button type="button" className="btn btn-primary pull-right plus_icon_table formtemplate_btn_plus_cusotm">+</button>
                  </a>
               </LinkContainer>
               <div className="clearfix"></div>
               <div className="overflow_scroll_sm">
                  <table className="table table-bordered timecard_table formtemplate_custome_field_table">
                     <thead>
                        <tr>
                           <th width="20%" align="center">Template Code</th>
                           <th width="20%" align="center">Template Description</th>
                           <th width="40%" align="center">Document</th>
                           <th width="20%" align="center">&nbsp;</th>
                        </tr>
                     </thead>
                     <tbody>
					 
					 {this.state.formtemplates.map((formtemplate,key)=>{
						// this.get_image(formtemplate.DigitalDoc);						 
						 return(
							<tr className="v_center" key={key}>
							   <td width="11%" align="center">{formtemplate.TemplateCode}</td>
							   <td align="center">{formtemplate.Desc}</td>
							   <td align="center">{(formtemplate.filename)?formtemplate.filename:''}</td>
							   <td align="center">
								  <div className="col-sm-4  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
									 
										<a href={() => false} className="CursorPointer" onClick={(e)=>this.delete(e,formtemplate.Guid)}>
										   <svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
											  <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"/>
										   </svg>
										</a>
									
								  </div>
								  <div className="col-sm-4  col-xs-6 p0 text-center timecard_edit6 pull-right">
									 
										<a href={() => false} className="CursorPointer"  onClick={(e)=>this.edit(e,formtemplate.Guid)}>
										   <svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
											  <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"/>
										   </svg>
										</a>
									 
								  </div>
							   </td>
							</tr>
						)
					 })}
                        
                        
                        
                        
                        
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         <div className="clear10"></div>
      </div>
   </div>
   <div className="clear10"></div>
   <div id="myModal" className="modal fade" role="dialog">
      <div className="modal-dialog">
         <div className="modal-content">
            <div className="modal-header">
               <button type="button" className="close formtemplate_OutlineNone" data-dismiss="modal"><img className="formtemplate_mt15" src="images/ic_clear_24px@2x.png" width="18" height="18" alt="" /></button>
               <h4 className="modal-title modal_customefield">Custom Fields</h4>
            </div>
            <div className="modal-body label_modal_custom">
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Description:</div>
                  <div className="col-sm-9 col-md-9"><input name="" type="text" /></div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Default Value:</div>
                  <div className="col-sm-9 col-md-9"><input name="" type="text" /></div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Type:</div>
                  <div className="col-sm-9 col-md-9"><select name=""></select></div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Tag:</div>
                  <div className="col-sm-9 col-md-9"><input name="" type="text" /></div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Validation:</div>
                  <div className="col-sm-9 col-md-9"><input name="" type="text" /></div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Length:</div>
                  <div className="col-sm-9 col-md-9"><input name="" type="text" /></div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">DB Field:</div>
                  <div className="col-sm-9 col-md-9"><input name="" type="text" /></div>
               </div>
               <div className="col-sm-6">
                  <div className="btn_cance_save">
                     <button type="button" className="btn_save_pro"  data-dismiss="modal">Save</button>
                     <button type="button" className="btn_cancel_pro"  data-dismiss="modal">Cancel</button>
                  </div>
               </div>
               <div className="clear20"></div>
            </div>
         </div>
      </div>
   </div>
</div>
                );
    }
}
;
export default FormTemplate;
