import React, { Component } from "react";
//======================================//
// ListItem AS A CHILD COMPONENT DEFINE //
// - - - - -  IN THIS SCOPE - - - - - - //
//--------------------------------------//

export default class ListItem extends Component
{
	constructor(props)
	{
	    super(props);
		this.state = {
            customFields: props.customFields
        };
  	}

	componentWillReceiveProps(nextProps)
	{
	  	this.setState({ customFields: nextProps.customFields });
	}


	//=======================================//
	//  - - - RENDER LISTITEM COMPONENT - -  //
	//---------------------------------------//
	render()
	{
        if(typeof this.props.customFields !== 'undefined' && this.props.customFields.length > 0)
        {

            let currentcustomFields = this.props.customFields;

            var customFields = currentcustomFields.map((field, key) => {

                return (
					<tr key={key}>
						<td align="left" valign="middle">{field.prompt}</td>
						<td align="left" valign="middle">{field.value}</td>
						<td align="center">
							<div className="col-sm-2  col-md-1 col-xs-4 p0 pull-right text-center timecard_dele pull-right">
								<a href={null} className="cursorPointer">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
										<path id="ic_delete_24px" onClick={(event) => this.props.removeField(event, key)}  className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"></path>
									</svg>
								</a>
							</div>
							<div className="col-sm-2  col-md-1 col-xs-4 p0 text-center timecard_edit6 pull-right">
								<a className="cursorPointer" href={null} data-toggle="modal" onClick={(event) => this.props.editField(event, key)}>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
										<path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"></path>
									</svg>
								</a>
							</div>
						</td>
					</tr>
                )
            });
            if(customFields.length === 0)
            {

                 customFields = <tr><td  colSpan="2">Record not found</td><td></td></tr>;
            }
        }
        else
        {
             customFields = <tr><td  colSpan="2">Record not found</td><td></td></tr>;
        }


        return(
            <tbody>
            		{
            			customFields
            		}
            </tbody>
        );
	}
};
