import React, { Component }
from "react";
import "./CustomFields.css";
import Header from "../Common/Header/Header";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";
import { toast } from 'react-toastify';
import $ from 'jquery';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';

window.$ = $;
global.jQuery = $;
 var Modal = require('react-bootstrap-modal');
require('react-bootstrap-modal/lib/css/rbm-patch.css');
class CustomFields extends Component {
	
	constructor(props){
		super(props);
		this.state={
			description:'',
			defaultValue:'',
			type:'',
			tag:'',
			validation:'',
			length:'',
			db_field:'',
			modalOpen: false,
			formerror:false,
			open: false,
			description_error:false,
			defaultValue_error:false,
			numberdefaultValue_error:false,
			datedefaultValue_error:false,
			type_error:false,
			tag_error:false,
			validation_error:false,
			length_error:false,
			db_field_error:false,
			customfields:[],
			isLoading: false,
			Guid:'',
			isEdit:false,
			isString:true,
			isNumber:false,
			isDate:false,
			isDroplist:false,
			isFile:false,
			startDate: moment(),
			
			add_datalist:false,
			datalist:[{'option':'yes','value':1},{'option':'no','value':0}],
			datalist_option:'',
			datalist_value:'',
			add_datalist_key:'',
			value_error:false,
			option_error:false,
                        userTypeForRoleManagement:localStorage.getItem('userType')
		
		}
		if(this.state.userTypeForRoleManagement === 'Approver' || this.state.userTypeForRoleManagement === 'Operator' || this.state.userTypeForRoleManagement === 'Operator/Approver'){
                     this.props.history.push("/dashboard");
                }
	}

	componentDidMount(){
		this.get_customfield();
	}
	
	onModalClose=()=>{
        this.setState({ 
            modalOpen: false,
			description_error:false,
		    length_error:false,
		    type_error:false,
		    tag_error:false,
		    defaultValue_error:false,
		    db_field_error:false,
		    validation_error:false,
			description:'',
			defaultValue:'',
			type:'',
			tag:'',
			validation:'',
			length:'',
			db_field:'',
			
			isEdit:false,
			Guid:'',
			datalist:[{'option':'yes','value':'1'},{'option':'no','value':'0'}]
					
        });
    }
    
	onModalOpen=()=>{
        this.setState({ 
			modalOpen: true,
			description_error:false,
		   length_error:false,
		   type_error:false,
		   tag_error:false,
		   defaultValue_error:false,
		   db_field_error:false,
		   validation_error:false,
		});
		if(this.state.isLoading && this.state.Guid){
			this.setState({
				description:'',
			   defaultValue:'',
			   type:'',
			   tag:'',
			   validation:'',
			   length:'',
			   db_field:'',
			});
		}
	}
	descriptionHandler=(event)=>{
		let description=event.target.value;
		console.log('description',description);
		this.setState({
			description:description
		});
		if(!description){
			this.setState({
				description_error:true
			});
		}else{
			this.setState({
				description_error:false
			});
			
		}
	}
	defaultValueHandler=(event)=>{
		let defaultValue=event.target.value;
		//console.log('defaultValue',defaultValue);
		const re = /^[0-9\b]+$/;
		console.log(re.test(defaultValue));
		if(this.state.isDate){
			 // var startDate = moment('test');
			 // console.log(startDate);
		}else if(this.state.isNumber){
			const re = /^[0-9\b]+$/;
			if(re.test(defaultValue)){
				this.setState({
					defaultValue:defaultValue,
					numberdefaultValue_error:false
				});
			}else{
				this.setState({
					defaultValue:'',
					numberdefaultValue_error:true
				});
			}
		}else if(this.state.isDroplist){
			if(defaultValue || defaultValue===0){
				this.setState({
					defaultValue:defaultValue,
					defaultValue_error:false
				});
			}else{
				this.setState({
					defaultValue:'',
					defaultValue_error:true
				});
			}
		}else{
			if(defaultValue){
				this.setState({
					defaultValue:defaultValue,
					defaultValue_error:false
				});
			}else{
				this.setState({
					defaultValue:'',
					defaultValue_error:true
				});
			}
		}		
		
		console.log(this.state.defaultValue);
	}
	typeHandler=(event)=>{
		let type=event.target.value;
		console.log('type',type);
		this.setState({
			type:type
		});
		if(!type){
			this.setState({
				type_error:true
			});
		}else{
			if(type==='Numeric'){
				this.setState({
					isNumber:true,
					isString:false,
					isDate:false,
					isDroplist:false,
					isFile:false,
					defaultValue:'',
				});
			}else if(type==='Drop List'){
				this.setState({
					isNumber:false,
					isString:false,
					isDate:false,
					isFile:false,
					isDroplist:true,
					defaultValue:'',
				});
			}else if(type==='Date'){
				this.setState({
					isNumber:false,
					isString:false,
					isDate:true,
					isDroplist:false,
					isFile:false,
					defaultValue:'',
				});
			}
			else if(type==='File'){
				this.setState({
					isNumber:false,
					isString:false,
					isDate:false,
					isDroplist:false,
					isFile:true,
					defaultValue:'',
				});
			}
			
			else{
				this.setState({
					isNumber:false,
					isString:true,
					isDate:false,
					isDroplist:false,
					isFile:false,
					defaultValue:'',
				});
			}
			
			this.setState({
				type_error:false
			});
		}
	}
	tagHandler=(event)=>{
		let tag=event.target.value;
		console.log('tag',tag);
		this.setState({
			tag:tag
		});
		if(!tag){
			this.setState({
				tag_error:true
			});
		}else{
			this.setState({
				tag_error:false
			});
		}
	}
	validationHandler=(event)=>{
		let validation=event.target.value;
		console.log('validation',validation);
		this.setState({
			validation:validation
		});
		
	}
	db_fieldHandler=(event)=>{
		let db_field=event.target.value;
		console.log('db_field',db_field);
		this.setState({
			db_field:db_field
		});
		
	}
	lengthHandler=(event)=>{
		let length=event.target.value;
		console.log('length',length);
		this.setState({
			length:length
		});
		if(!length){
			this.setState({
				length_error:true
			});
		}else{
			this.setState({
				length_error:false
			});
		}
	}
	
	
	
	create_customfield(description,defaultValue,type,tag,validation,length,db_field,datalist){
		
		return this.props.postRequest("/custom-fields/create-custom-field", {
            "description": description? description : null,
            "defaultValue": defaultValue? defaultValue : null,
			"type":type? type : null,
			"tag":tag? tag : null,
			"validation":validation? validation : null,
			"length":length? length : null,
			"db_field":db_field? db_field : null,
			"datalist":datalist
        });
	}
	
	update_customfield(description,defaultValue,type,tag,validation,length,db_field,Guid,datalist){
		
		
		return this.props.postRequest("/custom-fields/update-custom-field", {
				"Guid":Guid,
				"Description": description? description : null,
                "Value": defaultValue? defaultValue : null,
				"Type":type? type : null,
				"Tag":tag? tag : null,
				"Validation":validation? validation : null,
				"Length":length? length : null,
				"DBField":db_field? db_field : null,
				//"update_customfield":update_customfield,
				"datalist":datalist
        });
	}
	
	
	handleChange(date) {
		if(date){
			this.setState({
			  startDate: date,
			  defaultValue:date,
			  datedefaultValue_error:false
			});
		}else{
			this.setState({
			  startDate: date,
			  defaultValue:'',
			  datedefaultValue_error:true
			});
		}
		//console.log(date);
    
  }
	
	
	submitHandler = async event => {
		event.preventDefault();
		var description = this.state.description;
		let defaultValue = this.state.defaultValue;
		let type = this.state.type;
		let tag = this.state.tag;
		let validation = this.state.validation;
		let length = this.state.length;
		let db_field = this.state.db_field;
		let datalist = this.state.datalist;
		
		if(defaultValue){
			if(type==='Numeric'){
				const re = /^[0-9\b]+$/;
				if(re.test(defaultValue)){
					this.setState({
						numberdefaultValue_error:false,
					});
				}else{
					this.setState({
						numberdefaultValue_error:true,
					});
				}
			}
			
			
		}
		
		
		if(!description){
			this.setState({ description_error:true });
		}
		
		
		if(!type){
			this.setState({ type_error:true });
		}
		if(!tag){
			this.setState({ tag_error:true });
		}
		
		if(!length){
			this.setState({ length_error:true });
		}
		
		if(description && type && tag && length ){
			this.onModalClose();
			this.setState({
				isLoading: true,
				description:'',
				defaultValue:'',
				type:'',
				tag:'',
				validation:'',
				length:'',
				db_field:''
			});
			try{
				if(this.state.isEdit && this.state.Guid){
					var Guid = this.state.Guid;
					const response = await this.update_customfield(description,defaultValue,type,tag,validation,length,db_field,Guid,datalist);
					if (response.status) {
						toast.success("Success! Record has updaded successfully.", {
							autoClose: 5000
						});
						console.log('response',response);
						this.get_customfield();
						this.setState({
							isEdit:false,
							Guid:'',
						});
					}
				}else{
					const response = await this.create_customfield(description,defaultValue,type,tag,validation,length,db_field,datalist);
					//console.log("Create response=>", response); return false;
					if (response.status) {
						toast.success("Success! Record has added successfully.", {
							autoClose: 5000
						});
						console.log('response',response);
						this.get_customfield();
					}
				}
			}catch(e){
				console.log(e.message);
				this.setState({
					isLoading: false,
				});
				toast.error(e.message, { autoClose: 3000 });
			}
		}
		
	}
	
	delete_customfield_list = async (event,customfield_id)=>{
		var result = window.confirm('Are you sure you want to delete?');
		if (result) {
			this.setState({ isLoading: true });
			try{
				const response_delete = await this.delete_customfield(customfield_id);
				if(response_delete.status){
					this.get_customfield();
					this.setState({ isLoading: false });
					toast.success("Success! Record has deleted successfully.", {autoClose: 5000});
				}else{
					this.setState({ isLoading: false});
					toast.error(response_delete.error, { autoClose: 5000});
				}
			}catch(e){
				this.setState({ isLoading: false});
				toast.error(e.message, { autoClose: 5000});
			}	
		}
	} 
	
	delete_customfield(customfield_id){
		return this.props.postRequest("/custom-fields/delete-custom-field", {Guid: customfield_id});		
	}
	
	get_customfield_list(){
		return this.props.postRequest("/custom-fields/get-list", {});
	}
	
	get_customfield = async () => {
		this.setState({isLoading: true});
		try{
			const response = await this.get_customfield_list();
			if(response.status){
				this.setState({
					customfields:response.result,
					isLoading: false,
				});
			}
		}catch(e){
			this.setState({isLoading: false});
			console.log(e.message);
		}
	}
	
	get_customfield_single = async (event,customfield_id)=>{
		this.setState({isLoading:true});
		//console.log("customfield_id=>",customfield_id);
		const customfield = this.state.customfields.find(el => el.Guid === customfield_id);
		if(customfield){
			this.setState({
				datalist:(customfield.datalist)? customfield.datalist : []
			});
			let type = customfield.Type;
			if(type==='Numeric'){
				this.setState({
					isNumber:true,
					isString:false,
					isDate:false,
					isDroplist:false,
					isFile:false,
					//defaultValue:'',
				});
			}else if(type==='Drop List'){
				this.setState({
					isNumber:false,
					isString:false,
					isDate:false,
					isDroplist:true,
					isFile:false,
					//defaultValue:'',
				});
			}
			else if(type==='File'){
				this.setState({
					isNumber:false,
					isString:false,
					isDate:false,
					isDroplist:false,
					isFile:true,
					//defaultValue:'',
				});
			}
			else if(type==='Date'){
				this.setState({
					isNumber:false,
					isString:false,
					isFile:false,
					isDate:true,
					isDroplist:false,
					defaultValue:customfield.Value? moment(customfield.Value) : null,
					startDate:customfield.Value? moment(customfield.Value) : moment(),
				});
			}else{
				this.setState({
					isNumber:false,
					isString:true,
					isDate:false,
					isFile:false,
					isDroplist:false,
					//defaultValue:'',
				});
			}

			this.setState({
				description:customfield.Desc,
				type:customfield.Type,
				tag:customfield.Tag,
				defaultValue:customfield.Value,
				length:customfield.Length,
				db_field:customfield.DBField,
				Guid:customfield.Guid,
				validation:customfield.Validation,
				isEdit:true,
				isLoading:false,
			});
			this.onModalOpen();
		}
	}
	
	// datalist function
	datalist_closeModal=()=>{
		this.setState({
			add_datalist:false,
			datalist_option:'',
			datalist_value:'',
			add_datalist_key:'',
		});
	}
	datalist_openModal=()=>{
		this.setState({
			add_datalist:true
		});
	}
	
	delete_datalist=(e,key)=>{
		e.preventDefault();
		let found=false;
		let datalist = this.state.datalist;
		let defaultValue = this.state.defaultValue;
		// console.log('defaultValue',defaultValue);
		let isDroplist = this.state.isDroplist;
		
		if(isDroplist){
			datalist.forEach(function(item){
				console.log('defaultValue',item.value === defaultValue);
			   if(item.value === defaultValue){
				   
				   found=true;
				}
			});
		}
		if(found){
			console.log('found',found);
			this.setState({
				defaultValue:'',
				defaultValue_error:true,
			});
		}
		datalist.splice(key,1);
		this.setState({
			datalist:datalist
		});
		
		
		
	}
	
	edit_datalist=(e,key)=>{
		e.preventDefault();
		
		let value = this.state.datalist[key].value;
		let option = this.state.datalist[key].option;
		
		
		this.setState({
			datalist_option:option,
			datalist_value:value,
			add_datalist_key:key,
		})
		console.log("option",this.state.datalist_option);
		console.log("value",this.state.datalist_value);
	}
	
	add_datalist=(e)=>{
		e.preventDefault();
		
		let datalist = this.state.datalist;
		let defaultValue = this.state.defaultValue
		let isDroplist = this.state.isDroplist;
		let found=false;
                var value = this.state.datalist_value;
		if(value || value===0){
			if(isDroplist){
				datalist.forEach(function(item){
					console.log('defaultValue',item.value === defaultValue);
				   if(item.value === defaultValue){
					   
					   found=true;
					}
				});
			}
			if(found===false){
				this.setState({
					defaultValue:''
				});
			}
		}
		var option = this.state.datalist_option;
		if(option || option===0){
			this.setState({option_error:false});
		}else{
			this.setState({option_error:true});
		}
		
		if(value || value===0){
			this.setState({value_error:false});
		}else{
			this.setState({value_error:true});
		}
		
		
		
		
		let key = this.state.add_datalist_key;
		console.log('key=>',key);
		
		
		if(value && option){
			if(key || key===0){
				datalist[key].option=option;
				datalist[key].value=value;
			}else{
				let tmp_array={'option':option,'value':value};
				datalist.push(tmp_array);
			
			}
			this.setState({
				datalist:datalist,
				datalist_option:'',
				datalist_value:'',
				add_datalist_key:'',
				value_error:false,
				option_error:false
			});
		}
		
		

		
	}
	
	option_change=(e)=>{
		e.preventDefault();
		let option = e.target.value;
		if(option || option===0){
			this.setState({
				datalist_option:option,
				option_error:false
			});
		}else{
			this.setState({
				datalist_option:option,
				option_error:true
			});
		}
		
	}
	
	value_change=(e)=>{
		e.preventDefault();
		let value = e.target.value;
		if(value || value===0){
			this.setState({
				datalist_value:value,
				value_error:false
			});
		}else{
			this.setState({
				value_error:true,
				datalist_value:value
			});
		}
		
	}
	
	//datalist function end
	
	
    render() {
		
        return (
                <div className="CustomFields">
   <Header pageHeading="Custom Fields" props={this.props} isLoading={this.state.isLoading}/>
   <div className="clearfix"></div>
   <div className="col-sm-12 p0 top_mrg_general top_mrg_general_custom">
      <div className="">
         <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
            <div className="col-xs-12 col-sm-12 col-md-12">
               <div className="clear10"></div>
               <a href={null}>
               <button type="button" className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm customfields_btn_plus_cusotm" data-toggle="modal" data-target="#myModal" onClick={this.onModalOpen}>+</button>
               </a>
               <div className="clearfix"></div>
               <div className="overflow_scroll_sm">
                  <table className="table table-bordered timecard_table custome_field_table">
                     <thead>
                        <tr>
                           <th width="11%" align="center">Description</th>
                           <th width="11%" align="center">Default Value</th>
                           <th width="11%" align="center">Type</th>
                           <th width="11%" align="center">Tag</th>
                           <th width="11%" align="center">Validation</th>
                           <th width="11%" align="center">Length</th>
                           <th width="11%" align="center">DB Field</th>
                           <th width="13%" align="center">&nbsp;</th>
                        </tr>
                     </thead>
                     <tbody>
							{this.state.customfields.map((customfield,key)=>
                                                        {
                                                        	var value = customfield.Value;
                                                        	if(value && customfield.Type==='Date'){
																var date = new Date(customfield.Value);
                                                        		value = moment(date).format("DD/MM/YYYY");
                                                        	}

                                                        return(
								<tr key={key}>
								   <td width="11%" align="center">{customfield.Desc}</td>
								   <td align="center">{value}</td>
								   <td width="11%" align="center">{customfield.Type}</td>
								   <td align="center">{customfield.Tag}</td>
								   <td align="center">{customfield.Validation}</td>
								   <td align="center">{customfield.Length}</td>
								   <td align="center">{customfield.DBField}</td>
								   <td align="center">
									  <div className="col-sm-4  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
										 <a href={null} className="cursorPointer" data-toggle="modal" data-target="#exampleModalCenter"  onClick={(event)=>this.delete_customfield_list(event,customfield.Guid)}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
											   <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"/>
											</svg>
										 </a>
									  </div>
									  <div className="col-sm-4  col-xs-6 p0 text-center timecard_edit6 pull-right">
										 <a href={null} className="cursorPointer" onClick={(event)=>this.get_customfield_single(event,customfield.Guid)}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
											   <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"/>
											</svg>
										 </a>
									  </div>
								   </td>
								</tr>
                                                                )}
                                                        )}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         <div className="clear10"></div>
      </div>
   </div>
   <div className="clear10"></div>

    <Modal show={this.state.modalOpen} onHide={this.closeModal} className="modal modal2 fade" role="dialog">

            <div className="modal-header">
               <button type="button" className="close customfields_OutlineNone" data-dismiss="modal" onClick={this.onModalClose}><img className="customfields_mt20" src={ic_clear_24px}  width="18" height="18" alt="" /></button>
               <h4 className="modal-title modal_customefield">Custom Fields</h4>
            </div>
			<form onSubmit={(e)=>this.submitHandler(e)}>
            <div className="modal-body label_modal_custom">
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Description:</div>
                  <div className="col-sm-9 col-md-9">
						<input name="" type="text" value={this.state.description} onChange={(e)=>this.descriptionHandler(e)} />
						{(this.state.description_error)?<p className="error validation ">This field is required  </p>:''}

				  </div>
               </div>

			   <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Type:</div>
                  <div className="col-sm-9 col-md-9">
				  <select name="" value={this.state.type} onChange={(e)=>this.typeHandler(e)}>
					<option value="">Select Type</option>
					<option value="String">String</option>
					<option value="Text">Text</option>
					<option value="Numeric">Numeric</option>
					<option value="Drop List">Drop List</option>
					<option value="Date">Date</option>
					<option value="File">File</option>
				  </select>
				  {(this.state.type_error)?<p className="error validation ">This field is required  </p>:''}

				  </div>
               </div>
{ this.state.isFile?"":
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Default Value:</div>
                  <div className={(this.state.isDroplist)?"col-sm-9 col-md-9":"col-sm-9 col-md-9"}>
				  {(this.state.isString)?
				  <React.Fragment>
					<input name="" type="text" value={this.state.defaultValue} onChange={(e)=>this.defaultValueHandler(e)} />
					{(this.state.defaultValue_error)?<p className="error validation ">This field is required  </p>:''}
				  </React.Fragment>
				  :''}
				{(this.state.isNumber)?
				  <React.Fragment>
					<input name="" type="text" value={this.state.defaultValue} onChange={(e)=>this.defaultValueHandler(e)} />
					{(this.state.numberdefaultValue_error)?<p className="error validation ">This field should be Numeric  </p>:''}
				  </React.Fragment>
				 :''}

				  {(this.state.isDate)?
				  <React.Fragment>
					<DatePicker id="showDateFrom"
                        selected={this.state.startDate}
                        onChange={this.handleChange.bind(this)}
                        dateFormat="DD/MM/YYYY"
                        />
					{(this.state.datedefaultValue_error)?<p className="error validation ">This field should be Date  </p>:''}
				  </React.Fragment>
				 :''}


				  {(this.state.isDroplist)?
				  <React.Fragment>
					<div className="display_flex">
					<select  value={this.state.defaultValue} onChange={(e)=>this.defaultValueHandler(e)} >
						<option value=''>Select Option</option>
						{this.state.datalist.map((datalst,key)=>{
							return(
								<option value={datalst.value}>{datalst.option}</option>
							)
						}
						)}


					</select>

				   <a href={null} className="btn_margin_left">
					 <button type="button" className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm customfields_btn_plus_cusotm" data-toggle="modal" data-target="#myModal" onClick={this.datalist_openModal}>+</button>
				   </a>
				   </div>
				   <div className="clearfix"></div>
				  {(this.state.defaultValue_error)?<p className="error validation ">This field is required  </p>:''}
				  </React.Fragment>
				 :''}

				  </div>
               </div>
}
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Tag:</div>
                  <div className="col-sm-9 col-md-9">
				  <input name="" type="text" value={this.state.tag} onChange={(e)=>this.tagHandler(e)} />
				  {(this.state.tag_error)?<p className="error validation ">This field is required  </p>:''}

				  </div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Validation:</div>
                  <div className="col-sm-9 col-md-9">
				  <input name="" type="text" value={this.state.validation} onChange={(e)=>this.validationHandler(e)}/>
				  {(this.state.validation_error)?<p className="error validation ">This field is required  </p>:''}

				  </div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">Length:</div>
                  <div className="col-sm-9 col-md-9">
				  <input name="" type="text" value={this.state.length} onChange={(e)=>this.lengthHandler(e)}/>
				  {(this.state.length_error)?<p className="error validation ">This field is required  </p>:''}

				  </div>
               </div>
               <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">DB Field:</div>
                  <div className="col-sm-9 col-md-9">
				  <input name="" type="text" value={this.state.db_field} onChange={(e)=>this.db_fieldHandler(e)}/>
				  {(this.state.db_field_error)?<p className="error validation ">This field is required  </p>:''}

				  </div>
               </div>
               <div className="col-sm-6">
                  <div className="btn_cance_save">
					<button type="submit" className="btn_save_pro"  data-dismiss="modal">Save</button>

                     <button type="button" className="btn_cancel_pro"  data-dismiss="modal" onClick={this.onModalClose}>Cancel</button>
                  </div>
               </div>
               <div className="clear20"></div>
            </div>
			</form>

	  </Modal>


	<Modal show={this.state.add_datalist} onHide={this.datalist_closeModal} className="modal modal2 fade" role="dialog">

            <div className="modal-header">
               <button type="button" className="close customfields_OutlineNone" data-dismiss="modal" onClick={this.datalist_closeModal}><img className="customfields_mt20" src={ic_clear_24px}  width="18" height="18" alt="" /></button>
               <h4 className="modal-title modal_customefield">Custom Fields</h4>
            </div>

            <div className="modal-body label_modal_custom">

			   <div className="overflow_scroll_sm">
                  <table className="table table-bordered timecard_table custome_field_table">
                     <thead>
                        <tr>
                           <th width="11%" align="center">Option</th>
                           <th width="11%" align="center">Value</th>

                           <th width="13%" align="center">&nbsp;</th>
                        </tr>
                     </thead>
                     <tbody>
							{this.state.datalist.map((datalst,key)=>
                                {

                                return(
										<tr key={key}>
										   <td width="11%" align="center">{datalst.option}</td>
										   <td align="center">{datalst.value}</td>

										   <td align="center">
											  <div className="col-sm-4  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
												 <a href={null} className="cursorPointer" data-toggle="modal" data-target="#exampleModalCenter"  onClick={(event)=>this.delete_datalist(event,key)}>
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
													   <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"/>
													</svg>
												 </a>
											  </div>
											  <div className="col-sm-4  col-xs-6 p0 text-center timecard_edit6 pull-right">
												 <a href={null} className="cursorPointer" onClick={(event)=>this.edit_datalist(event,key)}>
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
													   <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"/>
													</svg>
												 </a>
											  </div>
										   </td>
										</tr>
                                        )}
                                )}
                     </tbody>
                  </table>
				  {(this.state.datalist.length)?'':'Record not found'}
               </div>

               <div className="clear20"></div>
			   <h2>Add Option/Value</h2>
				<div>
					<div className="col-sm-12 p0 mrg_bot_custom10">
						<div className="col-sm-3 col-md-3 label_csutom_pop">Option:</div>
						<div className="col-sm-9 col-md-9"><input name="" type="text"  value={this.state.datalist_option} onChange={(e)=>this.option_change(e)}/></div>
						{(this.state.option_error)?<p className="error validation ">This field is required  </p>:''}

					</div>

						<div className="col-sm-12 p0 mrg_bot_custom10">
							<div className="col-sm-3 col-md-3 label_csutom_pop">Value:</div>
							<div className="col-sm-9 col-md-9"><input name="" type="text"  value={this.state.datalist_value} onChange={(e)=>this.value_change(e)}/></div>
							{(this.state.value_error)?<p className="error validation ">This field is required  </p>:''}

						</div>
						<div className="col-sm-6">
						  <div className="btn_cance_save">
							<button type="submit" className="btn_save_pro"   onClick={(e)=>this.add_datalist(e)}>Save</button>

							 <button type="button" className="btn_cancel_pro"  data-dismiss="modal" onClick={this.datalist_closeModal}>Cancel</button>
						  </div>
					   </div>

				</div>
               <div className="clear20"></div>
            </div>


	  </Modal>



	</div>
                );
    }
};
export default CustomFields;
