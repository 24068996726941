import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
//import { ToastContainer, toast } from "mdbreact";
import "./Login.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/logo.png";
import moment from "moment";
import "moment-timezone";
import slider_img1 from "../../assets/images/slider_img1.jpg";
import slider_img2 from "../../assets/images/slider_img2.jpg";
import slider_img3 from "../../assets/images/slider_img3.jpg";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import ip from "ip";
import $ from 'jquery';
import { toast } from 'react-toastify';
//const publicIp = require("react-public-ip");
var sessionToken = "";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoading: true,
      acctLocked: false,
      emailValid: false,
      passwordValid: false,
      companyValid: false,
      formErrors: {
        email: "",
        password: "",
        companyID: ""
      },
      formValid: "",
      isActive: "",
      LoginText: "Login",
      companyID: "",
      companyfound: false,
      resultCompanies: [],
      TermsOfUseLink: null,
      PrivacyPolicyLink: null,
      UserTypeAdmin: false,
      acctFirstLogin: false,
      firstAcctGuid: "",
      gettedUserForFirstTime: false,
      gettedUserForFirstTimeData: "",
      passwordChangedBefore: false,
      validEmailAndPassword: false,
      passwordChangedOnce: false,
      codeSendedBeforeAssWell: false,
      accessDate:'',
      accessDatePresend:false,
      validUserData:'',
      ipv4:null
    };

    if (this.props.isAuthenticated === true) {
      this.props.history.push("/dashboard");
    }
  }

  async componentDidMount() {

    Auth.signOut({global: true}).then((data) => {
        console.log("ll=>",data)
    }).catch((error) => {
        console.log("le=>",error);
    });
    try {
      await Auth.currentSession();
      //this.props.userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

/*    this.getLinks().then(data => {
      //console.log("Links data=>", data);
      if(data.status){
        data.result.map(item => {
            this.setState({ TermsOfUseLink: item.Url });
        })
      }
    });*/


    this.setState({
      isLoading: false
    });

    //const ipv4 = await publicIp.v4() || null;
    //this.setState({ ipv4: ipv4});
    $.getJSON("https://jsonip.com?callback=?", async(jsonip) => {
        this.setState({ ipv4: jsonip.ip});
    });
  }

  login(email, password) {
    return API.post("users", "/users/login", {
      body: {
        email: email,
        password: password
      }
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let companyValid = this.state.companyValid;
    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid
          ? ""
          : "Email should be username@domain.com";
        break;
      case "password":
        //passwordValid = value.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}/i);
        passwordValid = value.length > 4;
        fieldValidationErrors.password = passwordValid
          ? ""
          : "Password must contain 8 characters, at least 1 letter, 1 special character and 1 number";
        break;
      case "companyID":
        companyValid = value.length > 0;
        fieldValidationErrors.companyID = companyValid
          ? ""
          : "User must belong to a company";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid,
        companyValid: companyValid
      },
      this.validateForm
    );
  }
  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.companyValid,
      isActive:
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.companyValid
    });
  }
  handleUserInput(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [name]: value
      },
      () => {
        this.validateField(name, value);
      }
    );
  }
  makeid() {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 512; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  registerNewUser = async UserInfo => {
    var UserregisterResult = '';
    await Auth.signUp({
      username: UserInfo.emailAddress,
      password: UserInfo.password,
      attributes: {
        email: UserInfo.emailAddress,
        phone_number: UserInfo.mobileNumber,
        given_name: UserInfo.userLogin,
        "custom:role": UserInfo.userType
      }
    }).then(data => {
      UserregisterResult = data;
      this.setState({
        codeSendedBeforeAssWell : true
      })
    });
    //console.log("CreatedUserInCognito => ", UserregisterResult);
    const apiData = {
        updateableGuid: this.state.validUserData.Guid,
        countryCode: this.state.validUserData.countryCode,
        teamGroupToView: this.state.validUserData.teamGroupToView,
        lastLogOff: this.state.validUserData.lastLogOff,
        sendReminderEvery: this.state.validUserData.sendReminderEvery,
        FAuth: this.state.validUserData.twoFAuth,
        AcctDisabled: this.state.validUserData.AcctDisabled,
        AcctLockedout: this.state.validUserData.AcctLockedout,
        BadPWCount: this.state.validUserData.BadPWCount,
        Department: this.state.validUserData.Department,
        EmailAddress: this.state.validUserData.EmailAddress,
        EndAccess: this.state.validUserData.EndAccess,
        InactivityLockoutDays: this.state.validUserData.InactivityLockoutDays,
        Initials: this.state.validUserData.Initials,
        LastLogin: this.state.validUserData.LastLogin,
        LoginCount: this.state.validUserData.LoginCount,
        MobileNumber: this.state.validUserData.MobileNumber,
        PWCannotChange: this.state.validUserData.PWCannotChange,
        PWExpireDays: this.state.validUserData.PWExpireDays,
        PWLastChanged: this.state.validUserData.PWLastChanged,
        PWMustChange: this.state.validUserData.PWMustChange,
        PWNeverExp: this.state.validUserData.PWNeverExp,
        PWStrength: this.state.validUserData.PWStrength,
        Reminder: this.state.validUserData.Reminder,
        SignatureFile: this.state.validUserData.SignatureFile,
        StartAccess: this.state.validUserData.StartAccess,
        TeamGroup: this.state.validUserData.TeamGroup,
        UserLogin: this.state.validUserData.UserLogin,
        UserType: this.state.validUserData.UserType,
        password: this.state.validUserData.Password,
        Activity: this.state.validUserData.Activity,
        ApproverGroup: this.state.validUserData.ApproverGroup,
        Avatar: this.state.validUserData.Avatar,
        CompanyID: this.state.validUserData.CompanyID,
        EULA: this.state.validUserData.EULA,
        FirstName: this.state.validUserData.FirstName,
        InviteDate: this.state.validUserData.InviteDate,
        InviteStatus: this.state.validUserData.InviteStatus,
        IPList: this.state.validUserData.IPList,
        LastName: this.state.validUserData.LastName,
        MoileDeviceTokens: this.state.validUserData.MoileDeviceTokens,
        PWHistory: this.state.validUserData.PWHistory,
        Salt: this.state.validUserData.Salt,
        SignatureType: this.state.validUserData.SignatureType,
        TeamList: this.state.validUserData.TeamList,
        TenantGuid: this.state.validUserData.TenantGuid,
        userSub: UserregisterResult.userSub,
        passwordChangedOnce: false
      };
    await API.post("updatedynamouser", "/updatedynamouser", {
      body: apiData
    })
      .then(data => {
          //console.log('User Updated Successfully');
          this.setState({
            validUserData : apiData
          });
      })
      .catch(err => console.log("Problem in updating user", err));
  };

  handleResendConfirmationCode = async ()=> {
    this.setState({ isLoading: true });

    try {
      await Auth.resendSignUp(this.state.email);
      //alert('Code resent succsessfully. Please check your cell phone for SMS.');
      this.setState({ isLoading: false });
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  handleSubmit = async event => {
    this.setState({isLoading: true, LoginText: "Logging In ...", isActive: false});
    event.preventDefault();

    try {
      var user = await Auth.signIn(this.state.email, this.state.password);
      //console.log("login_response=>", user);
      //console.log("update-login-count =>", response);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        //const {requiredAttributes} = user.challengeParam;
        this.props.history.push("/change-password", {
          state: {
            email: this.state.email,
            password: this.state.password
          }
        });
      }else{
        const {attributes} = user;
        //console.log("attributes=>", attributes);
        if(attributes.phone_number_verified){
          try{
            var response = await this.props.postRequest("/users/validate-user-company", {email: this.state.email, cID: this.state.companyID});
            console.log("validate-user-company response=>", response);
            if(response.status){
              this.props.history.push("/add-company", {
                  state: {
                    resultCompanies: response.companies_list,
                    userData: response.user_data,
                    userTypeCheck: this.state.UserTypeAdmin,
                    companyID: this.state.companyID
                  }
              });
            }else{
              await this.signOut();
              this.setState({isLoading: false, LoginText:"Login", isActive : true});
              toast.error(response.error, { autoClose: 5000 });
            }
          }catch(err){
            await this.signOut();
            this.setState({isLoading: false, LoginText:"Login", isActive : true});
            toast.error(response.error, { autoClose: 5000 });
          }
        }else{
          this.props.history.push({
              pathname: "/VerifyCode",
              state: {
                email: this.state.email,
                companyID: this.state.companyID,
                userTypeCheck: this.state.UserTypeAdmin
              }
          });
        } 
      }
    
    }catch(err){
      console.log("user signin error=>", err);
      this.setState({isLoading: false, LoginText:"Login", isActive : true});
      toast.error("Invalid email or password", { autoClose: 5000 });
    }
  }


  signOut = async event => {
    Auth.signOut({global: true}).then((data) => {
        console.log("SignOut succsessfully.");
        localStorage.setItem('loggedIn', false);
        localStorage.removeItem('userName');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userLastLogin');
        localStorage.removeItem('SignatureTyped');
        localStorage.removeItem('Guid');
        localStorage.removeItem('currentIpAddress');
        localStorage.removeItem('MobileNumber');
        localStorage.removeItem('countryCode');
        localStorage.removeItem('userType');
        localStorage.removeItem('ApproverGroup');
        localStorage.removeItem('TenantGuid');
        localStorage.removeItem('companyID');
        localStorage.removeItem('UserLogin');
        this.userHasAuthenticated(false);
    }).catch((error) => {
        console.log("SignOut Error=>",error);
    });
  }

  handleSubmit2 = async event => {
    this.setState({
      isLoading: true,
      LoginText: "Logging In ...",
      isActive: false
    });
    event.preventDefault();
    console.log("login clicked...."); return false;
    try {

      await API.post(
        "validateuserwithemailpass",
        "/validateuserwithemailpass",
        {
          body: {
            userEmail: this.state.email,
            Password: this.state.password
          }
        }
      )
        .then(data => {
          //console.log("User Returned Successfully => ", data); return false;

          this.setState({
            accessDatePresend : data.result.Items[0].StartAccess === undefined ? false : true, 
            accessDate : data.result.Items[0].StartAccess !== undefined ? data.result.Items[0].StartAccess : '',
            validUserData : data.result.Items[0]
          })  
          //console.log("Access Date => ",data.result.Items[0].StartAccess);

          if (data.result.Items.length > 0) {
            var passwordChanged = data.result.Items[0].passwordChangedOnce;
            if(passwordChanged === undefined){
              passwordChanged = true;
            }
            this.setState({
              validEmailAndPassword: true,
              passwordChangedOnce: passwordChanged 
            });
          }
        })
        .catch(err => {
          console.log("Login Error=>",err);
        });

        if(this.state.accessDatePresend){
          // date check krni he bs or return kra dena he toast lga k
          var access_date= moment(this.state.accessDate).format("YYYY-MM-DD");
          var current_date = new moment().format("YYYY-MM-DD");
          //console.log("Access Date => " ,access_date);
          //console.log("Current Date => " ,current_date);
          if(current_date < access_date ){
            toast.error(`You can log in after ${access_date}`);
            this.setState({
              isLoading: false,
              LoginText:"Login",
              isActive : true
            })
            return false;
          }
        }

      if (this.state.validEmailAndPassword === true) {
        if (this.state.validUserData.userSub === false) {
          this.setState({
            acctFirstLogin: true,
            firstAcctGuid: this.state.validUserData.Guid
          });
        }
        if (this.state.acctFirstLogin === true) {
          this.setState({
            gettedUserForFirstTime: true
          });
        }
        if (this.state.gettedUserForFirstTime) {
          const data = this.state.validUserData;
          let UserInfo = {
            userType: data.UserType,
            userLogin: data.UserLogin,
            emailAddress: data.EmailAddress,
            initials: data.Initials,
            mobileNumber: data.MobileNumber,
            department: data.Department,
            teamGroup: data.TeamGroup,
            teamGroupToView: data.teamGroupToView ? data.teamGroupToView : null,
            password: this.state.password
          };
          await this.registerNewUser(UserInfo);
        }
            
            if (this.state.validUserData.AcctLockedout > 0) {
              toast.error(
                "Account blocked due to too many wrong attempts.Please contact admin for further details.",
                {
                  autoClose: 5000
                }
              );
              this.setState({
                isLoading: false,
                acctLocked: true
              });
            }
            //console.log("User Type => ", this.state.validUserData);
            if (this.state.validUserData.UserType === "Admin" || this.state.validUserData.UserType === "SysAdmin") {
              this.setState({
                UserTypeAdmin: true
              });
            }
          

        if (this.state.acctLocked === false) {
          const response = await this.login(
            this.state.email,
            this.state.password
          );
          //console.log("Response login=>", response); return false;
          if (response.status === false) {
            await API.post("updateuserbadpwcount", "/updateuserbadpwcount", {
              body: {
                userEmail: this.state.email,
                activity: {
                  Description: "badPasswordEntered",
                  IPAddress: this.state.ipv4,
                  Time: moment.tz(moment(), "Australia/Canberra"),
                  Type: "Bad Password Entered"
                }
              }
            }).then(data => {
              if (data.updatedBadPwCount > 2) {
                toast.error(
                  "Account blocked due to too many wrong attempts.Please contact admin for further details.",
                  {
                    autoClose: 5000
                  }
                );
              } else {
                toast.error("Invalid email or password", {
                  autoClose: 5000
                });
              }
            });
            this.setState({
              isLoading: false,
              LoginText: "Login",
              isActive: true
            });
          } else {
            try {
              await Auth.signIn(this.state.email, this.state.password);
              try {
                await API.post("updateusermetadata", "/updateusermetadata", {
                  body: {
                    Guid: response.data[0].Guid,
                    key: "LastLogin",
                    value: moment.tz(moment(), "Australia/Canberra")
                  }
                });
                try {
                  //Write Login Count API Here
                  await API.post(
                    "updateuserlogincount",
                    "/updateuserlogincount",
                    {
                      body: {
                        userEmail: this.state.email
                      }
                    }
                  );
                } catch (e) {
                  toast.error("Error In Updating User Registers", {
                    autoClose: 5000
                  });
                }

                //==========================================================
                try {
                  await API.post("updateuseractivity", "/updateuseractivity", {
                    body: {
                      userEmail: this.state.email,
                      activity: {
                        Description: "login",
                        IPAddress: this.state.ipv4,
                        Time: moment.tz(moment(), "Australia/Canberra"),
                        Type: "Login"
                      }
                    }
                  });
                } catch (e) {
                  toast.error("Error In Updating User Registers", {
                    autoClose: 5000
                  });
                }
                //==========================================================
              } catch (e) {
                toast.error("Error In Updating User Registers", {
                  autoClose: 5000
                });
              }
            } catch (e) {
              //console.log("Cognito Login Error=>", e);
              if (e.code === "UserNotFoundException") {
                this.setState({
                  isLoading: false,
                  LoginText: "Login",
                  isActive: true
                });
                toast.error("User Not Found In Cognito Pool", {
                  autoClose: 5000
                });
                return false;
              }else if (e.code === "NotAuthorizedException") {
                this.setState({
                  isLoading: false,
                  LoginText: "Login",
                  isActive: true
                });
                toast.error(e.message, {
                  autoClose: 5000
                });
                return false;
              } else if (e.code === "UserNotConfirmedException") {
                this.setState({
                  isLoading: false,
                  LoginText: "Login",
                  isActive: true
                });
                toast.error("You have unverified account. Please verify.", {
                  autoClose: 5000
                });
                let id = this.makeid();
                
                if(this.state.codeSendedBeforeAssWell === false){
                  this.handleResendConfirmationCode();
                }

                this.props.history.push({
                  pathname: "/VerifyCode",
                  search: "?exhibition_code=" + id,
                  state: {
                    verificationemail: this.state.email,
                    authToken: id,
                    pwd: this.state.password
                  }
                });
                return false;
              }
            }

            this.setState({
              response
            });
            if (!response.status) {
              toast.error("Invalid email or password", {
                autoClose: 5000
              });
            }
            //======success Case================================================
            else {
              await API.post("get-all-companies", "/get-all-companies", {
                body: {
                  cID: this.state.companyID
                }
              }).then(result => {
                  //console.log("Companies", result);
                  if (result.length === 0) {
                    this.setState({
                      companyfound: false
                    });
                  } else {
                    this.setState({
                      companyfound: true,
                      resultCompanies: result
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                });
              if (this.state.companyfound) {
                // console.log(response.data[0]);
                const last = response.data[0].LastName === null? "" : response.data[0].LastName;
                const userName = response.data[0].FirstName + " " + last;
                const userEmail = response.data[0].EmailAddress;
                const userType = response.data[0].UserType;
                const userLastLogin = response.data[0].LastLogin;
                const MobileNumber = response.data[0].MobileNumber;
                const Guid = response.data[0].Guid;
                const SignatureTyped = response.data[0].SignatureType;
                const currentIpAddress = this.state.ipv4;
                const ApproverGroup = response.data[0].ApproverGroup;
                const TenantGuid = response.data[0].TenantGuid;
                const UserLogin = response.data[0].UserLogin;
                this.props.userHasAuthenticated(true);
                //userdata = response.data;
                if (typeof Storage !== "undefined") {
                  localStorage.setItem("loggedIn", true);
                  localStorage.setItem("userName", userName);
                  localStorage.setItem("userEmail", userEmail);
                  localStorage.setItem("userLastLogin", userLastLogin);
                  localStorage.setItem("SignatureTyped", SignatureTyped);
                  localStorage.setItem("Guid", Guid);
                  localStorage.setItem("currentIpAddress", currentIpAddress);
                  localStorage.setItem("MobileNumber", MobileNumber);
                  localStorage.setItem("userType", userType);
                  localStorage.setItem("ApproverGroup", ApproverGroup);
                  localStorage.setItem("TenantGuid", TenantGuid);
                  localStorage.setItem('companyID', this.state.companyID);
                  localStorage.setItem('UserLogin', UserLogin);
                }

                if (this.state.passwordChangedOnce === true) {
                  this.props.history.push("/add-company", {
                    state: {
                      resultCompanies: this.state.resultCompanies,
                      userTypeCheck: this.state.UserTypeAdmin
                    }
                  });
                } else {
                  toast.error('You need to change password for first login');
                  this.props.history.push({
                    pathname: "/forget-password-step-1",
                    sendedEmail: this.state.email,
                    fromRoute: "verifyCode"
                  });
                }
              } else {
                toast.error("Company Not Found");
              }
            }
          }
        }
      } else {
        toast.error("Invalid Username or Password");
        this.setState({
          isLoading:false,
          LoginText: "Login",
          isActive : true
        })
        return false;
      }
    } catch (e) {
      toast.error(e, {
        autoClose: 5000
      });
    }
    this.setState({
      isLoading: false,
      acctLocked: false,
      LoginText: "Login",
      isActive: true
    });
  };

  render() {
    return (
      <div className="Login">
        {this.state.isLoading ? <div className="se-pre-con" /> : ""}
        <div className="col-sm-12 p0">
          <div className="col-sm-6 p0">
            <div className="col-sm-12 logo_login">
              <LinkContainer to="/">
                <img
                  href={null}
                  className="login_CursorPointer"
                  src={LOGO}
                  alt="LOGO"
                />
              </LinkContainer>
            </div>
            <div className="col-sm-12 login_h2">
              <h2>Welcome to Digital-Forms</h2>
            </div>
            <div className="col-sm-12 noPadding">
              <div className="login_box">
                <form onSubmit={this.handleSubmit}>
                  <input
                    autoFocus
                    name="email"
                    onChange={event => this.handleUserInput(event)}
                    id="email"
                    type="text"
                    placeholder="User Name"
                  />
                  <input
                    autoFocus
                    name="companyID"
                    type="text"
                    onChange={event => this.handleUserInput(event)}
                    placeholder="Company ID"
                  />
                  <input
                    autoFocus
                    name="password"
                    onChange={event => this.handleUserInput(event)}
                    type="password"
                    placeholder="Password"
                  />
                  <div className="col-sm-12 p0 text-right login_forgot">
                    <LinkContainer to="/forget-password-step-1">
                      <a href={null}>Forgot Password?</a>
                    </LinkContainer>
                  </div>
                  <div className="clearfix" />
                  <button
                    href={null}
                    type="submit"
                    className={
                      "btn_login login_CursorPointer login_width100Percent" +
                      (this.state.isActive ? "" : " login_notActive")
                    }
                  >
                    {this.state.LoginText}
                  </button>
                </form>
                <div className="clearfix" />
                <div className="col-sm-12 p0 terms_label">
                  I accept the{" "}
                  <strong>
                    <a href={this.state.TermsOfUseLink}>Terms of Use</a>
                  </strong>{" "}
                  and{" "}
                  <strong>
                    <a href={this.state.PrivacyPolicyLink}>Privacy Policy</a>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 noPadding">
            <div className="login_right_area">
              <div
                id="myCarousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    className="active"
                  />
                  <li data-target="#myCarousel" data-slide-to="1" />
                  <li data-target="#myCarousel" data-slide-to="2" />
                </ol>
                <div className="carousel-inner">
                  <div className="item active">
                    <img src={slider_img1} className="img-responsive" alt="" />
                    <div className="clear20" />
                    <h2>Welcome to Digital-Forms</h2>
                    <label>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever, Lorem Ipsum has been the industry's standard dummy
                      text ever
                    </label>
                  </div>
                  <div className="item">
                    <img src={slider_img2} className="img-responsive" alt="" />
                    <div className="clear20" />
                    <h2>Welcome to Digital-Forms</h2>
                    <label>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever, Lorem Ipsum has been the industry's standard dummy
                      text ever
                    </label>
                  </div>
                  <div className="item">
                    <img src={slider_img3} className="img-responsive" alt="" />
                    <div className="clear20" />
                    <h2>Welcome to Digital-Forms</h2>
                    <label>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever, Lorem Ipsum has been the industry's standard dummy
                      text ever
                    </label>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="login_terms">
                @ Copyright TPH Technologies 2018
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}
export default Login;