import React, { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
const CustomCodeList = (props) => {
  const [Startdate, setStartdate] = useState("");
  const [endDate, setendDate] = useState("");
  var test = props.customCode.sort((a, b) => (a.order > b.order ? 1 : -1));
  console.log("++>>>", test);
  return (
    <tr>
      <td
        align="center"
        contentEditable="true"
        onBlur={(event) => {
          props.updateCellsValue(event, props.RowIndex, "cell_code");
        }}
      >
        {props.customCode["CustomCode"]
          ? props.customCode["CustomCode"]
          : "N/A"}
      </td>
      <td
        align="center"
        contentEditable="true"
        onBlur={(event) => {
          props.updateCellsValue(event, props.RowIndex, "cell_description");
        }}
      >
        {props.customCode["Desc"] ? props.customCode["Desc"] : "N/A"}
      </td>

      {props.customCode.map((obj, i) => {
        if (obj.Prompt === "amount") {
          if (obj.Hide) {
            return <td align="center">----</td>;
          } else {
            if (obj.DefaultValue) {
              return <td align="center">{obj.DefaultValue}</td>;
            } else {
              return <td align="center">0</td>;
            }
          }
        }

        if (obj.Prompt === "capped_amount") {
          if (obj.Hide) {
            return <td align="center">---</td>;
          } else {
            if (obj.DefaultValue) {
              return (
                <td
                  align="center"
                  contentEditable="true"
                  onBlur={(event) => {
                    props.updateCellsValue(event, props.RowIndex, "capped_amount");
                  }}
                >
                  {obj.DefaultValue}
                </td>
              );
            } else {
              return <td align="center">0</td>;
            }
          }
        }
        if (obj.Prompt === "startDate") {
          if (obj.Hide) {
            return <td align="center">----</td>;
          } else {
            if (obj.DefaultValue && props.Amount>0) {
              return (
                <td align="center" className="CustomCodeList_DatePicker">
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    placeholderText={moment(obj.DefaultValue).format(
                      "DD/MM/YYYY"
                    )}
                    selected={moment(obj.DefaultValue)}
                    onChange={(date) => {
                      props.onChangeFromDateInline(
                        date,
                        props.DocumentIndex,
                        props.RowIndex
                      );
                    }}
                  />
                </td>
              );
            } else {
              return <td align="center">----</td>;
            }
          }
        }
        if (obj.Prompt === "endDate") {
          if (props.customCode["TBA"]) {
            return <td align="center">TBA</td>;
          } else {
            if (obj.Hide) {
              return <td align="center">----</td>;
            } else {
              if(obj.DefaultValue && props.Amount>0)
              {
              return (
                <td align="center" className="CustomCodeList_DatePicker">
                  <DatePicker
                    dateFormat="DD/MM/YYYY"
                    placeholderText={moment(obj.DefaultValue).format(
                      "DD/MM/YYYY"
                    )}
                    selected={moment(obj.DefaultValue)}
                    onChange={(e_date) => {
                      props.onChangeToDateInline(
                        e_date,
                        props.DocumentIndex,
                        props.RowIndex
                      );
                    }}
                  />
                </td>
              );}
              else{
                return <td align="center">----</td>
              }
            }
          }
        }
        if (obj.Prompt === "chart_code") {
          if (obj.Hide) {
            return <td align="center">----</td>;
          } else {
            if (obj.DefaultValue) {
              return (
                <td
                  align="center"
                  contentEditable="true"
                  onBlur={(event) =>
                    props.updateCellsValue(event, props.RowIndex, "chart_code")
                  }
                >
                  {obj.DefaultValue}
                </td>
              );
            } else {
              return <td align="center">N/A</td>;
            }
          }
        }
        if (obj.Prompt === "calc_days") {
          if (obj.Hide) {
            return <td align="center">----</td>;
          } else {
            if (obj.DefaultValue) {
              return <td align="center">{obj.DefaultValue}</td>;
            } else {
              return <td align="center">0</td>;
            }
          }
        }
        if (obj.Prompt === "calc_weeks") {
          if (obj.Hide) {
            return <td align="center">----</td>;
          } else {
            if (obj.DefaultValue) {
              return <td align="center">{obj.DefaultValue}</td>;
            } else {
              return <td align="center">0</td>;
            }
          }
        }
      })}
      {/* {props.customCode[4].Hide? (
        <td align="center">----</td>
      ) : props.customCode[4] ? (
        <td align="center">{props.customCode[4].DefaultValue}</td>
      ) : (
        <td>0</td>
      )}
      {props.customCode[5].Hide? (
        <td align="center">---</td>
      ) : props.customCode[5] ? (
        <td
          align="center"
          contentEditable="true"
          onBlur={(event) => {
            props.updateCellsValue(event, props.RowIndex, 5);
          }}
        >
          {props.customCode[5].DefaultValue}
        </td>
      ) : (
        <td>0</td>
      )}

      {props.customCode["6"].Hide ? (
        <td align="center">----</td>
      ) : props.customCode[4].DefaultValue > 0 && props.customCode["6"] ? (
        <td align="center" className="CustomCodeList_DatePicker">
          <DatePicker
            dateFormat="DD/MM/YYYY"
            placeholderText={moment(props.customCode["6"].DefaultValue).format(
              "DD/MM/YYYY"
            )}
            selected={moment(props.customCode["6"].DefaultValue)}
            onChange={(date) => {
              props.onChangeFromDateInline(
                date,
                props.DocumentIndex,
                props.RowIndex
              );
            }}
          />
        </td>
      ) : (
        <td align="center">----</td>
      )}
      {
      props.TBA==="checked"?( <td align="center">TBA</td>):(
      props.customCode[7].Hide || props.customCode[4].DefaultValue===0 ? (
        <td align="center">----</td>
      ) : props.customCode[4].DefaultValue > 0 && props.customCode[7] ? (
        <td align="center" className="CustomCodeList_DatePicker">
          <DatePicker
            dateFormat="DD/MM/YYYY"
            placeholderText={moment(props.customCode[7].DefaultValue).format(
              "DD/MM/YYYY"
            )}
            selected={moment(props.customCode[7].DefaultValue)}
            onChange={(e_date) => {
              props.onChangeToDateInline(
                e_date,
                props.DocumentIndex,
                props.RowIndex
              );
            }}
          />
          {/* {moment(props.customCode[7].DefaultValue).format("DD/MM/YYYY")} */}
      {/* </td>
      ) : (
        <td align="center">----</td>
      ))}
      {props.customCode[13].Hide? (
        <td align="center">----</td>
      ) : props.customCode[13] ? (
        <td align="center">
          {props.customCode[13].DefaultValue
            ? props.customCode[13].DefaultValue
            : "0"}
        </td>
      ) : (
        <td></td>
      )}
      {props.customCode[12].Hide? (
        <td align="center">----</td>
      ) : props.customCode[12] ? (
        <td align="center">
          {props.customCode[12].DefaultValue
            ? props.customCode[12].DefaultValue
            : "0"}
        </td>
      ) : (
        <td></td>
      )}
      {props.customCode[9].Hide? (
        <td align="center">----</td>
      ) : props.customCode[9] ? (
        <td
          align="center"
          contentEditable="true"
          onBlur={(event) =>
            props.updateCellsValue(event, props.RowIndex, 9)
          }
        >
          {props.customCode[9].DefaultValue
            ? props.customCode[9].DefaultValue
            : "N/A"}
        </td>
      ) : (
        <td></td> */}
      {/* )}  */}
      <td align="center">
        <div className="col-xs-12 col-sm-8 col-md-8 pad-0 ml-35">
          <label className="check_box_pop">
            <input
              type="checkbox"
              value={props.TBA}
              checked={props.TBA}
              onChange={(event) => {
                props.updateCellsValue(
                  props.customCode["TBA"],
                  props.RowIndex,
                  "TBA"
                );
              }}
            />
            <span className="checkmark report_checkmark new-chekbox"></span>
          </label>
        </div>
      </td>

      <td align="center">
        <div className="col-sm-12 col-xs-12 p0 text-center id_custom_list_edit_pencil">
          <a
            href={null}
            data-documentindex={props.DocumentIndex}
            data-rowindex={props.RowIndex}
            className="cursorPointer ml-0"
            data-toggle="modal"
            onClick={props.handleModalOpen}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="1656.776 299 17.515 18.003"
            >
              <path
                id="ic_create_24px"
                className="cls-1"
                d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                transform="translate(1653.776 296.002)"
              ></path>
            </svg>
          </a>
        </div>
      </td>
    </tr>
  );
};
export default CustomCodeList;
