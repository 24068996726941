import React, { Component } from 'react';
import "../../assets/css/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css";
class PdfDetail extends Component {
  constructor(props) {
        super(props);}
     componentDidMount(){
                
    var previewData=localStorage.getItem('getreport');
                    
                console.log('Creating the report viewer with default options');
                var viewer = new window.Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);

                console.log('Creating a new report instance');
                var report = new window.Stimulsoft.Report.StiReport();

                console.log('Load report from url');
                report.loadFile('/reports/DF_Detailed_Report.mrt');

report.dictionary.databases.clear();
						    report.regData("DF_Report_Example_Summary", "DF_Report_Example_Summary", previewData);

                console.log('Assigning report to the viewer, the report will be built automatically after rendering the viewer');
                viewer.report = report;

                console.log('Rendering the viewer to selected element');
                viewer.renderHtml('viewer');
          }
  render() {
      
    return (
      <div id="viewer"></div>
    );
  }
}

export default PdfDetail;
