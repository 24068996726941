import React from 'react';
const CustomField = (props) => {

    let settings = props.settings;
    let Type  = props.Type;
    //let Value = props.Value;
    let Promt = props.Promt;
    //let DefaultValue = (props.DefaultValue) ? props.DefaultValue : "";
    let Name = props.Name;
    let Tag = props.Tag;
    let RequiredEle = props.Required? <p className={"error validation " + (props.customFormErrors ? '' : 'displayNone')}>{props.customFormErrors}</p> : "";
    
    if(Type === "Drop List"){
      return(
        <div>
          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">{Promt}:</div>
          <div className="col-sm-7 col-md-8">
              <div className="col-xs-12 p0">
                  <div className="col-xs-12 col-sm-6 p0">
                      <select style={(settings !== '' ? {'textTransform':settings} : {'textTransform':'capitalize'})} name={Name} value={props.Value || ''} onChange={(event) => props.onChange(event,props.Index,props.Tag)} onBlur={props.Tag? (event) => props.onBlur(event, props.Tag) : ""}  className="id_form_detail_select form_margin_zero" >
                         <option value="">Select</option>
                         {props.DataList.length > 0 ?
                             <React.Fragment>
                                 {props.DataList.map((data, key) => {

                                     return (
                                         <option key={key} value={data.value}>{data.option}</option>
                                     )
                                 })}
                             </React.Fragment>
                             : ""
                         }
                      </select>
                      {RequiredEle}
                  </div>
              </div>
          </div>
          <div className="clearfix"></div>
        </div>
      )
    }else if(Type === "Text"){
      return (
        <div>
          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">{Promt}:</div>
          <div className="col-sm-7 col-md-8">
              <div className="col-xs-12 p0">
                  <div className="col-xs-12 col-sm-6 p0">
                      <textarea style={(settings !== '' ? {'textTransform':settings} : {'textTransform':'capitalize'})} name={Name} value={props.Value || ''}  onChange={(event) => props.onChange(event,props.Index, props.Tag)}  onBlur={props.Tag? (event) => props.onBlur(event, props.Tag) : ""} className="form_detail_iput form_margin_zero" />
                      {RequiredEle}
                  </div>
              </div>
          </div>
          <div className="clearfix"></div>
        </div>
      )

    }else if(Type === "String"){
      return (
        <div>
          <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">{Promt}:</div>
          <div className="col-sm-7 col-md-8">
              <div className="col-xs-12 p0">
                  <div className="col-xs-12 col-sm-6 p0">
                      <input type="text" style={(settings !== '' ? {'textTransform':settings} : {'textTransform':'capitalize'})} name={Name} value={props.Value || ''} onChange={(event) => props.onChange(event,props.Index, props.Tag)}  onBlur={props.Tag? (event) => props.onBlur(event, props.Tag) : ""} className="form_detail_iput form_margin_zero"  />
                      {RequiredEle}
                  </div>
              </div>
          </div>
          <div className="clearfix"></div>
        </div>
      )

    }else{
        return (
      <div>
        <div className="col-sm-4 col-md-3  label_dataentry label_form_margin">{Promt}:</div>
        <div className="col-sm-7 col-md-8">
            <div className="col-xs-12 p0">
                <div className="col-xs-12 col-sm-6 p0">
                    <input type={Type} style={(settings !== '' ? {'textTransform':settings} : {'textTransform':'capitalize'})} name={Name} value={props.Value || ''} onChange={(event) => props.onChange(event,props.Index, props.Tag)}  onBlur={props.Tag? (event) => props.onBlur(event, props.Tag) : ""} className="form_detail_iput form_margin_zero"  />
                    {RequiredEle}
                </div>
            </div>
        </div>
        <div className="clearfix"></div>
      </div>
    )
  }
};
export default CustomField;
