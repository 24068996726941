import React, { Component } from "react";

import "./AddEmailTemplates.css";
import "../../assets/css/style.css";
import Header from "../Common/Header/Header";
import LOGO from "../../assets/images/logo2.png";
import ARROW from "../../assets/images/arrow_back_from.png";
import { LinkContainer }from "react-router-bootstrap";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { API} from "aws-amplify";
import { Storage } from 'aws-amplify';
import uploadLoader from "../../assets/images/Preloader.gif";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";

import async from 'async';
import $ from 'jquery';


window.jQuery = $;
window.$ = $;
global.jQuery = $;


// localStorage.setItem('loggedIn', true);
// localStorage.setItem('userName', userName);
// localStorage.setItem('userEmail', userEmail);
// localStorage.setItem('userLastLogin', userLastLogin);
// localStorage.setItem('MobileNumber', MobileNumber);

class AddEmailTemplates extends Component{
	constructor(props) {
		super(props);
		this.updateContent = this.updateContent.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.afterPaste = this.afterPaste.bind(this);
		this.quillRef = null;
  		this.reactQuillRef = null;
		this.attachQuillRefs = this.attachQuillRefs.bind(this);
	
		this.state = {
			BodyText: "<p>Hi, I&#39;m completig your contract but I need you to fill in your details online.</p>"+
			"<p>&nbsp;</p>"+
			"<p>&nbsp;</p>"+
			"<p>&nbsp;</p>"+
			"<p>Please click {{ContactLink}} to login and add your details.<br />"+
			"NOTE: This link will expire in 7days. Please fill in your details as soon as possible!<br />"+
			"If you need any help with filling your details check out this link:</p>",
			BodyHTML: "Hi, I'm completig your contract but I need you to fill in your details online.   Please click {{ContactLink}} to login and add your details.NOTE: This link will expire in 7days. Please fill in your details as soon as possible!If you need any help with filling your details check out this link:",
			config: {},
			templateName: '',
			templateType: 'New Contract',
			flag : 0,
			flagcheckbox: 0,
			subject: '',
			name: '',
			description: '',

			default: true,
			private: false,

			formValid:false,

			subjectValid:false,

			formErrors:{
				name:"",
				subject:"",
				description:"",
			},

			isLoading: false,
			imageWorking:false,
			FileAttachments: [],
			FileAttachments_urls: [],
			documentAttachment: null,
			displayLogo:"",
			fakePath:uploadLoader,
			attachmentPath:"",
			imageUploadModalOpen: false,
			listAttachments: [],
			listAttachments_urls: [],
			emailTemplateList: [],


			placeHolders:[],
			singletemplate: {},

			singleuserguid:'',
			allTemplates:[],
			alreadyPresent: false
		}
	}
	
	componentDidMount(){
		
		this.attachQuillRefs();
		this.hideOnMosDive();
		$('.dropdown').hover(function(){ 
			$('.onMos').show(); 
		});
		 		  
		/*if(localStorage.getItem('userEmail')){
			this.getuserdetails(localStorage.getItem('userEmail'))
		}*/
		this.getAllTemplates();
		this.getplaceHolders();
		
		$(".modal").css('display', 'none');
	}

    getAllTemplates = async() => {
    	try{
        	let response = await this.props.postRequest("/email-templates/get-email-templates", {Type: "All"});
        	if(response.status){
        		this.setState({
					allTemplates : response.result
				}, () => {
					if(localStorage.getItem('EditableEmailTemplateGuid')){
						this.get_template_for_edit(localStorage.getItem('EditableEmailTemplateGuid'))
					}
				});
        	}
    	}catch(err){

    	};
    }

	componentDidUpdate(){
		this.attachQuillRefs();

	}
	
	hideOnMosDive(){
		$(document).click(function(e){
			// Check if click was triggered on or within #menu_content
			if( $(e.target).closest(".onMos").length > 0 ) {
				return false;
			}else{
			$('.onMos').hide(); 
			}
			// Otherwise
			// trigger your click function
		});
	}

	closeInsertPlaceholder(){
			$('.onMos').hide(); 
	}

	attachQuillRefs() {
		// Ensure React-Quill reference is available:
		if (typeof this.reactQuillRef.getEditor !== 'function') return;
		// Skip if Quill reference is defined:
		if (this.quillRef != null) return;
		
		const quillRef = this.reactQuillRef.getEditor();
		if (quillRef != null) this.quillRef = quillRef;
	  }

	  insertPlaceholders(e, text, link, url, name){
		e.preventDefault();
		var textt;
		if(link === true){
			textt = "{{ <a href="+ url +">"+ text +"</a> }}";
		}
		else{
			textt = "{{"+ name+ "}}";	
		}
		var range = this.quillRef.getSelection();
		let position = range ? range.index : 0;
		this.quillRef.insertText(position, textt)
	}


	onChange = (evt) =>{
		
		// var newContent = evt.editor.getData();
		this.setState({
			BodyText: evt
		})
		var HTMLText = this.strip(this.state.BodyText);
		this.setState({
			BodyHTML: HTMLText
		})
	}

	strip(html){
		var doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || "";
	 }

	   
	 
	
	


	  getTemplates(tempType) {
		this.setState({isLoading: true})
		let templateList = this.getAllTemplates(tempType);
		templateList.then((data) => {
			if (data.status) {
				this.setState({
					emailTemplateList: data.result
				})
				var defaultUsers = [];
				if(this.state.emailTemplateList.length > 0){
					this.state.emailTemplateList.forEach((emailtemplate, index) => {
						if(emailtemplate.Private === 0 && this.state.templateType === emailtemplate.Type){
							if(this.state.singleuserguid !== emailtemplate.TenantGUID){
								defaultUsers.push(emailtemplate.TenantGUID)
								this.setState({defaultusers: defaultUsers, isLoading: false})
							}
							if(emailtemplate.Private === 0 && this.state.templateType === emailtemplate.Type && this.state.singleuserguid === emailtemplate.TenantGUID){
								this.setState({flagcheckbox: 1, default: false, private: true, flag: 1, isLoading: false })
							}
						}else{
							this.setState({flagcheckbox: 0, default: true, private: false, flag: 0, isLoading: false})
						}
					})
					this.setState({isLoading: false})
				}else{
					this.setState({flagcheckbox: 0, default: true, private: false, isLoading: false, defaultusers: defaultUsers, flag: 0, isLoading: false})
				}
			} else {
				this.setState({
					isLoading: false
				})
			}
		}).catch( err => {
			console.error(err, err.stack);
		});
	}


	/*getAllTemplates(tempType) {
		return this.props.postRequest("/email-templates/get-email-templates", {Type: tempType});
	}*/

	getuserdetails = (email) => {
		var that = this;
		this.setState({
            isLoading: true
        });
        const user = this.getuser(email);
        user.then(data => {
        	if(data.status){
				this.setState({singleuserguid: data.result.guid, isLoading: false})
				//this.getTemplates(this.state.templateType);
        	}else{
        		this.setState({isLoading: false});
        	}
		}).catch(
            err => {
				console.error(err, err.stack);
				setTimeout(function(){
					that.setState({
						isLoading: false
					});
				}, 1000);
			});
	}

	getuser(email){
		return this.props.postRequest("/users/get-by-email", {userEmail: email});
	}

	get_template_for_edit = (id) => {
		this.setState({ isLoading: true });
        const template = this.state.allTemplates.find(el => el.Guid === id);
        if(template){
        	this.setState({
                singletemplate: template,
				isLoading: false,
				FileAttachments: template.Attachments,
				BodyHTML: template.BodyHTML,
				BodyText: template.BodyText,
                default: (template.Default === 1 ? true : false),
                private: (template.Private === 1 ? true : false),
				description: template.Desc? template.Desc : "",
				templateName: template.TName,
				subject: template.Subject,
				name: template.Name? template.Name: "",
				templateType: template.Type,
				defaultusers: template.DefaultUsers
			});

			if(this.state.subject !== ""){
				this.setState({subjectValid: true})
			}
			//this.set_template_urls();
        }else{
        	this.setState({ isLoading: false });
        }
	}

	set_template_urls(){
		let selected_files_urls = this.state.FileAttachments_urls
		this.state.FileAttachments.forEach(attachment => {
			Storage.get(attachment.url, { level: 'private' })
			.then(result => {
				this.setState({
					attachmentPath: result
				})
				selected_files_urls.push(this.state.attachmentPath);
				this.setState({
					FileAttachments_urls: selected_files_urls,
				})
			})
			.catch(err => console.log(err));
		})
	}

	getplaceHolders = async () => {
		var that = this
        this.setState({ isLoading: true });
        const placeholders = await this.getallplaceHolders();
        if(placeholders.status){
        	this.setState({
                placeHolders: placeholders.result,
                isLoading: false
			});
        }else{
        	this.setState({isLoading: false});
        }

	}

    getallplaceHolders() {
        return this.props.postRequest("/email-placeholders/get-placeholders-list", {});
    }

	handleChange(e){

		let name = e.target.name;
		let value = e.target.value;
		this.setState({
			[name]: value,
		},
		() => {
			this.validateField(name, value)
		});
	}

	handledropdownChange(e){
		let name = e.target.name;
		let value = e.target.value;
		this.setState({
			[name]: value
		},
		() => {
			this.validateField(name, value)
		});
		//this.getTemplates(value);
	}

	handleCheckBoxes(e){
		let name = e.target.name;
		if(name === 'default' && e.target.checked){
			this.setState({
				default: true
			});
		}else if(name === 'default' && !e.target.checked){
			this.setState({
				default: false
			});
		}
		if(name === 'private' && e.target.checked){
			this.setState({
				private: true,
                                flag : 1,
			})
		}
                else if(name === 'private' && !e.target.checked){
			this.setState({
				private: false,
                                flag : 0,
			})
		}
	}

	updateContent(newContent) {
		this.setState({
			BodyHTML: newContent
		})
		var HTMLText = this.strip(this.state.BodyHTML);
		this.setState({
			BodyText: HTMLText
		})
	}



	onBlur(evt){
		//console.log("onBlur event called with event info: ", evt);
	}

	afterPaste(evt){
		console.log("afterPaste event called with event info: ", evt);
	}

	onDrop(files) {
		return false;
		var thisobj = this;
	    setTimeout(function(){
            thisobj.setState({
                imageWorking:true
            });
		}, 300);

		var selected_files = this.state.listAttachments;
		var selected_files_urls = this.state.FileAttachments_urls;
		let runtime_selected_urls = this.state.listAttachments_urls

        files.forEach(file => {
            var file_name = 'project-attachments/' + new Date().getTime() + '-' + file.name;
            Storage.vault.put(file_name, file)
                .then(result => {
                    var uploadedObj = {
                        "ContentType": "UTF-8",
                        "DocDesc": "Timecard document",
                        "DoumentType": file.type,
                        "Encrypted": "No",
                        "filename": file.name,
                        "PWHash": "a7e7ef%^%*&(7ke834",
                        "Salt": "HFHHGVHJBJB",
                        "Timestamp": new Date().getTime(),
                        "url": result.key
					}
					selected_files.push(uploadedObj);
					    Storage.get(uploadedObj.url, { level: 'private' })
						.then(result => {
							this.setState({
								attachmentPath: result
							})
							selected_files_urls.push(this.state.attachmentPath);
							runtime_selected_urls.push(this.state.attachmentPath);
							this.setState({
								listAttachments_urls: runtime_selected_urls,
								FileAttachments_urls: selected_files_urls
							})
						})
						.catch(err => console.log(err));

					this.setState({
						listAttachments_urls: selected_files_urls,
						FileAttachments_urls: selected_files_urls,
                        listAttachments: selected_files,
						imageUploadModalOpen:true,
						imageWorking: false
					});
				}).catch(err => console.log(err));
		});
	}

	deleteuploadedAttachment(e, id, url){
		e.preventDefault();
		var that = this
		var result = window.confirm('Are you sure you want to delete?');
        if (result) {
			that.setState({isLoading: true})
			that.state.listAttachments_urls.forEach((file, index) => {
				if(that.state.listAttachments_urls.indexOf(file) === id){
					this.remove_image(url, function(result){
						if(result){
							var attachments = that.state.listAttachments;
							attachments.splice(id, 1);
							var attachment_urls = that.state.listAttachments_urls;
							attachment_urls.splice(id, 1);
							that.setState({
								listAttachments: attachments,
								listAttachments_urls: attachment_urls,
								isLoading: false
							})
							toast.success("Success! Attachment has deleted successfully.", {
								autoClose: 2000
							});
						} else{
							that.setState({isLoading: false})
							toast.failed("failed! Attachment deletion failed.", {
								autoClose: 2000
							});
						}
					})
				}
			});
		}
	}



	deleteAttachment(e, id, url){
		e.preventDefault();
		var that = this;
		var result = window.confirm('Are you sure you want to delete?');
        if (result) {
			that.setState({isLoading: true})
			that.state.FileAttachments_urls.forEach((file, index) => {
				if(that.state.FileAttachments_urls.indexOf(file) === id){
					this.remove_image(url, function(result){
						if(result){
							var attachments = that.state.FileAttachments;
							attachments.splice(id, 1);
							var attachment_urls = that.state.FileAttachments_urls;
							attachment_urls.splice(id, 1);
							that.setState({
								FileAttachments: attachments,
								FileAttachments_urls: attachment_urls,
								isLoading: false
							})
							toast.success("Success! Attachment has deleted successfully.", {
								autoClose: 2000
							});
						} else{
							that.setState({isLoading: false})
							toast.failed("failed! Attachment deletion failed.", {
								autoClose: 2000
							});
						}
					})
				}
			});
		}
	}

	remove_image=(url, callback)=>{
		Storage.remove(url, {level: 'private'})
		.then(result => callback(result))
		.catch(err => console.log(err));
	}


	addImage(event){
		event.preventDefault();
		if(this.state.listAttachments.length > 0){
			if(this.state.FileAttachments.length > 0){
				let alreadyattached = this.state.FileAttachments;
				let newattached = this.state.listAttachments;
				newattached.forEach(attachment =>{
					alreadyattached.push(attachment)
				})
				this.setState({
					FileAttachments: alreadyattached,
					listAttachments: [],
					listAttachments_urls: []
				})
			} else {
				this.setState({FileAttachments: this.state.listAttachments, listAttachments: [], listAttachments_urls: []})
			}
		}

		if(this.state.FileAttachments.length){
			this.onImageUploadModalClose();
		}
		// else{
		// 	alert("plese upload image to save.")
		// }
	}

	cancelattachment(e){
		e.preventDefault();
		var that = this;
                var attachments;
		if(this.state.FileAttachments.length <= 0){
			if(this.state.listAttachments.length > 0){
				 attachments = this.state.listAttachments;
				async.every(attachments, function (attachment, key, callback) {
					that.remove_image(attachment.url, function(result){
						if(result){
							attachments.splice(key, 1);
							toast.success("Success! Record has deleted successfully.", {
								autoClose: 1000
							});
							callback(null);
						} else{
							this.setState({isLoading: false})
						}
					})
				})
				this.setState({
					FileAttachments: [],
					FileAttachments_urls: [],
					listAttachments: attachments,
					listAttachments_urls: []
				})
			}
		} else if(this.state.FileAttachments.length > 0) {
			if(this.state.listAttachments.length > 0){
				 attachments = this.state.listAttachments;
				async.every(attachments, function (attachment, key, callback) {
					that.remove_image(attachment.url, function(result){
						if(result){
							attachments.splice(key, 1);
							toast.success("Success! Record has deleted successfully.", {
								autoClose: 1000
							});
							callback(null);
						} else{
							this.setState({isLoading: false})
						}
					})
				})
				this.setState({
					listAttachments: attachments,
					listAttachments_urls: []
				})
			}

		}
	}

	onImageUploadModalClose(){
		this.setState({
			imageUploadModalOpen: false,
		});
	}

	


	validateField(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let subjectValid = this.state.subjectValid;

		switch (fieldName) {
			case 'subject':
				subjectValid = value.length > 0;
				fieldValidationErrors.subject = subjectValid ? '' : 'This field is required';
				break;
			default:
				break;
		}
		this.setState({
			formErrors: fieldValidationErrors,
			subjectValid: subjectValid,
		}, this.validateForm);
	}

	validateForm() {
		this.setState({
			formValid: this.state.subjectValid
		});
	}

	addTemplateRequest  = async event => {
		event.preventDefault();

		if(this.state.subject !== ''){
			//console.log('going in here');
			await this.setState({
				subjectValid: true,
				formValid:true
			})
		}

		let subject = this.state.subjectValid;

		let formErrors = Object.assign({}, this.state.formErrors);

		this.setState({formErrors});
		await this.setState({
			formValid: (this.state.subjectValid)
		});
		//console.log(this.state.formValid)
		if(this.state.formValid){
			
			this.setState({
				isLoading: true,
				formValid: false,
				subjectValid:false
			});

			try {
				this.setState({
					isLoading : true
				});
				
				this.state.allTemplates.filter((item) =>{
					if(item.TenantGUID === localStorage.getItem('useableGuid') && item.Default === 1 && this.state.default === true && item.Type === this.state.templateType && localStorage.getItem('EditableEmailTemplateGuid') === null){
						console.log('Already present');
						this.setState({
							alreadyPresent : true
						})
					}
				});


				if(this.state.alreadyPresent){
					toast.error("You can't have multiple defaults for single template type")
					this.setState({
						isLoading: false,
						alreadyPresent: false	
					})
					return false;
				}

				const response = await this.createTemplate((this.state.default === true ? 1 : 0) ,this.state.FileAttachments ,this.state.templateName, this.state.defaultusers, this.state.templateType, this.state.flag, this.state.flagcheckbox, this.state.singleuserguid, this.state.BodyHTML, this.state.BodyText, this.state.name, this.state.subject, this.state.description);
				//console.log(response);
				if (response.status) {
					this.setState({
						isLoading : false
					})
					this.setState({
						FileAttachments:[],
						templateType: "",
						templateName: "",
						flag: 1,
						defaultusers: [],
						BodyText : "",
						name: "",
						subject: "",
						description: "",
						flagcheckbox: 1,
						Default: false,
						Private: true
					});
					

					if(localStorage.getItem('EditableEmailTemplateGuid')){
						setTimeout(this.props.history.push({
												pathname: '/email-templates',
												flagID:true,
											}),1000)
					}
					else{
						setTimeout(this.props.history.push({
												pathname: '/email-templates',
												flagstatus: true
											}),1000)
					}



					// this.props.history.push({
					// 	pathname: '/email-templates',
					// });
				}
			} catch (e) {
				toast.error(e, {
					autoClose: 5000
				});
				this.setState({
					isLoading: false
				});
			}

		}else{
			console.log("create template validation failed.");
		}
	}

	createTemplate(Default , FileAttachments , templateName, defaultusers, templateType, flag, flagcheckbox, singleuserguid, BodyHTML, BodyText, name, subject, description){
		
		if(localStorage.getItem('EditableEmailTemplateGuid')){
			return this.props.postRequest("/email-templates/create-template", {
					"Guid": localStorage.getItem('EditableEmailTemplateGuid'),
					"Attachments": FileAttachments,
					"BodyHTML": BodyHTML,
                    "Default": Default,
					"BodyText": BodyText,
					"DefaultUsers": defaultusers?defaultusers:[],
					"Desc": description,
					"TName": templateName,
                    "Name": name,
					"Private": flag,
					"TenantGUID": localStorage.getItem('useableGuid'),
					"Subject": subject,
					"Type": templateType,
					"User": localStorage.getItem('userName'),
					"Flag": flagcheckbox
				});
		} else{
			return this.props.postRequest("/email-templates/create-template", {
					"Attachments": FileAttachments,
					"BodyHTML": BodyHTML,
                    "Default": Default,
					"BodyText": BodyText,
					"DefaultUsers": defaultusers,
					"Desc": description,
					"TName": templateName,
                    "Name": name,
					"Private": flag,
					"TenantGUID": localStorage.getItem('useableGuid'),
					"Subject": subject,
					"Type": templateType,
					"User": localStorage.getItem('userName'),
					"Flag": flagcheckbox
				});
		}
	}

	cancelformSubmission(event){
		event.preventDefault();
		this.setState({
			FileAttachments : [],
			templateName : "",
			templateType : "",
			name : "",
			subject : "",
			description : "",
			BodyText : "",
			flag: 0
		})
		this.props.history.push('/email-templates');
	}

	render(){
		return (
			<div>
				<Header pageHeading="" showMobileMenu="1" props={this.props} isLoading={this.state.isLoading}/>
				<div className="clearfix"></div>
					<div className="col-sm-12 p0 top_mrg_general top_mrg_general_custom">
						<div className="">
							<div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 general_setting_hd">
							<LinkContainer to="/email-templates">
								<a href={null} className="form_temp_detail"><img src={ARROW} width="20" height="20" alt=""/> Email Template</a>
							</LinkContainer>
									<div className="col-xs-12 col-sm-12 col-md-12 p0">
										<div className="clear30"></div>
										<div className="clear10"></div>
										<div className="clearfix"></div>
										<div className="email_temp_box">
											<div className="col-sm-8">
												<div className="emailadd_left_boxarea">
												<div className="col-sm-3 mrg_top5">Template Name:</div>
													<div className="col-sm-3 col-md-4">
														<input name="templateName" className="name_w_email" value={this.state.templateName} type="text" onChange={(e) => this.handleChange(e)} />
													</div>
													<div className="clear20"></div>
													<div className="col-sm-3 mrg_top5">Template Type:</div>
														<div className="col-sm-3 col-md-4 mrg_top5">
														<select className="name_w_email_select" value={this.state.templateType} name="templateType" onChange={(e) => this.handledropdownChange(e)}>
															<option>New Contract</option>
															<option>Reminder</option>
															<option>Forms submitted</option>
															<option>Forms Approved</option>
                                                            <option>User Invite</option>
                                                            <option>External Party Approved</option>
														</select>
														</div>
														<div className="mrg_top5_labels">
															<div className="col-xs-7 col-sm-2 col-md-1">Default:</div>
															<div className="col-xs-5 col-sm-1">
																<label className="check_box_pop">
																	<input disabled={(this.state.flagcheckbox === 1) ? true : false} type="checkbox" name= "default" checked={this.state.default} onChange={(e) => this.handleCheckBoxes(e)}/>
																	<span className="checkmark left0_check"></span>
																</label>
															</div>
														</div>
														<div className="mrg_top5_labels">
														<div className="col-xs-7 col-sm-2 col-md-1">Private:</div>
															<div className="col-xs-5 col-sm-1">
																<label className="check_box_pop">
																	<input type="checkbox" name="private" checked={this.state.private} onChange={(e) => this.handleCheckBoxes(e)} />
																	<span className="checkmark left0_check"></span>
																 </label>
															</div>
														</div>
														<div className="clear20"></div>
														<div className="col-sm-12 p0">
														<div className="col-sm-3 mrg_top10">Attachment:</div>
														<div className="col-sm-3 col-md-4 text-left mrg_top5">
															<a href={null} className="email_add_file_btn pull-left CursorPointer" data-toggle="modal" data-target="#myModal_attachment_email">
																Add File
															</a>
														</div>
														<div className="clear30"></div>

														<table width="100%" border="0" cellSpacing="2" cellPadding="2" className="email_attach_table_area3">
															<thead>
																<tr>
																	<th className="email_attach_table_desc_pad" width="95%" align="left">Description</th>
																	<th width="5%" align="left"></th>
																</tr>
															</thead>

															<tbody>
																{this.state.FileAttachments.map((file,index) => {
																	return(
																	<tr className="custom_field_tab" key={index}>
																		<td>{file.filename}</td>
																		<td>
																			<div className="col-sm-12 col-xs-6 p0 pull-right text-center timecard_dele pull-right">
																				<a href={null} onClick={(e) => this.deleteAttachment(e, index, file.url)}>
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
																					<path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"></path>
																				</svg>
																				</a>
																			</div>
																		</td>
																	</tr>
																	)
																})}
															</tbody>
														</table>
													</div>
													<div className="clear5"></div>
													<hr/>
													<div className="clear5"></div>
														<div className="email_temp_add_box3">
															<div className="col-xs-5 col-sm-5 col-md-7 mrg_top5">Subject: <span className="email_temp_add_box3_spn">*</span></div>
																<div className="col-xs-7 col-sm-7 col-md-5">
																	<div className="dropdown">
																		<a href={null} className="email_btn_insert_place pull-right CursorPointer" type="button" data-toggle="dropdown">
																			Insert Placeholders
																		</a>
																		</div>
																		<ul className="dropdown-menu mrg_top_emiltep onMos dropdown-menu_emiltemp_place">
																		<a href={null} className="close_mrg_email" onClick={() => this.closeInsertPlaceholder()}><img className="CursorPointer pull-right" src={ic_clear_24px} width="15" height="15" alt="" /></a>
																		<h2>Insert Placeholders</h2>
																		Click to insert Placeholders in content which will dynamically get resolved into the appropriate data.
																		<div className="clear20"></div>

																		<div className="col-sm-12 p0">
																		<div className="insert_place_bg_w">
																		{this.state.placeHolders.map((placeHolder, index) => {
																			return(
																				<div className="btn_placeholders" key={index}>
																					<button  className="btn_email_temp_status CursorPointer" onClick={(e)=> this.insertPlaceholders(e,placeHolder.Text, placeHolder.Link, placeHolder.Url, placeHolder.Name)}>{placeHolder.Name}</button>
																				</div>
																			)
																		})}
																		<div className="clearfix"></div>
																		<div className="clear10"></div>
																	</div>
																</div>
																<div className="clear40"></div>
															</ul>
														
													</div>
													<div className="clear10"></div>
													<div className="col-sm-12"><input name="subject" value={this.state.subject} onChange= {(e) => this.handleChange(e)} type="text" />
													<p className={"error validation " + (this.state.formErrors.subject ? '': 'displayNone')}>{this.state.formErrors.subject}</p>
													</div>
													<div className="clear20"></div>
														<div className="col-sm-7">Name: <span className="email_temp_add_box3_spn"></span></div>
													<div className="clear10"></div>
													<div className="col-sm-12"><input name="name" value={this.state.name} onChange= {(e) => this.handleChange(e)} type="text" />
													<p className={"error validation " + (this.state.formErrors.name ? '': 'displayNone')}>{this.state.formErrors.name}</p>
													</div>
															<div className="clear20"></div>
														<div className="col-sm-7">Description: <span className="email_temp_add_box3_spn"></span></div>
													<div className="clear10"></div>
													<div className="col-sm-12"><input name="description" value={this.state.description} onChange= {(e) => this.handleChange(e)} type="text"  />
													<p className={"error validation " + (this.state.formErrors.description ? '': 'displayNone')}>{this.state.formErrors.description}</p>
													</div>
													<div className="clear20"></div>
														<div className="col-sm-7">Message: <span className="email_temp_add_box3_spn">*</span></div>
													<div className="clear10"></div>
													<div className="col-sm-12">
													{/* <ReactQuill 
														ref={(el) => { this.reactQuillRef = el }} 
														defaultValue={this.state.BodyText} 
														onChange={this.onChange}
														/> */}
														<div>
															<ReactQuill 
															ref={(el) => { this.reactQuillRef = el }}
															// theme={'snow'}
															onChange={this.onChange}
															modules={AddEmailTemplates.modules}
															// formats={Editor.formats}
															defaultValue={this.state.BodyText}
															value={this.state.BodyText || ''}
															placeholder={this.props.placeholder} />
														</div>
													
													</div>
													<div className="clear20"></div>
												</div>
											</div>
										</div>


										<div className="col-sm-4 p0">
											<div className="email_add2_rightbox">
												<div className="email_right_logo_label">
													<img src={LOGO} alt="" />
														Instructions
												</div>
											</div>
										</div>

									<div className="clearfix"></div>
									</div>
										<div className="clear40"></div>
										<div className="col-sm-6">
											<div className="btn_cance_save">
											<button type="button" className="btn_save_pro" onClick={(e) => this.addTemplateRequest(e)} data-dismiss="modal">Save</button>
											<button type="button" className="btn_cancel_pro" data-dismiss="modal" onClick={(event) => this.cancelformSubmission(event)}>Cancel</button>
										</div>
										</div>
										<div className="clear20"></div>
									</div>
								</div>
									<div className="clear10"></div>
								</div>
								</div>
								<div className="clear10"></div>


					<div id="myModal_attachment_email" className="modal fade" role="dialog">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-header">
									<button type="button" className="close" data-dismiss="modal"><img className="emailtemplates_mt22px" onClick={(event) => this.cancelattachment(event) } src={ic_clear_24px}  width="18" height="18" alt="" /></button>
									<h4 className="modal-title modal_customefield">
									Attachments
									</h4>
								</div>
								<div className="modal-body label_modal_custom attchment_bottom_h">
								<div className="clear20"></div>
									{(this.state.listAttachments_urls.length > 0) ?
										this.state.listAttachments_urls.map((attachment, index) => {
											return(
												<div className='attachmentlist' key={index}>
													<a href={null} onClick={(e) => this.deleteuploadedAttachment(e, index, this.state.listAttachments[index].url)}>
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
															<path id="ic_clear_24px" className="cls-1" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" transform="translate(-5 -5)"/>
														</svg>
													</a>
													<img className='attachmentinlist' src={attachment} alt="" />
												</div>
											)
									})
									 : <div><p>No Attachment Available</p><div className="clearfix"></div></div> }
									<div className="clearfix"></div>
									<div className="clear20"></div>
								<div className="clearfix"></div>
									<Dropzone onDrop={this.onDrop.bind(this)} className="drag_drop_box CursorPointer ">
										<div className="drag_drop_box">
											<svg xmlns="http://www.w3.org/2000/svg" className={(this.state.imageWorking) ? "displayNone" : ""} viewBox="8655 7398 52 34.667">
												<path id="ic_backup_24px" className="cls-1" d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z" transform="translate(8655 7394)"/>
											</svg>
											<img id="dropZoneImage" width="100" height="100" className={(this.state.imageWorking === false) ? "displayNone": ""}  src={this.state.fakePath} alt="Broken Logo"/>
											<div className="clear10"></div>
												{this.state.imageWorking === false ?  "Drag files in or click to upload" :"Uploading , Please Wait . . ."}
										</div>
									</Dropzone>
								<div className="clear20"></div>
								<div className="col-sm-12">
									<div className="btn_cance_save">
										<button type="button" className="btn_save_pro" data-dismiss="modal" onClick={(event) => this.addImage(event)}>Save</button>
										<button type="button" className="btn_cancel_pro" data-dismiss="modal" onClick={(e) => this.cancelattachment(e)}>Cancel</button>
									</div>
								</div>
						<div className="clear30"></div>
				</div>
			</div>
		</div>
	</div>
</div>
		)
	}
}


AddEmailTemplates.modules = {
    toolbar: {
        container:
        [
            [{ 'placeholder': ['[GuestName]', '[HotelName]'] }], // my custom dropdown
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                    // remove formatting button
           
        ],
        handlers: {
            "placeholder": function (value) { 
                if (value) {
                    const cursorPosition = this.quill.getSelection().index;
                    this.quill.insertText(cursorPosition, value);
                    this.quill.setSelection(cursorPosition + value.length);
                }
            }
        }
    }
}

export default AddEmailTemplates;
