import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import "./NotFound.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/logo.jpg";

class NotFound extends Component {
    render() {
        return (
                <div className="NotFound">
                    <div className="login_bg">
                        <div className="col-sm-12 p0">
                            <div className="col-sm-6 p0">
                                <div className="col-sm-12 error_page_logo text-center">
                                    <img src={LOGO} alt="" />
                                </div>
                            </div>
                            <div className="col-sm-6 login_right_area login_right_error4">
                                <div className="error_page_logo text-center height_100_right">
                                    <div className="label_right_error">404
                                        <span>This page in not found</span>
                                        <div className="clear20"></div>
                                        <LinkContainer to="/dashboard">
                                            <a href={null} className="btn_backto_home">Back to Home</a>
                                        </LinkContainer>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
                );
    }
}
;
export default NotFound;
