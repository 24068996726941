import React, { Component } from "react";
import moment from 'moment';
//======================================//
// ListItem AS A CHILD COMPONENT DEFINE //
// - - - - -  IN THIS SCOPE - - - - - - //
//--------------------------------------//
import tic_black from "../../../assets/images/tic_black.png";

export default class CustomFieldsListItem extends Component
{
	constructor(props)
	{
	    super(props);
		this.state = {
            customFieldsList: props.customFieldsList
        };
  	}

	componentWillReceiveProps(nextProps)
	{
	  	this.setState({ customFieldsList: nextProps.customFieldsList });
	}


	//=======================================//
	//  - - - RENDER LISTITEM COMPONENT - -  //
	//---------------------------------------//
	render()
	{
        if(typeof this.props.customFieldsList !== 'undefined' && this.props.customFieldsList.length > 0)
        {
            let currentCustomFieldsListList = this.props.customFieldsList;
            var customFieldsList = currentCustomFieldsListList.map((field, key) => {
							let value;

								if(field.Type==='Date'){

									if(field.Value){
										var  dateto = new Date(field.Value);

										value = moment(dateto).format("DD/MM/YYYY");
										//console.log(field.Value);
										}

								}else{
									value = field.Value;
									//value = 'sohail';
								}

                return (
                    <tr key={"j7h1o1"+key}>
                       <td align="left" valign="middle">{field.Desc}</td>
                       <td align="left" valign="middle">{value}</td>
                       <td align="left" valign="middle">{field.Type}</td>
                       <td align="left" valign="middle">{field.Length}</td>
                       <td align="left" valign="middle"><img src={(field.Required===true)?tic_black:"no"} width="18" height="13" alt=""/></td>
                       <td align="left" valign="middle">{field.Tag}</td>
                       <td align="center">
                          <div className="col-sm-2  col-md-1 col-xs-4 p0 pull-right text-center timecard_dele pull-right">
                             <a href={null} className="CursorPointer" onClick={(event) => this.props.removeField(event, key)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
                                   <path id="ic_delete_24px"  className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"></path>
                                </svg>
                             </a>
                          </div>
                          <div className="col-sm-2  col-md-1 col-xs-4 p0 text-center timecard_edit6 pull-right">
                             <a href={null} className="CursorPointer" onClick={(event) => this.props.editField(event, key)} data-toggle="modal">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
                                   <path id="ic_create_24px"  className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"></path>
                                </svg>
                             </a>
                          </div>
                       </td>
                    </tr>
                )
            });
            if(customFieldsList.length === 0)
            {

                 customFieldsList = <tr><td  colSpan="7">Record not found</td></tr>;
            }
        }
        else
        {
             customFieldsList = <tr><td  colSpan="7">Record not found</td></tr>;
        }


        return(
            <tbody>
            		{
            			customFieldsList
            		}
            </tbody>
        );
	}
};
