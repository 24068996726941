import React, { Component } from "react";
import ListItem from "./Components/ListItem";
import Header from "../Common/Header/Header";
import { toast } from 'react-toastify';

// - -   IMPORT STYLING   - -//
// - - - - - - - - - - - - - //
import "./CompanyView.css";

// - -  DEFINE CONSTANTS  - -//
// - - - - - - - - - - - - - //
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from 'jquery';
window.$ = $;
global.jQuery = $;

 var Modal = require('react-bootstrap-modal');
require('react-bootstrap-modal/lib/css/rbm-patch.css');

//============================================//
// COMPANYDETAIL AS A PARENT COMPONENT DEFINE //
// - - - - - -  IN THIS SCOPE - - - - - - - - //
//--------------------------------------------//
class CompanyView extends Component {
    constructor(props) {

        super(props);
        this.state = {
            readOnly:true,
            isLoading: true,
            company_id:atob(this.props.match.params.company_id),
            isActive: '',
            isEditModal: false,
            customFieldIndex: null,
            currentCompanyDetails:'',
            customFieldList: [],
            Prompt: "",
            Value:"",
            modalOpen: false,
            promptValid: false,
            valueValid: false,
            companyNameValid: false,
            companyIdValid: false,
            companyEmailValid: false,
            contractDistEmailValid: false,
            townValid: false,
            stateValid: false,
            postalCodeValid: false,
            phoneValid: false,
            Start: "",
            mealBreak: "",
            End: "",
            HideTravelTo: false,
            HideTravelFrom: false,
            formErrors: {
                companyNameValid: '',
                companyIdValid: '',
                companyEmailValid: '',
                contractDistEmailValid: '',
                townValid: '',
                stateValid: '',
                postalCodeValid: '',
                phoneValid: '',
            },
            formErrors2: {
                Prompt: '',
                Value: ''
            },
            formValid: "",
            formValid2: "",

        };
    }
    onModalClose(){
        this.setState({ modalOpen: false });
    }
    onModalOpen(){
        this.setState({ modalOpen: true });
    }
    
    async getCompanyDetail(){
        return this.props.postRequest("/companies/get-company",{ Guid: this.state.company_id });
    }

    componentDidMount() {
        $('.modal-backdrop').hide();
        $(".modal-open").css("overflow", "scroll");
        this.getCompanyDetail().then(data => {

            if(data.status){
                var result = data.result;
                this.setState({   
                    companyName:result.CompanyName,
                    companyNameValid:result.CompanyName.length>0,
                    companyEmail:result.EmailAddress,
                    companyEmailValid:result.EmailAddress.length>0,
                    contractDistEmail:result.ContractDistEmail,
                    contractDistEmailValid:result.ContractDistEmail.length>0,
                    town:result.Town,
                    townValid:result.Town.length>0,
                    state:result.StateRe,
                    stateValid:result.StateRe.length>0,
                    postalCode:result.PCode,
                    postalCodeValid:result.PCode.length>0,
                    phone:result.Phone,
                    phoneValid:result.Phone.length>0,
                    fax:result.Fax,
                    companyId:result.CompanyId,
                    companyIdValid:result.CompanyId.length>0,
                    isLoading: false,
                    customFieldList:result.CustomFields,
                    isActive: result.CompanyName.length>0 && result.CompanyId.length>0 && result.EmailAddress.length>0 && result.Country.length>0 && result.State.length>0 && result.State.length>0,
                    Start: result.Start? result.Start : null,
                    mealBreak: result.mealBreak? result.mealBreak : null,
                    End: result.End? result.End : null,
                    HideTravelFrom: result.TravelFromHide,
                    HideTravelTo: result.TravelToHide,
                });
            }else{
                console.log("company data=>", data);
            }
        });

    }
    
    componentWillUnmount() {
        $(".modal-open").css("overflow", "visible");
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let companyIdValid = this.state.companyIdValid;
        let companyNameValid = this.state.companyNameValid;
        let companyEmailValid = this.state.companyEmailValid;
        let contractDistEmailValid = this.state.contractDistEmailValid;
        let townValid = this.state.townValid;
        let stateValid = this.state.stateValid;
        let postalCodeValid = this.state.postalCodeValid;
        let phoneValid = this.state.phoneValid;
        switch (fieldName) {
            case 'companyName':
                companyNameValid = value.length > 0;
                fieldValidationErrors.companyNameValid = companyNameValid ? '' : "Please fill " + fieldName + " properly";
                break;
            case 'companyId':
                companyIdValid = value.length > 0;
                fieldValidationErrors.companyIdValid = companyIdValid ? '' : "Please fill " + fieldName + " properly";
                break;
            case 'companyEmail':
                companyEmailValid = value.length > 0;
                fieldValidationErrors.companyEmailValid = companyEmailValid ? '' : "Please fill " + fieldName + " properly";
                break;
            case 'contractDistEmail':
                contractDistEmailValid = value.length > 0;
                fieldValidationErrors.contractDistEmailValid = contractDistEmailValid ? '' : "Please fill " + fieldName + " properly";
                break;
            case 'town':
                townValid = value.length > 0;
                fieldValidationErrors.townValid = townValid ? '' : "Please fill " + fieldName + " properly";
                break;
            case 'state':
                stateValid = value.length > 0;
                fieldValidationErrors.stateValid = stateValid ? '' : "Please fill " + fieldName + " properly";
                break;
            case 'postalCode':
                postalCodeValid = value.length > 0;
                fieldValidationErrors.postalCodeValid = postalCodeValid ? '' : "Please fill " + fieldName + " properly";
                break;
            case 'phone':
                phoneValid = value.length > 0;
                fieldValidationErrors.phoneValid = phoneValid ? '' : "Please fill " + fieldName + " properly";
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            companyNameValid: companyNameValid,
            companyIdValid: companyIdValid,
            companyEmailValid: companyEmailValid,
            contractDistEmailValid: contractDistEmailValid,
            townValid: townValid,
            stateValid: stateValid,
            postalCodeValid: postalCodeValid,
            phoneValid: phoneValid
        }, this.validateForm);
    }

    validateField2(fieldName, value) {

        let modalFieldValidationErrors = this.state.formErrors2;
        let promptValid = this.state.Prompt;

        let valueValid = this.state.Value;
        switch (fieldName) {
            case 'Prompt':
                promptValid = value.length > 0;
                modalFieldValidationErrors.Prompt = promptValid ? '' : "Please fill " + fieldName + " properly";
                break;
            case 'Value':
                valueValid = value.length > 0;
                modalFieldValidationErrors.Value = valueValid ? '' : "Please fill " + fieldName + " properly";
                break;
            default:
                break;
        }
        this.setState({
            formErrors: modalFieldValidationErrors,
            promptValid: promptValid,
            valueValid: valueValid,
        }, this.validateForm2);
    }

    validateForm() {
        this.setState({
            formValid: this.state.companyNameValid && this.state.companyIdValid && this.state.companyEmailValid && this.state.contractDistEmailValid && this.state.townValid && this.state.stateValid && this.state.postalCodeValid && this.state.phoneValid,
            isActive: this.state.companyNameValid && this.state.companyIdValid && this.state.companyEmailValid && this.state.contractDistEmailValid && this.state.townValid && this.state.stateValid && this.state.postalCodeValid && this.state.phoneValid
        });
    }

    validateForm2() {
        this.setState({
            formValid2: this.state.promptValid && this.state.valueValid
        });
    }

    handleUserInput(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
                [name]: value
            },
            () => {
                this.validateField(name, value)
            });
    }

    handleModalInput(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
                [name]: value
            },
            () => {
                this.validateField2(name, value)
            });
    }
    editCustomFieldFromArray(event, id){
        this.setState({"customFieldIndex": id})
        event.preventDefault();
        this.setState({"isEditModal": true});
        var customFieldList = this.state.customFieldList;
        console.log(customFieldList);
        customFieldList = customFieldList[id];
        this.setState({
            Prompt: customFieldList.prompt,
            Value: customFieldList.value
        });
        this.onModalOpen();
    }
    addCustomFieldsInToArray(event){
        event.preventDefault();
        var dataObj = {
            "prompt": this.state.Prompt,
            "value": this.state.Value
        };
        var isEditModal = this.state.isEditModal;
        if(isEditModal){
            var customFieldIndex = this.state.customFieldIndex;
            var customFieldList = this.state.customFieldList;
            customFieldList[customFieldIndex] = dataObj;

            this.setState({
                "customFieldList": customFieldList,
                "customFieldIndex": null,
                "isEditModal":false,
                "Prompt": "",
                "Value": ""
            })
        }
        else{
             customFieldList = this.state.customFieldList;
            customFieldList.push(dataObj);
            this.setState({
                "customFieldList": customFieldList,
                "Prompt": "",
                "Value": ""
            })
        }

        this.onModalClose();
    }
    removeCustomFieldFromArray(event, id){
        event.preventDefault();
        var customFieldList = this.state.customFieldList;
        customFieldList.splice(id, 1);
        this.setState({
            "customFieldList": customFieldList
        })
    }

    render() {
        return (
            <div className="CompanyView">
                <Header pageHeading="" hideHomeButton="0" props={this.props} isLoading={this.state.isLoading}/>
                <div className="col-sm-12 p0 top_mrg_general">
                    <div className="col-sm-9 col-sm-offset-2 general_setting_hd">
                        <h2>Production Company Details</h2>
                        <div className="col-sm-12 p0 company_setting mrg_bot_smc displayNone">
                            <div className="col-xs-4 col-sm-3 col-md-3 col-lg-3 ">Coy Guid:</div>
                            <div className="col-xs-6 col-sm-6 col-md-6"> Lorem Ipsume </div>
                        </div>
                        <form >
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Company Name:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" value={this.state.companyName} className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly}  name="companyName" onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Company ID:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} value={this.state.companyId} name="companyId" readOnly={this.state.readOnly} onChange={(event) => this.handleUserInput(event)} />
                            </div>
                        </div>
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Company Email:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="companyEmail" value={this.state.companyEmail} onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Contract Dist Email:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="contractDistEmail" value={this.state.contractDistEmail} onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Suburb/Town:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} value={this.state.town} name="town" onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">State/Province:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="state" value={this.state.state} onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Postcode/Zipcode:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="postalCode" value={this.state.postalCode}  onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Phone:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="phone" value={this.state.phone} onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div>
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Fax:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="fax" value={this.state.fax}  onChange={(event) => this.handleUserInput(event)}/>
                            </div>
                        </div>

                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">Start:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="Start" value={this.state.Start} />
                            </div>
                        </div>

                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">
                                Meal Break Period:
                            </div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="mealBreak" value={this.state.mealBreak} />
                            </div>
                        </div>
                
                        <div className="col-sm-12 p0 company_setting">
                            <div className="col-sm-3 col-md-3 col-lg-3 label">End:</div>
                            <div className="col-sm-6 col-md-5">
                                <input type="text" className={"" + (this.state.readOnly ? 'readonlyField': ' ')} readOnly={this.state.readOnly} name="End" value={this.state.End} />
                            </div> 
                        </div>

                        <div className="col-xs-12 col-sm-12 p0 mrg_bot_custom10">
                <div
                  className="col-xs-12 col-sm-3 col-md-4 label_csutom_pop"
                  style={{
                    fontSize: "18px",
                    color: "#656565",
                    marginLeft: "-4px",
                  }}
                >
                  Hide Travel To:
                </div>
                <div className="col-xs-12 col-sm-9 col-md-8">
                  <label className="check_box_pop">
                  <input
                      name="HideTravelTo"
                      type="checkbox"
                      id="HideTravelTo"
                      checked={this.state.HideTravelTo}
                    />
                    <span className="checkmark left0_check readonlyField"></span>
                  </label>
                </div>
              </div>

              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div
                  className="col-xs-12 col-sm-3 col-md-4 label_csutom_pop"
                  style={{
                    fontSize: "18px",
                    color: "#656565",
                    marginLeft: "-4px",
                  }}
                >
                 Hide Travel From:
                </div>
                <div className="col-sm-9 col-md-8">
                  <label className="check_box_pop">
                    <input name="HideTravelFrom" id="HideTravelFrom" type="checkbox" checked={this.state.HideTravelFrom} />
                    <span className="checkmark left0_check readonlyField"></span>
                  </label>
                </div>
              </div>

                        <div className="clear20"></div>
                        <h2>Custom Fields</h2>
                        <div className="clearfix"></div>
                        <div className="col-sm-11 table_formpck_pop_1 p0">
                            <div className="clear5"></div>
                            <div className="scroll_form_pcktable company_detail_overflow_inherit">
                                <table width="100%" border="0" cellSpacing="2" cellPadding="2">
                                    <thead>
                                        <tr>
                                            <th width="20%" align="left" valign="middle">Prompt</th>
                                            <th width="60%" align="left" valign="middle">Value</th>
                                            <th width="20%" align="center">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <ListItem customFields={this.state.customFieldList}
                                         removeField={this.removeCustomFieldFromArray.bind(this)}
                                         editField={this.editCustomFieldFromArray.bind(this)} />
                                </table>
                            </div>
                        </div>
                        <div className="clear40"></div>
                        <div className="col-sm-6 p0">
                            <div className="btn_cance_save">
                            </div>
                        </div>
                        </form>
                    </div>
                    <div className="clear40"></div>
                    <Modal show={this.state.modalOpen} onHide={this.closeModal} className="modal modal2 fade" role="dialog">
                         <div className="modal-header">
                             <button type="button" className="close OutlineNone" onClick={(event) => this.onModalClose()}><img className="mt22" src={ic_clear_24px} width="18" height="18" alt="" /></button>
                             <h4 className="modal-title modal_customefield">Custom Fields</h4>
                         </div>
                         <form onSubmit={(event) => this.addCustomFieldsInToArray(event)}>
                             <div className="modal-body label_modal_custom overflow_inherti_custom">
                                 <div className="col-sm-12 p0 mrg_bot_custom10">
                                     <div className="col-sm-3 col-md-3 label_csutom_pop">Prompt:</div>
                                     <div className="col-sm-9 col-md-9"><input type="text" value={this.state.Prompt} name="Prompt" onChange={(event) => this.handleModalInput(event)} /></div>
                                 </div>
                                 <div className="col-sm-12 p0 mrg_bot_custom10">
                                     <div className="col-sm-3 col-md-3 label_csutom_pop">Value:</div>
                                     <div className="col-sm-9 col-md-9"><input type="text" value={this.state.Value} name="Value" onChange={(event) => this.handleModalInput(event)} /></div>
                                 </div>
                                 <div className="clear20"></div>
                                 <div className="col-sm-6">
                                     <div className="btn_cance_save">
                                         <button type="submit" className="btn_save_pro">Save</button>
                                         <button type="button" className="btn_cancel_pro" onClick={(event) => this.onModalClose()} >Cancel</button>
                                     </div>
                                 </div>
                                 <div className="clear20"></div>
                             </div>
                        </form>
                    </Modal>
                </div>
            </div>
        );
    }
};
export default CompanyView;
