import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import "./FormTemplateDetail.css";
import arrowBlackFrom from "../../assets/images/arrow_back_from.png";
import uploadFile from "../../assets/images/upload_file.png";
import downArrowAmprvoe from "../../assets/images/down_arrow_amprvoe.png";
import cross from "../../assets/images/ic_clear_24px@2x.png";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";
import tickBlack from "../../assets/images/tic_black.png";
import Header from "../Common/Header/Header";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import $ from "jquery";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
var Modal = require("react-bootstrap-modal");
require("react-bootstrap-modal/lib/css/rbm-patch.css");

window.$ = $;
global.jQuery = $;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// const grid = 10;

const getItemStyle = (isDragging, draggableStyle) => ({
  cursor: "all-scroll",
  cursor: isDragging ? "all-scroll" : "all-scroll",
  margin: isDragging ? "-25px 10px 5px -400px" : "",
  // marginTop:isDragging?"-80px":"",
  // background: isDragging ? "lightgreen" : "grey",

  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  cursor: "all-scroll",
  cursor: isDraggingOver ? "all-scroll" : "all-scroll",
});

class FormTemplateDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      someState: "",

      add_datalist: false,
      datalist: [
        { option: "yes", value: 1 },
        { option: "no", value: 0 },
      ],
      datalist_option: "",
      datalist_value: "",
      add_datalist_key: "",
      value_error: false,
      option_error: false,
      datePickerDisable: false,
      Guid: null,
      detail_template_code: null,
      detail_template_description: null,
      detail_document: null,
      detail_doc_password: null,
      detail_confidential: false,
      detail_hide_from_external_signer: false,
      detail_email_dist: null,
      FileAttachments: [],
      FileAttachments_urls: [],
      documentAttachment: null,
      isLoading: false,
      imageUploadModalOpen: false,
      template_code_error: false,
      template_desc_error: false,
      document_error: false,
      email_dist_error: false,
      fakePath: "",

      custom_field_description: null,
      custom_field_default_value: null,
      custom_field_tag: null,
      custom_field_validations: null,
      custom_field_modalOpen: false,
      emails: [],

      form_entry_fields_group_heading: null,
      form_entry_fields_description: null,
      form_entry_fields_default_value: null,
      form_entry_fields_comments: null,
      form_entry_fields_tag: null,
      form_entry_fields_dbfield: null,
      form_entry_fields_Company_internal: false,
      form_entry_fields_Company_hide: false,
      form_entry_fields_Company_read_only: false,
      form_entry_fields_Company_required: false,
      form_entry_fields_external_hide: false,
      form_entry_fields_external_readonly: false,
      form_entry_fields_external_required: false,
      form_entry_fields_field_type: null,
      form_entry_fields_field_length: null,
      form_entry_fields_validation: null,
      form_entry_fields_modalOpen: false,
      form_entry_fields_description_error: false,
      form_entry_fields_type_error: false,
      form_entry_fields_default_value_error: false,
      numberform_entry_fields_default_value_error: false,
      dateform_entry_fields_default_value_error: false,
      form_entry_fields: [],
      customListData: [],
      customListValue: "",
      isString: true,
      isNumber: false,
      isDate: false,
      isDroplist: false,
      isFile: false,
      form_entry_startDate: moment(),
      //=========================States Cloned From Custom Fields List
      customlistcodeeditkey: "",
      recordupdating: "",
      actionsubmission: "added",
      upadteableid: "",
      updateabledata: "",
      tagsFormHtml: "",
      CustomListFields: [],
      modalOpen: false,
      ad_modalOpen: false,
      deletestatus: false,
      UnitDescModalOpen: false,
      RateModalOpen: false,
      FactorModalOpen: false,
      AmountModalOpen: false,
      DaysInWeekdModalOpen: false,
      CappedModalOpen: false,
      DateFromModalOpen: false,
      DateToModalOpen: false,
      ChartModalOpen: false,
      ChartCodeModalOpen: false,
      FlagModalOpen: false,
      PaymentFlagModalOpen: false,
      CalcDaysModalOpen: false,
      CalcWeeksModalOpen: false,
      listHandler: {
        CustomCode: "",
        CustomFields: [
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "unit_desc",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 2,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "amount",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 5,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "unit",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 1,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "factor",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 4,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "rate",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 3,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "capped_amount",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 6,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "days_in_week",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 7,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "startDate",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 8,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "endDate",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 9,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "chart_code",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 10,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "chart_sort",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 11,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "flag",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 12,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "payment_flag",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 13,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "calc_days",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 14,
          },
          {
            DBField: null,
            DefaultValue: "",
            Hide: null,
            Length: null,
            Prompt: "calc_weeks",
            Readonly: null,
            Required: null,
            Script: null,
            Tag: null,
            Type: null,
            Validation: null,
            order: 15,
          },
        ],
        Desc: "",
        TBA: undefined,
      },
      pmt_code: "",
      desc: "",
      TBA: null,
      unit: 0,
      unit_dbfield: null,
      unit_validate: null,
      unit_hide: null,
      unitC_hide: null,
      unit_readonly: null,
      unit_required: null,
      unit_length: null,
      unit_script: null,
      unit_tag: null,
      unit_type: null,
      unit_order: 1,
      unit_desc: null,
      unit_desc_dbfield: null,
      unit_desc_validate: null,
      unit_desc_hide: null,
      unitC_desc_hide: null,
      unit_desc_readonly: null,
      unit_desc_required: null,
      unit_desc_length: null,
      unit_desc_script: null,
      unit_desc_tag: null,
      unit_desc_type: null,
      unit_desc_order: 2,
      rate: 0,
      rate_dbfield: null,
      rate_validate: null,
      rate_hide: null,
      rateC_hide: null,
      rate_readonly: null,
      rate_required: null,
      rate_length: null,
      rate_script: null,
      rate_tag: null,
      rate_type: null,
      rate_order: 3,
      factor: 0,
      factor_dbfield: null,
      factor_validate: null,
      factor_hide: null,
      factorC_hide: null,
      factor_readonly: null,
      factor_required: null,
      factor_length: null,
      factor_script: null,
      factor_tag: null,
      factor_type: null,
      factor_order: 4,
      amount: 0,
      amount_dbfield: null,
      amount_validate: null,
      amount_hide: null,
      amountC_hide: null,
      amount_readonly: null,
      amount_required: null,
      amount_length: null,
      amount_script: null,
      amount_tag: null,
      amount_type: null,
      amount_order: 5,
      capped_amount: null,
      capped_amount_dbfield: null,
      capped_amount_validate: null,
      capped_amount_hide: null,
      cappedC_amount_hide: null,
      capped_amount_readonly: null,
      capped_amount_required: null,
      capped_amount_length: null,
      capped_amount_script: null,
      capped_amount_tag: null,
      capped_amount_type: null,
      capped_amount_order: 6,
      days_in_week: null,
      days_in_week_dbfield: null,
      days_in_week_validate: null,
      days_in_week_hide: null,
      daysC_in_week_hide: null,
      days_in_week_readonly: null,
      days_in_week_required: null,
      days_in_week_length: null,
      days_in_week_script: null,
      days_in_week_tag: null,
      days_in_week_type: null,
      days_in_week_order: 7,
      startDate: moment(),
      startDate_dbfield: null,
      startDate_validate: null,
      startDate_hide: null,
      startDateC_hide: null,
      startDate_readonly: null,
      startDate_required: null,
      startDate_length: null,
      startDate_script: null,
      startDate_tag: null,
      startDate_type: null,
      startDate_order: 8,
      handlestartdate: "",
      endDate: moment().add(1, "days"),
      endDate_dbfield: null,
      endDate_validate: null,
      endDate_hide: null,
      endDateC_hide: null,
      endDate_readonly: null,
      endDate_required: null,
      endDate_length: null,
      endDate_script: null,
      endDate_tag: null,
      endDate_type: null,
      endDate_order: 9,
      handleenddate: "",
      chart_sort: null,
      chart_sort_dbfield: null,
      chart_sort_validate: null,
      chart_sort_hide: null,
      chartC_sort_hide: null,
      chart_sort_readonly: null,
      chart_sort_required: null,
      chart_sort_length: null,
      chart_sort_script: null,
      chart_sort_tag: null,
      chart_sort_type: null,
      chart_sort_order: 10,
      chart_code: null,
      chart_code_dbfield: null,
      chart_code_validate: null,
      chart_code_hide: null,
      chartC_code_hide: null,
      chart_code_readonly: null,
      chart_code_required: null,
      chart_code_length: null,
      chart_code_script: null,
      chart_code_tag: null,
      chart_code_type: null,
      chart_code_order: 11,
      flag: null,
      flag_dbfield: null,
      flag_validate: null,
      flag_hide: null,
      flagC_hide: null,
      flag_readonly: null,
      flag_required: null,
      flag_length: null,
      flag_script: null,
      flag_tag: null,
      flag_type: null,
      flag_order: 12,
      payment_flag: null,
      payment_flag_dbfield: null,
      payment_flag_validate: null,
      payment_flag_hide: null,
      paymentC_flag_hide: null,
      payment_flag_readonly: null,
      payment_flag_required: null,
      payment_flag_length: null,
      payment_flag_script: null,
      payment_flag_tag: null,
      payment_flag_type: null,
      payment_flag_order: 13,
      calc_days: null,
      calc_days_dbfield: null,
      calc_days_validate: null,
      calc_days_hide: null,
      calcC_days_hide: null,
      calc_days_readonly: null,
      calc_days_required: null,
      calc_days_length: null,
      calc_days_script: null,
      calc_days_tag: null,
      calc_days_type: null,
      calc_days_order: 14,
      calc_weeks: null,
      calc_weeks_dbfield: null,
      calc_weeks_validate: null,
      calc_weeks_hide: null,
      calcC_weeks_hide: null,
      calc_weeks_readonly: null,
      calc_weeks_required: null,
      calc_weeks_length: null,
      calc_weeks_script: null,
      calc_weeks_tag: null,
      calc_weeks_type: null,
      calc_weeks_order: 15,
      setup_data: {},
      setup_chartSort: "",
      setup_chartCode: "",
      trackingList: [],
      formErrors: {
        pmt_code: "",
        desc: "",
        unit: "",
        unit_desc: "",
        rate: "",
        factor: "",
        amount: "",
        capped_amount: "",
        days_in_week: "",
        chart_sort: "",
        chart_code: "",
        flag: "",
        payment_flag: "",
      },
      chart_sortValid: "",
      payment_flagValid: "",
      flagValid: "",
      PmtCodeValid: "",
      unit_descValid: "",
      descValid: "",
      days_in_weekValid: "",
      unitValid: "",
      rateValid: "",
      amountValid: "",
      chart_codeValid: "",
      capped_amountValid: "",
      factorValid: "",
      formValid: "",
      isActive: "",
      //=========================States Cloned From Custom Fields List End
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }
  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    var items;
    if (
      result.destination.droppableId === "hide_detail" &&
      result.source.droppableId === "tagsForm"
    ) {
      items = reorder(
        this.state.listHandler.CustomFields,
        result.source.index,
        result.destination.index
      );
      if (result.draggableId === "unit") {
        items.map((obj, i) => {
          if (obj.Prompt === "unit") {
            obj.Hide = 1;
          }
        });
        this.setState({ unit_hide: "checked" });
      }
      if (result.draggableId === "unit_desc") {
        items.map((obj, i) => {
          if (obj.Prompt === "unit_desc") {
            obj.Hide = 1;
          }
        });
        this.setState({ unit_desc_hide: "checked" });
      }

      if (result.draggableId === "rate") {
        items.map((obj, i) => {
          if (obj.Prompt === "rate") {
            obj.Hide = 1;
          }
        });
        this.setState({ rate_hide: "checked" });
      }

      if (result.draggableId === "factor") {
        items.map((obj, i) => {
          if (obj.Prompt === "factor") {
            obj.Hide = 1;
          }
        });
        this.setState({ factor_hide: "checked" });
      }

      if (result.draggableId === "amount") {
        items.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.Hide = 1;
          }
        });
        this.setState({ amount_hide: "checked" });
      }

      if (result.draggableId === "capped_amount") {
        items.map((obj, i) => {
          if (obj.Prompt === "capped_amount") {
            obj.Hide = 1;
          }
        });
        this.setState({ capped_amount_hide: "checked" });
      }
      if (result.draggableId === "days_in_week") {
        items.map((obj, i) => {
          if (obj.Prompt === "days_in_week") {
            obj.Hide = 1;
          }
        });
        this.setState({ days_in_week_hide: "checked" });
      }

      if (result.draggableId === "startDate") {
        items.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.Hide = 1;
          }
        });
        this.setState({ startDate_hide: "checked" });
      }

      if (result.draggableId === "endDate") {
        items.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.Hide = 1;
          }
        });
        this.setState({ endDate_hide: "checked" });
      }

      if (result.draggableId === "chart_sort") {
        items.map((obj, i) => {
          if (obj.Prompt === "chart_sort") {
            obj.Hide = 1;
          }
        });
        this.setState({ chart_sort_hide: "checked" });
      }
      if (result.draggableId === "chart_code") {
        items.map((obj, i) => {
          if (obj.Prompt === "chart_code") {
            obj.Hide = 1;
          }
        });
        this.setState({ chart_code_hide: "checked" });
      }

      if (result.draggableId === "flag") {
        items.map((obj, i) => {
          if (obj.Prompt === "flag") {
            obj.Hide = 1;
          }
        });
        this.setState({ flag_hide: "checked" });
      }

      if (result.draggableId === "payment_flag") {
        items.map((obj, i) => {
          if (obj.Prompt === "payment_flag") {
            obj.Hide = 1;
          }
        });
        this.setState({ payment_flag_hide: "checked" });
      }

      if (result.draggableId === "calc_days") {
        items.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.Hide = 1;
          }
        });
        this.setState({ calc_days_hide: "checked" });
      }
      if (result.draggableId === "calc_weeks") {
        items.map((obj, i) => {
          if (obj.Prompt === "calc_weeks") {
            obj.Hide = 1;
          }
        });
        this.setState({ calc_weeks_hide: "checked" });
      }
    }
    if (
      result.destination.droppableId === "tagsForm" &&
      result.source.droppableId === "hide_detail"
    ) {
      items = reorder(
        this.state.listHandler.CustomFields,
        result.source.index,
        result.destination.index - 1
      );
      if (result.draggableId === "unit") {
        items.map((obj, i) => {
          if (obj.Prompt === "unit") {
            obj.Hide = 0;
          }
        });
        this.setState({ unit_hide: "" });
      }
      if (result.draggableId === "unit_desc") {
        items.map((obj, i) => {
          if (obj.Prompt === "unit_desc") {
            obj.Hide = 0;
          }
        });
        this.setState({ unit_desc_hide: "" });
      }

      if (result.draggableId === "rate") {
        items.map((obj, i) => {
          if (obj.Prompt === "rate") {
            obj.Hide = 0;
          }
        });
        this.setState({ rate_hide: "" });
      }

      if (result.draggableId === "factor") {
        items.map((obj, i) => {
          if (obj.Prompt === "factor") {
            obj.Hide = 0;
          }
        });
        this.setState({ factor_hide: "" });
      }

      if (result.draggableId === "amount") {
        items.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.Hide = 0;
          }
        });
        this.setState({ amount_hide: "" });
      }

      if (result.draggableId === "capped_amount") {
        items.map((obj, i) => {
          if (obj.Prompt === "capped_amount") {
            obj.Hide = 0;
          }
        });
        this.setState({ capped_amount_hide: "" });
      }
      if (result.draggableId === "days_in_week") {
        items.map((obj, i) => {
          if (obj.Prompt === "days_in_week") {
            obj.Hide = 0;
          }
        });
        this.setState({ days_in_week_hide: "" });
      }

      if (result.draggableId === "startDate") {
        items.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.Hide = 0;
          }
        });
        this.setState({ startDate_hide: "" });
      }

      if (result.draggableId === "endDate") {
        items.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.Hide = 0;
          }
        });
        this.setState({ endDate_hide: "" });
      }

      if (result.draggableId === "chart_sort") {
        items.map((obj, i) => {
          if (obj.Prompt === "chart_sort") {
            obj.Hide = 0;
          }
        });
        this.setState({ chart_sort_hide: "" });
      }
      if (result.draggableId === "chart_code") {
        items.map((obj, i) => {
          if (obj.Prompt === "chart_code") {
            obj.Hide = 0;
          }
        });
        this.setState({ chart_code_hide: "" });
      }

      if (result.draggableId === "flag") {
        items.map((obj, i) => {
          if (obj.Prompt === "flag") {
            obj.Hide = 0;
          }
        });
        this.setState({ flag_hide: "" });
      }

      if (result.draggableId === "payment_flag") {
        items.map((obj, i) => {
          if (obj.Prompt === "payment_flag") {
            obj.Hide = 0;
          }
        });
        this.setState({ payment_flag_hide: "" });
      }

      if (result.draggableId === "calc_days") {
        items.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.Hide = 0;
          }
        });
        this.setState({ calc_days_hide: "" });
      }
      if (result.draggableId === "calc_weeks") {
        items.map((obj, i) => {
          if (obj.Prompt === "calc_weeks") {
            obj.Hide = 0;
          }
        });
        this.setState({ calc_weeks_hide: "" });
      }
    } else {
      items = reorder(
        this.state.listHandler.CustomFields,
        result.source.index,
        result.destination.index
      );
    }
    items.map((field, i) => {
      if (field.Prompt === "unit") {
        var order = i + 1;
        this.setState({ unit_order: order });
      }
      if (field.Prompt === "unit_desc") {
        var order = i + 1;
        this.setState({ unit_desc_order: order });
      }
      if (field.Prompt === "rate") {
        var order = i + 1;
        this.setState({ rate_order: order });
      }
      if (field.Prompt === "factor") {
        var order = i + 1;
        this.setState({ factor_order: order });
      }

      if (field.Prompt === "amount") {
        var order = i + 1;
        this.setState({ amount_order: order });
      }

      if (field.Prompt === "capped_amount") {
        var order = i + 1;
        this.setState({ capped_amount_order: order });
      }

      if (field.Prompt === "startDate") {
        var order = i + 1;
        this.setState({ startDate_order: order });
      }

      if (field.Prompt === "endDate") {
        var order = i + 1;
        this.setState({ endDate_order: order });
      }

      if (field.Prompt === "chart_sort") {
        var order = i + 1;
        this.setState({ chart_sort_order: order });
      }
      if (field.Prompt === "chart_code") {
        var order = i + 1;
        this.setState({ chart_code_order: order });
      }
      if (field.Prompt === "flag") {
        var order = i + 1;
        this.setState({ flag_order: order });
      }
      if (field.Prompt === "payment_flag") {
        var order = i + 1;
        this.setState({ payment_flag_order: order });
      }
      if (field.Prompt === "calc_days") {
        var order = i + 1;
        this.setState({ calc_days_order: order });
      }
      if (field.Prompt === "calc_weeks") {
        var order = i + 1;
        this.setState({ calc_weeks_order: order });
      }
      if (field.Prompt === "days_in_weeks") {
        var order = i + 1;
        this.setState({ days_in_week_orde: order });
      }
    });
    var test = this.state.listHandler;
    test.CustomFields = items;
    console.log("=>>>>>T", test);
    this.setState(
      {
        listHandler: test,
      },
      console.log("=>>>>L", this.state.listHandler)
    );
  }

  getAllCustomListFields() {
    return this.props.postRequest("/custom-list-fields/get-list", {});
  }
  componentDidMount = async () => {
    if (localStorage.getItem("ftGuid")) {
      document.getElementById("Details").click();
      this.get_form_template();
    }
    $(".modal").css("display", "none");
    await this.getAllCustomListFields().then((response) => {
      if (response.status) {
        this.setState({ customListData: response.result });
      }
      console.log("DATA", response);
    });
    try {
      var setup = await this.props.postRequest("/setup/get-list", {});
      if (setup.status) {
        this.setState({
          setup_data: setup.result,
          setup_chartSort: setup.result.Codes.chart_sort,
          setup_chartCode: setup.result.Codes.chart_code,
          trackingList: setup.result.TrackingFields,
        });
      }
      console.log("setup=>", setup);
    } catch (err) {
      this.setState({ isLoading: false });
    }
    this.setState({ isLoading: false });
  };

  //================Cloned From Custom Fields List
  handleUserInputCheckbox(event) {
    let name = event.target.name;
    var value = event.target.value;
    // console.log("=>Check",name,value)
    if (value === "") {
      this.setState({
        [name]: "checked",
      });
      if (name === "TBA") {
        this.setState({ calc_weeks: 0, calc_days: 0 });
      }

      // if (name === "unit_hide") {
      //   this.state.listHandler.CustomFields.map((obj, i) => {
      //     if (obj.Prompt === "unit") {
      //       obj.Hide = 1;
      //     }
      //   });
      // }
      // if (name === "unit_desc_hide") {
      //   this.state.listHandler.CustomFields.map((obj, i) => {
      //     if (obj.Prompt === "unit_desc") {
      //       obj.Hide = 1;
      //     }
      //   });
      // }

      if (name === "rate_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "rate") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "factor_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "factor") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "amount_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "capped_amount_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "capped_amount") {
            obj.Hide = 1;
          }
        });
      }
      if (name === "days_in_week_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "days_in_week") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "startDate_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "endDate_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "chart_sort_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_sort") {
            obj.Hide = 1;
          }
        });
      }
      if (name === "chart_code_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_code") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "flag_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "flag") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "payment_flag_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "payment_flag") {
            obj.Hide = 1;
          }
        });
      }

      if (name === "calc_days_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.Hide = 1;
          }
        });
      }
      if (name === "calc_weeks_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_weeks") {
            obj.Hide = 1;
          }
        });
      }
    } else {
      this.setState({
        [name]: "",
      });
      if (name === "TBA") {
        this.handleChangeTo(this.state.endDate);
      }
      // if (name === "unit_hide") {
      //   this.state.listHandler.CustomFields.map((obj, i) => {
      //     if (obj.Prompt === "unit") {
      //       obj.Hide = 0;
      //     }
      //   });
      // }
      // if (name === "unit_desc_hide") {
      //   this.state.listHandler.CustomFields.map((obj, i) => {
      //     if (obj.Prompt === "unit_desc") {
      //       obj.Hide = 0;
      //     }
      //   });
      // }

      if (name === "rate_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "rate") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "factor_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "factor") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "amount_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "capped_amount_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "capped_amount") {
            obj.Hide = 0;
          }
        });
      }
      if (name === "days_in_week_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "days_in_week") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "startDate_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "endDate_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "chart_sort_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_sort") {
            obj.Hide = 0;
          }
        });
      }
      if (name === "chart_code_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_code") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "flag_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "flag") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "payment_flag_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "payment_flag") {
            obj.Hide = 0;
          }
        });
      }

      if (name === "calc_days_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.Hide = 0;
          }
        });
      }
      if (name === "calc_weeks_hide") {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_weeks") {
            obj.Hide = 0;
          }
        });
      }
    }
  }
  onModal_Save = (event) => {
    if (event.target.id === "unitModal") {
      if (this.state.unit_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ modalOpen: false });
    }
    if (event.target.id === "unitDescModal") {
      if (this.state.unit_desc_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit_desc") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit_desc") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ UnitDescModalOpen: false });
    }
    if (event.target.id === "rate_Modal") {
      if (this.state.rate_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "rate") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "rate") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ RateModalOpen: false });
    }
    if (event.target.id === "factor_Modal") {
      if (this.state.factor_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "factor") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "factor") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ FactorModalOpen: false });
    }
    if (event.target.id === "amount_Modal") {
      if (this.state.amount_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ AmountModalOpen: false });
    }
    if (event.target.id === "capped_Modal") {
      if (this.state.capped_amount_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "capped_amount") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "capped_amount") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ CappedModalOpen: false });
    }
    if (event.target.id === "chartS_Modal") {
      if (this.state.chart_sort_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_sort") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_sort") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ ChartModalOpen: false });
    }
    if (event.target.id === "chartC_Modal") {
      if (this.state.chart_code_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_code") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "chart_code") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ ChartCodeModalOpen: false });
    }
    if (event.target.id === "days_Modal") {
      if (this.state.days_in_week_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "days_in_week") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "days_in_week") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ DaysInWeekdModalOpen: false });
    }
    if (event.target.id === "startDate_Modal") {
      if (this.state.startDate_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ DateFromModalOpen: false });
    }
    if (event.target.id === "endDate_Modal") {
      if (this.state.endDate_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ DateToModalOpen: false });
    }
    if (event.target.id === "flag_Modal") {
      if (this.state.flag_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "flag") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "flag") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ FlagModalOpen: false });
    }
    if (event.target.id === "payment_Modal") {
      if (this.state.payment_flag_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "payment_flag") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "payment_flag") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ PaymentFlagModalOpen: false });
    }
    if (event.target.id === "calcD_Modal") {
      if (this.state.calc_days_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_days") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ CalcDaysModalOpen: false });
    }
    if (event.target.id === "calcW_Modal") {
      if (this.state.calc_weeks_hide) {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_weeks") {
            obj.Hide = 1;
          }
        });
      } else {
        this.state.listHandler.CustomFields.map((obj, i) => {
          if (obj.Prompt === "calc_weeks") {
            obj.Hide = 0;
          }
        });
      }
      this.setState({ CalcWeeksModalOpen: false });
    }
  };

  amount_calculation = (factor, rate, unit) => {
    let amount = 0;
    //console.log('factor=> ',factor);
    //console.log('rate=> ',rate);
    //console.log('unit=> ',unit);
    amount = factor * rate * unit;
    this.setState(
      {
        amount: amount,
      },
      () => {
        if (this.state.amount === 0) {
          this.setState({ datePickerDisable: true });
        } else {
          this.setState({ datePickerDisable: false });
        }
      }
    );
  };

  handleUserInput = async (event) => {
    let name = event.target.name;
    let value = event.target.value ? event.target.value : "";

    let factor = this.state.factor;
    let rate = this.state.rate;
    let unit = this.state.unit;

    if (name === "factor") {
      //console.log('factor name');
      this.amount_calculation((factor = value), rate, unit);
    } else if (name === "rate") {
      //console.log('rate name');
      this.amount_calculation(factor, (rate = value), unit);
    } else if (name === "unit") {
      this.amount_calculation(factor, rate, (unit = value));
    }

    await this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );

    if (name === "days_in_week") {
      if (moment(this.state.startDate).isBefore(this.state.endDate)) {
        var duration = moment.duration(
          this.state.endDate.diff(this.state.startDate)
        );
        var calc_days = (duration.asDays() + 1) % 7;
        var calc_weeks = duration.asWeeks();
        //console.log("Calculation days => ", calc_days);
        //console.log("Calculation weeks => ", calc_weeks);
        if (Math.round(calc_days) >= this.state.days_in_week) {
          //console.log("cal days => " , this.state.days_in_week);
          calc_weeks++;
          this.setState({
            calc_days: 0,
            calc_weeks: Math.floor(calc_weeks),
          });
        } else {
          if (calc_days === 0) {
            calc_weeks++;
          }
          this.setState({
            calc_days: Math.floor(calc_days),
            calc_weeks: Math.floor(calc_weeks),
          });
        }
      } else {
        //alert('Please Select date before '+ moment.utc(this.state.endDate).format('LL') +'');
      }
    }
    if (name === "flag") {
      var length;
      var type;
      var options;
      this.state.trackingList.map((obj, i) => {
        if (obj.defaultValue === value) {
          length = obj.Length;
          type = obj.type;
          options = obj.optionsValues;
        }
      });
    }
    this.setState({
      flag_length: length,
      flag_type: type,
    });
  };
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let PmtCodeValid = this.state.PmtCodeValid;
    let descValid = this.state.descValid;
    switch (fieldName) {
      case "pmt_code":
        PmtCodeValid = value.length > 0;
        fieldValidationErrors.pmt_code = PmtCodeValid
          ? ""
          : "This field is required";
        break;
      case "desc":
        descValid = value.length > 0;
        fieldValidationErrors.desc = descValid ? "" : "This field is required";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        PmtCodeValid: PmtCodeValid,
      },
      this.validateForm
    );
  }
  validateForm() {
    this.setState({
      formValid: this.state.pmt_code && this.state.desc,
      isActive: this.state.pmt_code && this.state.desc,
    });
  }
  handleChangeTo(date) {
    let startDate = moment(this.state.startDate.format("YYYY-MM-DD"));
    let endDate = moment(date.format("YYYY-MM-DD"));
    if (endDate.isSameOrAfter(startDate)) {
      this.setState({
        endDate: date,
      });
      var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
      var calc_weeks = endDate.diff(startDate, "weeks");
      if (calc_days >= this.state.days_in_week) {
        calc_weeks++;
        this.setState({
          calc_days: 0,
          calc_weeks: calc_weeks,
        });
      } else {
        if (calc_days === 0) {
          calc_weeks++;
        }
        this.setState({
          calc_days: calc_days,
          calc_weeks: calc_weeks,
        });
      }
    } else {
      this.setState({
        endDate: date,
        calc_days: 0,
        calc_weeks: 0,
      });
    }
  }
  showDateFrom() {
    document.getElementById("showDateFrom").click();
  }
  showDateTo() {
    document.getElementById("showDateTo").click();
  }
  onad_ModalClose = () => {
    this.setState({ ad_modalOpen: false });

    this.state.listHandler.CustomFields.map((field, i) => {
      if (field.Prompt === "unit") {
        field.order = 1;
        field.Hide = null;
      }
      if (field.Prompt === "unit_desc") {
        field.order = 2;
        field.Hide = null;
      }
      if (field.Prompt === "rate") {
        field.order = 3;
        field.Hide = null;
      }
      if (field.Prompt === "factor") {
        field.order = 4;
        field.Hide = null;
      }

      if (field.Prompt === "amount") {
        field.order = 5;
        field.Hide = null;
      }

      if (field.Prompt === "capped_amount") {
        field.order = 6;
        field.Hide = null;
      }

      if (field.Prompt === "startDate") {
        field.order = 7;
        field.Hide = null;
      }

      if (field.Prompt === "endDate") {
        field.order = 8;
        field.Hide = null;
      }

      if (field.Prompt === "chart_sort") {
        field.order = 9;
        field.Hide = null;
      }
      if (field.Prompt === "chart_code") {
        field.order = 10;
        field.Hide = null;
      }
      if (field.Prompt === "flag") {
        field.order = 11;
        field.Hide = null;
      }
      if (field.Prompt === "payment_flag") {
        field.order = 12;
        field.Hide = null;
      }
      if (field.Prompt === "calc_days") {
        field.order = 13;
        field.Hide = null;
      }
      if (field.Prompt === "calc_weeks") {
        field.order = 14;
        field.Hide = null;
      }
      if (field.Prompt === "days_in_weeks") {
        field.order = 15;
        field.Hide = null;
      }
    });

    this.setState({
      recordupdating: "",
      upadteableid: "",
      updateabledata: "",
      customlistcodeeditkey: "",
      customListValue: "",
    });
    this.setState({
      tagsFormHtml: "",
      modalOpen: false,
      ad_modalOpen: false,
      deletestatus: false,
      UnitDescModalOpen: false,
      RateModalOpen: false,
      FactorModalOpen: false,
      AmountModalOpen: false,
      DaysInWeekdModalOpen: false,
      CappedModalOpen: false,
      DateFromModalOpen: false,
      DateToModalOpen: false,
      ChartModalOpen: false,
      ChartCodeModalOpen: false,
      FlagModalOpen: false,
      PaymentFlagModalOpen: false,
      CalcDaysModalOpen: false,
      CalcWeeksModalOpen: false,
      pmt_code: "",
      desc: "",
      TBA: "",
      unit: 0,
      unit_dbfield: null,
      unit_validate: null,
      unit_hide: null,
      unit_readonly: null,
      unit_required: null,
      unit_length: null,
      unit_script: null,
      unit_tag: null,
      unit_type: null,
      unit_order: 1,
      unit_desc: null,
      unit_desc_dbfield: null,
      unit_desc_validate: null,
      unit_desc_hide: null,
      unit_desc_readonly: null,
      unit_desc_required: null,
      unit_desc_length: null,
      unit_desc_script: null,
      unit_desc_tag: null,
      unit_desc_type: null,
      unit_desc_order: 2,
      rate: 0,
      rate_dbfield: null,
      rate_validate: null,
      rate_hide: null,
      rate_readonly: null,
      rate_required: null,
      rate_length: null,
      rate_script: null,
      rate_tag: null,
      rate_type: null,
      rate_order: 3,
      factor: 0,
      factor_dbfield: null,
      factor_validate: null,
      factor_hide: null,
      factor_readonly: null,
      factor_required: null,
      factor_length: null,
      factor_script: null,
      factor_tag: null,
      factor_type: null,
      factor_order: 4,
      amount: 0,
      amount_dbfield: null,
      amount_validate: null,
      amount_hide: null,
      amount_readonly: null,
      amount_required: null,
      amount_length: null,
      amount_script: null,
      amount_tag: null,
      amount_type: null,
      amount_order: 5,
      capped_amount: null,
      capped_amount_dbfield: null,
      capped_amount_validate: null,
      capped_amount_hide: null,
      capped_amount_readonly: null,
      capped_amount_required: null,
      capped_amount_length: null,
      capped_amount_script: null,
      capped_amount_tag: null,
      capped_amount_type: null,
      capped_amount_order: 6,
      days_in_week: null,
      days_in_week_dbfield: null,
      days_in_week_validate: null,
      days_in_week_hide: null,
      days_in_week_readonly: null,
      days_in_week_required: null,
      days_in_week_length: null,
      days_in_week_script: null,
      days_in_week_tag: null,
      days_in_week_type: null,
      days_in_week_order: 7,
      startDate: moment(),
      startDate_dbfield: null,
      startDate_validate: null,
      startDate_hide: null,
      startDate_readonly: null,
      startDate_required: null,
      startDate_length: null,
      startDate_script: null,
      startDate_tag: null,
      startDate_type: null,
      startDate_order: 8,
      endDate: moment().add(1, "days"),
      endDate_dbfield: null,
      endDate_validate: null,
      endDate_hide: null,
      endDate_readonly: null,
      endDate_required: null,
      endDate_length: null,
      endDate_script: null,
      endDate_tag: null,
      endDate_type: null,
      endDate_order: 9,
      chart_sort: null,
      chart_sort_dbfield: null,
      chart_sort_validate: null,
      chart_sort_hide: null,
      chart_sort_readonly: null,
      chart_sort_required: null,
      chart_sort_length: null,
      chart_sort_script: null,
      chart_sort_tag: null,
      chart_sort_type: null,
      chart_sort_order: 10,
      chart_code: null,
      chart_code_dbfield: null,
      chart_code_validate: null,
      chart_code_hide: null,
      chart_code_readonly: null,
      chart_code_required: null,
      chart_code_length: null,
      chart_code_script: null,
      chart_code_tag: null,
      chart_code_type: null,
      chart_code_order: 11,
      flag: null,
      flag_dbfield: null,
      flag_validate: null,
      flag_hide: null,
      flag_readonly: null,
      flag_required: null,
      flag_length: null,
      flag_script: null,
      flag_tag: null,
      flag_type: null,
      flag_order: 12,
      payment_flag: null,
      payment_flag_dbfield: null,
      payment_flag_validate: null,
      payment_flag_hide: null,
      payment_flag_readonly: null,
      payment_flag_required: null,
      payment_flag_length: null,
      payment_flag_script: null,
      payment_flag_tag: null,
      payment_flag_type: null,
      payment_flag_order: 13,
      calc_days: null,
      calc_days_dbfield: null,
      calc_days_validate: null,
      calc_days_hide: null,
      calc_days_readonly: null,
      calc_days_required: null,
      calc_days_length: null,
      calc_days_script: null,
      calc_days_tag: null,
      calc_days_type: null,
      calc_days_order: 14,
      calc_weeks: null,
      calc_weeks_dbfield: null,
      calc_weeks_validate: null,
      calc_weeks_hide: null,
      calc_weeks_readonly: null,
      calc_weeks_required: null,
      calc_weeks_length: null,
      calc_weeks_script: null,
      calc_weeks_tag: null,
      calc_weeks_type: null,
      calc_weeks_order: 15,
      formErrors: {
        pmt_code: "",
        desc: "",
        unit: "",
        unit_desc: "",
        rate: "",
        factor: "",
        amount: "",
        capped_amount: "",
        days_in_week: "",
        chart_sort: "",
        chart_code: "",
        flag: "",
        payment_flag: "",
      },
      chart_sortValid: "",
      payment_flagValid: "",
      flagValid: "",
      PmtCodeValid: "",
      unit_descValid: "",
      descValid: "",
      days_in_weekValid: "",
      unitValid: "",
      rateValid: "",
      amountValid: "",
      chart_codeValid: "",
      capped_amountValid: "",
      factorValid: "",
      formValid: "",
      isActive: "",
    });
  };
  onad_ModalOpen() {
    var thisobj = this;
    setTimeout(function () {
      if (thisobj.state.amount === 0 || thisobj.state.amount === null) {
        thisobj.setState({ datePickerDisable: true, amount: 0 });
      } else {
        thisobj.setState({ datePickerDisable: false });
      }
      var startDate = moment(thisobj.state.startDate.format("YYYY-MM-DD"));
      var endDate = moment(thisobj.state.endDate.format("YYYY-MM-DD"));
      if (startDate.isSameOrBefore(endDate)) {
        var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
        var calc_weeks = endDate.diff(startDate, "days");

        if (calc_days >= thisobj.state.days_in_week) {
          calc_weeks++;
          thisobj.setState({
            calc_days: 0,
            calc_weeks: calc_weeks,
          });
        } else {
          if (calc_days === 0) {
            calc_days++;
          }
          thisobj.setState({
            calc_days: Math.floor(calc_days),
            calc_weeks: Math.floor(calc_weeks),
          });
        }
      } else {
        thisobj.setState({
          calc_days: 0,
          calc_weeks: 0,
        });
      }
      if (endDate.isSameOrAfter(startDate)) {
        var duration = moment.duration(
          thisobj.state.endDate.diff(thisobj.state.startDate)
        );
        var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
        var calc_weeks = endDate.diff(startDate, "weeks");
        if (calc_days >= thisobj.state.days_in_week) {
          calc_weeks++;
          thisobj.setState({
            calc_days: 0,
            calc_weeks: calc_weeks,
          });
        } else {
          if (Math.floor(calc_days) === 0) {
            calc_weeks++;
          }
          thisobj.setState({
            calc_days: calc_days,
            calc_weeks: calc_weeks,
          });
        }
      } else {
        thisobj.setState({
          calc_days: 0,
          calc_weeks: 0,
        });
      }

      if (thisobj.state.TBA === "checked") {
        thisobj.setState({
          calc_days: 0,
          calc_weeks: 0,
        });
      }
      if (thisobj.state.CustomListFields.length > 0) {
        var unit_order;
        var unit_desc_order;
        var rate_order;
        var factor_order;
        var amount_order;
        var days_in_week_order;
        var startDate_order;
        var endDate_order;
        var capped_amount_order;
        var chart_sort_order;
        var chart_code_order;
        var flag_order;
        var payment_flag_order;
        var calc_days_order;
        var calc_weeks_order;

        thisobj.state.CustomListFields[
          thisobj.state.CustomListFields.length - 1
        ].CustomFields.map((field, i) => {
          if (field.Prompt === "unit") {
            unit_order = field.order;
          }
          if (field.Prompt === "unit_desc") {
            unit_desc_order = field.order;
          }
          if (field.Prompt === "rate") {
            rate_order = field.order;
          }
          if (field.Prompt === "factor") {
            factor_order = field.order;
          }

          if (field.Prompt === "amount") {
            amount_order = field.order;
          }

          if (field.Prompt === "capped_amount") {
            capped_amount_order = field.order;
          }

          if (field.Prompt === "startDate") {
            startDate_order = field.order;
          }

          if (field.Prompt === "endDate") {
            endDate_order = field.order;
          }

          if (field.Prompt === "chart_sort") {
            chart_sort_order = field.order;
          }
          if (field.Prompt === "chart_code") {
            chart_code_order = field.order;
          }
          if (field.Prompt === "flag") {
            flag_order = field.order;
          }
          if (field.Prompt === "payment_flag") {
            payment_flag_order = field.order;
          }
          if (field.Prompt === "calc_days") {
            calc_days_order = field.order;
          }
          if (field.Prompt === "calc_weeks") {
            calc_weeks_order = field.order;
          }
          if (field.Prompt === "days_in_weeks") {
            days_in_week_order = field.order;
          }
        });

        thisobj.setState({
          unit_order: unit_order,
          unit_desc_order: unit_desc_order,
          rate_order: rate_order,
          factor_order: factor_order,
          amount_order: amount_order,
          days_in_week_orde: days_in_week_order,
          startDate_order: startDate_order,
          endDate_order: endDate_order,
          capped_amount_order: capped_amount_order,
          chart_sort_order: chart_sort_order,
          chart_code_order: chart_code_order,
          flag_order: flag_order,
          payment_flag_order: payment_flag_order,
          calc_days_order: calc_days_order,
          calc_weeks_order: calc_weeks_order,
        });
        thisobj.state.listHandler.CustomFields.map((field, i) => {
          if (field.Prompt === "unit") {
            field.order = unit_order;
          }
          if (field.Prompt === "unit_desc") {
            field.order = unit_desc_order;
          }
          if (field.Prompt === "rate") {
            field.order = rate_order;
          }
          if (field.Prompt === "factor") {
            field.order = factor_order;
          }

          if (field.Prompt === "amount") {
            field.order = amount_order;
          }

          if (field.Prompt === "capped_amount") {
            field.order = capped_amount_order;
          }

          if (field.Prompt === "startDate") {
            field.order = startDate_order;
          }

          if (field.Prompt === "endDate") {
            field.order = endDate_order;
          }

          if (field.Prompt === "chart_sort") {
            field.order = chart_sort_order;
          }
          if (field.Prompt === "chart_code") {
            field.order = chart_code_order;
          }
          if (field.Prompt === "flag") {
            field.order = flag_order;
          }
          if (field.Prompt === "payment_flag") {
            field.order = payment_flag_order;
          }
          if (field.Prompt === "calc_days") {
            field.order = calc_days_order;
          }
          if (field.Prompt === "calc_weeks") {
            field.order = calc_weeks_order;
          }
          if (field.Prompt === "days_in_weeks") {
            field.order = days_in_week_order;
          }
        });
      }
        
        if(thisobj.state.customlistcodeeditkey!=="")
        {
          console.log("EDIT")
        }
        else{
          thisobj.setState({TBA:""})
        }
      thisobj.setState({ ad_modalOpen: true });
      thisobj.state.listHandler.CustomFields.sort((a, b) =>
        a.order > b.order ? 1 : -1
      );
      var tagsFormHtml = $("#tagsForm");
      thisobj.setState({ tagsFormHtml: tagsFormHtml });
    }, 70);
  }
  onUnitModalClose() {
    if (this.state.unitC_hide) {
      this.setState({ unit_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "unit") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ unit_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "unit") {
          obj.Hide = 0;
        }
      });
    }
    this.setState({ modalOpen: false });
  }
  onUnitModalOpen() {
    if (this.state.unit_hide) {
      this.setState({ unitC_hide: true });
    } else {
      this.setState({ unitC_hide: false });
    }
    this.setState({ modalOpen: true });
  }
  onUnitDescModalOpen() {
    if (this.state.unit_desc_hide) {
      this.setState({ unitC_desc_hide: true });
    } else {
      this.setState({ unitC_desc_hide: false });
    }
    this.setState({ UnitDescModalOpen: true });
  }
  onUnitDescModalClose() {
    if (this.state.unitC_desc_hide) {
      this.setState({ unit_desc_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "unit_desc") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ unit_desc_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "unit_desc") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ UnitDescModalOpen: false });
  }
  onRateModalOpen() {
    if (this.state.rate_hide) {
      this.setState({ rateC_hide: true });
    } else {
      this.setState({ rateC_hide: false });
    }
    this.setState({ RateModalOpen: true });
  }
  onRateModalClose() {
    if (this.state.rateC_hide) {
      this.setState({ rate_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "rate") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ rate_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "rate") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ RateModalOpen: false });
  }
  onFactorModalOpen() {
    if (this.state.factor_hide) {
      this.setState({ factorC_hide: true });
    } else {
      this.setState({ factorC_hide: false });
    }
    this.setState({ FactorModalOpen: true });
  }
  onFactorModalClose() {
    if (this.state.factorC_hide) {
      this.setState({ factor_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "factor") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ factor_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "factor") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ FactorModalOpen: false });
  }
  onAmountModalOpen() {
    if (this.state.amount_hide) {
      this.setState({ amountC_hide: true });
    } else {
      this.setState({ amountC_hide: false });
    }

    this.setState({ AmountModalOpen: true });
  }
  onAmountModalClose() {
    if (this.state.amountC_hide) {
      this.setState({ amount_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "amount") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ amount_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "amount") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ AmountModalOpen: false });
  }
  onCappedModalOpen() {
    if (this.state.capped_amount_hide) {
      this.setState({ cappedC_amount_hide: true });
    } else {
      this.setState({ cappedC_amount_hide: false });
    }

    this.setState({ CappedModalOpen: true });
  }
  onCappedModalClose() {
    if (this.state.cappedC_amount_hide) {
      this.setState({ capped_amount_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "capped_amount") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ capped_amount_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "capped_amount") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ CappedModalOpen: false });
  }
  onDaysInWeekModalOpen() {
    if (this.state.days_in_week_hide) {
      this.setState({ daysC_in_week_hide: true });
    } else {
      this.setState({ daysC_in_week_hide: false });
    }

    this.setState({ DaysInWeekdModalOpen: true });
  }
  onDaysInWeekModalClose() {
    if (this.state.daysC_in_week_hide) {
      this.setState({ days_in_week_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "days_in_week") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ days_in_week_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "days_in_week") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ DaysInWeekdModalOpen: false });
  }
  onDateFromModalOpen() {
    if (this.state.startDate_hide) {
      this.setState({ startDateC_hide: true });
    } else {
      this.setState({ startDateC_hide: false });
    }

    this.setState({ DateFromModalOpen: true });
  }
  onDateFromModalClose() {
    if (this.state.startDateC_hide) {
      this.setState({ startDate_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "startDate") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ startDate_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "startDate") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ DateFromModalOpen: false });
  }
  onDateToModalOpen() {
    if (this.state.endDate_hide) {
      this.setState({ endDateC_hide: true });
    } else {
      this.setState({ endDateC_hide: false });
    }

    this.setState({ DateToModalOpen: true });
  }
  onDateToModalClose() {
    if (this.state.endDateC_hide) {
      this.setState({ endDate_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "endDate") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ endDate_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "endDate") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ DateToModalOpen: false });
  }
  onChartModalOpen() {
    if (this.state.chart_sort_hide) {
      this.setState({ chartC_sort_hide: true });
    } else {
      this.setState({ chartC_sort_hide: false });
    }

    this.setState({ ChartModalOpen: true });
  }
  onChartModalClose() {
    if (this.state.chartC_sort_hide) {
      this.setState({ chart_sort_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "chart_sort") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ chart_sort_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "chart_sort") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ ChartModalOpen: false });
  }
  onChartCodeModalOpen() {
    if (this.state.chart_code_hide) {
      this.setState({ chartC_code_hide: true });
    } else {
      this.setState({ chartC_code_hide: false });
    }

    this.setState({ ChartCodeModalOpen: true });
  }
  onChartCodeModalClose() {
    if (this.state.chartC_code_hide) {
      this.setState({ chart_code_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "chart_code") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ chart_code_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "chart_code") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ ChartCodeModalOpen: false });
  }
  onFlagModalOpen() {
    if (this.state.flag_hide) {
      this.setState({ flagC_hide: true });
    } else {
      this.setState({ flagC_hide: false });
    }

    this.setState({ FlagModalOpen: true });
  }
  onFlagModalClose() {
    if (this.state.flagC_hide) {
      this.setState({ flag_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "flag") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ flag_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "flag") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ FlagModalOpen: false });
  }
  onPaymentFlagModalOpen() {
    if (this.state.payment_flag_hide) {
      this.setState({ paymentC_flag_hide: true });
    } else {
      this.setState({ paymentC_flag_hide: false });
    }

    this.setState({ PaymentFlagModalOpen: true });
  }
  onPaymentFlagModalClose() {
    if (this.state.paymentC_flag_hide) {
      this.setState({ payment_flag_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "payment_flag") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ payment_flag_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "payment_flag") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ PaymentFlagModalOpen: false });
  }
  onCalcDaysModalClose() {
    if (this.state.calcC_days_hide) {
      this.setState({ calc_days_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "calc_days") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ calc_days_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "calc_days") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ CalcDaysModalOpen: false });
  }
  onCalcDaysModalOpen() {
    if (this.state.calc_days_hide) {
      this.setState({ calcC_days_hide: true });
    } else {
      this.setState({ calcC_days_hide: false });
    }

    this.setState({ CalcDaysModalOpen: true });
  }
  onCalcWeeksModalClose() {
    if (this.state.calcC_weeks_hide) {
      this.setState({ calc_weeks_hide: "checked" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "calc_weeks") {
          obj.Hide = 1;
        }
      });
    } else {
      this.setState({ calc_weeks_hide: "" });
      this.state.listHandler.CustomFields.map((obj, i) => {
        if (obj.Prompt === "calc_weeks") {
          obj.Hide = 0;
        }
      });
    }

    this.setState({ CalcWeeksModalOpen: false });
  }
  onCalcWeeksModalOpen() {
    if (this.state.calc_weeks_hide) {
      this.setState({ calcC_weeks_hide: true });
    } else {
      this.setState({ calcC_weeks_hide: false });
    }

    this.setState({ CalcWeeksModalOpen: true });
  }

  editCustomlistCode = async (e, key) => {
    e.preventDefault();

    let CustomListFields = this.state.CustomListFields[key];

    //SET sTATE
    var eDate;
    var eDate_dbfield;
    var eDate_validate;
    var eDate_hide;
    var eDate_readonly;
    var eDate_required;
    var eDate_length;
    var eDate_script;
    var eDate_tag;
    var eDate_type;
    var eDate_order;

    var sDate;
    var sDate_dbfield;
    var sDate_validate;
    var sDate_hide;
    var sDate_readonly;
    var sDate_required;
    var sDate_length;
    var sDate_script;
    var sDate_tag;
    var sDate_type;
    var sDate_order;

    var unitV;
    var unitV_dbfield;
    var unitV_validate;
    var unitV_hide;
    var unitV_readonly;
    var unitV_required;
    var unitV_length;
    var unitV_script;
    var unitV_tag;
    var unitV_type;
    var unitV_order;

    var unitDes;
    var unitDes_dbfield;
    var unitDes_validate;
    var unitDes_hide;
    var unitDes_readonly;
    var unitDes_required;
    var unitDes_length;
    var unitDes_script;
    var unitDes_tag;
    var unitDes_type;
    var unitDes_order;

    var rateV;
    var rateV_dbfield;
    var rateV_validate;
    var rateV_hide;
    var rateV_readonly;
    var rateV_required;
    var rateV_length;
    var rateV_script;
    var rateV_tag;
    var rateV_type;
    var rateV_order;

    var factorV;
    var factorV_dbfield;
    var factorV_validate;
    var factorV_hide;
    var factorV_readonly;
    var factorV_required;
    var factorV_length;
    var factorV_script;
    var factorV_tag;
    var factorV_type;
    var factorV_order;

    var amountV;
    var amountV_dbfield;
    var amountV_validate;
    var amountV_hide;
    var amountV_readonly;
    var amountV_required;
    var amountV_length;
    var amountV_script;
    var amountV_tag;
    var amountV_type;
    var amountV_order;

    var calcWeeks;
    var calcWeeks_dbfield;
    var calcWeeks_validate;
    var calcWeeks_hide;
    var calcWeeks_readonly;
    var calcWeeks_required;
    var calcWeeks_length;
    var calcWeeks_script;
    var calcWeeks_tag;
    var calcWeeks_type;
    var calcWeeks_order;

    var calcDays;
    var calcDays_dbfield;
    var calcDays_validate;
    var calcDays_hide;
    var calcDays_readonly;
    var calcDays_required;
    var calcDays_length;
    var calcDays_script;
    var calcDays_tag;
    var calcDays_type;
    var calcDays_order;

    var dayWeek;
    var dayWeek_dbfield;
    var dayWeek_validate;
    var dayWeek_hide;
    var dayWeek_readonly;
    var dayWeek_required;
    var dayWeek_length;
    var dayWeek_script;
    var dayWeek_tag;
    var dayWeek_type;
    var dayWeek_order;

    var cap_amountV;
    var cap_amountV_dbfield;
    var cap_amountV_validate;
    var cap_amountV_hide;
    var cap_amountV_readonly;
    var cap_amountV_required;
    var cap_amountV_length;
    var cap_amountV_script;
    var cap_amountV_tag;
    var cap_amountV_type;
    var cap_amountV_order;

    var chart_S;
    var chart_S_dbfield;
    var chart_S_validate;
    var chart_S_hide;
    var chart_S_readonly;
    var chart_S_required;
    var chart_S_length;
    var chart_S_script;
    var chart_S_tag;
    var chart_S_type;
    var chart_S_order;

    var chart_C;
    var chart_C_dbfield;
    var chart_C_validate;
    var chart_C_hide;
    var chart_C_readonly;
    var chart_C_required;
    var chart_C_length;
    var chart_C_script;
    var chart_C_tag;
    var chart_C_type;
    var chart_C_order;

    var flagV;
    var flagV_dbfield;
    var flagV_validate;
    var flagV_hide;
    var flagV_readonly;
    var flagV_required;
    var flagV_length;
    var flagV_script;
    var flagV_tag;
    var flagV_type;
    var flagV_order;

    var pay_flag;
    var pay_flag_dbfield;
    var pay_flag_validate;
    var pay_flag_hide;
    var pay_flag_readonly;
    var pay_flag_required;
    var pay_flag_length;
    var pay_flag_script;
    var pay_flag_tag;
    var pay_flag_type;
    var pay_flag_order;

    this.state.CustomListFields[key].CustomFields.map((obj, i) => {
      if (obj.Prompt === "unit") {
        unitV = obj.DefaultValue;
        unitV_dbfield = obj.DBField;
        unitV_hide = obj.Hide;
        unitV_length = obj.Length;
        unitV_order = obj.order;
        unitV_readonly = obj.Readonly;
        unitV_required = obj.Required;
        unitV_script = obj.Script;
        unitV_tag = obj.Tag;
        unitV_type = obj.Type;
        unitV_validate = obj.Validation;
      }
      if (obj.Prompt === "unit_desc") {
        unitDes = obj.DefaultValue;
        unitDes_dbfield = obj.DBField;
        unitDes_hide = obj.Hide;
        unitDes_length = obj.Length;
        unitDes_order = obj.order;
        unitDes_readonly = obj.Readonly;
        unitDes_required = obj.Required;
        unitDes_script = obj.Script;
        unitDes_tag = obj.Tag;
        unitDes_type = obj.Type;
        unitDes_validate = obj.Validation;
      }
      if (obj.Prompt === "rate") {
        rateV = obj.DefaultValue;
        rateV_dbfield = obj.DBField;
        rateV_hide = obj.Hide;
        rateV_length = obj.Length;
        rateV_order = obj.order;
        rateV_readonly = obj.Readonly;
        rateV_required = obj.Required;
        rateV_script = obj.Script;
        rateV_tag = obj.Tag;
        rateV_type = obj.Type;
        rateV_validate = obj.Validation;
      }
      if (obj.Prompt === "factor") {
        factorV = obj.DefaultValue;

        factorV_dbfield = obj.DBField;
        factorV_hide = obj.Hide;
        factorV_length = obj.Length;
        factorV_order = obj.order;
        factorV_readonly = obj.Readonly;
        factorV_required = obj.Required;
        factorV_script = obj.Script;
        factorV_tag = obj.Tag;
        factorV_type = obj.Type;
        factorV_validate = obj.Validation;
      }

      if (obj.Prompt === "amount") {
        amountV = obj.DefaultValue;

        amountV_dbfield = obj.DBField;
        amountV_hide = obj.Hide;
        amountV_length = obj.Length;
        amountV_order = obj.order;
        amountV_readonly = obj.Readonly;
        amountV_required = obj.Required;
        amountV_script = obj.Script;
        amountV_tag = obj.Tag;
        amountV_type = obj.Type;
        amountV_validate = obj.Validation;
      }

      if (obj.Prompt === "capped_amount") {
        cap_amountV = obj.DefaultValue;

        cap_amountV_dbfield = obj.DBField;
        cap_amountV_hide = obj.Hide;
        cap_amountV_length = obj.Length;
        cap_amountV_order = obj.order;
        cap_amountV_readonly = obj.Readonly;
        cap_amountV_required = obj.Required;
        cap_amountV_script = obj.Script;
        cap_amountV_tag = obj.Tag;
        cap_amountV_type = obj.Type;
        cap_amountV_validate = obj.Validation;
      }

      if (obj.Prompt === "startDate") {
        sDate = obj.DefaultValue;

        sDate_dbfield = obj.DBField;
        sDate_hide = obj.Hide;
        sDate_length = obj.Length;
        sDate_order = obj.order;
        sDate_readonly = obj.Readonly;
        sDate_required = obj.Required;
        sDate_script = obj.Script;
        sDate_tag = obj.Tag;
        sDate_type = obj.Type;
        sDate_validate = obj.Validation;
      }

      if (obj.Prompt === "endDate") {
        eDate = obj.DefaultValue;

        eDate_dbfield = obj.DBField;
        eDate_hide = obj.Hide;
        eDate_length = obj.Length;
        eDate_order = obj.order;
        eDate_readonly = obj.Readonly;
        eDate_required = obj.Required;
        eDate_script = obj.Script;
        eDate_tag = obj.Tag;
        eDate_type = obj.Type;
        eDate_validate = obj.Validation;
      }

      if (obj.Prompt === "chart_sort") {
        chart_S = obj.DefaultValue;

        chart_S_dbfield = obj.DBField;
        chart_S_hide = obj.Hide;
        chart_S_length = obj.Length;
        chart_S_order = obj.order;
        chart_S_readonly = obj.Readonly;
        chart_S_required = obj.Required;
        chart_S_script = obj.Script;
        chart_S_tag = obj.Tag;
        chart_S_type = obj.Type;
        chart_S_validate = obj.Validation;
      }
      if (obj.Prompt === "chart_code") {
        chart_C = obj.DefaultValue;

        chart_C_dbfield = obj.DBField;
        chart_C_hide = obj.Hide;
        chart_C_length = obj.Length;
        chart_C_order = obj.order;
        chart_C_readonly = obj.Readonly;
        chart_C_required = obj.Required;
        chart_C_script = obj.Script;
        chart_C_tag = obj.Tag;
        chart_C_type = obj.Type;
        chart_C_validate = obj.Validation;
      }
      if (obj.Prompt === "flag") {
        flagV = obj.DefaultValue;

        flagV_dbfield = obj.DBField;
        flagV_hide = obj.Hide;
        flagV_length = obj.Length;
        flagV_order = obj.order;
        flagV_readonly = obj.Readonly;
        flagV_required = obj.Required;
        flagV_script = obj.Script;
        flagV_tag = obj.Tag;
        flagV_type = obj.Type;
        flagV_validate = obj.Validation;
      }
      if (obj.Prompt === "payment_flag") {
        pay_flag = obj.DefaultValue;

        pay_flag_dbfield = obj.DBField;
        pay_flag_hide = obj.Hide;
        pay_flag_length = obj.Length;
        pay_flag_order = obj.order;
        pay_flag_readonly = obj.Readonly;
        pay_flag_required = obj.Required;
        pay_flag_script = obj.Script;
        pay_flag_tag = obj.Tag;
        pay_flag_type = obj.Type;
        pay_flag_validate = obj.Validation;
      }
      if (obj.Prompt === "calc_days") {
        calcDays = obj.DefaultValue;

        calcDays_dbfield = obj.DBField;
        calcDays_hide = obj.Hide;
        calcDays_length = obj.Length;
        calcDays_order = obj.order;
        calcDays_readonly = obj.Readonly;
        calcDays_required = obj.Required;
        calcDays_script = obj.Script;
        calcDays_tag = obj.Tag;
        calcDays_type = obj.Type;
        calcDays_validate = obj.Validation;
      }
      if (obj.Prompt === "calc_weeks") {
        calcWeeks = obj.DefaultValue;

        calcWeeks_dbfield = obj.DBField;
        calcWeeks_hide = obj.Hide;
        calcWeeks_length = obj.Length;
        calcWeeks_order = obj.order;
        calcWeeks_readonly = obj.Readonly;
        calcWeeks_required = obj.Required;
        calcWeeks_script = obj.Script;
        calcWeeks_tag = obj.Tag;
        calcWeeks_type = obj.Type;
        calcWeeks_validate = obj.Validation;
      }
      if (obj.Prompt === "days_in_week") {
        dayWeek = obj.DefaultValue;

        dayWeek_dbfield = obj.DBField;
        dayWeek_hide = obj.Hide;
        dayWeek_length = obj.Length;
        dayWeek_order = obj.order;
        dayWeek_readonly = obj.Readonly;
        dayWeek_required = obj.Required;
        dayWeek_script = obj.Script;
        dayWeek_tag = obj.Tag;
        dayWeek_type = obj.Type;
        dayWeek_validate = obj.Validation;
      }
    });

    this.setState(
      {
        customlistcodeeditkey: key,
        pmt_code: CustomListFields.CustomCode,
        desc: CustomListFields.Desc,
        TBA: CustomListFields.TBA,
        unit: unitV ? unitV : null,
        unit_dbfield: unitV_dbfield,
        unit_validate: unitV_validate,
        unit_hide: unitV_hide,
        unit_readonly: unitV_readonly,
        unit_required: unitV_required,
        unit_length: unitV_length,
        unit_script: unitV_script,
        unit_tag: unitV_tag,
        unit_type: unitV_type,
        unit_order: unitV_order,

        unit_desc: unitDes ? unitDes : null,
        unit_desc_dbfield: unitDes_dbfield,
        unit_desc_validate: unitDes_validate,
        unit_desc_hide: unitDes_hide,
        unit_desc_readonly: unitDes_readonly,
        unit_desc_required: unitDes_required,
        unit_desc_length: unitDes_length,
        unit_desc_script: unitDes_script,
        unit_desc_tag: unitDes_tag,
        unit_desc_type: unitDes_type,
        unit_desc_order: unitDes_order,

        rate: rateV ? rateV : null,
        rate_dbfield: rateV_dbfield,
        rate_validate: rateV_validate,
        rate_hide: rateV_hide,
        rate_readonly: rateV_readonly,
        rate_required: rateV_required,
        rate_length: rateV_length,
        rate_script: rateV_script,
        rate_tag: rateV_tag,
        rate_type: rateV_type,
        rate_order: rateV_order,

        factor: factorV ? factorV : null,
        factor_dbfield: factorV_dbfield,
        factor_validate: factorV_validate,
        factor_hide: factorV_hide,
        factor_readonly: factorV_readonly,
        factor_required: factorV_required,
        factor_length: factorV_length,
        factor_script: factorV_script,
        factor_tag: factorV_tag,
        factor_type: factorV_type,
        factor_order: factorV_order,

        amount: amountV ? amountV : null,
        amount_dbfield: amountV_dbfield,
        amount_validate: amountV_validate,
        amount_hide: amountV_hide,
        amount_readonly: amountV_readonly,
        amount_required: amountV_required,
        amount_length: amountV_length,
        amount_script: amountV_script,
        amount_tag: amountV_tag,
        amount_type: amountV_type,
        amount_order: amountV_order,

        capped_amount: cap_amountV ? cap_amountV : null,
        capped_amount_dbfield: cap_amountV_dbfield,
        capped_amount_validate: cap_amountV_validate,
        capped_amount_hide: cap_amountV_hide,
        capped_amount_readonly: cap_amountV_readonly,
        capped_amount_required: cap_amountV_required,
        capped_amount_length: cap_amountV_length,
        capped_amount_script: cap_amountV_script,
        capped_amount_tag: cap_amountV_tag,
        capped_amount_type: cap_amountV_type,
        capped_amount_order: cap_amountV_order,

        startDate: sDate ? moment(sDate) : moment(),
        startDate_dbfield: sDate_dbfield,
        startDate_validate: sDate_validate,
        startDate_hide: sDate_hide,
        startDate_readonly: sDate_readonly,
        startDate_required: sDate_required,
        startDate_length: sDate_length,
        startDate_script: sDate_script,
        startDate_tag: sDate_tag,
        startDate_type: sDate_type,
        startDate_order: sDate_order,

        endDate: eDate ? moment(eDate) : moment(),
        endDate_dbfield: eDate_dbfield,
        endDate_validate: eDate_validate,
        endDate_hide: eDate_hide,
        endDate_readonly: eDate_readonly,
        endDate_required: eDate_required,
        endDate_length: eDate_length,
        endDate_script: eDate_script,
        endDate_tag: eDate_tag,
        endDate_type: eDate_type,
        endDate_order: eDate_order,

        chart_sort: chart_S ? chart_S : null,
        chart_sort_dbfield: chart_S_dbfield,
        chart_sort_validate: chart_S_validate,
        chart_sort_hide: chart_S_hide,
        chart_sort_readonly: chart_S_readonly,
        chart_sort_required: chart_S_required,
        chart_sort_length: chart_S_length,
        chart_sort_script: chart_S_script,
        chart_sort_tag: chart_S_tag,
        chart_sort_type: chart_S_type,
        chart_sort_order: chart_S_order,

        chart_code: chart_C ? chart_C : null,
        chart_code_dbfield: chart_C_dbfield,
        chart_code_validate: chart_C_validate,
        chart_code_hide: chart_C_hide,
        chart_code_readonly: chart_C_readonly,
        chart_code_required: chart_C_required,
        chart_code_length: chart_C_length,
        chart_code_script: chart_C_script,
        chart_code_tag: chart_C_tag,
        chart_code_type: chart_C_type,
        chart_code_order: chart_C_order,

        flag: flagV ? flagV : null,
        flag_dbfield: flagV_dbfield,
        flag_validate: flagV_validate,
        flag_hide: flagV_hide,
        flag_readonly: flagV_readonly,
        flag_required: flagV_required,
        flag_length: flagV_length,
        flag_script: flagV_script,
        flag_tag: flagV_tag,
        flag_type: flagV_type,
        flag_order: flagV_order,

        payment_flag: pay_flag ? pay_flag : null,
        payment_flag_dbfield: pay_flag_dbfield,
        payment_flag_validate: pay_flag_validate,
        payment_flag_hide: pay_flag_hide,
        payment_flag_readonly: pay_flag_readonly,
        payment_flag_required: pay_flag_required,
        payment_flag_length: pay_flag_length,
        payment_flag_script: pay_flag_script,
        payment_flag_tag: pay_flag_tag,
        payment_flag_type: pay_flag_type,
        payment_flag_order: pay_flag_order,

        calc_days: calcDays ? calcDays : null,
        calc_days_dbfield: calcDays_dbfield,
        calc_days_validate: calcDays_validate,
        calc_days_hide: calcDays_hide,
        calc_days_readonly: calcDays_readonly,
        calc_days_required: calcDays_required,
        calc_days_length: calcDays_length,
        calc_days_script: calcDays_script,
        calc_days_tag: calcDays_tag,
        calc_days_type: calcDays_type,
        calc_days_order: calcDays_order,

        calc_weeks: calcWeeks ? calcWeeks : null,
        calc_weeks_dbfield: calcWeeks_dbfield,
        calc_weeks_validate: calcWeeks_validate,
        calc_weeks_hide: calcWeeks_hide,
        calc_weeks_readonly: calcWeeks_readonly,
        calc_weeks_required: calcWeeks_required,
        calc_weeks_length: calcWeeks_length,
        calc_weeks_script: calcWeeks_script,
        calc_weeks_tag: calcWeeks_tag,
        calc_weeks_type: calcWeeks_type,
        calc_weeks_order: calcWeeks_order,
      },
      () => console.log("???", this.state.unit_desc_hide)
    );

    if (dayWeek) {
      this.setState({
        days_in_week: dayWeek ? dayWeek : null,
        days_in_week_dbfield: dayWeek_dbfield,
        days_in_week_validate: dayWeek_validate,
        days_in_week_hide: dayWeek_hide,
        days_in_week_readonly: dayWeek_readonly,
        days_in_week_required: dayWeek_required,
        days_in_week_length: dayWeek_length,
        days_in_week_script: dayWeek_script,
        days_in_week_tag: dayWeek_tag,
        days_in_week_type: dayWeek_type,
        days_in_week_order: dayWeek_order,
      });
    } else {
      this.setState({
        days_in_week: null,
        days_in_week_dbfield: null,
        days_in_week_validate: null,
        days_in_week_hide: null,
        days_in_week_readonly: null,
        days_in_week_required: null,
        days_in_week_length: null,
        days_in_week_script: null,
        days_in_week_tag: null,
        days_in_week_type: null,
        days_in_week_order: 15,
      });
    }
    //SET sTATE END

    this.state.listHandler.CustomFields.map((obj, i) => {
      if (obj.Prompt === "unit") {
        obj.Hide = unitV_hide;
        obj.order = unitV_order;
      }
      if (obj.Prompt === "unit_desc") {
        obj.Hide = unitDes_hide;
        obj.order = unitDes_order;
      }
      if (obj.Prompt === "rate") {
        obj.Hide = rateV_hide;
        obj.order = rateV_order;
      }
      if (obj.Prompt === "factor") {
        obj.Hide = factorV_hide;
        obj.order = factorV_order;
      }

      if (obj.Prompt === "amount") {
        obj.Hide = amountV_hide;
        obj.order = amountV_order;
      }

      if (obj.Prompt === "capped_amount") {
        obj.Hide = cap_amountV_hide;
        obj.order = cap_amountV_order;
      }

      if (obj.Prompt === "startDate") {
        obj.Hide = sDate_hide;
        obj.order = sDate_order;
      }

      if (obj.Prompt === "endDate") {
        obj.Hide = eDate_hide;
        obj.order = eDate_order;
      }

      if (obj.Prompt === "chart_sort") {
        obj.Hide = chart_S_hide;
        obj.order = chart_S_order;
      }
      if (obj.Prompt === "chart_code") {
        obj.Hide = chart_C_hide;
        obj.order = chart_C_order;
      }
      if (obj.Prompt === "flag") {
        obj.Hide = flagV_hide;
        obj.order = flagV_order;
      }
      if (obj.Prompt === "payment_flag") {
        obj.Hide = pay_flag_hide;
        obj.order = pay_flag_order;
      }
      if (obj.Prompt === "calc_days") {
        obj.Hide = calcDays_hide;
        obj.order = calcDays_order;
      }
      if (obj.Prompt === "calc_weeks") {
        obj.Hide = calcWeeks_hide;
        obj.order = calcWeeks_order;
      }
      if (obj.Prompt === "days_in_week") {
        obj.Hide = dayWeek_hide;
        obj.order = dayWeek_order;
      }
    });
    this.state.listHandler.CustomFields.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    this.onad_ModalOpen();
    //console.log('edit custom list code');
  };

  deleteCustomlistCode = (e, key) => {
    e.preventDefault();
    let CustomListFields = this.state.CustomListFields;
    let test;
    test = CustomListFields.filter((obj, i) => i !== key);

    this.setState(
      {
        CustomListFields: [...test],
      },
      () => console.log(this.state.CustomListFields)
    );
  };

  get_form_template = async () => {
    this.setState({ isLoading: true });
    try {
      var response = await this.props.postRequest(
        "/form-template/get-template",
        { Guid: localStorage.getItem("ftGuid") }
      );
      if (response.status) {
        var result = response.result;
        this.setState({
          //formtemplates:result,
          detail_template_code: result.TemplateCode
            ? result.TemplateCode
            : null,
          detail_template_description: result.Desc ? result.Desc : null,
          detail_document: result.filename ? result.filename : null,
          detail_doc_password: result.DocPassword ? result.DocPassword : null,
          detail_confidential: result.Confidential
            ? result.Confidential
            : false,
          detail_hide_from_external_signer: result.ExtParty
            ? result.ExtParty
            : false,
          detail_email_dist: result.EmailDist[0]
            ? result.EmailDist.join(";")
            : null,
          documentAttachment: result.DigitalDoc ? result.DigitalDoc : null,
          documentFilename: result.filename ? result.filename : null,
          FileAttachments: result.DocumentAtt ? result.DocumentAtt : null,
          CustomListFields: result.CustomList ? result.CustomList : [],
          form_entry_fields: result.DocDetails ? result.DocDetails : [],
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        toast.error(response.error, { autoClose: 5000 });
      }
      console.log("/form-template/get-template=>", response);
    } catch (err) {
      this.setState({ isLoading: false });
      toast.error(err.message, { autoClose: 5000 });
      console.log("/form-template/get-template error=>", err);
    }
    /*result.DocumentAtt.forEach(file => {
            //console.log('result.DocumentAtt',file);
            this.get_image(file.url);
        });*/
  };
  //EDIT FORMTEMPLATE END

  handleSubmit = async (event) => {
    event.preventDefault();
    let pmt_code = this.state.pmt_code;
    let desc = this.state.desc;
    let TBA = this.state.TBA;
    let formErrors = Object.assign({}, this.state.formErrors);
    if (pmt_code === false || pmt_code === null || pmt_code === "") {
      formErrors.pmt_code = "This field is required";
    }
    if (desc === false || desc === null || desc === "") {
      formErrors.desc = "This field is required";
      $("#addCustomFieldlistmodal .modal-body").scrollTop(0);
    }
    if (pmt_code === false || pmt_code === null || pmt_code === "") {
      formErrors.pmt_code = "This field is required";
      $("#addCustomFieldlistmodal .modal-body").scrollTop(0);
    }

    let customListArray = [
      {
        DefaultValue: this.state.unit ? this.state.unit : 0,
        DBField: this.state.unit_dbfield ? this.state.unit_dbfield : null,
        Validation: this.state.unit_validate ? this.state.unit_validate : null,
        Hide: this.state.unit_hide === "" ? null : this.state.unit_hide,
        Readonly:
          this.state.unit_readonly === "" ? null : this.state.unit_readonly,
        Required:
          this.state.unit_required === "" ? null : this.state.unit_required,
        Length: this.state.unit_length ? this.state.unit_length : null,
        Script: this.state.unit_script ? this.state.unit_script : null,
        Tag: this.state.unit_tag ? this.state.unit_tag : null,
        Type: this.state.unit_type ? this.state.unit_type : null,
        order: this.state.unit_order,
        Prompt: "unit",
      },
      {
        DefaultValue: this.state.unit_desc ? this.state.unit_desc : null,
        DBField: this.state.unit_desc_dbfield
          ? this.state.unit_desc_dbfield
          : null,
        Validation: this.state.unit_desc_validate
          ? this.state.unit_desc_validate
          : null,
        Hide:
          this.state.unit_desc_hide === "" ? null : this.state.unit_desc_hide,
        Readonly:
          this.state.unit_desc_readonly === ""
            ? null
            : this.state.unit_desc_readonly,
        Required:
          this.state.unit_desc_required === ""
            ? null
            : this.state.unit_desc_required,
        Length: this.state.unit_desc_length
          ? this.state.unit_desc_length
          : null,
        Script: this.state.unit_desc_script
          ? this.state.unit_desc_script
          : null,
        Tag: this.state.unit_desc_tag ? this.state.unit_desc_tag : null,
        Type: this.state.unit_desc_type ? this.state.unit_desc_type : null,
        order: this.state.unit_desc_order,
        Prompt: "unit_desc",
      },
      {
        DefaultValue: this.state.rate ? this.state.rate : 0,
        DBField: this.state.rate_dbfield ? this.state.rate_dbfield : null,
        Validation: this.state.rate_validate ? this.state.rate_validate : null,
        Hide: this.state.rate_hide === "" ? null : this.state.rate_hide,
        Readonly:
          this.state.rate_readonly === "" ? null : this.state.rate_readonly,
        Required:
          this.state.rate_required === "" ? null : this.state.rate_required,
        Length: this.state.rate_length ? this.state.rate_length : null,
        Script: this.state.rate_script ? this.state.rate_script : null,
        Tag: this.state.rate_tag ? this.state.rate_tag : null,
        Type: this.state.rate_type ? this.state.rate_type : null,
        order: this.state.rate_order,
        Prompt: "rate",
      },
      {
        DefaultValue: this.state.factor ? this.state.factor : 0,
        DBField: this.state.factor_dbfield ? this.state.factor_dbfield : null,
        Validation: this.state.factor_validate
          ? this.state.factor_validate
          : null,
        Hide: this.state.factor_hide === "" ? null : this.state.factor_hide,
        Readonly:
          this.state.factor_readonly === "" ? null : this.state.factor_readonly,
        Required:
          this.state.factor_required === "" ? null : this.state.factor_required,
        Length: this.state.factor_length ? this.state.factor_length : null,
        Script: this.state.factor_script ? this.state.factor_script : null,
        Tag: this.state.factor_tag ? this.state.factor_tag : null,
        Type: this.state.factor_type ? this.state.factor_type : null,
        order: this.state.factor_order,
        Prompt: "factor",
      },
      {
        DefaultValue: this.state.amount ? this.state.amount : 0,
        DBField: this.state.amount_dbfield ? this.state.amount_dbfield : null,
        Validation: this.state.amount_validate
          ? this.state.amount_validate
          : null,
        Hide: this.state.amount_hide === "" ? null : this.state.amount_hide,
        Readonly:
          this.state.amount_readonly === "" ? null : this.state.amount_readonly,
        Required:
          this.state.amount_required === "" ? null : this.state.amount_required,
        Length: this.state.amount_length ? this.state.amount_length : null,
        Script: this.state.amount_script ? this.state.amount_script : null,
        Tag: this.state.amount_tag ? this.state.amount_tag : null,
        Type: this.state.amount_type ? this.state.amount_type : null,
        order: this.state.amount_order,
        Prompt: "amount",
      },
      {
        DefaultValue: this.state.capped_amount ? this.state.capped_amount : 0,
        DBField: this.state.capped_amount_dbfield
          ? this.state.capped_amount_dbfield
          : null,
        Validation: this.state.capped_amount_validate
          ? this.state.capped_amount_validate
          : null,
        Hide:
          this.state.capped_amount_hide === ""
            ? null
            : this.state.capped_amount_hide,
        Readonly:
          this.state.capped_amount_readonly === ""
            ? null
            : this.state.capped_amount_readonly,
        Required:
          this.state.capped_amount_required === ""
            ? null
            : this.state.capped_amount_required,
        Length: this.state.capped_amount_length
          ? this.state.capped_amount_length
          : null,
        Script: this.state.capped_amount_script
          ? this.state.capped_amount_script
          : null,
        Tag: this.state.capped_amount_tag ? this.state.capped_amount_tag : null,
        Type: this.state.capped_amount_type
          ? this.state.capped_amount_type
          : null,
        order: this.state.capped_amount_order,
        Prompt: "capped_amount",
      },
      {
        DefaultValue: this.state.startDate.toString(),
        DBField: this.state.startDate_dbfield
          ? this.state.startDate_dbfield
          : null,
        Validation: this.state.startDate_validate
          ? this.state.startDate_validate
          : null,
        Hide:
          this.state.startDate_hide === "" ? null : this.state.startDate_hide,
        Readonly:
          this.state.startDate_readonly === ""
            ? null
            : this.state.startDate_readonly,
        Required:
          this.state.startDate_required === ""
            ? null
            : this.state.startDate_required,
        Length: this.state.startDate_length
          ? this.state.startDate_length
          : null,
        Script: this.state.startDate_script
          ? this.state.startDate_script
          : null,
        Tag: this.state.startDate_tag ? this.state.startDate_tag : null,
        Type: this.state.startDate_type ? this.state.startDate_type : null,
        order: this.state.startDate_order,
        Prompt: "startDate",
      },
      {
        DefaultValue: this.state.endDate.toString(),
        DBField: this.state.endDate_dbfield ? this.state.endDate_dbfield : null,
        Validation: this.state.endDate_validate
          ? this.state.endDate_validate
          : null,
        Hide: this.state.endDate_hide === "" ? null : this.state.endDate_hide,
        Readonly:
          this.state.endDate_readonly === ""
            ? null
            : this.state.endDate_readonly,
        Required:
          this.state.endDate_required === ""
            ? null
            : this.state.endDate_required,
        Length: this.state.endDate_length ? this.state.endDate_length : null,
        Script: this.state.endDate_script ? this.state.endDate_script : null,
        Tag: this.state.endDate_tag ? this.state.endDate_tag : null,
        Type: this.state.endDate_type ? this.state.endDate_type : null,
        order: this.state.endDate_order,
        Prompt: "endDate",
      },
      {
        DefaultValue: this.state.chart_sort ? this.state.chart_sort : null,
        DBField: this.state.chart_sort_dbfield
          ? this.state.chart_sort_dbfield
          : null,
        Validation: this.state.chart_sort_validate
          ? this.state.chart_sort_validate
          : null,
        Hide:
          this.state.chart_sort_hide === "" ? null : this.state.chart_sort_hide,
        Readonly:
          this.state.chart_sort_readonly === ""
            ? null
            : this.state.chart_sort_readonly,
        Required:
          this.state.chart_sort_required === ""
            ? null
            : this.state.chart_sort_required,
        Length: this.state.chart_sort_length
          ? this.state.chart_sort_length
          : null,
        Script: this.state.chart_sort_script
          ? this.state.chart_sort_script
          : null,
        Tag: this.state.chart_sort_tag ? this.state.chart_sort_tag : null,
        Type: this.state.chart_sort_type ? this.state.chart_sort_type : null,
        order: this.state.chart_sort_order,
        Prompt: "chart_sort",
      },
      {
        DefaultValue: this.state.chart_code ? this.state.chart_code : null,
        DBField: this.state.chart_code_dbfield
          ? this.state.chart_code_dbfield
          : null,
        Validation: this.state.chart_code_validate
          ? this.state.chart_code_validate
          : null,
        Hide:
          this.state.chart_code_hide === "" ? null : this.state.chart_code_hide,
        Readonly:
          this.state.chart_code_readonly === ""
            ? null
            : this.state.chart_code_readonly,
        Required:
          this.state.chart_code_required === ""
            ? null
            : this.state.chart_code_required,
        Length: this.state.chart_code_length
          ? this.state.chart_code_length
          : null,
        Script: this.state.chart_code_script
          ? this.state.chart_code_script
          : null,
        Tag: this.state.chart_code_tag ? this.state.chart_code_tag : null,
        Type: this.state.chart_code_type ? this.state.chart_code_type : null,
        order: this.state.chart_code_order,
        Prompt: "chart_code",
      },
      {
        DefaultValue: this.state.flag ? this.state.flag : null,
        DBField: this.state.flag_dbfield ? this.state.flag_dbfield : null,
        Validation: this.state.flag_validate ? this.state.flag_validate : null,
        Hide: this.state.flag_hide === "" ? null : this.state.flag_hide,
        Readonly:
          this.state.flag_readonly === "" ? null : this.state.flag_readonly,
        Required:
          this.state.flag_required === "" ? null : this.state.flag_required,
        Length: this.state.flag_length ? this.state.flag_length : null,
        Script: this.state.flag_script ? this.state.flag_script : null,
        Tag: this.state.flag_tag ? this.state.flag_tag : null,
        Type: this.state.flag_type ? this.state.flag_type : null,
        order: this.state.flag_order,
        Prompt: "flag",
      },
      {
        DefaultValue: this.state.payment_flag ? this.state.payment_flag : null,
        DBField: this.state.payment_flag_dbfield
          ? this.state.payment_flag_dbfield
          : null,
        Validation: this.state.payment_flag_validate
          ? this.state.payment_flag_validate
          : null,
        Hide:
          this.state.payment_flag_hide === ""
            ? null
            : this.state.payment_flag_hide,
        Readonly:
          this.state.payment_flag_readonly === ""
            ? null
            : this.state.payment_flag_readonly,
        Required:
          this.state.payment_flag_required === ""
            ? null
            : this.state.payment_flag_required,
        Length: this.state.payment_flag_length
          ? this.state.payment_flag_length
          : null,
        Script: this.state.payment_flag_script
          ? this.state.payment_flag_script
          : null,
        Tag: this.state.payment_flag_tag ? this.state.payment_flag_tag : null,
        Type: this.state.payment_flag_type
          ? this.state.payment_flag_type
          : null,
        order: this.state.payment_flag_order,
        Prompt: "payment_flag",
      },
      {
        DefaultValue: this.state.calc_days ? this.state.calc_days : null,
        DBField: this.state.calc_days_dbfield
          ? this.state.calc_days_dbfield
          : null,
        Validation: this.state.calc_days_validate
          ? this.state.calc_days_validate
          : null,
        Hide:
          this.state.calc_days_hide === "" ? null : this.state.calc_days_hide,
        Readonly:
          this.state.calc_days_readonly === ""
            ? null
            : this.state.calc_days_readonly,
        Required:
          this.state.calc_days_required === ""
            ? null
            : this.state.calc_days_required,
        Length: this.state.calc_days_length
          ? this.state.calc_days_length
          : null,
        Script: this.state.calc_days_script
          ? this.state.calc_days_script
          : null,
        Tag: this.state.calc_days_tag ? this.state.calc_days_tag : null,
        Type: this.state.calc_days_type ? this.state.calc_days_type : null,
        order: this.state.calc_days_order,
        Prompt: "calc_days",
      },
      {
        DefaultValue: this.state.calc_weeks ? this.state.calc_weeks : null,
        DBField: this.state.calc_weeks_dbfield
          ? this.state.calc_weeks_dbfield
          : null,
        Validation: this.state.calc_weeks_validate
          ? this.state.calc_weeks_validate
          : null,
        Hide:
          this.state.calc_weeks_hide === "" ? null : this.state.calc_weeks_hide,
        Readonly:
          this.state.calc_weeks_readonly === ""
            ? null
            : this.state.calc_weeks_readonly,
        Required:
          this.state.calc_weeks_required === ""
            ? null
            : this.state.calc_weeks_required,
        Length: this.state.calc_weeks_length
          ? this.state.calc_weeks_length
          : null,
        Script: this.state.calc_weeks_script
          ? this.state.calc_weeks_script
          : null,
        Tag: this.state.calc_weeks_tag ? this.state.calc_weeks_tag : null,
        Type: this.state.calc_weeks_type ? this.state.calc_weeks_type : null,
        order: this.state.calc_weeks_order,
        Prompt: "calc_weeks",
      },
      {
        DefaultValue: this.state.days_in_week ? this.state.days_in_week : null,
        DBField: this.state.days_in_week_dbfield
          ? this.state.days_in_week_dbfield
          : null,
        Validation: this.state.days_in_week_validate
          ? this.state.days_in_week_validate
          : null,
        Hide:
          this.state.days_in_week_hide === ""
            ? null
            : this.state.days_in_week_hide,
        Readonly:
          this.state.days_in_week_readonly === ""
            ? null
            : this.state.days_in_week_readonly,
        Required:
          this.state.days_in_week_required === ""
            ? null
            : this.state.days_in_week_required,
        Length: this.state.days_in_week_length
          ? this.state.days_in_week_length
          : null,
        Script: this.state.days_in_week_script
          ? this.state.days_in_week_script
          : null,
        Tag: this.state.days_in_week_tag ? this.state.days_in_week_tag : null,
        Type: this.state.days_in_week_type
          ? this.state.days_in_week_type
          : null,
        order: this.state.days_in_week_order,
        Prompt: "days_in_week",
      },
    ];

    let main_object = {
      CustomCode: this.state.pmt_code,
      CustomFields: customListArray,
      Desc: this.state.desc,
      TBA: this.state.TBA,
    };
    // console.log('main obj=>',main_object);
    var CustomListFields_array = this.state.CustomListFields;
    //console.log('main obj=>',main_object); return fals
    this.setState({ formErrors });
    if (this.state.pmt_code && this.state.desc) {
      if (this.state.customlistcodeeditkey !== "") {
        //console.log('hello------');
        CustomListFields_array[this.state.customlistcodeeditkey] = main_object;
        await this.updateAssignOrder(this.state.customlistcodeeditkey);
        this.setState({
          customlistcodeeditkey: "",
        });
      } else {
        CustomListFields_array.push(main_object);
        await this.assignOrder();
      }

      this.setState({
        CustomListFields: CustomListFields_array,
        customlistcodeeditkey: "",
      });
      console.log("GOT V", this.state.CustomListFields);
      this.onad_ModalClose();
    }
  };

  handleChange(date) {
    let startDate = moment(date.format("YYYY-MM-DD"));
    let endDate = moment(this.state.endDate.format("YYYY-MM-DD"));
    if (startDate.isSameOrBefore(this.state.endDate.format("YYYY-MM-DD"))) {
      this.setState({
        startDate: date,
      });
      var calc_days = (endDate.diff(startDate, "days") + 1) % 7;
      var calc_weeks = endDate.diff(startDate, "weeks");
      if (calc_days >= this.state.days_in_week) {
        calc_weeks++;
        this.setState({
          calc_days: 0,
          calc_weeks: calc_weeks,
        });
      } else {
        if (calc_days === 0) {
          calc_weeks++;
        }
        this.setState({
          calc_days: calc_days,
          calc_weeks: calc_weeks,
        });
      }
    } else {
      this.setState({
        startDate: date,
        calc_weeks: 0,
        calc_days: 0,
      });
    }
  }
  //================Cloned From Custom Fields List End
  detail_template_code = (e) => {
    if (!e.target.value) {
      this.setState({
        template_code_error: true,
      });
    } else {
      this.setState({
        template_code_error: false,
      });
    }
    this.setState({
      detail_template_code: e.target.value ? e.target.value : null,
    });
  };
  detail_template_description = (e) => {
    if (!e.target.value) {
      this.setState({
        template_desc_error: true,
      });
    } else {
      this.setState({
        template_desc_error: false,
      });
    }
    this.setState({
      detail_template_description: e.target.value ? e.target.value : null,
    });
  };
  detail_document = (e) => {
    this.setState({
      detail_document: e.target.value ? e.target.value : null,
    });
  };
  detail_doc_password = (e) => {
    this.setState({
      detail_doc_password: e.target.value ? e.target.value : null,
    });
  };
  detail_confidential = (e) => {
    if (this.state.detail_confidential) {
      this.setState({
        detail_confidential: false,
      });
    } else {
      this.setState({
        detail_confidential: e.target.value ? e.target.value : null,
      });
    }
    //console.log(this.state.detail_confidential);
  };
  detail_hide_from_external_signer = (e) => {
    if (this.state.detail_hide_from_external_signer) {
      this.setState({
        detail_hide_from_external_signer: false,
      });
    } else {
      this.setState({
        detail_hide_from_external_signer: e.target.value
          ? e.target.value
          : null,
      });

      //console.log(this.state.detail_hide_from_external_signer);
    }
  };
  detail_email_dist = (e) => {
    //var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let email = e.target.value;
    var emails = email.split(";");

    let match = false;
    let emails_array = emails;
    emails.forEach((mail) => {
      var check = this.validateEmail(mail);
      if (!check) {
        match = true;
      }
    });

    if (!e.target.value || match) {
      this.setState({
        email_dist_error: true,
        detail_email_dist: e.target.value ? e.target.value : null,
        emails: [],
      });
    } else {
      this.setState({
        email_dist_error: false,
        detail_email_dist: e.target.value ? e.target.value : null,
        emails: emails_array,
      });
    }
  };

  backToProjects = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("ftGuid")) {
      this.props.history.push({
        pathname: "/form-template",
      });
    } else {
      if (this.state.documentAttachment) {
        let upload_path = this.state.documentAttachment.url;
        await this.props.postRequest("/attachments/remove", {
          upload_path: upload_path,
        });
      }
      this.props.history.push({
        pathname: "/form-template",
      });
      /*
			let FileAttachments_urls=this.state.FileAttachments_urls;
			var thisobj = this;
			FileAttachments_urls.forEach(file => {
				thisobj.remove_image(file);
			});*/
    }
  };

  validateEmail(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  }

  submit_document_detail = async (e, currenttab) => {
    e.preventDefault();
    var detail_template_code = this.state.detail_template_code;
    var detail_template_description = this.state.detail_template_description;
    var documentAttachment = this.state.documentAttachment;
    var documentFilename = this.state.documentFilename;
    var detail_doc_password = this.state.detail_doc_password;
    var detail_confidential = this.state.detail_confidential;
    var detail_hide_from_external_signer = this.state
      .detail_hide_from_external_signer;
    var detail_email_dist = this.state.detail_email_dist;
    var FileAttachments = this.state.FileAttachments;
    //console.log('documentAttachment=>',documentAttachment)
    var emails = [];
    if (detail_email_dist) {
      emails = detail_email_dist.split(";");
    }

    //alert(typeof documentAttachment); return false;

    var CustomListFields = this.state.CustomListFields;
    //console.log("Custom List Fields Required => ", CustomListFields);
    // return false;
    //console.log('CustomListFields=>',CustomListFields); return false;
    //form entry fields
    var form_entry_fields = this.state.form_entry_fields;

    //console.log('detail_hide_from_external_signer',detail_hide_from_external_signer);

    //console.log('form_entry_fields=>',form_entry_fields);
    //form entry fields end

    //var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({
      isLoading: true,
    });

    if (!detail_template_code) {
      this.setState({
        isLoading: false,
        template_code_error: true,
      });
    } else {
      this.setState({
        template_code_error: false,
      });
    }

    if (!detail_template_description) {
      this.setState({
        isLoading: false,
        template_desc_error: true,
      });
    } else {
      this.setState({
        template_desc_error: false,
      });
    }

    if (!documentAttachment) {
      this.setState({
        isLoading: false,
        document_error: true,
      });
    } else {
      this.setState({
        document_error: false,
      });
    }

    let match = false;
    //let emails_array=emails;

    //console.log("emails length",emails);

    if (!emails.length && detail_email_dist) {
      emails.push(detail_email_dist);
    }

    emails.forEach((mail) => {
      var check = this.validateEmail(mail);
      if (!check) {
        match = true;
      }
    });

    if (!emails.length || match) {
      detail_email_dist = "";
      emails = [];
      this.setState({
        isLoading: false,
        email_dist_error: true,
      });
    } else {
      this.setState({
        email_dist_error: false,
      });
    }
    var url = "";
    var filename = "";

    //console.log('emails=>',emails.length);

    if (
      detail_template_code &&
      detail_template_description &&
      documentAttachment &&
      emails.length
    ) {
      /*if(localStorage.getItem('ftGuid')){
				url = documentAttachment.url;
				filename = documentAttachment.filename;
			}else{
				 url = documentAttachment.url;
			 filename = documentAttachment.filename;
			}*/

      url =
        typeof documentAttachment === "object"
          ? documentAttachment.url
          : documentAttachment;
      filename =
        typeof documentAttachment === "object"
          ? documentAttachment.filename
          : documentFilename;

      this.setState({
        Guid: localStorage.getItem("ftGuid"),
      });

      try {
        var response = await this.createDocumentDetail(
          detail_template_code,
          detail_template_description,
          url,
          filename,
          detail_doc_password,
          detail_confidential,
          detail_hide_from_external_signer,
          emails,
          FileAttachments,
          form_entry_fields,
          CustomListFields
        );
        //console.log("response", response); return false;
        if (response.status) {
          this.setState({ isLoading: false });
          toast.success(response.message, { autoClose: 5000 });
          if (localStorage.getItem("ftGuid")) {
            this.props.history.push({
              pathname: "/form-template",
              state: { updateSuccessfully: true },
            });
          } else {
            this.props.history.push({
              pathname: "/form-template",
              state: { addSuccessfully: true },
            });
          }
        } else {
          this.setState({ isLoading: false });
          toast.success(response.error, { autoClose: 5000 });
        }
      } catch (e) {
        this.setState({ isLoading: false });
        toast.warning(e.message, { autoClose: 5000 });
      }
    } else {
      if (currenttab !== "detail") {
        document.getElementById("Details").click();
        $("html, body").animate({ scrollTop: 0 }, "300");
      } else {
        $("html, body").animate({ scrollTop: 0 }, "300");
      }
    }
  };

  createDocumentDetail(
    TemplateCode,
    Desc,
    DigitalDoc,
    filename,
    DocPassword,
    Confidential,
    ExtParty,
    EmailDist,
    DocumentAtt,
    form_entry_fields,
    CustomList
  ) {
    if (localStorage.getItem("ftGuid")) {
      return this.props.postRequest("/form-template/update-template", {
        TemplateCode: TemplateCode,
        Desc: Desc,
        DigitalDoc: DigitalDoc,
        filename: filename,
        DocPassword: DocPassword,
        Confidential: Confidential,
        ExtParty: ExtParty,
        EmailDist: EmailDist,
        DocumentAtt: DocumentAtt,
        Guid: localStorage.getItem("ftGuid"),
        form_entry_fields: form_entry_fields,
        CustomList: CustomList,
        attachmentsupdate: false,
      });
    } else {
      return this.props.postRequest("/form-template/create-template", {
        TemplateCode: TemplateCode,
        Desc: Desc,
        DigitalDoc: DigitalDoc,
        filename: filename,
        DocPassword: DocPassword,
        Confidential: Confidential,
        ExtParty: ExtParty,
        EmailDist: EmailDist,
        DocumentAtt: DocumentAtt,
        form_entry_fields: form_entry_fields,
        CustomList: CustomList,
        TenantGuid: localStorage.getItem("useableGuid"),
      });
    }
  }

  //functions for custom field

  custom_field_description = (e) => {
    this.setState({
      custom_field_description: e.target.value ? e.target.value : null,
    });
  };
  custom_field_default_value = (e) => {
    this.setState({
      custom_field_default_value: e.target.value ? e.target.value : null,
    });
  };
  custom_field_tag = (e) => {
    this.setState({
      custom_field_tag: e.target.value ? e.target.value : null,
    });
  };
  custom_field_validations = (e) => {
    this.setState({
      custom_field_validations: e.target.value ? e.target.value : null,
    });
  };
  custom_field_submit_form = (e) => {
    e.preventDefault();
    //var custom_field_description = this.state.custom_field_description;
    //		var custom_field_default_value = this.state.custom_field_default_value;
    //		var custom_field_tag = this.state.custom_field_tag;
    //		var custom_field_validations = this.state.custom_field_validations;
    this.setState({
      custom_field_modalOpen: false,
    });
  };
  custom_field_onModalOpen = (e) => {
    e.preventDefault();
    //console.log('event target value',e.target.value);
    this.setState({
      custom_field_modalOpen: true,
    });
  };
  //Custom field functions end

  //Form Entry Fields
  form_entry_fields_group_heading = (e) => {
    this.setState({
      form_entry_fields_group_heading: e.target.value ? e.target.value : null,
    });
  };
  form_entry_fields_description = (e) => {
    if (e.target.value) {
      this.setState({
        form_entry_fields_description_error: false,
      });
    } else {
      this.setState({
        form_entry_fields_description_error: true,
      });
    }
    this.setState({
      form_entry_fields_description: e.target.value ? e.target.value : null,
    });
  };
  form_entry_fields_default_value = (e) => {
    let defaultValue = e.target.value;
    //console.log('defaultValue',defaultValue);
    //const re = /^[0-9\b]+$/;
    //console.log(re.test(defaultValue));
    if (this.state.isDate) {
      // var startDate = moment('test');
      // //console.log(startDate);
    } else if (this.state.isNumber) {
      const re = /^[0-9\b]+$/;
      if (re.test(defaultValue)) {
        this.setState({
          form_entry_fields_default_value: defaultValue,
          numberform_entry_fields_default_value_error: false,
        });
      } else {
        this.setState({
          form_entry_fields_default_value: null,
          numberform_entry_fields_default_value_error: true,
        });
      }
    } else {
      if (defaultValue) {
        this.setState({
          form_entry_fields_default_value: defaultValue,
          form_entry_fields_default_value_error: false,
        });
      } else {
        this.setState({
          form_entry_fields_default_value: null,
          form_entry_fields_default_value_error: true,
        });
      }
    }
  };
  form_entry_fields_comments = (e) => {
    this.setState({
      form_entry_fields_comments: e.target.value ? e.target.value : null,
    });
  };
  form_entry_fields_tag = (e) => {
    this.setState({
      form_entry_fields_tag: e.target.value ? e.target.value : null,
    });
  };
  form_entry_fields_dbfield = (e) => {
    this.setState({
      form_entry_fields_dbfield: e.target.value ? e.target.value : null,
    });
  };
  form_entry_fields_Company_internal = (e) => {
    if (this.state.form_entry_fields_Company_internal) {
      this.setState({
        form_entry_fields_Company_internal: false,
      });
    } else {
      this.setState({
        form_entry_fields_Company_internal: e.target.value
          ? e.target.value
          : null,
        form_entry_fields_Company_hide: false,
        form_entry_fields_Company_read_only: false,
        form_entry_fields_Company_required: false,
      });
    }
  };
  form_entry_fields_Company_hide = (e) => {
    if (this.state.form_entry_fields_Company_hide) {
      this.setState({
        form_entry_fields_Company_hide: false,
      });
    } else {
      this.setState({
        form_entry_fields_Company_hide: e.target.value ? e.target.value : null,

        form_entry_fields_Company_internal: false,
        form_entry_fields_Company_read_only: false,
        form_entry_fields_Company_required: false,
      });
    }
  };
  form_entry_fields_Company_read_only = (e) => {
    if (this.state.form_entry_fields_Company_read_only) {
      this.setState({
        form_entry_fields_Company_read_only: false,
      });
    } else {
      this.setState({
        form_entry_fields_Company_read_only: e.target.value
          ? e.target.value
          : null,
        form_entry_fields_Company_internal: false,
        form_entry_fields_Company_hide: false,

        form_entry_fields_Company_required: false,
      });
    }
  };
  form_entry_fields_Company_required = (e) => {
    if (this.state.form_entry_fields_Company_required) {
      this.setState({
        form_entry_fields_Company_required: false,
      });
    } else {
      this.setState({
        form_entry_fields_Company_required: e.target.value
          ? e.target.value
          : null,
        form_entry_fields_Company_internal: false,
        form_entry_fields_Company_hide: false,
        form_entry_fields_Company_read_only: false,
      });
    }
  };
  form_entry_fields_external_hide = (e) => {
    if (this.state.form_entry_fields_external_hide) {
      this.setState({
        form_entry_fields_external_hide: false,
      });
    } else {
      this.setState({
        form_entry_fields_external_hide: e.target.value ? e.target.value : null,
        form_entry_fields_external_readonly: false,
        form_entry_fields_external_required: false,
      });
    }
  };
  form_entry_fields_external_readonly = (e) => {
    if (this.state.form_entry_fields_external_readonly) {
      this.setState({
        form_entry_fields_external_readonly: false,
      });
    } else {
      this.setState({
        form_entry_fields_external_readonly: e.target.value
          ? e.target.value
          : null,

        form_entry_fields_external_hide: false,

        form_entry_fields_external_required: false,
      });
    }
  };
  form_entry_fields_external_required = (e) => {
    if (this.state.form_entry_fields_external_required) {
      this.setState({
        form_entry_fields_external_required: false,
      });
    } else {
      this.setState({
        form_entry_fields_external_required: e.target.value
          ? e.target.value
          : null,
        form_entry_fields_external_hide: false,
        form_entry_fields_external_readonly: false,
      });
    }
  };

  form_entry_fields_field_type = (e) => {
    //console.log('type=>',e.target.value);
    let type = e.target.value;
    let StartDate = this.state.form_entry_startDate;
    if (e.target.value) {
      //change default value field
      if (type === "Numeric") {
        this.setState({
          isNumber: true,
          isString: false,
          isDate: false,
          isDroplist: false,
          isFile: false,
          form_entry_fields_default_value: "",
        });
      } else if (type === "Drop List") {
        this.setState({
          isNumber: false,
          isString: false,
          isDate: false,
          isDroplist: true,
          isFile: false,
          form_entry_fields_default_value: "",
        });
      } else if (type === "Date") {
        this.setState({
          isNumber: false,
          isString: false,
          isDate: true,
          isDroplist: false,
          isFile: false,
          form_entry_fields_default_value: StartDate,
        });
      } else if (type === "File") {
        this.setState({
          isNumber: false,
          isString: false,
          isDate: false,
          isDroplist: false,
          isFile: true,
          form_entry_fields_default_value: "",
        });
      } else {
        this.setState({
          isNumber: false,
          isString: true,
          isDate: false,
          isDroplist: false,
          isFile: false,
          form_entry_fields_default_value: "",
        });
      }
      //change default value field end

      this.setState({
        form_entry_fields_field_type_error: false,
      });
    } else {
      this.setState({
        form_entry_fields_field_type_error: true,
      });
    }
    this.setState({
      form_entry_fields_field_type: e.target.value ? e.target.value : null,
    });
  };

  form_entry_fields_field_length = (e) => {
    this.setState({
      form_entry_fields_field_length: e.target.value ? e.target.value : null,
    });
  };
  form_entry_fields_validation = (e) => {
    this.setState({
      form_entry_fields_validation: e.target.value ? e.target.value : null,
    });
  };
  form_entry_fields_onModalOpen = (e) => {
    e.preventDefault();
    this.setState({
      form_entry_fields_modalOpen: true,
    });
  };
  showdetail = (e, key) => {
    var divkey = "#" + key + "";
    var hide_class = $(divkey).hasClass("hide_table_detail");
    if (hide_class) {
      $(divkey).removeClass("hide_table_detail");
      $(divkey).addClass("display_table_detail");
    } else {
      $(divkey).addClass("hide_table_detail");
      $(divkey).removeClass("display_table_detail");
    }
  };
  form_entry_fields_submit_form = (e) => {
    e.preventDefault();

    var form_entry_fields_group_heading =
      this.state.form_entry_fields_group_heading ||
      this.state.form_entry_fields_group_heading === "0"
        ? this.state.form_entry_fields_group_heading
        : null;
    var form_entry_fields_description =
      this.state.form_entry_fields_description ||
      this.state.form_entry_fields_description === "0"
        ? this.state.form_entry_fields_description
        : null;
    var form_entry_fields_default_value =
      this.state.form_entry_fields_default_value ||
      this.state.form_entry_fields_default_value === "0"
        ? this.state.form_entry_fields_default_value
        : null;
    var form_entry_fields_comments =
      this.state.form_entry_fields_comments ||
      this.state.form_entry_fields_comments === "0"
        ? this.state.form_entry_fields_comments
        : null;
    var form_entry_fields_tag =
      this.state.form_entry_fields_tag ||
      this.state.form_entry_fields_tag === "0"
        ? this.state.form_entry_fields_tag
        : null;
    var form_entry_fields_dbfield =
      this.state.form_entry_fields_dbfield ||
      this.state.form_entry_fields_dbfield === "0"
        ? this.state.form_entry_fields_dbfield
        : null;
    var form_entry_fields_Company_internal = this.state
      .form_entry_fields_Company_internal;
    var form_entry_fields_Company_hide = this.state
      .form_entry_fields_Company_hide;
    var form_entry_fields_Company_read_only = this.state
      .form_entry_fields_Company_read_only;
    var form_entry_fields_Company_required = this.state
      .form_entry_fields_Company_required;
    var form_entry_fields_external_hide = this.state
      .form_entry_fields_external_hide;
    var form_entry_fields_external_readonly = this.state
      .form_entry_fields_external_readonly;
    var form_entry_fields_external_required = this.state
      .form_entry_fields_external_required;
    var form_entry_fields_field_type = this.state.form_entry_fields_field_type;
    var type = this.state.form_entry_fields_field_type;
    var form_entry_fields_field_length =
      this.state.form_entry_fields_field_length ||
      this.state.form_entry_fields_field_length === "0"
        ? this.state.form_entry_fields_field_length
        : null;
    var form_entry_fields_validation =
      this.state.form_entry_fields_validation ||
      this.state.form_entry_fields_validation === 0
        ? this.state.form_entry_fields_validation
        : null;
    var datalist = this.state.datalist;
    this.setState({
      numberform_entry_fields_default_value_error: false,
      dateform_entry_fields_default_value_error: false,
      form_entry_fields_default_value_error: false,
    });
    if (form_entry_fields_dbfield) {
      this.setState({
        numberform_entry_fields_default_value_error: false,
        dateform_entry_fields_default_value_error: false,
        form_entry_fields_default_value_error: false,
      });
    } else {
      this.setState({
        //	numberform_entry_fields_default_value_error:true,
      });
    }
    if (type === "Numeric") {
      const re = /^[0-9\b]+$/;
      if (re.test(form_entry_fields_default_value)) {
        this.setState({
          numberform_entry_fields_default_value_error: false,
        });
      } else {
        form_entry_fields_default_value = "";
        this.setState({
          form_entry_fields_default_value: "",
          numberform_entry_fields_default_value_error: true,
        });
      }
    }

    if (type === "Date") {
      form_entry_fields_default_value = form_entry_fields_default_value
        ? form_entry_fields_default_value.toString()
        : null;
    }

    if (!form_entry_fields_description) {
      this.setState({
        form_entry_fields_description_error: true,
      });
    } else {
      this.setState({
        form_entry_fields_description_error: false,
      });
    }

    if (!form_entry_fields_field_type) {
      this.setState({
        form_entry_fields_field_type_error: true,
      });
    } else {
      this.setState({
        form_entry_fields_field_type_error: false,
      });
    }
    // console.log(
    // 'form_entry_fields_group_heading=>'+form_entry_fields_group_heading+"\n"+
    // 'form_entry_fields_description=>'+form_entry_fields_description+"\n"+
    // 'form_entry_fields_default_value=>'+form_entry_fields_default_value+"\n"+
    // 'form_entry_fields_comments=>'+form_entry_fields_comments+"\n"+
    // 'form_entry_fields_tag=>'+form_entry_fields_tag+"\n"+
    // 'form_entry_fields_dbfield=>'+form_entry_fields_dbfield+"\n"+
    // 'form_entry_fields_Company_internal=>'+form_entry_fields_Company_internal+"\n"+
    // 'form_entry_fields_Company_hide=>'+form_entry_fields_Company_hide+"\n"+
    // 'form_entry_fields_Company_read_only=>'+form_entry_fields_Company_read_only+"\n"+
    // 'form_entry_fields_Company_required=>'+form_entry_fields_Company_required+"\n"+
    // 'form_entry_fields_external_hide=>'+form_entry_fields_external_hide+"\n"+
    // 'form_entry_fields_external_readonly=>'+form_entry_fields_external_readonly+"\n"+
    // 'form_entry_fields_external_required=>'+form_entry_fields_external_required+"\n"+
    // 'form_entry_fields_field_type=>'+form_entry_fields_field_type+"\n"+
    // 'form_entry_fields_field_length=>'+form_entry_fields_field_length+"\n"+
    // 'form_entry_fields_validation=>'+form_entry_fields_validation+"\n"
    // );
    var form_entry_fields = this.state.form_entry_fields;
    if (form_entry_fields_field_type && form_entry_fields_description) {
      var form_entry_fields_tmp = {
        Comments: form_entry_fields_comments
          ? form_entry_fields_comments
          : null,
        DBField: form_entry_fields_dbfield ? form_entry_fields_dbfield : null,
        DefaultValue: form_entry_fields_default_value
          ? form_entry_fields_default_value
          : null,
        HideExt: form_entry_fields_external_hide
          ? form_entry_fields_external_hide
          : false,
        HideInt: form_entry_fields_Company_hide
          ? form_entry_fields_Company_hide
          : false,
        Internal: form_entry_fields_Company_internal
          ? form_entry_fields_Company_internal
          : false,
        Length: form_entry_fields_field_length
          ? form_entry_fields_field_length
          : null,
        Order: 1,
        Promt: form_entry_fields_description
          ? form_entry_fields_description
          : null,
        ReadOnlyExt: form_entry_fields_external_readonly
          ? form_entry_fields_external_readonly
          : false,
        ReadOnlyInt: form_entry_fields_Company_read_only
          ? form_entry_fields_Company_read_only
          : false,
        ReqExt: form_entry_fields_external_required
          ? form_entry_fields_external_required
          : false,
        ReqInt: form_entry_fields_Company_required
          ? form_entry_fields_Company_required
          : false,
        Script: null,
        SubHeading: form_entry_fields_group_heading
          ? form_entry_fields_group_heading
          : null,
        Tag: form_entry_fields_tag ? form_entry_fields_tag : null,
        Type: form_entry_fields_field_type
          ? form_entry_fields_field_type
          : null,
        Validation: form_entry_fields_validation
          ? form_entry_fields_validation
          : null,
        Value: "111111111",
        datalist: datalist,
      };

      //console.log("form_entry_fields_tmp=>", form_entry_fields_tmp); return false;

      var editkey = localStorage.getItem("editkey");
      if (editkey) {
        form_entry_fields[editkey] = form_entry_fields_tmp;
        localStorage.removeItem("editkey");
      } else {
        form_entry_fields.push(form_entry_fields_tmp);
      }
      this.setState({
        form_entry_fields: form_entry_fields,
        form_entry_fields_modalOpen: false,
      });
      this.closeModal();
    }

    //console.log('form_entry_fields=>',this.state.form_entry_fields);
  };

  form_entry_handleChange(date) {
    if (date) {
      this.setState({
        form_entry_startDate: date,
        form_entry_fields_default_value: date,
        dateform_entry_fields_default_value_error: false,
      });
    } else {
      this.setState({
        form_entry_startDate: date,
        form_entry_fields_default_value: "",
        dateform_entry_fields_default_value_error: true,
      });
    }
    ////console.log(date);
  }

  deleteformentry = (e, key) => {
    var form_entry_fields = this.state.form_entry_fields;
    form_entry_fields.splice(key, 1);
    this.setState({
      form_entry_fields: form_entry_fields,
    });
  };
  editformentry = (e, key) => {
    //console.log('edit',this.state.form_entry_fields[key]); return false;

    let form_entry_fields = this.state.form_entry_fields[key];

    localStorage.setItem("editkey", key);

    if (form_entry_fields.Type === "Date" && form_entry_fields.DefaultValue) {
      var form_entry_startDate = moment(form_entry_fields.DefaultValue);
    } else {
      var form_entry_startDate = null;
    }

    this.setState({
      numberform_entry_fields_default_value_error: false,
      dateform_entry_fields_default_value_error: false,
      form_entry_fields_default_value_error: false,
      form_entry_fields_group_heading: form_entry_fields.SubHeading,

      form_entry_fields_description: form_entry_fields.Promt,

      form_entry_fields_default_value: form_entry_fields.DefaultValue,

      form_entry_fields_comments: form_entry_fields.Comments,

      form_entry_fields_tag: form_entry_fields.Tag,

      form_entry_fields_dbfield: form_entry_fields.DBField,

      form_entry_fields_Company_internal: form_entry_fields.Internal,

      form_entry_fields_Company_hide: form_entry_fields.HideInt,

      form_entry_fields_Company_read_only: form_entry_fields.ReadOnlyInt,

      form_entry_fields_Company_required: form_entry_fields.ReqInt,

      form_entry_fields_external_hide: form_entry_fields.HideExt,

      form_entry_fields_external_readonly: form_entry_fields.ReadOnlyExt,

      form_entry_fields_external_required: form_entry_fields.ReqExt,

      form_entry_fields_field_type: form_entry_fields.Type,

      form_entry_fields_field_length: form_entry_fields.Length,

      form_entry_fields_validation: form_entry_fields.Validation,
      datalist: form_entry_fields.datalist ? form_entry_fields.datalist : [],
      form_entry_fields_modalOpen: true,
      form_entry_startDate: form_entry_startDate,
    });

    let type = form_entry_fields.Type;

    if (type === "Numeric") {
      this.setState({
        isNumber: true,
        isString: false,
        isDate: false,
        isDroplist: false,
        isFile: false,
      });
    } else if (type === "Drop List") {
      this.setState({
        isNumber: false,
        isString: false,
        isDate: false,
        isDroplist: true,
        isFile: false,
      });
    } else if (type === "Date") {
      this.setState({
        isNumber: false,
        isString: false,
        isDate: true,
        isDroplist: false,
        isFile: false,
      });
    } else if (type === "File") {
      this.setState({
        isNumber: false,
        isString: false,
        isDate: false,
        isDroplist: false,
        isFile: true,
      });
    } else {
      this.setState({
        isNumber: false,
        isString: true,
        isDate: false,
        isDroplist: false,
        isFile: false,
      });
    }
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  filechange = async (e) => {
    var file = e.target.files[0];
    //console.log("file=>", file); return false;
    if (!file) {
      this.setState({ document_error: true });
    } else {
      if (
        this.state.documentAttachment &&
        localStorage.getItem("ftGuid") === null
      ) {
        var upload_path = this.state.documentAttachment.url;
        await this.props.postRequest("/attachments/remove", {
          upload_path: upload_path,
        });
      }
      /*this.setState({
				document_error :false
			});*/
    }

    this.setState({ detail_document: file.name, isLoading: true });
    try {
      const base64Data = await this.toBase64(file);
      const upload_path =
        "formTemplate-documents/" +
        +new Date().getTime() +
        "-" +
        file.name.replace(/\s+/g, "-").toLowerCase();
      const content_type = file.type;
      //var response = await this.props.postRequest("/attachments/fetch", {upload_path: "form-templates/1605114548056-edits_24-oct-20(a).docx", content_type: content_type, base64Data: base64Data});
      var response = await this.props.postRequest("/attachments/upload", {
        upload_path: upload_path,
        content_type: content_type,
        base64Data: base64Data,
      });
      if (response.status) {
        var uploadedObj = {
          Confidential: false,
          ContentType: content_type,
          DocDesc: "Form Template Document",
          DocType: "Document",
          Encrypted: "No",
          filename: file.name,
          PWHash: "a7e7ef%^%*&(7ke834",
          Salt: "HFHHGVHJBJB",
          Timestamp: new Date().getTime(),
          url: upload_path,
        };
        this.setState({
          documentAttachment: uploadedObj,
          isLoading: false,
          imageUploadModalOpen: true,
        });
      } else {
        toast.error("Failed to upload file.", { autoClose: 5000 });
        this.setState({ isLoading: false });
      }
      console.log("/attachments/upload=>", response);
    } catch (err) {
      this.setState({ isLoading: false });
      console.log("upload attachment Error=>", err);
      toast.error(err.message, { autoClose: 5000 });
    }
  };

  onDrop = async (files) => {
    //console.log('files=>',files);
    this.setState({ isLoading: true });
    var selected_files = this.state.FileAttachments;
    var selected_files_urls = this.state.FileAttachments_urls;
    //var file = files[0];

    files.forEach(async (file) => {
      try {
        const base64Data = await this.toBase64(file);
        const upload_path =
          "formTemplate-documents/" +
          +new Date().getTime() +
          "-" +
          file.name.replace(/\s+/g, "-").toLowerCase();
        const content_type = file.type;
        //var response = await this.props.postRequest("/attachments/fetch", {upload_path: "form-templates/1605114548056-edits_24-oct-20(a).docx", content_type: content_type, base64Data: base64Data});
        var response = await this.props.postRequest("/attachments/upload", {
          upload_path: upload_path,
          content_type: content_type,
          base64Data: base64Data,
        });
        if (response.status) {
          var uploadedObj = {
            Confidential: false,
            ContentType: content_type,
            DocDesc: "Form Template Document",
            DocType: "Document",
            Encrypted: "No",
            filename: file.name,
            PWHash: "a7e7ef%^%*&(7ke834",
            Salt: "HFHHGVHJBJB",
            Timestamp: new Date().getTime(),
            url: upload_path,
          };
          selected_files.push(uploadedObj);

          this.setState({
            FileAttachments_urls: selected_files_urls,
            FileAttachments: selected_files,
            isLoading: false,
            imageUploadModalOpen: true,
          });
        } else {
          toast.error("Failed to upload file.", { autoClose: 5000 });
          this.setState({ isLoading: false });
        }
        console.log("/attachments/upload=>", response);
      } catch (err) {
        this.setState({ isLoading: false });
        console.log("upload attachment Error=>", err);
        toast.error(err.message, { autoClose: 5000 });
      }
    });
  };

  closeModal = (e) => {
    this.setState({
      custom_field_modalOpen: false,
      form_entry_fields_modalOpen: false,
      form_entry_fields_group_heading: null,

      form_entry_fields_description: null,

      form_entry_fields_default_value: null,

      form_entry_fields_comments: null,

      form_entry_fields_tag: null,

      form_entry_fields_dbfield: null,

      form_entry_fields_Company_internal: false,

      form_entry_fields_Company_hide: false,

      form_entry_fields_Company_read_only: false,

      form_entry_fields_Company_required: false,

      form_entry_fields_external_hide: false,

      form_entry_fields_external_readonly: false,

      form_entry_fields_external_required: false,

      form_entry_fields_field_type: null,

      form_entry_fields_field_length: null,

      form_entry_fields_validation: null,

      numberform_entry_fields_default_value_error: false,

      dateform_entry_fields_default_value_error: false,

      form_entry_fields_default_value_error: false,

      datalist: [
        { option: "yes", value: "1" },
        { option: "no", value: "0" },
      ],
      isString: true,
      isNumber: false,
      isDate: false,
      isDroplist: false,
      form_entry_startDate: moment(),
    });

    localStorage.removeItem("editkey");
  };
  //common functions end

  // datalist function
  datalist_closeModal = () => {
    this.setState({
      add_datalist: false,
      datalist_option: "",
      datalist_value: "",
      add_datalist_key: "",
    });
  };
  datalist_openModal = () => {
    this.setState({
      add_datalist: true,
    });
  };

  delete_datalist = (e, key) => {
    e.preventDefault();
    let found = false;
    let datalist = this.state.datalist;
    let defaultValue = this.state.defaultValue;
    // console.log('defaultValue',defaultValue);
    let isDroplist = this.state.isDroplist;

    if (isDroplist) {
      datalist.forEach(function (item) {
        console.log("defaultValue", item.value === defaultValue);
        if (item.value === defaultValue) {
          found = true;
        }
      });
    }
    if (found) {
      console.log("found", found);
      this.setState({
        defaultValue: "",
        defaultValue_error: true,
      });
    }
    datalist.splice(key, 1);
    this.setState({
      datalist: datalist,
    });
  };

  edit_datalist = (e, key) => {
    e.preventDefault();

    let value = this.state.datalist[key].value;
    let option = this.state.datalist[key].option;

    this.setState({
      datalist_option: option,
      datalist_value: value,
      add_datalist_key: key,
    });
    console.log("option", this.state.datalist_option);
    console.log("value", this.state.datalist_value);
  };

  add_datalist = (e) => {
    e.preventDefault();

    let datalist = this.state.datalist;
    let defaultValue = this.state.defaultValue;
    let isDroplist = this.state.isDroplist;
    let found = false;
    var value = this.state.datalist_value;
    if (value || value === 0) {
      if (isDroplist) {
        datalist.forEach(function (item) {
          console.log("defaultValue", item.value === defaultValue);
          if (item.value === defaultValue) {
            found = true;
          }
        });
      }
      if (found === false) {
        this.setState({
          defaultValue: "",
        });
      }
    }
    var option = this.state.datalist_option;
    if (option || option === 0) {
      this.setState({ option_error: false });
    } else {
      this.setState({ option_error: true });
    }

    if (value || value === 0) {
      this.setState({ value_error: false });
    } else {
      this.setState({ value_error: true });
    }

    let key = this.state.add_datalist_key;
    console.log("key=>", key);

    if (value && option) {
      if (key || key === 0) {
        datalist[key].option = option;
        datalist[key].value = value;
      } else {
        let tmp_array = { option: option, value: value };
        datalist.push(tmp_array);
      }
      this.setState({
        datalist: datalist,
        datalist_option: "",
        datalist_value: "",
        add_datalist_key: "",
        value_error: false,
        option_error: false,
      });
    }
  };

  option_change = (e) => {
    e.preventDefault();
    let option = e.target.value;
    if (option || option === 0) {
      this.setState({
        datalist_option: option,
        option_error: false,
      });
    } else {
      this.setState({
        datalist_option: option,
        option_error: true,
      });
    }
  };

  value_change = (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (value || value === 0) {
      this.setState({
        datalist_value: value,
        value_error: false,
      });
    } else {
      this.setState({
        value_error: true,
        datalist_value: value,
      });
    }
  };

  //datalist function end

  deleteuploadedAttachment = async (e, id, url) => {
    e.preventDefault();
    var that = this;
    var result = window.confirm("Are you sure you want to delete?");

    if (result) {
      this.setState({ isLoading: true });
      try {
        var remove_response = await this.props.postRequest(
          "/attachments/remove",
          { upload_path: url }
        );
        console.log("remove_response=>", remove_response);
        if (remove_response.status) {
          var attachments = that.state.FileAttachments;
          attachments.splice(id, 1);
          that.setState({
            FileAttachments: attachments,
            isLoading: false,
            someState: true,
          });

          if (localStorage.getItem("ftGuid")) {
            this.props.postRequest(
              "/form-template/update-template-attachments",
              { Guid: localStorage.getItem("ftGuid"), DocumentAtt: attachments }
            );
          }
        } else {
          this.setState({ isLoading: false });
          toast.error("Something went wrong, please try later.", {
            autoClose: 5000,
          });
        }
      } catch (err) {
        this.setState({ isLoading: false });
        toast.error("Something went wrong, please try later.", {
          autoClose: 5000,
        });
      }
    }
  };

  customListHandler = async (event) => {
    var name = event.target.name;
    var value = event.target.value;

    this.setState({ customListValue: value }, () => {});
    var found = this.state.customListData.find((obj) => obj.Guid === value);
    // console.log("GOT IT", found);
    if (found) {
      var f_unit = found.CustomFields.find((obj) => obj.Desc === "Units");
      var f_unitDesc = found.CustomFields.find(
        (obj) => obj.Desc === "Unit Desc"
      );
      var f_rate = found.CustomFields.find((obj) => obj.Desc === "Rate");
      var f_factor = found.CustomFields.find((obj) => obj.Desc === "Factor");
      var f_amount = found.CustomFields.find((obj) => obj.Desc === "Amount");
      var f_cappedAmount = found.CustomFields.find(
        (obj) => obj.Desc === "Capped Amount"
      );
      var f_dayWeek = found.CustomFields.find(
        (obj) => obj.Desc === "Days in Week"
      );
      var f_dateFrom = found.CustomFields.find(
        (obj) => obj.Desc === "Date From:"
      );
      var f_dateTo = found.CustomFields.find((obj) => obj.Desc === "Date To:");
      var f_chartSort = found.CustomFields.find(
        (obj) => obj.Desc === "Chart Sort"
      );
      var f_chartCode = found.CustomFields.find(
        (obj) => obj.Desc === "Chart Code"
      );
      var f_flag = found.CustomFields.find((obj) => obj.Desc === "Flag:");
      var f_payFlag = found.CustomFields.find(
        (obj) => obj.Desc === "Payment Flag:"
      );
      var f_calcDays = found.CustomFields.find(
        (obj) => obj.Desc === "Calc Days"
      );
      var f_calcWeeks = found.CustomFields.find(
        (obj) => obj.Desc === "Calc Weeks"
      );
      console.log("GOT U",f_unit,f_unitDesc,f_rate,f_factor,f_amount,f_cappedAmount,f_dayWeek,f_dateFrom,f_dateTo,f_chartSort,
         f_chartCode,f_flag,f_payFlag,f_calcDays,f_calcWeeks)
      if (f_amount.Value === 0 || f_amount.Value === null) {
        this.setState({ datePickerDisable: true });
      } else {
        this.setState({ datePickerDisable: false });
      }
      f_dateFrom.Value = moment(f_dateFrom.Value);
      f_dateTo.Value = moment(f_dateTo.Value);
      if (
        moment(f_dateTo.Value).isBefore(f_dateFrom.Value) ||
        moment(f_dateFrom.Value.format("YYYY-MM-DD")).isAfter(
          f_dateTo.Value.format("YYYY-MM-DD")
        )
      ) {
        f_calcDays.Value = 0;
        f_calcWeeks.Value = 0;
      } else {
        var dateTo = moment(f_dateTo.Value);
        var dateFrom = moment(f_dateFrom.Value);
        var duration = moment.duration(dateTo.diff(dateFrom));
        var Ucalc_days = (dateTo.diff(dateFrom, "days") + 1) % 7;
        var Ucalc_weeks = dateTo.diff(dateFrom, "weeks");

        if (Ucalc_days >= f_dayWeek.Value) {
          Ucalc_weeks++;
          f_calcDays.Value = 0;
        } else {
          if (Ucalc_days === 0) {
            Ucalc_weeks++;
          }
          f_calcDays.Value = Ucalc_days;
          f_calcWeeks.Value = Ucalc_weeks;
        }
      }

      var unit_order;
      var unit_desc_order;
      var rate_order;
      var factor_order;
      var amount_order;
      var days_in_week_order;
      var startDate_order;
      var endDate_order;
      var capped_amount_order;
      var chart_sort_order;
      var chart_code_order;
      var flag_order;
      var payment_flag_order;
      var calc_days_order;
      var calc_weeks_order;
      // console.log("DATAAAAA", this.state.CustomListFields);
      if (this.state.CustomListFields.length > 0) {
        await this.state.CustomListFields[
          this.state.CustomListFields.length - 1
        ].CustomFields.map((field, i) => {
          if (field.Prompt === "unit") {
            unit_order = field.order;
          }
          if (field.Prompt === "unit_desc") {
            unit_desc_order = field.order;
          }
          if (field.Prompt === "rate") {
            rate_order = field.order;
          }
          if (field.Prompt === "factor") {
            factor_order = field.order;
          }

          if (field.Prompt === "amount") {
            amount_order = field.order;
          }

          if (field.Prompt === "capped_amount") {
            capped_amount_order = field.order;
          }

          if (field.Prompt === "startDate") {
            startDate_order = field.order;
          }

          if (field.Prompt === "endDate") {
            endDate_order = field.order;
          }

          if (field.Prompt === "chart_sort") {
            chart_sort_order = field.order;
          }
          if (field.Prompt === "chart_code") {
            chart_code_order = field.order;
          }
          if (field.Prompt === "flag") {
            flag_order = field.order;
          }
          if (field.Prompt === "payment_flag") {
            payment_flag_order = field.order;
          }
          if (field.Prompt === "calc_days") {
            calc_days_order = field.order;
          }
          if (field.Prompt === "calc_weeks") {
            calc_weeks_order = field.order;
          }
          if (field.Prompt === "days_in_week") {
            days_in_week_order = field.order;
          }
        });
      } else {
        unit_order = f_unit.Order;
        unit_desc_order = f_unitDesc.Order;
        rate_order = f_rate.Order;
        factor_order = f_factor.Order;
        amount_order = f_amount.Order;
        days_in_week_order = f_dayWeek.Order;
        startDate_order = f_dateFrom.Order;
        endDate_order = f_dateTo.Order;
        capped_amount_order = f_cappedAmount.Order;
        chart_sort_order = f_chartSort.Order;
        chart_code_order = f_chartCode.Order;
        flag_order = f_flag.Order;
        payment_flag_order = f_payFlag.Order;
        calc_days_order = f_calcDays.Order;
        calc_weeks_order = f_calcWeeks.Order;
      }
      console.log(
        "GOT D",
        f_unit.Order,
        f_unitDesc.Order,
        f_rate.Order,
        f_factor.Order,
        f_amount.Order,
        f_dayWeek.Order,
        f_dateFrom.Order,
        f_dateTo.Order,
        f_cappedAmount.Order,
        f_chartSort.Order,
        f_chartCode.Order,
        f_flag.Order,
        f_payFlag.Order,
        f_calcDays.Order,
        f_calcWeeks.Order
      );
      if (f_unit.Value === null) {
        f_unit.Value = "";
      }
      if (f_unitDesc.Value === null) {
        f_unitDesc.Value = "";
      }
      if (f_rate.Value === null) {
        f_rate.Value = "";
      }
      if (f_factor.Value === null) {
        f_factor.Value = "";
      }
      if (f_cappedAmount.Value === null) {
        f_cappedAmount.Value = "";
      }
      if (f_chartSort.Value === null) {
        f_chartSort.Value = "";
      }
      if (f_chartCode.Value === null) {
        f_chartCode.Value = "";
      }
      if (f_flag.Value === null) {
        f_flag.Value = "";
      }
      if (f_payFlag.Value === null) {
        f_payFlag.Value = "";
      }
      if (f_dayWeek.Value === null) {
        f_dayWeek.Value = "";
      }
      if (f_amount.Value === null) {
        f_amount.Value = "";
      }
      if (found.TBA === "checked") {
        f_calcDays.Value = 0;
        f_calcWeeks.Value = 0;
      }

      this.state.listHandler.CustomFields.map((field, i) => {
        if (field.Prompt === "unit") {
          field.order = unit_order;
          field.Hide = f_unit.Hide;
        }
        if (field.Prompt === "unit_desc") {
          field.Hide = f_unitDesc.Hide;
          field.order = unit_desc_order;
        }
        if (field.Prompt === "rate") {
          field.Hide = f_rate.Hide;
          field.order = rate_order;
        }
        if (field.Prompt === "factor") {
          field.Hide = f_factor.Hide;
          field.order = factor_order;
        }

        if (field.Prompt === "amount") {
          field.Hide = f_amount.Hide;
          field.order = amount_order;
        }

        if (field.Prompt === "capped_amount") {
          field.Hide = f_cappedAmount.Hide;
          field.order = capped_amount_order;
        }

        if (field.Prompt === "startDate") {
          field.Hide = f_dateFrom.Hide;
          field.order = startDate_order;
        }

        if (field.Prompt === "endDate") {
          field.Hide = f_dateTo.Hide;
          field.order = endDate_order;
          console.log("GOT ED", field.order);
        }

        if (field.Prompt === "chart_sort") {
          field.Hide = f_chartSort.Hide;
          field.order = chart_sort_order;
          console.log("GOT Cs", field.order);
        }
        if (field.Prompt === "chart_code") {
          field.Hide = f_chartCode.Hide;
          field.order = chart_code_order;
        }
        if (field.Prompt === "flag") {
          field.Hide = f_flag.Hide;
          field.order = flag_order;
        }
        if (field.Prompt === "payment_flag") {
          field.Hide = f_payFlag.Hide;
          field.order = payment_flag_order;
        }
        if (field.Prompt === "calc_days") {
          field.Hide = f_calcDays.Hide;
          field.order = calc_days_order;
        }
        if (field.Prompt === "calc_weeks") {
          field.Hide = f_calcWeeks.Hide;
          field.order = calc_weeks_order;
        }
        if (field.Prompt === "days_in_weeks") {
          field.Hide = f_dayWeek.Hide;
          field.order = days_in_week_order;
        }
      });
      this.state.listHandler.CustomFields.sort((a, b) =>
        a.order > b.order ? 1 : -1
      );
      console.log(
        "GOT U",
        this.state.listHandler,
        chart_sort_order,
        endDate_order
      );
      await this.setState(
        {
          pmt_code: found.Code,
          desc: found.Desc,
          TBA: found.TBA,
          unit: f_unit.Value,
          unit_desc: f_unitDesc.Value,
          rate: f_rate.Value,
          factor: f_factor.Value,
          amount: f_amount.Value,
          capped_amount: f_cappedAmount.Value,
          days_in_week: f_dayWeek.Value,
          startDate: moment(f_dateFrom.Value),
          endDate: moment(f_dateTo.Value),
          chart_sort: f_chartSort.Value,
          chart_code: f_chartCode.Value,
          flag: f_flag.Value,
          payment_flag: f_payFlag.Value,
          calc_days: f_calcDays.Value,
          calc_weeks: f_calcWeeks.Value,

          //UNIT MODAL
          unit_order: unit_order,
          unit_dbfield: f_unit.DBField,
          unit_validate: f_unit.Validate,
          unit_length: f_unit.Length,
          unit_script: f_unit.Script,
          unit_type: f_unit.Type,
          unit_tag: f_unit.Tag,
          unit_hide: f_unit.Hide,
          unit_readonly: f_unit.Readonly,
          unit_required: f_unit.Required,
          //UNIT DESC MODAL
          unit_desc_order: unit_desc_order,
          unit_desc_dbfield: f_unitDesc.DBField,
          unit_desc_validate: f_unitDesc.Validate,
          unit_desc_length: f_unitDesc.Length,
          unit_desc_script: f_unitDesc.Script,
          unit_desc_type: f_unitDesc.Type,
          unit_desc_tag: f_unitDesc.Tag,
          unit_desc_hide: f_unitDesc.Hide,
          unit_desc_readonly: f_unitDesc.Readonly,
          unit_desc_required: f_unitDesc.Required,

          //RATE Modal
          rate_order: rate_order,
          rate_dbfield: f_rate.DBField,
          rate_validate: f_rate.Validate,
          rate_length: f_rate.Length,
          rate_script: f_rate.Script,
          rate_type: f_rate.Type,
          rate_tag: f_rate.Tag,
          rate_hide: f_rate.Hide,
          rate_readonly: f_rate.Readonly,
          rate_required: f_rate.Required,

          //Factor Modal
          factor_order: factor_order,
          factor_dbfield: f_factor.DBField,
          factor_validate: f_factor.Validate,
          factor_length: f_factor.Length,
          factor_script: f_factor.Script,
          factor_type: f_factor.Type,
          factor_tag: f_factor.Tag,
          factor_hide: f_factor.Hide,
          factor_readonly: f_factor.Readonly,
          factor_required: f_factor.Required,

          // AMOUNT MODAL
          amount_order: amount_order,
          amount_dbfield: f_amount.DBField,
          amount_validate: f_amount.Validate,
          amount_length: f_amount.Length,
          amount_script: f_amount.Script,
          amount_type: f_amount.Type,
          amount_tag: f_amount.Tag,
          amount_hide: f_amount.Hide,
          amount_readonly: f_amount.Readonly,
          amount_required: f_amount.Required,

          //Capped Amount Modal
          capped_amount_order: capped_amount_order,
          capped_amount_dbfield: f_cappedAmount.DBField,
          capped_amount_validate: f_cappedAmount.Validate,
          capped_amount_length: f_cappedAmount.Length,
          capped_amount_script: f_cappedAmount.Script,
          capped_amount_type: f_cappedAmount.Type,
          capped_amount_tag: f_cappedAmount.Tag,
          capped_amount_hide: f_cappedAmount.Hide,
          capped_amount_readonly: f_cappedAmount.Readonly,
          capped_amount_required: f_cappedAmount.Required,

          //Days in Week modal
          days_in_week_order: days_in_week_order,
          days_in_week_dbfield: f_dayWeek.DBField,
          days_in_week_validate: f_dayWeek.Validate,
          days_in_week_length: f_dayWeek.Length,
          days_in_week_script: f_dayWeek.Script,
          days_in_week_type: f_dayWeek.Type,
          days_in_week_tag: f_dayWeek.Tag,
          days_in_week_hide: f_dayWeek.Hide,
          days_in_week_readonly: f_dayWeek.Readonly,
          days_in_week_required: f_dayWeek.Required,

          //DATE FROM
          startDate_order: startDate_order,
          startDate_dbfield: f_dateFrom.DBField,
          startDate_validate: f_dateFrom.Validate,
          startDate_length: f_dateFrom.Length,
          startDate_script: f_dateFrom.Script,
          startDate_type: f_dateFrom.Type,
          startDate_tag: f_dateFrom.Tag,
          startDate_hide: f_dateFrom.Hide,
          startDate_readonly: f_dateFrom.Readonly,
          startDate_required: f_dateFrom.Required,
          //DATE TO
          endDate_order: endDate_order,
          endDate_dbfield: f_dateTo.DBField,
          endDate_validate: f_dateTo.Validate,
          endDate_length: f_dateTo.Length,
          endDate_script: f_dateTo.Script,
          endDate_type: f_dateTo.Type,
          endDate_tag: f_dateTo.Tag,
          endDate_hide: f_dateTo.Hide,
          endDate_readonly: f_dateTo.Readonly,
          endDate_required: f_dateTo.Required,

          //Chart Sort Modal
          chart_sort_order: chart_sort_order,
          chart_sort_dbfield: f_chartSort.DBField,
          chart_sort_validate: f_chartSort.Validate,
          chart_sort_length: f_chartSort.Length,
          chart_sort_script: f_chartSort.Script,
          chart_sort_type: f_chartSort.Type,
          chart_sort_tag: f_chartSort.Tag,
          chart_sort_hide: f_chartSort.Hide,
          chart_sort_readonly: f_chartSort.Readonly,
          chart_sort_required: f_chartSort.Required,

          //Chart Code Modal
          chart_code_order: chart_code_order,
          chart_code_dbfield: f_chartCode.DBField,
          chart_code_validate: f_chartCode.Validate,
          chart_code_length: f_chartCode.Length,
          chart_code_script: f_chartCode.Script,
          chart_code_type: f_chartCode.Type,
          chart_code_tag: f_chartCode.Tag,
          chart_code_hide: f_chartCode.Hide,
          chart_code_readonly: f_chartCode.Readonly,
          chart_code_required: f_chartCode.Required,

          //Flag Modal
          flag_order: flag_order,
          flag_dbfield: f_flag.DBField,
          flag_validate: f_flag.Validate,
          flag_length: f_flag.Length,
          flag_script: f_flag.Script,
          flag_type: f_flag.Type,
          flag_tag: f_flag.Tag,
          flag_hide: f_flag.Hide,
          flag_readonly: f_flag.Readonly,
          flag_required: f_flag.Required,

          // Flag Payment Modal
          payment_flag_order: payment_flag_order,
          payment_flag_dbfield: f_payFlag.DBField,
          payment_flag_validate: f_payFlag.Validate,
          payment_flag_length: f_payFlag.Length,
          payment_flag_script: f_payFlag.Script,
          payment_flag_type: f_payFlag.Type,
          payment_flag_tag: f_payFlag.Tag,
          payment_flag_hide: f_payFlag.Hide,
          payment_flag_readonly: f_payFlag.Readonly,
          payment_flag_required: f_payFlag.Required,

          //Calc Days
          calc_days_order: calc_days_order,
          calc_days_dbfield: f_calcDays.DBField,
          calc_days_validate: f_calcDays.Validate,
          calc_days_length: f_calcDays.Length,
          calc_days_script: f_calcDays.Script,
          calc_days_type: f_calcDays.Type,
          calc_days_tag: f_calcDays.Tag,
          calc_days_hide: f_calcDays.Hide,
          calc_days_readonly: f_calcDays.Readonly,
          calc_days_required: f_calcDays.Required,

          //Calc Weeks
          calc_weeks_order: calc_weeks_order,
          calc_weeks_dbfield: f_calcWeeks.DBField,
          calc_weeks_validate: f_calcWeeks.Validate,
          calc_weeks_length: f_calcWeeks.Length,
          calc_weeks_script: f_calcWeeks.Script,
          calc_weeks_type: f_calcWeeks.Type,
          calc_weeks_tag: f_calcWeeks.Tag,
          calc_weeks_hide: f_calcWeeks.Hide,
          calc_weeks_readonly: f_calcWeeks.Readonly,
          calc_weeks_required: f_calcWeeks.Required,
        },
        () =>
          console.log(
            "GOT A",
            this.state.endDate_order,
            this.state.chart_sort_order
          )
      );
    } else {
      this.setState({
        pmt_code: "",
        desc: "",
        TBA: null,
        unit: "",
        unit_desc: "",
        rate: "",
        factor: "",
        amount: "",
        capped_amount: "",
        days_in_week: "",
        //   startDate:"",
        //   endDate:"",
        chart_sort: "",
        chart_code: "",
        flag: "",
        payment_flag: "",
        calc_days: "",
        calc_weeks: "",
      });
    }
  };

  updateCellvalues = async (event, index, key) => {
    if (key === "TBA") {
      if (
        this.state.CustomListFields[index].TBA === "" ||
        this.state.CustomListFields[index].TBA === null
      ) {
        this.state.CustomListFields[index].TBA = "checked";
        await this.setState({ TBA: "checked" });
      } else {
        this.state.CustomListFields[index].TBA = "";
        await this.setState({ TBA: "" });
      }
      console.log("=>TBA", this.state.CustomListFields[index]);
    }
    if (key === "code") {
      var value = event.target.innerHTML;
      this.state.CustomListFields[index].CustomCode = value;
      console.log(this.state.CustomListFields);
    }
    if (key === "desc") {
      var value = event.target.innerHTML;
      this.state.CustomListFields[index].Desc = value;
      console.log(this.state.CustomListFields);
    }
    if (key === "unit") {
      var value = event.target.innerHTML;
      var rate;
      var factor;
      var n_amount;
      if (value >= 0) {
        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "rate") {
            rate = obj.DefaultValue;
          }
          if (obj.Prompt === "factor") {
            factor = obj.DefaultValue;
          }

          if (obj.Prompt === "unit") {
            obj.DefaultValue = value;
          }
        });
        n_amount = factor * rate * value;

        this.state.CustomListFields[index].CustomFields.map(async (obj, i) => {
          if (obj.Prompt === "amount") {
            obj.DefaultValue = n_amount;
            console.log(this.state.CustomListFields);
          }
        });
        await this.setState({
          amount: n_amount,
        });
        console.log(this.state.CustomListFields);
        console.log("TOTAL", value, rate, factor, n_amount);
      } else {
        toast.error("Invalid unit value");
      }
    }
    if (key === "unit_desc") {
      var value = event.target.innerHTML;
      this.state.CustomListFields[index].CustomFields.map((obj, i) => {
        if (obj.Prompt === "unit_desc") {
          obj.DefaultValue = value;
          console.log(this.state.CustomListFields);
        }
      });
    }
    if (key === "rate") {
      var value = event.target.innerHTML;
      var unit;
      var factor;
      var n_amount;
      if (value >= 0) {
        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "unit") {
            unit = obj.DefaultValue;
          }
          if (obj.Prompt === "factor") {
            factor = obj.DefaultValue;
          }

          if (obj.Prompt === "rate") {
            obj.DefaultValue = value;
          }
        });
        n_amount = factor * value * unit;
        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.DefaultValue = n_amount;
          }
        });

        await this.setState({
          amount: n_amount,
        });
        console.log("TOTAL", unit, value, factor, n_amount);
      } else {
        toast.error("Invalid rate value", { autoClose: 5000 });
      }
    }
    if (key === "factor") {
      var value = event.target.innerHTML;
      var rate;
      var unit;
      var n_amount;
      if (value >= 0) {
        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "rate") {
            rate = obj.DefaultValue;
          }
          if (obj.Prompt === "unit") {
            unit = obj.DefaultValue;
          }

          if (obj.Prompt === "factor") {
            obj.DefaultValue = value;
          }
        });
        n_amount = value * rate * unit;
        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "amount") {
            obj.DefaultValue = n_amount;
          }
        });

        await this.setState({
          amount: n_amount,
        });
      } else {
        toast.error("Invalid Factor Value", { autoClose: 5000 });
      }
    }

    if (key === "capped") {
      var value = event.target.innerHTML;
      this.state.CustomListFields[index].CustomFields.map((obj, i) => {
        if (obj.Prompt === "capped_amount") {
          obj.DefaultValue = value;
          console.log(this.state.CustomListFields);
        }
      });
    }
    if (key === "sort") {
      var value = event.target.innerHTML;
      this.state.CustomListFields[index].CustomFields.map((obj, i) => {
        if (obj.Prompt === "chart_sort") {
          obj.DefaultValue = value;
          console.log(this.state.CustomListFields);
        }
      });
    }
    if (key === "code") {
      var value = event.target.innerHTML;
      this.state.CustomListFields[index].CustomFields.map((obj, i) => {
        if (obj.Prompt === "chart_code") {
          obj.DefaultValue = value;
          console.log(this.state.CustomListFields);
        }
      });
    }
    if (key === "flag") {
      var value = event.target.innerHTML;
      this.state.CustomListFields[index].CustomFields.map((obj, i) => {
        if (obj.Prompt === "flag") {
          obj.DefaultValue = value;
          console.log(this.state.CustomListFields);
        }
      });
    }
    if (key === "pay_flag") {
      var value = event.target.innerHTML;
      this.state.CustomListFields[index].CustomFields.map((obj, i) => {
        if (obj.Prompt === "payment_flag") {
          obj.DefaultValue = value;
          console.log(this.state.CustomListFields);
        }
      });
    }
    if (key === "date_from") {
      var startDate = moment(event.format("YYYY-MM-DD"));
      var e_date;
      var dayWeeks;
      this.state.CustomListFields[index].CustomFields.map((obj, i) => {
        if (obj.Prompt === "endDate") {
          e_date = moment(moment(obj.DefaultValue).format("YYYY-MM-DD"));
        }
        if (obj.Prompt === "days_in_week") {
          dayWeeks = obj.DefaultValue;
        }
      });
      if (startDate.isSameOrBefore(e_date)) {
        var calc_days = (e_date.diff(startDate, "days") + 1) % 7;
        var calc_weeks = e_date.diff(startDate, "weeks");
        console.log(">>>>>>>", calc_weeks, calc_days);
        if (calc_days >= dayWeeks) {
          calc_weeks++;
          this.state.CustomListFields[index].CustomFields.map((obj, i) => {
            if (obj.Prompt === "calc_days") {
              obj.DefaultValue = "0";
            }
          });
          this.state.CustomListFields[index].CustomFields.map((obj, i) => {
            if (obj.Prompt === "calc_weeks") {
              obj.DefaultValue = calc_weeks;
            }
          });
        } else {
          if (calc_days === 0) {
            calc_weeks++;
          }
          this.state.CustomListFields[index].CustomFields.map((obj, i) => {
            if (obj.Prompt === "calc_days") {
              obj.DefaultValue = calc_days;
            }
          });
          this.state.CustomListFields[index].CustomFields.map((obj, i) => {
            if (obj.Prompt === "calc_weeks") {
              obj.DefaultValue = calc_weeks;
            }
          });
        }

        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.DefaultValue = startDate.toString();
            this.setState({ startDate: event });
          }
        });
      } else {
        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "startDate") {
            obj.DefaultValue = startDate.toString();
            this.setState({ startDate: event });
          }
          if (obj.Prompt === "calc_weeks") {
            obj.DefaultValue = "0";
          }
          if (obj.Prompt === "calc_days") {
            obj.DefaultValue = "0";
          }
        });
      }
    }
    if (key === "date_to") {
      var endDate = moment(event.format("YYYY-MM-DD"));
      var s_date;
      var dayWeeks;
      this.state.CustomListFields[index].CustomFields.map((obj, i) => {
        if (obj.Prompt === "startDate") {
          s_date = moment(moment(obj.DefaultValue).format("YYYY-MM-DD"));
        }
        if (obj.Prompt === "days_in_week") {
          dayWeeks = obj.DefaultValue;
        }
      });

      if (endDate.isSameOrAfter(s_date)) {
        var calc_days = (endDate.diff(s_date, "days") + 1) % 7;
        var calc_weeks = endDate.diff(s_date, "weeks");
        console.log(">>>>>>>", calc_weeks, calc_days);
        if (calc_days >= dayWeeks) {
          calc_weeks++;
          this.state.CustomListFields[index].CustomFields.map((obj, i) => {
            if (obj.Prompt === "calc_days") {
              obj.DefaultValue = "0";
            }
          });
          this.state.CustomListFields[index].CustomFields.map((obj, i) => {
            if (obj.Prompt === "calc_weeks") {
              obj.DefaultValue = calc_weeks;
            }
          });
        } else {
          if (calc_days === 0) {
            calc_weeks++;
          }
          this.state.CustomListFields[index].CustomFields.map((obj, i) => {
            if (obj.Prompt === "calc_days") {
              obj.DefaultValue = calc_days;
            }
          });
          this.state.CustomListFields[index].CustomFields.map((obj, i) => {
            if (obj.Prompt === "calc_weeks") {
              obj.DefaultValue = calc_weeks;
            }
          });
        }

        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.DefaultValue = endDate.toString();
            this.setState({ endDate: event });
          }
        });
      } else {
        this.state.CustomListFields[index].CustomFields.map((obj, i) => {
          if (obj.Prompt === "endDate") {
            obj.DefaultValue = endDate.toString();
            this.setState({ endDate: event });
          }
          if (obj.Prompt === "calc_weeks") {
            obj.DefaultValue = "0";
          }
          if (obj.Prompt === "calc_days") {
            obj.DefaultValue = "0";
          }
        });
      }
    }
    console.log("this.state.CustomListFields=>", this.state.CustomListFields);
  };
  assignOrder = async () => {
    var unit_order;
    var unit_desc_order;
    var rate_order;
    var factor_order;
    var amount_order;
    var days_in_week_order;
    var startDate_order;
    var endDate_order;
    var capped_amount_order;
    var chart_sort_order;
    var chart_code_order;
    var flag_order;
    var payment_flag_order;
    var calc_days_order;
    var calc_weeks_order;

    await this.state.CustomListFields[
      this.state.CustomListFields.length - 1
    ].CustomFields.map((field, i) => {
      if (field.Prompt === "unit") {
        unit_order = field.order;
      }
      if (field.Prompt === "unit_desc") {
        unit_desc_order = field.order;
      }
      if (field.Prompt === "rate") {
        rate_order = field.order;
      }
      if (field.Prompt === "factor") {
        factor_order = field.order;
      }

      if (field.Prompt === "amount") {
        amount_order = field.order;
      }

      if (field.Prompt === "capped_amount") {
        capped_amount_order = field.order;
      }

      if (field.Prompt === "startDate") {
        startDate_order = field.order;
      }

      if (field.Prompt === "endDate") {
        endDate_order = field.order;
      }

      if (field.Prompt === "chart_sort") {
        chart_sort_order = field.order;
      }
      if (field.Prompt === "chart_code") {
        chart_code_order = field.order;
      }
      if (field.Prompt === "flag") {
        flag_order = field.order;
      }
      if (field.Prompt === "payment_flag") {
        payment_flag_order = field.order;
      }
      if (field.Prompt === "calc_days") {
        calc_days_order = field.order;
      }
      if (field.Prompt === "calc_weeks") {
        calc_weeks_order = field.order;
      }
      if (field.Prompt === "days_in_weeks") {
        days_in_week_order = field.order;
      }
    });

    await this.state.CustomListFields.map((obj, index) => {
      obj.CustomFields.sort((a, b) => (a.order > b.order ? 1 : -1));
      obj.CustomFields.map((data, i) => {
        if (data.Prompt === "unit") {
          data.order = unit_order;
        }
        if (data.Prompt === "unit_desc") {
          data.order = unit_desc_order;
        }
        if (data.Prompt === "rate") {
          data.order = rate_order;
        }
        if (data.Prompt === "factor") {
          data.order = factor_order;
        }

        if (data.Prompt === "amount") {
          data.order = amount_order;
        }

        if (data.Prompt === "capped_amount") {
          data.order = capped_amount_order;
        }

        if (data.Prompt === "startDate") {
          data.order = startDate_order;
        }

        if (data.Prompt === "endDate") {
          data.order = endDate_order;
        }

        if (data.Prompt === "chart_sort") {
          data.order = chart_sort_order;
        }
        if (data.Prompt === "chart_code") {
          data.order = chart_code_order;
        }
        if (data.Prompt === "flag") {
          data.order = flag_order;
        }
        if (data.Prompt === "payment_flag") {
          data.order = payment_flag_order;
        }
        if (data.Prompt === "calc_days") {
          data.order = calc_days_order;
        }
        if (data.Prompt === "calc_weeks") {
          data.order = calc_weeks_order;
        }
        if (data.Prompt === "days_in_weeks") {
          data.order = days_in_week_order;
        }
      });
    });
  };
  updateAssignOrder = async (key) => {
    console.log("KEY", key);
    var unit_order;
    var unit_desc_order;
    var rate_order;
    var factor_order;
    var amount_order;
    var days_in_week_order;
    var startDate_order;
    var endDate_order;
    var capped_amount_order;
    var chart_sort_order;
    var chart_code_order;
    var flag_order;
    var payment_flag_order;
    var calc_days_order;
    var calc_weeks_order;

    await this.state.CustomListFields[key].CustomFields.map((field, i) => {
      if (field.Prompt === "unit") {
        unit_order = field.order;
      }
      if (field.Prompt === "unit_desc") {
        unit_desc_order = field.order;
      }
      if (field.Prompt === "rate") {
        rate_order = field.order;
      }
      if (field.Prompt === "factor") {
        factor_order = field.order;
      }

      if (field.Prompt === "amount") {
        amount_order = field.order;
      }

      if (field.Prompt === "capped_amount") {
        capped_amount_order = field.order;
      }

      if (field.Prompt === "startDate") {
        startDate_order = field.order;
      }

      if (field.Prompt === "endDate") {
        endDate_order = field.order;
      }

      if (field.Prompt === "chart_sort") {
        chart_sort_order = field.order;
      }
      if (field.Prompt === "chart_code") {
        chart_code_order = field.order;
      }
      if (field.Prompt === "flag") {
        flag_order = field.order;
      }
      if (field.Prompt === "payment_flag") {
        payment_flag_order = field.order;
      }
      if (field.Prompt === "calc_days") {
        calc_days_order = field.order;
      }
      if (field.Prompt === "calc_weeks") {
        calc_weeks_order = field.order;
      }
      if (field.Prompt === "days_in_weeks") {
        days_in_week_order = field.order;
      }
    });
    console.log(">>>", unit_order, unit_desc_order, rate_order, factor_order);
    await this.state.CustomListFields.map((obj, index) => {
      obj.CustomFields.sort((a, b) => (a.order > b.order ? 1 : -1));
      obj.CustomFields.map((data, i) => {
        if (data.Prompt === "unit") {
          data.order = unit_order;
        }
        if (data.Prompt === "unit_desc") {
          data.order = unit_desc_order;
        }
        if (data.Prompt === "rate") {
          data.order = rate_order;
        }
        if (data.Prompt === "factor") {
          data.order = factor_order;
        }

        if (data.Prompt === "amount") {
          data.order = amount_order;
        }

        if (data.Prompt === "capped_amount") {
          data.order = capped_amount_order;
        }

        if (data.Prompt === "startDate") {
          data.order = startDate_order;
        }

        if (data.Prompt === "endDate") {
          data.order = endDate_order;
        }

        if (data.Prompt === "chart_sort") {
          data.order = chart_sort_order;
        }
        if (data.Prompt === "chart_code") {
          data.order = chart_code_order;
        }
        if (data.Prompt === "flag") {
          data.order = flag_order;
        }
        if (data.Prompt === "payment_flag") {
          data.order = payment_flag_order;
        }
        if (data.Prompt === "calc_days") {
          data.order = calc_days_order;
        }
        if (data.Prompt === "calc_weeks") {
          data.order = calc_weeks_order;
        }
        if (data.Prompt === "days_in_weeks") {
          data.order = days_in_week_order;
        }
      });
      // console.log(">>>>>>>",obj)
    });
  };
  render() {
    return (
      <div className="FormTemplateDetail">
        <Header
          pageHeading="Form Templates"
          props={this.props}
          isLoading={this.state.isLoading}
        />
        <div className="clearfix"></div>
        <div className="col-sm-12 p0 top_mrg_general top_mrg_general_custom">
          <div className="">
            <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 p0">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <LinkContainer to="/form-template">
                  <a href={null} className="form_temp_detail">
                    <img src={arrowBlackFrom} width="20" height="20" alt="" />{" "}
                    Form Templates
                  </a>
                </LinkContainer>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="clear40"></div>
                  <div className="panel-group" id="accordion">
                    <div className="panel panel-default help_panel_group">
                      <div className="panel-heading help_panel_heading formtemplatedetail_panel_heading">
                        <h4 className="panel-title">
                          <a
                            id="Details"
                            className="accordion-toggle collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                          >
                            &nbsp; Details
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" className="panel-collapse collapse">
                        <div className="panel-body help_body_box">
                          <div className="clear10"></div>
                          <div className="col-sm-5 col-md-4 label_dataentry custom_label">
                            Template Code:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <input
                              name=""
                              className="form_detail_iput"
                              value={
                                this.state.detail_template_code
                                  ? this.state.detail_template_code
                                  : ""
                              }
                              onChange={(e) => this.detail_template_code(e)}
                              type="text"
                            />
                            {this.state.template_code_error ? (
                              <p className="error validation ">
                                This field is required{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-5 col-md-4 label_dataentry custom_label">
                            Template Description:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <input
                              name=""
                              className="form_detail_iput"
                              type="text"
                              value={this.state.detail_template_description}
                              onChange={(e) =>
                                this.detail_template_description(e)
                              }
                            />
                            {this.state.template_desc_error ? (
                              <p className="error validation ">
                                This field is required{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-5 col-md-4 label_dataentry custom_label">
                            Document:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-10 col-sm-10 p0">
                                <input
                                  name=""
                                  className="form_detail_iput fileupload-preview"
                                  value={this.state.detail_document}
                                  type="text"
                                />
                              </div>

                              <div className="col-xs-2 col-sm-2 calendar_time2 cursorPointer">
                                <div
                                  className="fileupload fileupload-new"
                                  data-provides="fileupload "
                                >
                                  <span className="btn-file formtemplate-btn-file">
                                    <span className="fileupload-new1 ">
                                      <img
                                        src={uploadFile}
                                        className="cursorPointer"
                                        width="40"
                                        height="31"
                                        alt=""
                                      />
                                    </span>
                                    <input
                                      type="file"
                                      className="cursorPointer"
                                      onChange={(e) => this.filechange(e)}
                                      accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    />
                                  </span>
                                </div>
                                <a href={null}></a>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            {this.state.document_error ? (
                              <p className="error validation ">
                                This field is required{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-5 col-md-4 label_dataentry custom_label">
                            Doc Password:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <div className="col-xs-12 p0">
                              <div className="col-xs-6 col-sm-6 p0">
                                <input
                                  name=""
                                  className="form_detail_iput"
                                  type="password"
                                  value={this.state.detail_doc_password}
                                  onChange={(e) => this.detail_doc_password(e)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-xs-5 col-sm-5 col-md-4 label_dataentry custom_label label_dataentry_form_temp">
                            Confidential:
                          </div>
                          <div className="col-xs-7 col-sm-7 col-md-8">
                            <div className="clearfix"></div>
                            <label className="check_box_pop">
                              <input
                                type="checkbox"
                                className="form_detail_iput"
                                value="1"
                                checked={this.state.detail_confidential}
                                onChange={(e) => this.detail_confidential(e)}
                              />
                              <span
                                style={{ marginTop: "25px" }}
                                className="checkmark left0_check"
                              ></span>
                            </label>
                            <div className="clearfix"></div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-xs-5 col-sm-5 col-md-4 label_dataentry custom_label label_dataentry_form_temp">
                            Hide from External Signer:
                          </div>
                          <div className="col-xs-7 col-sm-7 col-md-8">
                            <div className="clearfix"></div>
                            <label className="check_box_pop">
                              <input
                                type="checkbox"
                                value="1"
                                checked={
                                  this.state.detail_hide_from_external_signer
                                }
                                onChange={(e) =>
                                  this.detail_hide_from_external_signer(e)
                                }
                              />
                              <span
                                style={{ marginTop: "25px" }}
                                className="checkmark left0_check"
                              ></span>
                            </label>
                            <div className="clearfix"></div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="col-sm-5 col-md-4 label_dataentry custom_label">
                            Email Dist:
                          </div>
                          <div className="col-sm-7 col-md-8">
                            <input
                              name=""
                              className="form_detail_iput"
                              type="text"
                              value={this.state.detail_email_dist}
                              onChange={(e) => this.detail_email_dist(e)}
                            />
                            {this.state.email_dist_error ? (
                              <p className="error validation ">
                                Email should be in format demo@mail.com{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="clear20"></div>
                          <div className="col-sm-12 col-md-12 label_attchment_form">
                            Attachments
                          </div>
                          <div className="clear10"></div>
                          <div className="col-sm-12 attchment_bottom_label">
                            <div className="label_dataentry">
                              {this.state.FileAttachments.map(
                                (FileAttachment, key) => {
                                  return (
                                    <React.Fragment>
                                      {FileAttachment !== "" ? (
                                        <div
                                          className="attachmentlist"
                                          key={key}
                                        >
                                          <a
                                            href={null}
                                            onClick={(e) =>
                                              this.deleteuploadedAttachment(
                                                e,
                                                key,
                                                FileAttachment.url
                                              )
                                            }
                                            title="Remove"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="1700 296 15 19.286"
                                            >
                                              <path
                                                id="ic_clear_24px"
                                                className="cls-1"
                                                d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                                transform="translate(1695 293)"
                                              ></path>
                                            </svg>
                                          </a>
                                          {/*<img className='attachmentinlist' src={FileAttachments_url} alt="" />*/}
                                          {FileAttachment.filename}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                              {this.state.FileAttachments.length
                                ? ""
                                : "Record not found"}
                            </div>
                            <div className="clear20"></div>
                            <div className="col-sm-12 p0">
                              <Dropzone
                                onDrop={this.onDrop.bind(this)}
                                className="drag_drop_box "
                              >
                                <div className="drag_drop_box">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="8655 7398 52 34.667"
                                  >
                                    <path
                                      id="ic_backup_24px"
                                      className="cls-1"
                                      d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z"
                                      transform="translate(8655 7394)"
                                    />
                                  </svg>
                                  <div className="clear10"></div>
                                  Drag files in or click to upload
                                </div>
                              </Dropzone>
                            </div>

                            <div className="clear20"></div>
                            {/*
																	<div className="col-sm-6">
                                     <div className="btn_cance_save">
                                        <button type="button" className="btn_save_pro" onClick={(e)=>this.submit_document_detail(e,'detail')}>Save</button>
                                        <button type="button" className="btn_cancel_pro" data-dismiss="modal" onClick={(e)=>this.backToProjects(e)}>Cancel</button>
                                     </div>
                                  </div>

																	*/}
                          </div>
                          <div className="clear10"></div>
                          <div className="btn_cance_save formtemplatedetail_hide">
                            <div className="col-sm-12">
                              <button
                                type="button"
                                className="btn_general_report"
                              >
                                Generate Report
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default help_panel_group">
                      {/*<div className="panel-heading help_panel_heading formtemplatedetail_panel_heading">
                           <h4 className="panel-title">
                              <a className="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTen">
                              &nbsp; Custom Fields</a>
                           </h4>
					 </div>*/}
                      <div
                        id="collapseTen"
                        className="panel-collapse  col-sm-12 collapse p0"
                      >
                        <div className="panel-body p0">
                          <div className="clear20"></div>
                          <div>
                            <a href={null}>
                              <button
                                type="button"
                                className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm formtemplatedetail_btn_plus_cusotm"
                                onClick={(e) =>
                                  this.custom_field_onModalOpen(e)
                                }
                              >
                                +
                              </button>
                            </a>
                          </div>
                          <div className="clear10"></div>
                          <div className="help_body_box approvel_new_open_table">
                            <table
                              width="100%"
                              border="0"
                              cellSpacing="2"
                              cellPadding="2"
                            >
                              <thead>
                                <tr>
                                  <th width="15%" align="left">
                                    Description
                                  </th>
                                  <th width="20%" align="left">
                                    Default Value
                                  </th>
                                  <th
                                    width="20%"
                                    align="center"
                                    className="text-center"
                                  >
                                    Tag
                                  </th>
                                  <th
                                    width="25%"
                                    align="center"
                                    className="text-center"
                                  >
                                    Validation
                                  </th>
                                  <th width="20%" align="left"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="custom_field_tab">
                                  <td>Position</td>
                                  <td>Production</td>
                                  <td align="center">Dept</td>
                                  <td align="center">Uppercase</td>
                                  <td>
                                    <div className="col-sm-3  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
                                      <a href={null}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="1700 296 15 19.286"
                                        >
                                          <path
                                            id="ic_delete_24px"
                                            className="cls-1"
                                            d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                            transform="translate(1695 293)"
                                          ></path>
                                        </svg>
                                      </a>
                                    </div>
                                    <div className="col-sm-3  col-xs-6 p0 text-center timecard_edit6 pull-right">
                                      <a
                                        href={null}
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="1656.776 299 17.515 18.003"
                                        >
                                          <path
                                            id="ic_create_24px"
                                            className="cls-1"
                                            d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                            transform="translate(1653.776 296.002)"
                                          ></path>
                                        </svg>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="clear10"></div>
                        </div>
                      </div>
                    </div>
                    <div className="clear10"></div>
                    <div className="panel panel-default help_panel_group">
                      <div className="panel-heading help_panel_heading formtemplatedetail_panel_heading">
                        <h4 className="panel-title">
                          <a
                            className="accordion-toggle collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseEleven"
                          >
                            &nbsp; Custom List Codes
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseEleven"
                        className="panel-collapse collapse"
                      >
                        <div className="panel-body help_body_box p0 noBorder">
                          <div className="col-sm-12 col-md-12 general_setting_hd p0">
                            <div className="col-xs-12 col-sm-12 col-md-12 p0">
                              <div className="clear10"></div>
                              <a href={null}>
                                <button
                                  type="button"
                                  className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm btn_custom_list formtemplatedetail_btn_plus_cusotm"
                                  onClick={(event) => this.onad_ModalOpen()}
                                >
                                  +
                                </button>
                              </a>
                              <div className="clearfix"></div>
                              <div className="overflow_scroll_sm_list overflow_tab_custom_fld">
                                <table
                                  width="100%"
                                  className="table  timecard_table custome_lsit_field_table noBroder"
                                >
                                  <thead>
                                    <tr className="color_form_dtl_td">
                                      <th
                                        width="7%"
                                        align="center"
                                        valign="middle"
                                      >
                                        PmtCode
                                      </th>
                                      <th
                                        width="6%"
                                        align="center"
                                        valign="middle"
                                      >
                                        Desc
                                      </th>

                                      {this.state.CustomListFields.length >
                                      0 ? (
                                        this.state.CustomListFields[
                                          this.state.CustomListFields.length - 1
                                        ].CustomFields.sort((a, b) =>
                                          a.order > b.order ? 1 : -1
                                        ) ? (
                                          this.state.CustomListFields[
                                            this.state.CustomListFields.length -
                                              1
                                          ].CustomFields.map((obj, index) => {
                                            // console.log("OBJJJJ", obj);
                                            if (obj.Prompt === "unit_desc") {
                                              return (
                                                <th
                                                  width="6%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  UnitDesc
                                                </th>
                                              );
                                            }
                                            if (obj.Prompt === "unit") {
                                              return (
                                                <th
                                                  width="4%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  Unit
                                                </th>
                                              );
                                            }

                                            if (obj.Prompt === "rate") {
                                              return (
                                                <th
                                                  width="5%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  Rate
                                                </th>
                                              );
                                            }

                                            if (obj.Prompt === "factor") {
                                              return (
                                                <th
                                                  width="5%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  Factor
                                                </th>
                                              );
                                            }

                                            if (obj.Prompt === "amount") {
                                              return (
                                                <th
                                                  width="5%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  Amount
                                                </th>
                                              );
                                            }

                                            if (
                                              obj.Prompt === "capped_amount"
                                            ) {
                                              return (
                                                <th
                                                  width="6%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  Capped
                                                </th>
                                              );
                                            }
                                            if (obj.Prompt === "startDate") {
                                              return (
                                                <th
                                                  width="6%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  DateFrom
                                                </th>
                                              );
                                            }
                                            if (obj.Prompt === "endDate") {
                                              return (
                                                <th
                                                  width="6%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  DateTo
                                                </th>
                                              );
                                            }

                                            if (obj.Prompt === "chart_sort") {
                                              return (
                                                <th
                                                  width="6%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  ChartSort
                                                </th>
                                              );
                                            }

                                            if (obj.Prompt === "chart_code") {
                                              return (
                                                <th
                                                  width="6%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  ChartCode
                                                </th>
                                              );
                                            }

                                            if (obj.Prompt === "flag") {
                                              return (
                                                <th
                                                  width="5%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  Flag
                                                </th>
                                              );
                                            }
                                            if (obj.Prompt === "payment_flag") {
                                              return (
                                                <th
                                                  width="6%"
                                                  align="center"
                                                  valign="middle"
                                                >
                                                  PaymentFlag
                                                </th>
                                              );
                                            }
                                          })
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        <React.Fragment>
                                          <th
                                            width="4%"
                                            align="center"
                                            valign="middle"
                                          >
                                            Units
                                          </th>
                                          <th
                                            width="6%"
                                            align="center"
                                            valign="middle"
                                          >
                                            UnitsDesc
                                          </th>
                                          <th
                                            width="5%"
                                            align="center"
                                            valign="middle"
                                          >
                                            Rate
                                          </th>
                                          <th
                                            width="5%"
                                            align="center"
                                            valign="middle"
                                          >
                                            Factor
                                          </th>
                                          <th
                                            width="5%"
                                            align="center"
                                            valign="middle"
                                          >
                                            Amount
                                          </th>
                                          <th
                                            width="5%"
                                            align="center"
                                            valign="middle"
                                          >
                                            Capped
                                          </th>
                                          <th
                                            width="6%"
                                            align="center"
                                            valign="middle"
                                          >
                                            DateFrom
                                          </th>
                                          <th
                                            width="6%"
                                            align="center"
                                            valign="middle"
                                          >
                                            DateTo
                                          </th>
                                          <th
                                            width="6%"
                                            align="center"
                                            valign="middle"
                                          >
                                            ChartSort
                                          </th>
                                          <th
                                            width="6%"
                                            align="center"
                                            valign="middle"
                                          >
                                            ChartCode
                                          </th>
                                          <th
                                            width="5%"
                                            align="center"
                                            valign="middle"
                                          >
                                            Flag
                                          </th>
                                          <th
                                            width="6%"
                                            align="center"
                                            valign="middle"
                                          >
                                            PaymentFlag
                                          </th>
                                        </React.Fragment>
                                      )}
                                      <th
                                        width="6%"
                                        align="center"
                                        valign="middle"
                                      >
                                        TBA
                                      </th>

                                      <th
                                        width="20%"
                                        align="center"
                                        valign="middle"
                                      >
                                        &nbsp;
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {this.state.CustomListFields.length > 0 ? (
                                      this.state.CustomListFields.map(
                                        (CustomListField, key) => {
                                          CustomListField.CustomFields.sort(
                                            (a, b) =>
                                              a.order > b.order ? 1 : -1
                                          );
                                          console.log(
                                            "IIIIIII",
                                            CustomListField
                                          );
                                          var checkamount;
                                          CustomListField.CustomFields.map(
                                            (value, k) => {
                                              if (value.Prompt === "amount") {
                                                checkamount =
                                                  value.DefaultValue;
                                              }
                                            }
                                          );
                                          return (
                                            <tr>
                                              <td
                                                width="11%"
                                                align="center"
                                                valign="middle"
                                                contentEditable="true"
                                                onBlur={(event) =>
                                                  this.updateCellvalues(
                                                    event,
                                                    key,
                                                    "code"
                                                  )
                                                }
                                              >
                                                {CustomListField.CustomCode}
                                              </td>
                                              <td
                                                align="center"
                                                valign="middle"
                                                contentEditable="true"
                                                onBlur={(event) =>
                                                  this.updateCellvalues(
                                                    event,
                                                    key,
                                                    "desc"
                                                  )
                                                }
                                              >
                                                {CustomListField.Desc}
                                              </td>
                                              {CustomListField.CustomFields.map(
                                                (field, i) => {
                                                  if (field.Prompt === "unit") {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          width="11%"
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "unit"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    field.Prompt === "unit_desc"
                                                  ) {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "unit_desc"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (field.Prompt === "rate") {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "rate"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }

                                                  if (
                                                    field.Prompt === "factor"
                                                  ) {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "factor"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    field.Prompt === "amount"
                                                  ) {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "0"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    field.Prompt ===
                                                    "capped_amount"
                                                  ) {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "capped"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }

                                                  if (
                                                    field.Prompt === "startDate"
                                                  ) {
                                                    if (!field.Hide) {
                                                      if (checkamount > 0) {
                                                        var startDate = moment(
                                                          field.DefaultValue
                                                        ).format("DD/MM/YYYY");
                                                        return (
                                                          <td
                                                            align="center"
                                                            valign="middle"
                                                            className="FormtemplateDatePicker"
                                                          >
                                                            <DatePicker
                                                              dateFormat="DD/MM/YYYY"
                                                              placeholderText={
                                                                startDate
                                                                  ? startDate
                                                                  : ""
                                                              }
                                                              selected={moment(
                                                                field.DefaultValue
                                                              )}
                                                              onChange={(
                                                                date_from
                                                              ) =>
                                                                this.updateCellvalues(
                                                                  date_from,
                                                                  key,
                                                                  "date_from"
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                        );
                                                      } else {
                                                        return (
                                                          <td align="center">
                                                            ----
                                                          </td>
                                                        );
                                                      }
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }

                                                  if (
                                                    field.Prompt === "endDate"
                                                  ) {
                                                    if (
                                                      CustomListField.TBA !==
                                                      "checked"
                                                    ) {
                                                      if (!field.Hide) {
                                                        if (checkamount > 0) {
                                                          var endDate = moment(
                                                            field.DefaultValue
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          );
                                                          return (
                                                            <td
                                                              align="center"
                                                              valign="middle"
                                                              className="FormtemplateDatePicker"
                                                            >
                                                              <DatePicker
                                                                dateFormat="DD/MM/YYYY"
                                                                placeholderText={
                                                                  endDate
                                                                    ? endDate
                                                                    : ""
                                                                }
                                                                selected={moment(
                                                                  field.DefaultValue
                                                                )}
                                                                onChange={(
                                                                  date_from
                                                                ) =>
                                                                  this.updateCellvalues(
                                                                    date_from,
                                                                    key,
                                                                    "date_to"
                                                                  )
                                                                }
                                                              />
                                                            </td>
                                                          );
                                                        } else {
                                                          return (
                                                            <td align="center">
                                                              ----
                                                            </td>
                                                          );
                                                        }
                                                      } else {
                                                        return (
                                                          <td align="center">
                                                            ----
                                                          </td>
                                                        );
                                                      }
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          TBA
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    field.Prompt ===
                                                    "chart_sort"
                                                  ) {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "sort"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    field.Prompt ===
                                                    "chart_code"
                                                  ) {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "code"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (field.Prompt === "flag") {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "flag"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    field.Prompt ===
                                                    "payment_flag"
                                                  ) {
                                                    if (!field.Hide) {
                                                      return (
                                                        <td
                                                          align="center"
                                                          valign="middle"
                                                          contentEditable="true"
                                                          onBlur={(event) =>
                                                            this.updateCellvalues(
                                                              event,
                                                              key,
                                                              "pay_flag"
                                                            )
                                                          }
                                                        >
                                                          {field.DefaultValue
                                                            ? field.DefaultValue
                                                            : "N/A"}
                                                        </td>
                                                      );
                                                    } else {
                                                      return (
                                                        <td align="center">
                                                          ----
                                                        </td>
                                                      );
                                                    }
                                                  }
                                                  if (
                                                    field.Prompt === "calc_days"
                                                  ) {
                                                  }
                                                  if (
                                                    field.Prompt ===
                                                    "calc_weeks"
                                                  ) {
                                                  }
                                                  if (
                                                    field.Prompt ===
                                                    "days_in_week"
                                                  ) {
                                                  }
                                                }
                                              )}
                                              <td
                                                align="center"
                                                valign="middle"
                                              >
                                                <div className="col-xs-12 col-sm-8 col-md-8 pad-0">
                                                  <label className="check_box_pop">
                                                    <input
                                                      type="checkbox"
                                                      value={
                                                        CustomListField.TBA
                                                      }
                                                      checked={
                                                        CustomListField.TBA
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(event) =>
                                                        this.updateCellvalues(
                                                          event,
                                                          key,
                                                          "TBA"
                                                        )
                                                      }
                                                    />
                                                    <span className="checkmark report_checkmark new-chekbox"></span>
                                                  </label>
                                                </div>
                                              </td>

                                              <td
                                                align="center"
                                                valign="middle"
                                              >
                                                <div className="col-sm-4  col-xs-6 p0 pull-right text-center timecard_dele pull-right cursorPointer">
                                                  <a
                                                    href={null}
                                                    data-toggle="modal"
                                                    data-target="#exampleModalCenter"
                                                    onClick={(e) =>
                                                      this.deleteCustomlistCode(
                                                        e,
                                                        key
                                                      )
                                                    }
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="1700 296 15 19.286"
                                                    >
                                                      <path
                                                        id="ic_delete_24px"
                                                        className="cls-1"
                                                        d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                                        transform="translate(1695 293)"
                                                      />
                                                    </svg>
                                                  </a>
                                                </div>
                                                <div className="col-sm-4  col-xs-6 p0 text-center timecard_edit6 pull-right cursorPointer">
                                                  <a
                                                    href={null}
                                                    onClick={(e) =>
                                                      this.editCustomlistCode(
                                                        e,
                                                        key
                                                      )
                                                    }
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="1656.776 299 17.515 18.003"
                                                    >
                                                      <path
                                                        id="ic_create_24px"
                                                        className="cls-1"
                                                        d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                        transform="translate(1653.776 296.002)"
                                                      />
                                                    </svg>
                                                  </a>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="9">Record not found.</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className="clear20"></div>

                              {/*
										<div className="col-sm-6">
										<div className="btn_cance_save">
											 <button type="button" className="btn_save_pro" onClick={(e)=>this.submit_document_detail(e,'customfieldlist')}>Save</button>
											 <button type="button" className="btn_cancel_pro" data-dismiss="modal" onClick={(e)=>this.backToProjects(e)}>Cancel</button>
										</div>
									 </div>

										*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clear10"></div>
                    <div className="panel panel-default help_panel_group">
                      <div className="panel-heading help_panel_heading formtemplatedetail_panel_heading">
                        <h4 className="panel-title formtemplate-panel-title">
                          <a
                            className="accordion-toggle collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapse12"
                          >
                            &nbsp; Form Entry Fields
                          </a>
                        </h4>
                      </div>
                      <div id="collapse12" className="panel-collapse collapse">
                        <div className="panel-body help_body_box p0 noBorder">
                          <div className="clear10"></div>
                          <div>
                            <a href={null}>
                              <button
                                type="button"
                                className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm formtemplatedetail_btn_plus_cusotm"
                                onClick={(e) =>
                                  this.form_entry_fields_onModalOpen(e)
                                }
                              >
                                +
                              </button>
                            </a>
                          </div>
                          <div className="clear5"></div>
                          <div className="clearfix"></div>
                          <div className="overflow_scroll_sm">
                            <table
                              width="100%"
                              className="table table-bordered timecard_table approvel_field_table formtemplatedetail_approvel_field_table"
                            >
                              <thead>
                                <tr>
                                  <th width="3%" align="left">
                                    Order
                                  </th>
                                  <th
                                    width="4%"
                                    align="center"
                                    className="text_center_tb"
                                  >
                                    Detail
                                  </th>
                                  <th width="10%" align="left">
                                    GroupHeading
                                  </th>
                                  <th width="10%" align="left">
                                    Description
                                  </th>
                                  <th width="18%" align="left">
                                    Default Value
                                  </th>
                                  <th width="15%" align="left">
                                    Comments
                                  </th>
                                  <th width="20%" align="left">
                                    Tag
                                  </th>
                                  <th width="10%" align="left">
                                    DBField
                                  </th>
                                  <th width="10%" align="left">
                                    &nbsp;
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.form_entry_fields.map(
                                  (form_entry_field, key) => {
                                    var date = new Date(
                                      form_entry_field.DefaultValue
                                    );
                                    var value = "";
                                    if (form_entry_field.DefaultValue) {
                                      value =
                                        form_entry_field.Type === "Date"
                                          ? moment(date).format("DD/MM/YYYY")
                                          : form_entry_field.DefaultValue;
                                    }

                                    return (
                                      <React.Fragment>
                                        <tr>
                                          <td width="15%" align="left">
                                            {key + 1}
                                          </td>
                                          <td width="15%" align="center">
                                            <a
                                              href={null}
                                              id="music"
                                              onClick={(e) =>
                                                this.showdetail(e, key)
                                              }
                                            >
                                              <img
                                                src={downArrowAmprvoe}
                                                alt=""
                                              />
                                            </a>
                                          </td>
                                          <td align="left">
                                            {form_entry_field.SubHeading}
                                          </td>
                                          <td width="20%" align="left">
                                            {form_entry_field.Promt}
                                          </td>
                                          <td width="25%" align="left">
                                            {value}
                                          </td>
                                          <td align="left">
                                            {form_entry_field.Comments}
                                          </td>
                                          <td align="left">
                                            {form_entry_field.Tag}
                                          </td>
                                          <td align="left">
                                            {form_entry_field.DBField}
                                          </td>
                                          <td align="center" valign="middle">
                                            <div className="col-sm-4  col-xs-6 p0 pull-right text-center timecard_dele pull-right cursorPointer">
                                              <a
                                                href={null}
                                                onClick={(e) =>
                                                  this.deleteformentry(e, key)
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="1700 296 15 19.286"
                                                >
                                                  <path
                                                    id="ic_delete_24px"
                                                    className="cls-1"
                                                    d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                                    transform="translate(1695 293)"
                                                  ></path>
                                                </svg>
                                              </a>
                                            </div>
                                            <div className="col-sm-4  col-xs-6 p0 text-center timecard_edit6 pull-right cursorPointer">
                                              <a
                                                href={null}
                                                onClick={(e) =>
                                                  this.editformentry(e, key)
                                                }
                                                data-toggle="modal"
                                                data-target="#myModal3"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="1656.776 299 17.515 18.003"
                                                >
                                                  <path
                                                    id="ic_create_24px"
                                                    className="cls-1"
                                                    d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                    transform="translate(1653.776 296.002)"
                                                  ></path>
                                                </svg>
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          id={key}
                                          className="approvel_new_open_table hide_table_detail"
                                        >
                                          <td colSpan="9">
                                            <div className="clear10"></div>
                                            <table
                                              width="100%"
                                              border="0"
                                              cellSpacing="2"
                                              celladding="2"
                                              className="clr_tr_bg_entry_form"
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    width="30%"
                                                    align="center"
                                                    className="text-center"
                                                  >
                                                    Company
                                                  </th>
                                                  <th
                                                    width="30%"
                                                    align="center"
                                                    className="text-center"
                                                  >
                                                    External
                                                  </th>
                                                  <th
                                                    width="15%"
                                                    align="center"
                                                  ></th>
                                                  <th
                                                    width="15%"
                                                    align="center"
                                                  ></th>
                                                  <th
                                                    width="10%"
                                                    align="left"
                                                  ></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr className="clr_tr_bg_entry_form2">
                                                  <td className="formtemplatedetail_borderSolidGrey">
                                                    <table
                                                      width="100%"
                                                      border="0"
                                                      cellSpacing="2"
                                                      cellPadding="2"
                                                      className="clr_tr_bg_entry_form3"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td>Internal</td>
                                                          <td>Hide</td>
                                                          <td>Read Only</td>
                                                          <td>Required</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td className="formtemplatedetail_borderSolidGrey">
                                                    <table
                                                      width="100%"
                                                      border="0"
                                                      cellSpacing="2"
                                                      cellPadding="2"
                                                      className="clr_tr_bg_entry_form3"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td>Hide</td>
                                                          <td>Read Only</td>
                                                          <td>Required</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td
                                                    className="formtemplatedetail_borderSolidGrey centerAlign"
                                                    align="center"
                                                  >
                                                    Field Type
                                                  </td>
                                                  <td
                                                    className="formtemplatedetail_borderSolidGrey centerAlign"
                                                    align="center "
                                                  >
                                                    Field Length
                                                  </td>
                                                  <td className="centerAlign">
                                                    Validation
                                                  </td>
                                                </tr>
                                                <tr className="clr_tr_bg_entry_form2 clr_tr_bg_entry_form2_custom transparentBackground">
                                                  <td className="svg_tic_form_entry transparentBackground formtemplatedetail_borderSolidGrey">
                                                    <table width="100%">
                                                      <tbody>
                                                        <tr className="innertr">
                                                          <td
                                                            className="innertd"
                                                            width="25%"
                                                          >
                                                            {" "}
                                                            <img
                                                              src={
                                                                form_entry_field.Internal
                                                                  ? tickBlack
                                                                  : ""
                                                              }
                                                              width="18"
                                                              height="13"
                                                              alt=""
                                                            />
                                                          </td>
                                                          <td
                                                            className="innertd"
                                                            width="25%"
                                                          >
                                                            {" "}
                                                            <img
                                                              src={
                                                                form_entry_field.HideInt
                                                                  ? tickBlack
                                                                  : ""
                                                              }
                                                              width="18"
                                                              height="13"
                                                              alt=""
                                                            />
                                                          </td>
                                                          <td
                                                            className="innertd"
                                                            width="25%"
                                                          >
                                                            {" "}
                                                            <img
                                                              src={
                                                                form_entry_field.ReadOnlyInt
                                                                  ? tickBlack
                                                                  : ""
                                                              }
                                                              width="18"
                                                              height="13"
                                                              alt=""
                                                            />
                                                          </td>
                                                          <td
                                                            className="innertd"
                                                            width="25%"
                                                          >
                                                            {" "}
                                                            <img
                                                              src={
                                                                form_entry_field.ReqInt
                                                                  ? tickBlack
                                                                  : ""
                                                              }
                                                              width="18"
                                                              height="13"
                                                              alt=""
                                                            />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                  <td className="svg_tic_form_entry transparentBackground formtemplatedetail_borderSolidGrey">
                                                    <table width="100%">
                                                      <tbody>
                                                        <tr className="innertr">
                                                          <td
                                                            className="innertd"
                                                            width="30%"
                                                          >
                                                            {" "}
                                                            <img
                                                              src={
                                                                form_entry_field.HideExt
                                                                  ? tickBlack
                                                                  : ""
                                                              }
                                                              width="18"
                                                              height="13"
                                                              alt=""
                                                            />
                                                          </td>
                                                          <td
                                                            className="innertd"
                                                            width="33%"
                                                          >
                                                            {" "}
                                                            <img
                                                              src={
                                                                form_entry_field.ReadOnlyExt
                                                                  ? tickBlack
                                                                  : ""
                                                              }
                                                              width="18"
                                                              height="13"
                                                              alt=""
                                                            />
                                                          </td>
                                                          <td
                                                            className="innertd"
                                                            width="33%"
                                                          >
                                                            {" "}
                                                            <img
                                                              src={
                                                                form_entry_field.ReqExt
                                                                  ? tickBlack
                                                                  : ""
                                                              }
                                                              width="18"
                                                              height="13"
                                                              alt=""
                                                            />
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>

                                                  <td
                                                    className="transparentBackground formtemplatedetail_borderSolidGrey text_center"
                                                    align="center"
                                                  >
                                                    {form_entry_field.Type
                                                      ? form_entry_field.Type
                                                      : ""}
                                                  </td>
                                                  <td
                                                    className="transparentBackground formtemplatedetail_borderSolidGrey text_center"
                                                    align="center"
                                                  >
                                                    {form_entry_field.Length
                                                      ? form_entry_field.Length
                                                      : ""}
                                                  </td>
                                                  <td className="text_center">
                                                    {form_entry_field.Validation
                                                      ? form_entry_field.Validation
                                                      : ""}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div className="clear30"></div>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }
                                )}

                                {this.state.form_entry_fields.length ? (
                                  ""
                                ) : (
                                  <tr>
                                    <td colSpan="9">Record not found.</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            <div className="clear20"></div>
                            {/*
																	 <div className="col-sm-6">
																			<div className="btn_cance_save">
																				 <button type="button" className="btn_save_pro" onClick={(e)=>this.submit_document_detail(e,'form_entry_field')}>Save</button>
																				 <button type="button" className="btn_cancel_pro" data-dismiss="modal" onClick={(e)=>this.backToProjects(e)}>Cancel</button>
																			</div>
																	 </div>

																	 */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear20"></div>
                <div className="col-sm-6 offset-sm-3">
                  <div className="btn_cance_save">
                    <button
                      type="button"
                      className="btn_save_pro"
                      onClick={(e) =>
                        this.submit_document_detail(e, "customfieldlist")
                      }
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_cancel_pro"
                      data-dismiss="modal"
                      onClick={(e) => this.backToProjects(e)}
                    >
                      Close
                    </button>
                  </div>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="clear10"></div>
          </div>
        </div>
        <div className="clear10"></div>

        <Modal
          show={this.state.custom_field_modalOpen}
          onHide={this.closeModal}
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close formtemplatedetail_OutlineNone"
              onClick={(e) => this.closeModal(e)}
            >
              <img
                className="formtemplatedetail_mt22"
                src={cross}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Custom Fields</h4>
          </div>

          <div className="modal-body label_modal_custom">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-3 col-md-3 label_csutom_pop">
                Description:
              </div>
              <div className="col-sm-9 col-md-9">
                <select
                  name=""
                  value={this.state.custom_field_description}
                  onChange={(e) => this.custom_field_description(e)}
                ></select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-3 col-md-3 label_csutom_pop">
                Default Value:
              </div>
              <div className="col-sm-9 col-md-9">
                <input
                  name=""
                  type="text"
                  value={this.state.custom_field_default_value}
                  onChange={(e) => this.custom_field_default_value(e)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-3 col-md-3 label_csutom_pop">Tag:</div>
              <div className="col-sm-9 col-md-9">
                <input
                  name=""
                  type="text"
                  value={this.state.custom_field_tag}
                  onChange={(e) => this.custom_field_tag(e)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-sm-3 col-md-3 label_csutom_pop">
                Validations:
              </div>
              <div className="col-sm-9 col-md-9">
                <input
                  name=""
                  type="text"
                  value={this.state.custom_field_validations}
                  onChange={(e) => this.custom_field_validations(e)}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button type="submit" className="btn_save_pro">
                  Save
                </button>
                <button
                  type="button"
                  className="btn_cancel_pro"
                  onClick={(e) => this.closeModal(e)}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>

        <Modal
          show={this.state.ad_modalOpen}
          onHide={this.onad_ModalClose}
          id="addCustomFieldlistmodal"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={this.onad_ModalClose}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Custom List Code {this.state.recordupdating ? "Update" : ""}{" "}
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist modal_pop_list_scroll customfieldslist_padding">
            <div className="col-sm-12">
              NOTE: Drag fields to change order in form and list.
              <div className="clear20"></div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Custom List:
                </div>
                <div className="col-xs-10 col-sm-7 col-md-7">
                  <select
                    name="customListValue"
                    value={this.state.customListValue}
                    onChange={(event) => this.customListHandler(event)}
                  >
                    <option value="">Select</option>
                    {this.state.customListData.map((listvalue, index) => (
                      <option value={listvalue.Guid}>{listvalue.Desc}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div
                  id="pmt_code"
                  className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop"
                >
                  Pmt Code:
                </div>
                <div className="col-xs-10 col-sm-7 col-md-7">
                  <input
                    name="pmt_code"
                    type="text"
                    value={this.state.pmt_code}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.pmt_code ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.pmt_code}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div
                  id="desc"
                  className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop"
                >
                  Description:
                </div>
                <div className="col-xs-10 col-sm-7 col-md-7">
                  <input
                    name="desc"
                    value={this.state.desc}
                    onChange={(event) => this.handleUserInput(event)}
                    type="text"
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.desc ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.desc}
                  </p>
                </div>
              </div>

              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  TBA:
                </div>
                <div className="col-xs-12 col-sm-8 col-md-8">
                  <label className="check_box_pop">
                    <input
                      type="checkbox"
                      name="TBA"
                      value={this.state.TBA}
                      checked={this.state.TBA ? true : false}
                      onChange={(event) => this.handleUserInputCheckbox(event)}
                    />
                    <span className="checkmark report_checkmark"></span>
                  </label>
                </div>
              </div>

              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="tagsForm">
                  {(provided, snapshot) => (
                    <div
                      id="tagsForm"
                      className="sortable_list connectedSortable"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <div className="clear10"></div>
                      {this.state.listHandler.CustomFields.map((obj, index) => {
                        if (!obj.Hide) {
                          if (obj.Prompt === "unit") {
                            if (!this.state.unit_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="unit"
                                      >
                                        Unit:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="unit"
                                          value={this.state.unit}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="number"
                                          min="0"
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.unit
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.unit}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onUnitModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone customfielddlist_pen_custom_list_pop"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "unit_desc") {
                            if (!this.state.unit_desc_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="unit_desc"
                                      >
                                        Unit Desc:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="unit_desc"
                                          value={this.state.unit_desc}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="text"
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.unit_desc
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.unit_desc}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onUnitDescModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone customfielddlist_pen_custom_list_pop"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "rate") {
                            if (!this.state.rate_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="rate"
                                      >
                                        Rate:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="rate"
                                          value={this.state.rate}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="number"
                                          step="0.01"
                                          min="0"
                                          max="1000000000"
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.rate
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.rate}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onRateModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone customfielddlist_pen_custom_list_pop"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "factor") {
                            if (!this.state.factor_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="factor"
                                      >
                                        Factor:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="factor"
                                          value={this.state.factor}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="number"
                                          step="0.01"
                                          min="0"
                                          max="1000000000"
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.factor
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.factor}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onFactorModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone customfielddlist_pen_custom_list_pop"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "amount") {
                            if (!this.state.amount_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="amount"
                                      >
                                        Amount:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="amount"
                                          value={this.state.amount}
                                          type="text"
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.amount
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.amount}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onAmountModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "capped_amount") {
                            if (!this.state.capped_amount_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="capped_amount"
                                      >
                                        Capped Amount:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="capped_amount"
                                          value={this.state.capped_amount}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="text"
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.capped_amount
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.capped_amount}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onCappedModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "days_in_week") {
                            if (!this.state.days_in_week_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="days_in_week"
                                      >
                                        Days in Week:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="days_in_week"
                                          value={this.state.days_in_week}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="number"
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.days_in_week
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.days_in_week}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onDaysInWeekModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "startDate") {
                            if (!this.state.startDate_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="startDate"
                                      >
                                        Date From:
                                      </div>
                                      <div className="form__date--picker col-xs-10 col-sm-7 col-md-7">
                                        <div className="col-xs-8 col-sm-8 p0">
                                          <DatePicker
                                            id="showDateFrom"
                                            dateFormat="DD/MM/YYYY"
                                            disabled={
                                              this.state.datePickerDisable
                                            }
                                            selected={
                                              this.state.datePickerDisable
                                                ? ""
                                                : this.state.startDate
                                            }
                                            onChange={this.handleChange.bind(
                                              this
                                            )}
                                          />
                                        </div>
                                        <div className="Svg--icons--picker">
                                          <svg
                                            onClick={(event) =>
                                              this.showDateFrom()
                                            }
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="2936.352 349.176 18.501 23.145"
                                          >
                                            <a
                                              href={null}
                                              className="svg__color--blue"
                                            >
                                              <path
                                                id="ic_date_range_24px"
                                                className="cls-1"
                                                d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                transform="translate(2933.352 347.176)"
                                              ></path>
                                            </a>
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onDateFromModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "endDate") {
                            if (!this.state.endDate_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props form__display--between"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="endDate"
                                      >
                                        Date To:
                                      </div>
                                      {this.state.TBA ? (
                                        <div className="col-xs-10 col-sm-7 col-md-7 tba-box">
                                          TBA
                                        </div>
                                      ) : (
                                        <React.Fragment>
                                          <div className="form__date--picker col-xs-10 col-sm-7 col-md-7">
                                            <div className="col-xs-8 col-sm-8 p0">
                                              <DatePicker
                                                id="showDateTo"
                                                dateFormat="DD/MM/YYYY"
                                                disabled={
                                                  this.state.datePickerDisable
                                                }
                                                selected={
                                                  this.state.datePickerDisable
                                                    ? ""
                                                    : this.state.endDate
                                                }
                                                onChange={this.handleChangeTo.bind(
                                                  this
                                                )}
                                              />
                                            </div>
                                            <div className="Svg--icons--picker">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="cursorPointer"
                                                onClick={(event) =>
                                                  this.showDateTo()
                                                }
                                                viewBox="2936.352 349.176 18.501 23.145"
                                              >
                                                <a
                                                  href={null}
                                                  className="svg__color--blue"
                                                >
                                                  <path
                                                    id="ic_date_range_24px"
                                                    className="cls-1"
                                                    d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                    transform="translate(2933.352 347.176)"
                                                  ></path>
                                                </a>
                                              </svg>
                                            </div>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onDateToModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </React.Fragment>
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "chart_sort") {
                            if (!this.state.chart_sort_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="chart_sort"
                                      >
                                        Chart Sort:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        {/* <input
                                          name="chart_sort"
                                          value={this.state.chart_sort}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="text"
                                        /> */}
                                        <InputMask
                                          mask={this.state.setup_chartSort.replace(
                                            /x/g,
                                            "*"
                                          )}
                                          maskChar={null}
                                          type="text"
                                          name="chart_sort"
                                          value={this.state.chart_sort}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.chart_sort
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.chart_sort}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          onClick={(event) =>
                                            this.onChartModalOpen()
                                          }
                                          type="button"
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "chart_code") {
                            if (!this.state.chart_code_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="chart_code"
                                      >
                                        Chart Code:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        {/* <input
                                          name="chart_code"
                                          value={this.state.chart_code}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="text"
                                        /> */}
                                        <InputMask
                                          mask={this.state.setup_chartCode.replace(
                                            /x/g,
                                            "*"
                                          )}
                                          maskChar={null}
                                          type="text"
                                          name="chart_code"
                                          value={this.state.chart_code}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                        />

                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.chart_code
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.chart_code}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onChartCodeModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "flag") {
                            if (!this.state.flag_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="flag"
                                      >
                                        Tracking Field:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <select
                                          name="flag"
                                          value={this.state.flag}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                        >
                                          <option value="">Select</option>
                                          {this.state.trackingList.map(
                                            (obj, i) => {
                                              return (
                                                <option
                                                  value={obj.defaultValue}
                                                >
                                                  {obj.Prompt}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.flag
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.flag}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onFlagModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "payment_flag") {
                            if (!this.state.payment_flag_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="payment_flag"
                                      >
                                        Payment Flag:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="payment_flag"
                                          value={this.state.payment_flag}
                                          onChange={(event) =>
                                            this.handleUserInput(event)
                                          }
                                          type="text"
                                        />
                                        <p
                                          className={
                                            "error validation " +
                                            (this.state.formErrors.payment_flag
                                              ? ""
                                              : "displayNone")
                                          }
                                        >
                                          {this.state.formErrors.payment_flag}
                                        </p>
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onPaymentFlagModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "calc_days") {
                            if (!this.state.calc_days_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="calc_days"
                                      >
                                        Calc Days:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="calc_days"
                                          value={this.state.calc_days}
                                          type="text"
                                          readOnly={true}
                                        />
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1 pull-right">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onCalcDaysModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                          if (obj.Prompt === "calc_weeks") {
                            if (!this.state.calc_weeks_hide) {
                              return (
                                <Draggable
                                  key={obj.Prompt}
                                  draggableId={obj.Prompt}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="form__display--props"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        id="calc_weeks"
                                      >
                                        Calc Weeks:
                                      </div>
                                      <div className="col-xs-10 col-sm-7 col-md-7">
                                        <input
                                          name="calc_weeks"
                                          value={this.state.calc_weeks}
                                          type="text"
                                          readOnly={true}
                                        />
                                      </div>
                                      <div className="col-xs-2 col-sm-1 col-md-1 pull-right">
                                        <button
                                          type="button"
                                          onClick={(event) =>
                                            this.onCalcWeeksModalOpen()
                                          }
                                          className="pen_custom_list_pop customfieldlist_OutlineNone "
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="1656.776 299 17.515 18.003"
                                          >
                                            <path
                                              id="ic_create_24px"
                                              className="cls-1"
                                              d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                              transform="translate(1653.776 296.002)"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            } else {
                              return "";
                            }
                          }
                        }
                      })}
                      {provided.placeholder}
                      <div className="clear5"></div>
                    </div>
                  )}
                </Droppable>

                <div
                  className="panel-heading panel_width exclude"
                  style={{ cursor: "all-scroll" }}
                >
                  <h4 className="panel-title">
                    <a
                      className="accordion-toggle collapsed text_change"
                      data-toggle="collapse"
                      data-parent=".sortable_list"
                      href="#hide_detail"
                    >
                      More Detail
                    </a>
                  </h4>
                </div>
                <Droppable droppableId="hide_detail">
                  {(provided, snapshot) => (
                    <div
                      id="hide_detail"
                      className="panel-collapse collapse "
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <div className="clear10"></div>
                      <div className="panel-body p0 ">
                        {this.state.listHandler.CustomFields.map(
                          (obj, index) => {
                            if (obj.Hide) {
                              if (obj.Prompt === "unit") {
                                if (this.state.unit_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="unit"
                                          >
                                            Unit:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="unit"
                                              value={this.state.unit}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="number"
                                              min="0"
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors.unit
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {this.state.formErrors.unit}
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onUnitModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone customfielddlist_pen_custom_list_pop"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }
                              if (obj.Prompt === "unit_desc") {
                                if (this.state.unit_desc_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="unit_desc"
                                          >
                                            Unit Desc:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="unit_desc"
                                              value={this.state.unit_desc}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="text"
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors.unit_desc
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {this.state.formErrors.unit_desc}
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onUnitDescModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone customfielddlist_pen_custom_list_pop"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }
                              if (obj.Prompt === "rate") {
                                if (this.state.rate_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="rate"
                                          >
                                            Rate:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="rate"
                                              value={this.state.rate}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="number"
                                              step="0.01"
                                              min="0"
                                              max="1000000000"
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors.rate
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {this.state.formErrors.rate}
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onRateModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone customfielddlist_pen_custom_list_pop"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }

                              if (obj.Prompt === "factor") {
                                if (this.state.factor_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="factor"
                                          >
                                            Factor:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="factor"
                                              value={this.state.factor}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="number"
                                              step="0.01"
                                              min="0"
                                              max="1000000000"
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors.factor
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {this.state.formErrors.factor}
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onFactorModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone customfielddlist_pen_custom_list_pop"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }
                              if (obj.Prompt === "amount") {
                                if (this.state.amount_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="amount"
                                          >
                                            Amount:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="amount"
                                              value={this.state.amount}
                                              type="text"
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors.amount
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {this.state.formErrors.amount}
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onAmountModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }
                              if (obj.Prompt === "capped_amount") {
                                if (this.state.capped_amount_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="capped_amount"
                                          >
                                            Capped Amount:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="capped_amount"
                                              value={this.state.capped_amount}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="text"
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors
                                                  .capped_amount
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {
                                                this.state.formErrors
                                                  .capped_amount
                                              }
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onCappedModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }
                              if (obj.Prompt === "days_in_week") {
                                if (this.state.days_in_week_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="days_in_week"
                                          >
                                            Days in Week:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="days_in_week"
                                              value={this.state.days_in_week}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="number"
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors
                                                  .days_in_week
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {
                                                this.state.formErrors
                                                  .days_in_week
                                              }
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onDaysInWeekModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }

                              if (obj.Prompt === "startDate") {
                                if (this.state.startDate_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="startDate"
                                          >
                                            Date From:
                                          </div>
                                          <div className="form__date--picker col-xs-10 col-sm-7 col-md-7">
                                            <div className="col-xs-8 col-sm-8 p0">
                                              <DatePicker
                                                id="showDateFrom"
                                                dateFormat="DD/MM/YYYY"
                                                disabled={
                                                  this.state.datePickerDisable
                                                }
                                                selected={
                                                  this.state.datePickerDisable
                                                    ? ""
                                                    : this.state.startDate
                                                }
                                                onChange={this.handleChange.bind(
                                                  this
                                                )}
                                              />
                                            </div>
                                            <div className="Svg--icons--picker">
                                              <svg
                                                onClick={(event) =>
                                                  this.showDateFrom()
                                                }
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="2936.352 349.176 18.501 23.145"
                                              >
                                                <a
                                                  href={null}
                                                  className="svg__color--blue"
                                                >
                                                  <path
                                                    id="ic_date_range_24px"
                                                    className="cls-1"
                                                    d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                    transform="translate(2933.352 347.176)"
                                                  ></path>
                                                </a>
                                              </svg>
                                            </div>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onDateFromModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }

                              if (obj.Prompt === "endDate") {
                                if (this.state.endDate_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props form__display--between"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="endDate"
                                          >
                                            Date To:
                                          </div>
                                          {this.state.TBA ? (
                                            <div className="col-xs-10 col-sm-7 col-md-7 tba-box">
                                              TBA
                                            </div>
                                          ) : (
                                            <React.Fragment>
                                              <div className="form__date--picker col-xs-10 col-sm-7 col-md-7">
                                                <div className="col-xs-8 col-sm-8 p0">
                                                  <DatePicker
                                                    id="showDateTo"
                                                    dateFormat="DD/MM/YYYY"
                                                    disabled={
                                                      this.state
                                                        .datePickerDisable
                                                    }
                                                    selected={
                                                      this.state
                                                        .datePickerDisable
                                                        ? ""
                                                        : this.state.endDate
                                                    }
                                                    onChange={this.handleChangeTo.bind(
                                                      this
                                                    )}
                                                  />
                                                </div>
                                                <div className="Svg--icons--picker">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="cursorPointer"
                                                    onClick={(event) =>
                                                      this.showDateTo()
                                                    }
                                                    viewBox="2936.352 349.176 18.501 23.145"
                                                  >
                                                    <a
                                                      href={null}
                                                      className="svg__color--blue"
                                                    >
                                                      <path
                                                        id="ic_date_range_24px"
                                                        className="cls-1"
                                                        d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                                                        transform="translate(2933.352 347.176)"
                                                      ></path>
                                                    </a>
                                                  </svg>
                                                </div>
                                              </div>
                                              <div className="col-xs-2 col-sm-1 col-md-1">
                                                <button
                                                  type="button"
                                                  onClick={(event) =>
                                                    this.onDateToModalOpen()
                                                  }
                                                  className="pen_custom_list_pop customfieldlist_OutlineNone "
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="1656.776 299 17.515 18.003"
                                                  >
                                                    <path
                                                      id="ic_create_24px"
                                                      className="cls-1"
                                                      d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                      transform="translate(1653.776 296.002)"
                                                    ></path>
                                                  </svg>
                                                </button>
                                              </div>
                                            </React.Fragment>
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }

                              if (obj.Prompt === "chart_sort") {
                                if (this.state.chart_sort_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="chart_sort"
                                          >
                                            Chart Sort:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            {/* <input
                                              name="chart_sort"
                                              value={this.state.chart_sort}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="text"
                                            /> */}
                                            <InputMask
                                              mask={this.state.setup_chartSort.replace(
                                                /x/g,
                                                "*"
                                              )}
                                              maskChar={null}
                                              type="text"
                                              name="chart_sort"
                                              value={this.state.chart_sort}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors
                                                  .chart_sort
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {this.state.formErrors.chart_sort}
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              onClick={(event) =>
                                                this.onChartModalOpen()
                                              }
                                              type="button"
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }

                              if (obj.Prompt === "chart_code") {
                                if (this.state.chart_code_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="chart_code"
                                          >
                                            Chart Code:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <InputMask
                                              mask={this.state.setup_chartCode.replace(
                                                /x/g,
                                                "*"
                                              )}
                                              maskChar={null}
                                              type="text"
                                              name="chart_code"
                                              value={this.state.chart_code}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                            />

                                            {/* <input
                                              name="chart_code"
                                              value={this.state.chart_code}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="text"
                                            /> */}
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors
                                                  .chart_code
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {this.state.formErrors.chart_code}
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onChartCodeModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }

                              if (obj.Prompt === "flag") {
                                if (this.state.flag_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="flag"
                                          >
                                            Tracking Field:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <select
                                              name="flag"
                                              value={this.state.flag}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                            >
                                              <option value="">Select</option>
                                              {this.state.trackingList.map(
                                                (obj, i) => {
                                                  return (
                                                    <option
                                                      value={obj.defaultValue}
                                                    >
                                                      {obj.Prompt}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>

                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors.flag
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {this.state.formErrors.flag}
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onFlagModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }
                              if (obj.Prompt === "payment_flag") {
                                if (this.state.payment_flag_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="payment_flag"
                                          >
                                            Payment Flag:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="payment_flag"
                                              value={this.state.payment_flag}
                                              onChange={(event) =>
                                                this.handleUserInput(event)
                                              }
                                              type="text"
                                            />
                                            <p
                                              className={
                                                "error validation " +
                                                (this.state.formErrors
                                                  .payment_flag
                                                  ? ""
                                                  : "displayNone")
                                              }
                                            >
                                              {
                                                this.state.formErrors
                                                  .payment_flag
                                              }
                                            </p>
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onPaymentFlagModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }

                              if (obj.Prompt === "calc_days") {
                                if (this.state.calc_days_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="calc_days"
                                          >
                                            Calc Days:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="calc_days"
                                              value={this.state.calc_days}
                                              type="text"
                                              readOnly={true}
                                            />
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1 pull-right">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onCalcDaysModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }
                              if (obj.Prompt === "calc_weeks") {
                                if (this.state.calc_weeks_hide) {
                                  return (
                                    <Draggable
                                      key={obj.Prompt}
                                      draggableId={obj.Prompt}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          className="form__display--props"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          <div
                                            className="col-xs-12 col-sm-4 col-md-4"
                                            id="calc_weeks"
                                          >
                                            Calc Weeks:
                                          </div>
                                          <div className="col-xs-10 col-sm-7 col-md-7">
                                            <input
                                              name="calc_weeks"
                                              value={this.state.calc_weeks}
                                              type="text"
                                              readOnly={true}
                                            />
                                          </div>
                                          <div className="col-xs-2 col-sm-1 col-md-1 pull-right">
                                            <button
                                              type="button"
                                              onClick={(event) =>
                                                this.onCalcWeeksModalOpen()
                                              }
                                              className="pen_custom_list_pop customfieldlist_OutlineNone "
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="1656.776 299 17.515 18.003"
                                              >
                                                <path
                                                  id="ic_create_24px"
                                                  className="cls-1"
                                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                                  transform="translate(1653.776 296.002)"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                } else {
                                  return "";
                                }
                              }
                            }
                          }
                        )}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="clear30"></div>
              <div className="col-sm-6">
                <div className="btn_cance_save">
                  <button
                    type="submit"
                    className="btn_save_pro"
                    data-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={this.onad_ModalClose}
                    className="btn_cancel_pro"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>

        <div id="myModal4" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal formtemplatedetail_OutlineNone"
                >
                  <img
                    className="formtemplatedetail_mt22"
                    src={cross}
                    width="18"
                    height="18"
                    alt=""
                  />
                </button>
                <h4 className="modal-title modal_customefield">Setting</h4>
              </div>
              <div className="modal-body label_modal_custom">
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    DB Field:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <input name="" type="text" />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Validate:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <input name="" type="text" />
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Hide:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <label className="check_box_pop">
                      <input type="checkbox" />
                      <span className="checkmark left0_check"></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Read Only:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <label className="check_box_pop">
                      <input type="checkbox" />
                      <span className="checkmark left0_check"></span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Required:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <label className="check_box_pop">
                      <input type="checkbox" />
                      <span className="checkmark left0_check"></span>
                    </label>
                  </div>
                </div>
                <div className="clear20"></div>
                <div className="col-sm-6">
                  <div className="btn_cance_save">
                    <button
                      type="button"
                      className="btn_save_pro"
                      data-dismiss="modal"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_cancel_pro"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="clear20"></div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.form_entry_fields_modalOpen}
          onHide={this.closeModal}
          className="modal modal2 fade"
          role="dialog"
        >
          <form onSubmit={(e) => this.form_entry_fields_submit_form(e)}>
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={(e) => this.closeModal(e)}
              >
                <img
                  className="formtemplatedetail_mt22"
                  src={cross}
                  width="18"
                  height="18"
                  alt=""
                />
              </button>
              <h4 className="modal-title modal_customefield">
                Form Entry Fields
              </h4>
            </div>
            <div className="modal-body label_modal_custom pdg_modal_csutom_leist modal_pop_list_scroll formtemplatedetail_modal">
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Group Heading:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8">
                  <input
                    name=""
                    type="text"
                    value={this.state.form_entry_fields_group_heading}
                    onChange={(e) => this.form_entry_fields_group_heading(e)}
                  />
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Description:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8">
                  <input
                    name=""
                    type="text"
                    value={this.state.form_entry_fields_description}
                    onChange={(e) => this.form_entry_fields_description(e)}
                  />
                  {this.state.form_entry_fields_description_error ? (
                    <p className="error validation ">This field is required </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Field Type:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8">
                  <select
                    name="form_entry_fields_field_type"
                    value={this.state.form_entry_fields_field_type}
                    onChange={(e) => this.form_entry_fields_field_type(e)}
                  >
                    <option value="">Select Type</option>
                    <option value="String">String</option>
                    <option value="Text">Text</option>
                    <option value="Numeric">Numeric</option>
                    <option value="Drop List">Drop List</option>
                    <option value="Date">Date</option>
                    <option value="File">File</option>
                  </select>
                  {this.state.form_entry_fields_field_type_error ? (
                    <p className="error validation ">This field is required </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.isFile ? (
                ""
              ) : (
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                    Default Value:
                  </div>
                  <div className="col-xs-10 col-sm-8 col-md-8">
                    {this.state.isString ? (
                      <React.Fragment>
                        <input
                          name=""
                          type="text"
                          value={this.state.form_entry_fields_default_value}
                          onChange={(e) =>
                            this.form_entry_fields_default_value(e)
                          }
                        />
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    {this.state.isNumber ? (
                      <React.Fragment>
                        <input
                          name=""
                          type="text"
                          value={this.state.form_entry_fields_default_value}
                          onChange={(e) =>
                            this.form_entry_fields_default_value(e)
                          }
                        />
                        {this.state
                          .numberform_entry_fields_default_value_error ? (
                          <p className="error validation ">
                            This field should be Numeric{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    {this.state.isDate ? (
                      <React.Fragment>
                        <DatePicker
                          id="showDateFrom"
                          dateFormat="DD/MM/YYYY"
                          selected={this.state.form_entry_startDate}
                          onChange={this.form_entry_handleChange.bind(this)}
                        />
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    {this.state.isDroplist ? (
                      <React.Fragment>
                        <div className="display_flex">
                          <select
                            value={this.state.form_entry_fields_default_value}
                            onChange={(e) =>
                              this.form_entry_fields_default_value(e)
                            }
                          >
                            <option value="">Select Option</option>
                            {this.state.datalist.map((datalst, key) => {
                              return (
                                <option value={datalst.value}>
                                  {datalst.option}
                                </option>
                              );
                            })}
                          </select>
                          <a href={null} className="btn_margin_left">
                            <button
                              type="button"
                              className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm customfields_btn_plus_cusotm"
                              data-toggle="modal"
                              data-target="#myModal"
                              onClick={this.datalist_openModal}
                            >
                              +
                            </button>
                          </a>
                        </div>
                        <div className="clearfix"></div>
                        {this.state.form_entry_fields_default_value_error ? (
                          <p className="error validation ">
                            This field is required{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Comments:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8">
                  <input
                    name=""
                    type="text"
                    value={this.state.form_entry_fields_comments}
                    onChange={(e) => this.form_entry_fields_comments(e)}
                  />
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Tag:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8">
                  <input
                    name=""
                    type="text"
                    value={this.state.form_entry_fields_tag}
                    onChange={(e) => this.form_entry_fields_tag(e)}
                  />
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  DBField:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8">
                  <input
                    name=""
                    type="text"
                    value={this.state.form_entry_fields_dbfield}
                    onChange={(e) => this.form_entry_fields_dbfield(e)}
                  />
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Company:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8 mrg_top_from8">
                  <div className="width_check_form_dtl_pop">
                    <label className="check_box_pop">
                      <input
                        type="checkbox"
                        checked={this.state.form_entry_fields_Company_internal}
                        value="1"
                        onChange={(e) =>
                          this.form_entry_fields_Company_internal(e)
                        }
                      />
                      <span className="checkmark"></span>
                      Internal:
                    </label>
                  </div>
                  <div className="width_check_form_dtl_pop2">
                    <label className="check_box_pop">
                      <input
                        type="checkbox"
                        checked={this.state.form_entry_fields_Company_hide}
                        value="1"
                        onChange={(e) => this.form_entry_fields_Company_hide(e)}
                      />
                      <span className="checkmark"></span>
                      Hide:
                    </label>
                  </div>
                  <div className="width_check_form_dtl_pop3">
                    <label className="check_box_pop">
                      <input
                        type="checkbox"
                        checked={this.state.form_entry_fields_Company_read_only}
                        value="1"
                        onChange={(e) =>
                          this.form_entry_fields_Company_read_only(e)
                        }
                      />
                      <span className="checkmark"></span>
                      Read Only:
                    </label>
                  </div>
                  <div className="width_check_form_dtl_pop4">
                    <label className="check_box_pop">
                      <input
                        type="checkbox"
                        checked={this.state.form_entry_fields_Company_required}
                        value="1"
                        onChange={(e) =>
                          this.form_entry_fields_Company_required(e)
                        }
                      />
                      <span className="checkmark"></span>
                      Required:
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  External:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8 mrg_top_from8">
                  <div className="width_check_form_dtl_pop5">
                    <label className="check_box_pop">
                      <input
                        type="checkbox"
                        checked={this.state.form_entry_fields_external_hide}
                        value="1"
                        onChange={(e) =>
                          this.form_entry_fields_external_hide(e)
                        }
                      />
                      <span className="checkmark"></span>
                      Hide:
                    </label>
                  </div>
                  <div className="width_check_form_dtl_pop6">
                    <label className="check_box_pop">
                      <input
                        type="checkbox"
                        checked={this.state.form_entry_fields_external_readonly}
                        value="1"
                        onChange={(e) =>
                          this.form_entry_fields_external_readonly(e)
                        }
                      />
                      <span className="checkmark"></span>
                      Read Only:
                    </label>
                  </div>
                  <div className="width_check_form_dtl_pop7">
                    <label className="check_box_pop">
                      <input
                        type="checkbox"
                        checked={this.state.form_entry_fields_external_required}
                        value="1"
                        onChange={(e) =>
                          this.form_entry_fields_external_required(e)
                        }
                      />
                      <span className="checkmark"></span>
                      Required:
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Field Length:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8">
                  <input
                    name=""
                    type="text"
                    value={this.state.form_entry_fields_field_length}
                    onChange={(e) => this.form_entry_fields_field_length(e)}
                  />
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                  Validation:
                </div>
                <div className="col-xs-10 col-sm-8 col-md-8">
                  <input
                    name=""
                    type="text"
                    value={this.state.form_entry_fields_validation}
                    onChange={(e) => this.form_entry_fields_validation(e)}
                  />
                </div>
              </div>
              <div className="clear30"></div>
              <div className="col-sm-6">
                <div className="btn_cance_save">
                  <button type="submit" className="btn_save_pro">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn_cancel_pro"
                    onClick={(e) => this.closeModal(e)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          show={this.state.modalOpen}
          onHide={(event) => this.onUnitModalClose()}
          id="myModal2"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onUnitModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Unit Settings</h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_dbfield"
                  value={this.state.unit_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_validate"
                  value={this.state.unit_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_length"
                  value={this.state.unit_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_script"
                  value={this.state.unit_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="unit_type"
                  value={this.state.unit_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_tag"
                  type="text"
                  value={this.state.unit_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="unit_hide"
                    value={this.state.unit_hide}
                    checked={this.state.unit_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="unit_readonly"
                    value={this.state.unit_readonly}
                    checked={this.state.unit_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="unit_required"
                    value={this.state.unit_required}
                    checked={this.state.unit_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="unitModal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onUnitModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.UnitDescModalOpen}
          onHide={(event) => this.onUnitDescModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onUnitDescModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Unit Desc Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_desc_dbfield"
                  value={this.state.unit_desc_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_desc_validate"
                  value={this.state.unit_desc_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_desc_length"
                  value={this.state.unit_desc_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_desc_script"
                  value={this.state.unit_desc_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="unit_desc_type"
                  value={this.state.unit_desc_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="unit_desc_tag"
                  type="text"
                  value={this.state.unit_desc_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="unit_desc_hide"
                    value={this.state.unit_desc_hide}
                    checked={this.state.unit_desc_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="unit_desc_readonly"
                    value={this.state.unit_desc_readonly}
                    checked={this.state.unit_desc_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="unit_desc_required"
                    value={this.state.unit_desc_required}
                    checked={this.state.unit_desc_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  id="unitDescModal"
                  onClick={(event) => this.onModal_Save(event)}
                  className="btn_save_pro"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onUnitDescModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.RateModalOpen}
          onHide={(event) => this.onRateModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onRateModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Rate Settings</h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="rate_dbfield"
                  value={this.state.rate_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="rate_validate"
                  value={this.state.rate_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="rate_length"
                  value={this.state.rate_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="rate_script"
                  value={this.state.rate_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="rate_type"
                  value={this.state.rate_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="rate_tag"
                  type="text"
                  value={this.state.rate_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="rate_hide"
                    value={this.state.rate_hide}
                    checked={this.state.rate_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="rate_readonly"
                    value={this.state.rate_readonly}
                    checked={this.state.rate_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="rate_required"
                    value={this.state.rate_required}
                    checked={this.state.rate_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  id="rate_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                  className="btn_save_pro"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onRateModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.FactorModalOpen}
          onHide={(event) => this.onFactorModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onFactorModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Factor Settings</h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="factor_dbfield"
                  value={this.state.factor_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="factor_validate"
                  value={this.state.factor_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="factor_length"
                  value={this.state.factor_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="factor_script"
                  value={this.state.factor_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="factor_type"
                  value={this.state.factor_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="factor_tag"
                  type="text"
                  value={this.state.factor_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="factor_hide"
                    value={this.state.factor_hide}
                    checked={this.state.factor_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="factor_readonly"
                    value={this.state.factor_readonly}
                    checked={this.state.factor_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="factor_required"
                    value={this.state.factor_required}
                    checked={this.state.factor_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="factor_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onFactorModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.AmountModalOpen}
          onHide={(event) => this.onAmountModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onAmountModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Amount Settings</h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="amount_dbfield"
                  value={this.state.amount_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="amount_validate"
                  value={this.state.amount_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="amount_length"
                  value={this.state.amount_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="amount_script"
                  value={this.state.amount_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="amount_type"
                  value={this.state.amount_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="amount_tag"
                  type="text"
                  value={this.state.amount_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="amount_hide"
                    value={this.state.amount_hide}
                    checked={this.state.amount_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="amount_readonly"
                    value={this.state.amount_readonly}
                    checked={this.state.amount_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="amount_required"
                    value={this.state.amount_required}
                    checked={this.state.amount_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="amount_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onAmountModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.CappedModalOpen}
          onHide={(event) => this.onCappedModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onCappedModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Capped Amount Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="capped_amount_dbfield"
                  value={this.state.capped_amount_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="capped_amount_validate"
                  value={this.state.capped_amount_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="capped_amount_length"
                  value={this.state.capped_amount_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="capped_amount_script"
                  value={this.state.capped_amount_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="capped_amount_type"
                  value={this.state.capped_amount_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="capped_amount_tag"
                  type="text"
                  value={this.state.capped_amount_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="capped_amount_hide"
                    value={this.state.capped_amount_hide}
                    checked={this.state.capped_amount_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="capped_amount_readonly"
                    value={this.state.capped_amount_readonly}
                    checked={this.state.capped_amount_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="capped_amount_required"
                    value={this.state.capped_amount_required}
                    checked={this.state.capped_amount_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="capped_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onCappedModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.DaysInWeekdModalOpen}
          onHide={(event) => this.onDaysInWeekModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onDaysInWeekModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Days In Week Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="days_in_week_dbfield"
                  value={this.state.days_in_week_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="days_in_week_validate"
                  value={this.state.days_in_week_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="days_in_week_length"
                  value={this.state.days_in_week_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="days_in_week_script"
                  value={this.state.days_in_week_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="days_in_week_type"
                  value={this.state.days_in_week_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="days_in_week_tag"
                  type="text"
                  value={this.state.days_in_week_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="days_in_week_hide"
                    value={this.state.days_in_week_hide}
                    checked={this.state.days_in_week_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="days_in_week_readonly"
                    value={this.state.days_in_week_readonly}
                    checked={this.state.days_in_week_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="days_in_week_required"
                    value={this.state.days_in_week_required}
                    checked={this.state.days_in_week_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="days_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onDaysInWeekModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.DateFromModalOpen}
          onHide={(event) => this.onDateFromModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onDateFromModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Days From Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="startDate_dbfield"
                  value={this.state.startDate_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="startDate_validate"
                  value={this.state.startDate_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="startDate_length"
                  value={this.state.startDate_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="startDate_script"
                  value={this.state.startDate_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="startDate_type"
                  value={this.state.startDate_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="startDate_tag"
                  type="text"
                  value={this.state.startDate_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="startDate_hide"
                    value={this.state.startDate_hide}
                    checked={this.state.startDate_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="startDate_readonly"
                    value={this.state.startDate_readonly}
                    checked={this.state.startDate_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="startDate_required"
                    value={this.state.startDate_required}
                    checked={this.state.startDate_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  id="startDate_Modal"
                  className="btn_save_pro"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onDateFromModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.DateToModalOpen}
          onHide={(event) => this.onDateToModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onDateToModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Days To Settings</h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="endDate_dbfield"
                  value={this.state.endDate_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="endDate_validate"
                  value={this.state.endDate_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="endDate_length"
                  value={this.state.endDate_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="endDate_script"
                  value={this.state.endDate_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="endDate_type"
                  value={this.state.endDate_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="endDate_tag"
                  type="text"
                  value={this.state.endDate_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="endDate_hide"
                    value={this.state.endDate_hide}
                    checked={this.state.endDate_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="endDate_readonly"
                    value={this.state.endDate_readonly}
                    checked={this.state.endDate_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="endDate_required"
                    value={this.state.endDate_required}
                    checked={this.state.endDate_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="endDate_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onDateToModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.ChartModalOpen}
          onHide={(event) => this.onChartModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onChartModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Chart Sort Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_sort_dbfield"
                  value={this.state.chart_sort_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_sort_validate"
                  value={this.state.chart_sort_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_sort_length"
                  value={this.state.chart_sort_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_sort_script"
                  value={this.state.chart_sort_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            {/* 
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="chart_sort_type"
                  value={this.state.chart_sort_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
         */}
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_sort_tag"
                  type="text"
                  value={this.state.chart_sort_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="chart_sort_hide"
                    value={this.state.chart_sort_hide}
                    checked={this.state.chart_sort_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="chart_sort_readonly"
                    value={this.state.chart_sort_readonly}
                    checked={this.state.chart_sort_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="chart_sort_required"
                    value={this.state.chart_sort_required}
                    checked={this.state.chart_sort_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="chartS_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onChartModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.ChartCodeModalOpen}
          onHide={(event) => this.onChartCodeModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onChartCodeModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Chart Code Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_code_dbfield"
                  value={this.state.chart_code_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_code_validate"
                  value={this.state.chart_code_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_code_length"
                  value={this.state.chart_code_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_code_script"
                  value={this.state.chart_code_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            {/*             
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="chart_code_type"
                  value={this.state.chart_code_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
       */}
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="chart_code_tag"
                  type="text"
                  value={this.state.chart_code_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="chart_code_hide"
                    value={this.state.chart_code_hide}
                    checked={this.state.chart_code_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="chart_code_readonly"
                    value={this.state.chart_code_readonly}
                    checked={this.state.chart_code_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="chart_code_required"
                    value={this.state.chart_code_required}
                    checked={this.state.chart_code_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="chartC_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onChartCodeModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.FlagModalOpen}
          onHide={(event) => this.onFlagModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onFlagModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Flag Settings</h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="flag_dbfield"
                  value={this.state.flag_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="flag_validate"
                  value={this.state.flag_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="flag_length"
                  value={this.state.flag_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="flag_script"
                  value={this.state.flag_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="flag_type"
                  value={this.state.flag_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="flag_tag"
                  type="text"
                  value={this.state.flag_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="flag_hide"
                    value={this.state.flag_hide}
                    checked={this.state.flag_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="flag_readonly"
                    value={this.state.flag_readonly}
                    checked={this.state.flag_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="flag_required"
                    value={this.state.flag_required}
                    checked={this.state.flag_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="flag_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onFlagModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.PaymentFlagModalOpen}
          onHide={(event) => this.onPaymentFlagModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onPaymentFlagModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Payment Flag Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="payment_flag_dbfield"
                  value={this.state.payment_flag_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="payment_flag_validate"
                  value={this.state.payment_flag_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="payment_flag_length"
                  value={this.state.payment_flag_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="payment_flag_script"
                  value={this.state.payment_flag_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="payment_flag_type"
                  value={this.state.payment_flag_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="payment_flag_tag"
                  type="text"
                  value={this.state.payment_flag_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="payment_flag_hide"
                    value={this.state.payment_flag_hide}
                    checked={this.state.payment_flag_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="payment_flag_readonly"
                    value={this.state.payment_flag_readonly}
                    checked={this.state.payment_flag_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="payment_flag_required"
                    value={this.state.payment_flag_required}
                    checked={this.state.payment_flag_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="payment_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onPaymentFlagModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>

        <Modal
          show={this.state.CalcDaysModalOpen}
          onHide={(event) => this.onCalcDaysModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onCalcDaysModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Calc Days Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_days_dbfield"
                  value={this.state.calc_days_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_days_validate"
                  value={this.state.calc_days_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_days_length"
                  value={this.state.calc_days_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_days_script"
                  value={this.state.calc_days_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="calc_days_type"
                  value={this.state.calc_days_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_days_tag"
                  type="text"
                  value={this.state.calc_days_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="calc_days_hide"
                    value={this.state.calc_days_hide}
                    checked={this.state.calc_days_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="calc_days_readonly"
                    value={this.state.calc_days_readonly}
                    checked={this.state.calc_days_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="calc_days_required"
                    value={this.state.calc_days_required}
                    checked={this.state.calc_days_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="calcD_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onCalcDaysModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>
        <Modal
          show={this.state.CalcWeeksModalOpen}
          onHide={(event) => this.onCalcWeeksModalClose()}
          id="unitdesc"
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={(event) => this.onCalcWeeksModalClose()}
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Calc Weeks Settings
            </h4>
          </div>
          <div className="modal-body label_modal_custom pdg_modal_csutom_leist customfieldslist_modalBodyCustomStyle modal_pop_list_scroll ">
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                DB Field:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_weeks_dbfield"
                  value={this.state.calc_weeks_dbfield}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Validate:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_weeks_validate"
                  value={this.state.calc_weeks_validate}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Length:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_weeks_length"
                  value={this.state.calc_weeks_length}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Script:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_weeks_script"
                  value={this.state.calc_weeks_script}
                  onChange={(event) => this.handleUserInput(event)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Type:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <select
                  name="calc_weeks_type"
                  value={this.state.calc_weeks_type}
                  onChange={(event) => this.handleUserInput(event)}
                >
                  <option value="">Select</option>
                  <option value="String">String</option>
                  <option value="Text">Text</option>
                  <option value="Date">Date</option>
                  <option value="Numeric">Numeric</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Tag:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <input
                  name="calc_weeks_tag"
                  type="text"
                  value={this.state.calc_weeks_tag}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Hide:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="calc_weeks_hide"
                    value={this.state.calc_weeks_hide}
                    checked={this.state.calc_weeks_hide}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Read Only:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="calc_weeks_readonly"
                    value={this.state.calc_weeks_readonly}
                    checked={this.state.calc_weeks_readonly}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-sm-12 p0 mrg_bot_custom10">
              <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop">
                Required:
              </div>
              <div className="col-xs-12 col-sm-8 col-md-8">
                <label className="check_box_pop">
                  <input
                    type="checkbox"
                    name="calc_weeks_required"
                    value={this.state.calc_weeks_required}
                    checked={this.state.calc_weeks_required}
                    onChange={(event) => this.handleUserInputCheckbox(event)}
                  />
                  <span className="checkmark report_checkmark"></span>
                </label>
              </div>
            </div>
            <div className="clear30"></div>
            <div className="col-sm-6">
              <div className="btn_cance_save">
                <button
                  type="button"
                  className="btn_save_pro"
                  id="calcW_Modal"
                  onClick={(event) => this.onModal_Save(event)}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={(event) => this.onCalcWeeksModalClose()}
                  className="btn_cancel_pro"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>

        <Modal
          show={this.state.add_datalist}
          onHide={this.datalist_closeModal}
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close customfields_OutlineNone"
              data-dismiss="modal"
              onClick={this.datalist_closeModal}
            >
              <img
                className="customfields_mt20"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Custom Fields</h4>
          </div>

          <div className="modal-body label_modal_custom">
            <div className="overflow_scroll_sm">
              <table className="table table-bordered timecard_table custome_field_table">
                <thead>
                  <tr>
                    <th width="11%" align="center">
                      Option
                    </th>
                    <th width="11%" align="center">
                      Value
                    </th>

                    <th width="13%" align="center">
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datalist.map((datalst, key) => {
                    return (
                      <tr key={key}>
                        <td width="11%" align="center">
                          {datalst.option}
                        </td>
                        <td align="center">{datalst.value}</td>

                        <td align="center">
                          <div className="col-sm-4  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
                            <a
                              href={null}
                              className="cursorPointer"
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                              onClick={(event) =>
                                this.delete_datalist(event, key)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="1700 296 15 19.286"
                              >
                                <path
                                  id="ic_delete_24px"
                                  className="cls-1"
                                  d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                  transform="translate(1695 293)"
                                />
                              </svg>
                            </a>
                          </div>
                          <div className="col-sm-4  col-xs-6 p0 text-center timecard_edit6 pull-right">
                            <a
                              href={null}
                              className="cursorPointer"
                              onClick={(event) =>
                                this.edit_datalist(event, key)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="1656.776 299 17.515 18.003"
                              >
                                <path
                                  id="ic_create_24px"
                                  className="cls-1"
                                  d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                  transform="translate(1653.776 296.002)"
                                />
                              </svg>
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {this.state.datalist.length ? "" : "Record not found"}
            </div>

            <div className="clear20"></div>
            <h2>Add Option/Value</h2>
            <div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-3 label_csutom_pop">
                  Option:
                </div>
                <div className="col-sm-9 col-md-9">
                  <input
                    name=""
                    type="text"
                    value={this.state.datalist_option}
                    onChange={(e) => this.option_change(e)}
                  />
                </div>
                {this.state.option_error ? (
                  <p className="error validation ">This field is required </p>
                ) : (
                  ""
                )}
              </div>

              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-3 label_csutom_pop">Value:</div>
                <div className="col-sm-9 col-md-9">
                  <input
                    name=""
                    type="text"
                    value={this.state.datalist_value}
                    onChange={(e) => this.value_change(e)}
                  />
                </div>
                {this.state.value_error ? (
                  <p className="error validation ">This field is required </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-6">
                <div className="btn_cance_save">
                  <button
                    type="submit"
                    className="btn_save_pro"
                    onClick={(e) => this.add_datalist(e)}
                  >
                    Save
                  </button>

                  <button
                    type="button"
                    className="btn_cancel_pro"
                    data-dismiss="modal"
                    onClick={this.datalist_closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="clear20"></div>
          </div>
        </Modal>

        <div className="clear20"></div>
      </div>
    );
  }
}
export default FormTemplateDetail;
