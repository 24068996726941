import React, { Component } from "react";
import ListItem from "./Components/ListItem";
import Header from "../Common/Header/Header";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import { TimePicker } from "antd";
import moment from "moment";

// - -   IMPORT STYLING   - -//
// - - - - - - - - - - - - - //
import "./CompanyDetail.css";
import "antd/dist/antd.css";
// - -  DEFINE CONSTANTS  - -//
// - - - - - - - - - - - - - //
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from "jquery";
window.$ = $;
global.jQuery = $;

var Modal = require("react-bootstrap-modal");
require("react-bootstrap-modal/lib/css/rbm-patch.css");

//============================================//
// COMPANYDETAIL AS A PARENT COMPONENT DEFINE //
// - - - - - -  IN THIS SCOPE - - - - - - - - //
//--------------------------------------------//
class CompanyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //isActive: '',
      //isActive2:'',
      customFieldList: [],
      Prompt: "",
      Value: "",
      isEditModal: false,
      customFieldIndex: null,
      modalOpen: false,
      promptValid: false,
      valueValid: false,
      companyNameValid: false,
      companyIdValid: false,
      companyEmailValid: false,
      contractDistEmailValid: false,
      addressValid: false,
      townValid: false,
      stateValid: false,
      postalCodeValid: false,
      phoneValid: false,
      Start: null,
      mealBreak: null,
      End: null,
      HideTravelTo: false,
      HideTravelFrom: false,
      formErrors: {
        companyNameValid: "",
        companyIdValid: "",
        companyEmailValid: "",
        contractDistEmailValid: "",
        addressValid: "",
        townValid: "",
        stateValid: "",
        postalCodeValid: "",
        phoneValid: "",
      },
      formErrors2: {
        Prompt: "",
        Value: "",
      },
      formValid: "",
      formValid2: "",
    };
  }

  onModalClose() {
    this.setState({
      modalOpen: false,
      formErrors2: {
        Value: "",
        Prompt: "",
      },
    });
  }
  backToCompanies() {
    this.props.history.push({
      pathname: "/dashboard",
      state: { showcompany: true },
    });
  }
  onModalOpen() {
    this.setState({ modalOpen: true });
  }
  getAllFields() {
    return API.get("custom-fields", "/custom-fields");
  }

  componentDidMount() {
    $(".modal-backdrop").hide();
    $(".modal-open").css("overflow", "scroll");

    // const getAllFields = this.getAllFields();
    // getAllFields.then(
    //     data =>
    //     this.setState({
    //         customFieldList: data
    //     })
    // ).catch(
    //     err => {
    //         console.error(err, err.stack);
    //     });
  }
  componentWillUnmount() {
    $(".modal-open").css("overflow", "visible");
  }

  createCompany(
    companyName,
    companyId,
    companyEmail,
    contractDistEmail,
    address,
    town,
    state,
    postalCode,
    phone,
    fax,
    customFieldList
  ) {
    return API.post("company", "/company", {
      body: {
        companyName: companyName,
        companyId: companyId,
        companyEmail: companyEmail,
        contractDistEmail: contractDistEmail,
        address: address,
        town: town,
        state: state,
        postalCode: postalCode,
        phone: phone,
        fax: fax,
        CustomFields: customFieldList,
      },
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let companyIdValid = this.state.companyIdValid;
    let companyNameValid = this.state.companyNameValid;
    let companyEmailValid = this.state.companyEmailValid;
    let contractDistEmailValid = this.state.contractDistEmailValid;
    let addressValid = this.state.addressValid;
    let townValid = this.state.townValid;
    let stateValid = this.state.stateValid;
    let postalCodeValid = this.state.postalCodeValid;
    let phoneValid = this.state.phoneValid;
    switch (fieldName) {
      case "companyName":
        companyNameValid = value.length > 0;
        fieldValidationErrors.companyNameValid = companyNameValid
          ? ""
          : "This field is required";
        break;
      case "companyId":
        companyIdValid = value.length > 0;
        fieldValidationErrors.companyIdValid = companyIdValid
          ? ""
          : "This field is required";
        break;
      case "companyEmail":
        companyEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.companyEmailValid = companyEmailValid
          ? ""
          : "Email should be username@domain.com";
        break;
      case "contractDistEmail":
        contractDistEmailValid = value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        fieldValidationErrors.contractDistEmailValid = contractDistEmailValid
          ? ""
          : "Email should be username@domain.com";
        break;
      case "address":
        addressValid = value.length > 0;
        fieldValidationErrors.addressValid = addressValid
          ? ""
          : "This field is required";
        break;
      case "town":
        townValid = value.length > 0;
        fieldValidationErrors.townValid = townValid
          ? ""
          : "This field is required";
        break;
      case "state":
        stateValid = value.length > 0;
        fieldValidationErrors.stateValid = stateValid
          ? ""
          : "This field is required";
        break;
      case "postalCode":
        postalCodeValid = value.length > 0;
        fieldValidationErrors.postalCodeValid = postalCodeValid
          ? ""
          : "This field is required";
        break;
      case "phone":
        phoneValid = value.match(/^\+?\d+$/);
        fieldValidationErrors.phoneValid = phoneValid
          ? ""
          : "Format should be (+)3234567891";
        break;
      // case 'fax':
      //     faxValid = value.length > 0;
      //     fieldValidationErrors.faxValid = faxValid ? '' : "This field is required";
      //     break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        companyNameValid: companyNameValid,
        companyIdValid: companyIdValid,
        companyEmailValid: companyEmailValid,
        contractDistEmailValid: contractDistEmailValid,
        addressValid: addressValid,
        townValid: townValid,
        stateValid: stateValid,
        postalCodeValid: postalCodeValid,
        phoneValid: phoneValid,
      },
      this.validateForm
    );
  }

  validateField2(fieldName, value) {
    let modalFieldValidationErrors = this.state.formErrors2;
    let promptValid = this.state.Prompt;

    let valueValid = this.state.Value;
    switch (fieldName) {
      case "Prompt":
        promptValid = value.length > 0;
        modalFieldValidationErrors.Prompt = promptValid
          ? ""
          : "This field is required";
        break;
      case "Value":
        valueValid = value.length > 0;
        modalFieldValidationErrors.Value = valueValid
          ? ""
          : "This field is required";
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: modalFieldValidationErrors,
        promptValid: promptValid,
        valueValid: valueValid,
      },
      this.validateForm2
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.companyNameValid &&
        this.state.companyIdValid &&
        this.state.companyEmailValid &&
        this.state.contractDistEmailValid &&
        this.state.townValid &&
        this.state.stateValid &&
        this.state.postalCodeValid &&
        this.state.phoneValid,
      //isActive: this.state.companyNameValid && this.state.companyIdValid && this.state.companyEmailValid && this.state.contractDistEmailValid && this.state.townValid && this.state.stateValid && this.state.postalCodeValid && this.state.phoneValid && this.state.faxValid
    });
  }

  validateForm2() {
    this.setState({
      formValid2: this.state.promptValid && this.state.valueValid,
      //isActive2: this.state.promptValid && this.state.valueValid
    });
  }

  handleUserInput(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleModalInput(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField2(name, value);
      }
    );
  }
  addCustomFieldsInToArray(event) {
    event.preventDefault();
    let prompt = this.state.Prompt;
    let value = this.state.Value;
    let promptlength = prompt.length;
    let valuelength = value.length;
    let formErrors2 = Object.assign({}, this.state.formErrors2);
    if (promptlength < 1) {
      formErrors2.Prompt = "This field is required";
    }
    if (valuelength < 1) {
      formErrors2.Value = "This field is required";
    }
    if (promptlength < 1 || valuelength < 1) {
      this.setState({ formErrors2 });
      return false;
    }
    var dataObj = {
      prompt: this.state.Prompt,
      value: this.state.Value,
    };
    if (promptlength > 0 && valuelength > 0) {
      var isEditModal = this.state.isEditModal;
      if (isEditModal) {
        var customFieldIndex = this.state.customFieldIndex;
        var customFieldList = this.state.customFieldList;
        customFieldList[customFieldIndex] = dataObj;

        this.setState({
          customFieldList: customFieldList,
          customFieldIndex: null,
          isEditModal: false,
          Prompt: "",
          Value: "",
          // "isActive2":"",
        });
      } else {
        customFieldList = this.state.customFieldList;
        customFieldList.push(dataObj);
        this.setState({
          customFieldList: customFieldList,
          Prompt: "",
          Value: "",
          //"isActive2":"",
        });
      }

      this.setState({
        promptValid: false,
        valueValid: false,
      });

      this.onModalClose();
    }
  }
  removeCustomFieldFromArray(event, id) {
    event.preventDefault();
    var customFieldList = this.state.customFieldList;
    customFieldList.splice(id, 1);
    this.setState({
      customFieldList: customFieldList,
    });
  }
  editCustomFieldFromArray(event, id) {
    this.setState({ customFieldIndex: id });
    event.preventDefault();
    this.setState({
      isEditModal: true,
      //"isActive2": true
    });
    var customFieldList = this.state.customFieldList;
    customFieldList = customFieldList[id];
    this.setState({
      Prompt: customFieldList.prompt,
      Value: customFieldList.value,
    });
    this.onModalOpen();
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    let companyName = this.state.companyNameValid;
    let companyId = this.state.companyIdValid;
    let companyEmail = this.state.companyEmailValid;
    let contractDistEmail = this.state.contractDistEmailValid;
    let address = this.state.addressValid;
    let town = this.state.townValid;
    let state = this.state.stateValid;
    let postalCode = this.state.postalCodeValid;
    let phone = this.state.phoneValid;
    let formErrors = Object.assign({}, this.state.formErrors);
    if (companyName === false || companyName === null) {
      formErrors.companyNameValid = "This field is required";
    }
    if (companyId === false || companyId === null) {
      formErrors.companyIdValid = "This field is required";
    }
    if (companyEmail === false || companyEmail === null) {
      formErrors.companyEmailValid = "Email should be username@domain.com";
    }
    if (contractDistEmail === false || contractDistEmail === null) {
      formErrors.contractDistEmailValid = "Email should be username@domain.com";
    }
    if (address === false || address === null) {
      formErrors.addressValid = "This field is required";
    }
    if (town === false || town === null) {
      formErrors.townValid = "This field is required";
    }
    if (state === false || state === null) {
      formErrors.stateValid = "This field is required";
    }
    if (postalCode === false || postalCode === null) {
      formErrors.postalCodeValid = "This field is required";
    }
    if (phone === false || phone === null) {
      formErrors.phoneValid = "Format should be (+)3234567891";
    }

    this.setState({ formErrors });
    if (this.state.formValid) {
      this.setState({
        isLoading: true,
        formValid: false,
        companyNameValid: false,
        companyIdValid: false,
        companyEmailValid: false,
        contractDistEmailValid: false,
        addressValid: false,
        townValid: false,
        stateValid: false,
        postalCodeValid: false,
        phoneValid: false,
      });
      let company_data = {
        companyName: this.state.companyName,
        companyId: this.state.companyId,
        companyEmail: this.state.companyEmail,
        contractDistEmail: this.state.contractDistEmail,
        address: this.state.address,
        town: this.state.town,
        state: this.state.state,
        postalCode: this.state.postalCode,
        phone: this.state.phone,
        fax: this.state.fax,
        Start: this.state.Start,
        mealBreak: this.state.mealBreak,
        End: this.state.End,
        HideTravelTo: this.state.HideTravelTo,
        HideTravelFrom: this.state.HideTravelFrom,
        CustomFields: this.state.customFieldList,
      };
      console.log("company_data=>", company_data);
      try {
        var response = await this.props.postRequest(
          "/companies/create-company",
          company_data
        );
        if (response.status) {
          toast.success("Success! Record has added successfully.", {
            autoClose: 5000,
          });
          this.setState({
            companyName: "",
            companyId: "",
            companyEmail: "",
            contractDistEmail: "",
            address: "",
            town: "",
            state: "",
            postalCode: "",
            phone: "",
            fax: "",
            Start: null,
            mealBreak: null,
            End: null,
            HideTravelTo: false,
            HideTravelFrom: false,
            //isActive: false,
            customFieldList: [],
          });
          this.props.history.push({
            pathname: "/dashboard",
            state: { modalOpen: true },
          });
        } else {
          console.log("/companies/create-company error=>", response);
        }
      } catch (err) {
        console.log("/companies/create-company error=>", err);
        toast.error(err.message, { autoClose: 5000 });
      }
    }
  };
  handleInputCheckboxTravel(e) {
    this.setState({
      [e.target.name]: e.target.checked,
    });

    if (e.target.checked) {
      $("#id" + e.target.name).prop("checked", true);
    } else {
      $("#" + e.target.name).prop("checked", false);
    }
  }
 

  handelStartTime = (time, timeString) => {
    this.setState(
      {
        Start: timeString,
      },
      () => {
        console.log("Time", this.state.Start);
      }
    );
  };
  handelmealBreakTime = (time, timeString) => {
    this.setState(
      {
        mealBreak: timeString,
      },
      () => {
        console.log("Time", this.state.mealBreak);
      }
    );
  };
  handelEndTime = (time, timeString) => {
    this.setState(
      {
        End: timeString,
      },
      () => {
        console.log("Time", this.state.End);
      }
    );
  };

  render() {
    const format = "HH:mm";
    return (
      <div className="CompanyDetail">
        <Header
          pageHeading=""
          hideHomeButton="0"
          props={this.props}
          isLoading={this.state.isLoading}
        />
        <div className="col-sm-12 p0 top_mrg_general">
          <div className="col-sm-9 col-sm-offset-2 general_setting_hd">
            <h2>Production Company Details</h2>
            <div className="col-sm-12 p0 company_setting mrg_bot_smc displayNone">
              <div className="col-xs-4 col-sm-3 col-md-3 col-lg-3 ">
                Coy Guid:
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6"> Lorem Ipsume </div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Company Name:
                </div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="companyName"
                    value={this.state.companyName}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.companyNameValid
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.formErrors.companyNameValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Company ID:
                </div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="companyId"
                    value={this.state.companyId}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.companyIdValid
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.formErrors.companyIdValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Company Email:
                </div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="companyEmail"
                    value={this.state.companyEmail}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.companyEmailValid
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.formErrors.companyEmailValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Contract Dist Email:
                </div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="contractDistEmail"
                    value={this.state.contractDistEmail}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.contractDistEmailValid
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.formErrors.contractDistEmailValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">Address:</div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="address"
                    value={this.state.address}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.addressValid ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.addressValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Suburb/Town:
                </div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="town"
                    value={this.state.town}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.townValid ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.townValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  State/Province:
                </div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="state"
                    value={this.state.state}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.stateValid ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.stateValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Postcode/Zipcode:
                </div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="postalCode"
                    value={this.state.postalCode}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.postalCodeValid
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.formErrors.postalCodeValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">Phone:</div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="phone"
                    value={this.state.phone}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.phoneValid ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.phoneValid}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">Fax:</div>
                <div className="col-sm-6 col-md-5">
                  <input
                    type="text"
                    name="fax"
                    value={this.state.fax}
                    onChange={(event) => this.handleUserInput(event)}
                  />
                </div>
              </div>

              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">Start:</div>
                <div className="col-sm-6 col-md-5">
                  <TimePicker
                    defaultValue={this.state.Start? moment(this.state.Start, format) : null}
                    format={format}
                    size="large"
                    id="Start"
                    name="Start"
                    onChange={this.handelStartTime}
                  />
                </div>
              </div>

              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Meal Break Period:
                </div>
                <div className="col-sm-6 col-md-5">
                  <TimePicker
                    value={this.state.mealBreak? moment(this.state.mealBreak, format) : null}
                    format={format}
                    size="large"
                    onChange={this.handelmealBreakTime}
                  />
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">End:</div>
                <div className="col-sm-6 col-md-5">
                  <TimePicker
                    value={this.state.End? moment(this.state.End, format) : null}
                    format={format}
                    size="large"
                    onChange={this.handelEndTime}
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 p0 mrg_bot_custom10">
                <div
                  className="col-xs-12 col-sm-3 col-md-4 label_csutom_pop"
                  style={{
                    fontSize: "18px",
                    color: "#656565",
                    marginLeft: "-4px",
                  }}
                >
                  Hide Travel To:
                </div>
                <div className="col-xs-12 col-sm-9 col-md-8">
                  <label className="check_box_pop">
                    <input
                      name="HideTravelTo"
                      type="checkbox"
                      id="HideTravelTo"
                      checked={this.state.HideTravelTo}
                      onChange={(event) =>
                        this.handleInputCheckboxTravel(event)
                      }
                    />
                    <span className="checkmark left0_check"></span>
                  </label>
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div
                  className="col-xs-12 col-sm-3 col-md-4 label_csutom_pop"
                  style={{
                    fontSize: "18px",
                    color: "#656565",
                    marginLeft: "-4px",
                  }}
                >
                  Hide Travel From:
                </div>
                <div className="col-sm-9 col-md-8">
                  <label className="check_box_pop">
                    <input
                      name="HideTravelFrom"
                      id="HideTravelFrom"
                      type="checkbox"
                      checked={this.state.HideTravelFrom}
                      onChange={(event) =>
                        this.handleInputCheckboxTravel(event)
                      }
                    />
                    <span className="checkmark left0_check"></span>
                  </label>
                </div>
              </div>

              <div className="clear20"></div>
              <h2>Custom Fields</h2>
              <div className="clearfix"></div>
              <div className="col-sm-11 table_formpck_pop_1 p0">
                <a href={null}>
                  <button
                    type="button"
                    className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm company_detail_btn_plus_cusotm"
                    onClick={(event) => this.onModalOpen()}
                    data-toggle="modal"
                  >
                    +
                  </button>
                </a>
                <div className="clear5"></div>
                <div className="scroll_form_pcktable company_detail_overflow_inherit">
                  <table
                    width="100%"
                    border="0"
                    cellSpacing="2"
                    cellPadding="2"
                  >
                    <thead>
                      <tr>
                        <th width="20%" align="left" valign="middle">
                          Prompt
                        </th>
                        <th width="60%" align="left" valign="middle">
                          Value
                        </th>
                        <th width="20%" align="center">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <ListItem
                      customFields={this.state.customFieldList}
                      removeField={this.removeCustomFieldFromArray.bind(this)}
                      editField={this.editCustomFieldFromArray.bind(this)}
                    />
                  </table>
                </div>
              </div>
              <div className="clear40"></div>
              <div className="col-sm-6 p0">
                <div className="btn_cance_save">
                  <button
                    href={null}
                    type="submit"
                    className="btn_save_pro"
                    data-dismiss="modal"
                  >
                    Save
                  </button>

                  <button
                    type="button"
                    onClick={(event) => this.backToCompanies()}
                    className="btn_cancel_pro"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="clear40"></div>
          <Modal
            show={this.state.modalOpen}
            onHide={this.closeModal}
            className="modal modal2 fade"
            role="dialog"
          >
            <div className="modal-header">
              <button
                type="button"
                className="close OutlineNone"
                onClick={(event) => this.onModalClose()}
              >
                <img
                  className="mt22"
                  src={ic_clear_24px}
                  width="18"
                  height="18"
                  alt=""
                />
              </button>
              <h4 className="modal-title modal_customefield">Custom Fields</h4>
            </div>
            <form onSubmit={(event) => this.addCustomFieldsInToArray(event)}>
              <div className="modal-body label_modal_custom overflow_inherti_custom">
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Prompt:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <input
                      type="text"
                      value={this.state.Prompt}
                      name="Prompt"
                      onChange={(event) => this.handleModalInput(event)}
                    />
                    <p
                      className={
                        "error validation " +
                        (this.state.formErrors2.Prompt ? "" : "displayNone")
                      }
                    >
                      {this.state.formErrors2.Prompt}
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 p0 mrg_bot_custom10">
                  <div className="col-sm-3 col-md-3 label_csutom_pop">
                    Value:
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <input
                      type="text"
                      value={this.state.Value}
                      name="Value"
                      onChange={(event) => this.handleModalInput(event)}
                    />
                    <p
                      className={
                        "error validation " +
                        (this.state.formErrors2.Value ? "" : "displayNone")
                      }
                    >
                      {this.state.formErrors2.Value}
                    </p>
                  </div>
                </div>
                <div className="clear20"></div>
                <div className="col-sm-6">
                  <div className="btn_cance_save">
                    <button type="submit" className="btn_save_pro">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_cancel_pro"
                      onClick={(event) => this.onModalClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="clear20"></div>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    );
  }
}
export default CompanyDetail;
