import React, { Component }
    from "react";
import "./EmailTemplates.css";
import { API } from "aws-amplify";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";
import Header from "../Common/Header/Header";
import { LinkContainer } from "react-router-bootstrap";
import { toast } from 'react-toastify';
import { Storage } from 'aws-amplify';

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
var Modal = require('react-bootstrap-modal');
require('react-bootstrap-modal/lib/css/rbm-patch.css');


export default class EmailTemplates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            body : "",
            isLoading: false,
            placeholderName: "",
            placeholderDBvalue: "",
            placeholderLink: false,
            placeholderURL: "",
            placeholderText: "",
            //placeholder Form valid
            formValid: false,
            //form errors
            formErrors: {
                placeholderName: "",
                placeholderDBvalue: "",
                placeholderURL: "",
                placeholderText: "",
            },
            //placeholder edit index
            editPlaceholderIndex: "",
            //for validation
            placeholderNameValid: false,
            placeholderDBvalueValid: false,
            placeholderURLValid: false,
            placeholderTextValid: false,
            editPlaceholderList: [],
            emailTemplateList: [],
            selectedtemplateType: "", // which will be shown side of dropdown
            singleTemplate: {},
            buttonDisable: true,
            disabledClass: "disableButton",
            selectedDropdown: "",
            placeholderList: [],
            //all popup modals
            modalPlaceholderList: false,
            modalOpenPlaceHolder: false,
            modalOpenEditPlaceHolder: false,
            selectedtemplateID: '',
            FileAttachments_urls: [],
            attachmentPath: ""
        }
        localStorage.removeItem("EditableEmailTemplateGuid");
    }


    componentDidMount = async () => {

        if(this.props.location.flagstatus === true){
            toast.success("Success! Record has added successfully.", {
                autoClose: 5000
            })
        }
        else if(this.props.location.flagID === true){
          toast.success("Success! Record has updated successfully.", {
              autoClose: 5000
          })
        }


        this.getTemplates();
        this.getPlaceholders();
        
        $(".modal").css('display', 'none');
    }

    delPlaceholder(id) {
        return this.props.postRequest("/email-placeholders/delete-placeholder", {Guid: id});
    }

    delPlaceholderClick(e, id) {
        e.preventDefault();
        var result = window.confirm('Are you sure you want to delete?');
        if (result) {
            this.setState({
                isLoading: true,
                modalPlaceholderList: false
            })
            let response = this.delPlaceholder(id);
            response.then(data => {
                if (data.status) {
                    toast.success("Success! Record has deleted successfully.", {
                        autoClose: 5000
                    });
                    this.getPlaceholders();
                    this.setState({
                        isLoading: false,
                        modalPlaceholderList: true
                    })
                }else{
                    toast.error("Something went wrong, please try later.", {autoClose: 5000});
                    this.setState({
                        isLoading: false,
                        modalPlaceholderList: true
                    });
                }
            }).catch(err => {
                toast.error("Something went wrong, please try later.", {autoClose: 5000});
                this.setState({
                    isLoading: false,
                    modalPlaceholderList: true
                });
            });
        }
    }

    getAllTemplates() {
        return this.props.postRequest("/email-templates/get-email-templates", {Type: "All"});
    }

    getTemplates = async () => {
        let response = await this.getAllTemplates();
        if (response.status) {
            this.setState({
                emailTemplateList: response.result,
                selectedDropdown: 0,
                selectedtemplateType: "",
                isLoading: false,
                buttonDisable: true,
                disabledClass: "disableButton"
            })
        } else {
            this.setState({
                buttonDisable: true,
                disabledClass: "disableButton"
            })
        }
    }



    getAllPlaceholders() {
        return this.props.postRequest("/email-placeholders/get-placeholders-list", {});
    }

    getPlaceholders() {
        this.getAllPlaceholders().then(data => {
            if (data.status) {
                this.setState({
                    placeholderList: data.result,
                })
            }
        }).catch(
            err => {
                console.error(err, err.stack);
            });
    }

    deleteTemplate() {
        return this.props.postRequest("/email-templates/delete-template",{ Guid: this.state.selectedtemplateID});
    }

    btnClickDeleteTemplate() {
        var result = window.confirm('Are you sure you want to delete?');
        if (result) {
            this.setState({isLoading: true});
            this.deleteTemplate().then(data => {
                if (data.status) {
                    $("#emailTemplateID").val($("#emailTemplateID option:first").val());
                    toast.success("Success! Record has deleted successfully.", {
                        autoClose: 5000
                    });
                    this.getTemplates();
                }
            }).catch(
                err => {
                    console.error(err, err.stack);
                });
        }
    }

    editemailTemplate(e) {
        e.preventDefault();
        var id = this.state.selectedtemplateID;
        localStorage.setItem('EditableEmailTemplateGuid', id);
        this.props.history.push('/add-email-templates')
    }


    modalOpenPlaceHolder() {
        this.setState({
            modalOpenPlaceHolder: true
        })
    }

    onModalClosePlaceHolder() {
        this.setState({
            modalOpenPlaceHolder: false,
            placeholderDBvalue: "",
            placeholderLink: false,
            placeholderText: "",
            placeholderURL: "",
            placeholderName: "",
            formValid: false,
            formErrors: {},
            placeholderDBvalueValid: false,
            placeholderNameValid: false,
            placeholderTextValid: false,
            placeholderURLValid: false
        })
    }

    onModalCloseEditPlaceHolder() {
        this.setState({
            modalOpenEditPlaceHolder: false,
            modalOpenPlaceHolder: false,
            placeholderDBvalue: "",
            placeholderLink: false,
            placeholderText: "",
            placeholderURL: "",
            placeholderName: "",
            formValid: false,
            formErrors: {},
            placeholderDBvalueValid: false,
            placeholderNameValid: false,
            placeholderTextValid: false,
            placeholderURLValid: false
        })
    }

    modalOpenEditPlaceHolder() {
        this.setState({
            modalOpenEditPlaceHolder: true
        })
    }

    modalPlaceholderList(e) {
        e.preventDefault();
        this.setState({
            modalPlaceholderList: true
        })
    }

    onModalPlaceholderClose() {
        this.setState({
            modalPlaceholderList: false
        })
    }

    validateFields(fieldName, value) {
        let formErrors = this.state.formErrors;
        let placeholderDBvalueValid = this.state.placeholderDBvalueValid;
        let placeholderNameValid = this.state.placeholderNameValid;
        // let placeholderURLValid = this.state.placeholderURLValid;
        // let placeholderTextValid = this.state.placeholderTextValid;
        switch (fieldName) {
            case 'placeholderName':
                placeholderNameValid = value.length > 0;
                formErrors.placeholderName = placeholderNameValid ? '' : "This field is required";
                break;
            case 'placeholderDBvalue':
                placeholderDBvalueValid = value.length > 0;
                formErrors.placeholderDBvalue = placeholderDBvalueValid ? '' : "This field is required";
                break;
            // case 'placeholderURL':
            //     placeholderURLValid = value.length > 0;
            //     formErrors.placeholderURL = placeholderURLValid ? '' : "This field is required";
            //     break;
            // case 'placeholderText':
            //     placeholderTextValid = value.length > 0;
            //     formErrors.placeholderText = placeholderTextValid ? '' : "This field is required";
            //     break;

            default:
                break;
        }
        this.setState({
            formErrors: formErrors,
            placeholderDBvalueValid: placeholderDBvalueValid,
            // placeholderURLValid: placeholderURLValid,
            placeholderNameValid: placeholderNameValid,
            // placeholderTextValid: placeholderTextValid
        }, this.validateForm)
    }

    validateForm() {
        this.setState({
            formValid: this.state.placeholderDBvalueValid && this.state.placeholderNameValid
        })
    }

    handleChange(e) {
        let name = e.target.name;
        let val = e.target.value;
        this.setState({
            [name]: val
        }, this.validateFields(name, val))
    }

    _toggleChange(event) {
        this.setState({
            [event.target.id]: event.target.checked
        });
    }

   

    createPlaceHolders(placeholderName, placeholderLink, placeholderDBvalue, placeholderText, placeholderURL) {
        return this.props.postRequest("/email-placeholders/create-placeholder",{
                "Name": placeholderName,
                "Link": placeholderLink,
                "DBValue": placeholderDBvalue,
                "Text": (placeholderText? placeholderText : "N/A" ),
                "Url": (placeholderURL? placeholderURL : "N/A") 
            });
    }

    handleSubmit() {
        if (this.state.formValid) {
            this.setState({
                modalOpenPlaceHolder: false,
                modalPlaceholderList: false,
                isLoading: true
            }, () => {
                let response = this.createPlaceHolders(this.state.placeholderName, this.state.placeholderLink, this.state.placeholderDBvalue, this.state.placeholderText, this.state.placeholderURL);
                response.then(data => {
                    if (data.status) {
                        this.getPlaceholders();
                        toast.success("Success! Record has Added successfully.", {
                            autoClose: 5000
                        });
                        this.setState({
                            placeholderName: "",
                            placeholderText: "",
                            placeholderLink: false,
                            placeholderURL: "",
                            placeholderDBvalue: "",
                            placeholderNameValid: false,
                            placeholderDBvalueValid: false,
                            placeholderTextValid: false,
                            placeholderURLValid: false,
                            formValid: false,
                            modalPlaceholderList: true,
                            isLoading: false,
                            formErrors: {}
                        })
                    }
                }).catch(err => {
                    console.error(err, err.stack);
                });
            });
        } else {
            if (!this.state.placeholderTextValid) {
                let fieldValidationErrors = this.state.formErrors;
                fieldValidationErrors.placeholderText = "This field is required";
                this.setState({
                    formErrors: fieldValidationErrors
                })
            }
            if (!this.state.placeholderURLValid) {
                let fieldValidationErrors = this.state.formErrors;
                fieldValidationErrors.placeholderURL = "This field is required";
                this.setState({
                    formErrors: fieldValidationErrors
                })
            }

            if (!this.state.placeholderNameValid) {
                let fieldValidationErrors = this.state.formErrors;
                fieldValidationErrors.placeholderName = "This field is required";
                this.setState({
                    formErrors: fieldValidationErrors
                })
            }
            if (!this.state.placeholderDBvalueValid) {
                let fieldValidationErrors = this.state.formErrors;
                fieldValidationErrors.placeholderDBvalue = "This field is required";
                this.setState({
                    formErrors: fieldValidationErrors
                })
            }
        }
    }


    handleEdit(e, id) {
        e.preventDefault();
        this.setState({
            isLoading: true,
            modalPlaceholderList: false,
        });

        let placeholder = this.state.placeholderList.find(el => el.Guid === id);
        if(placeholder){
            console.log("placeholder=>", placeholder);
            this.setState({
                    placeholderName: placeholder.Name,
                    placeholderLink: placeholder.Link,
                    placeholderDBvalue: placeholder.DBValue,
                    placeholderText: placeholder.Text,
                    placeholderURL: placeholder.Url,
                    editPlaceholderIndex: id
            }, () => {
                this.setState({
                    modalOpenEditPlaceHolder: true,
                    modalPlaceholderList: true,
                    isLoading: false
                })
            })
        }else{
            toast.error("Something went wrong, please try later.", {autoClose: 5000});
            this.setState({isLoading: false});
        }
    }

    editPlaceHolders(placeholderName, placeholderLink, placeholderDBvalue, placeholderText, placeholderURL) {
        return this.props.postRequest("/email-placeholders/create-placeholder",{
            "Name": placeholderName,
            "Link": placeholderLink,
            "DBValue": placeholderDBvalue,
            "Text": placeholderText? placeholderText : "N/A",
            "Url": placeholderURL? placeholderURL : "N/A",
            "Guid": this.state.editPlaceholderIndex
        });
    }

    handleEditSubmit() {
        if (this.state.formValid || (this.state.placeholderName  && this.state.placeholderDBvalue)) {
            let response = this.editPlaceHolders(this.state.placeholderName, this.state.placeholderLink, this.state.placeholderDBvalue, this.state.placeholderText, this.state.placeholderURL)
            this.setState({
                isLoading: true,
                modalOpenEditPlaceHolder: false,
                modalPlaceholderList: false
            })
            response.then(data => {

                if (data.status) {
                    toast.success("Success! Record has updated successfully.", {
                        autoClose: 5000
                    });
                    this.getPlaceholders();
                    this.setState({
                        isLoading: false,
                        modalPlaceholderList: true,
                        placeholderName: "",
                        placeholderText: "",
                        placeholderLink: false,
                        placeholderURL: "",
                        placeholderDBvalue: "",
                        placeholderNameValid: false,
                        placeholderDBvalueValid: false,
                        placeholderTextValid: false,
                        placeholderURLValid: false,
                        formValid: false,
                    })
                }
            }).catch(
                err => {
                    console.error(err, err.stack);
                });
        }
    }
    
    
    htmlEntities(html){
        //remove code brakes and tabs
        html = html.replace(/\n/g, "");
        html = html.replace(/\t/g, "");
    
        //keep html brakes and tabs
        html = html.replace(/<\/td>/g, "\t");
        html = html.replace(/<\/table>/g, "\n");
        html = html.replace(/<\/tr>/g, "\n");
        html = html.replace(/<\/p>/g, "\n");
        html = html.replace(/<\/div>/g, "\n");
        html = html.replace(/<\/h>/g, "\n");
        html = html.replace(/<br>/g, "\n"); html = html.replace(/<br( )*\/>/g, "\n");
    
        //parse html into text
        var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
        return dom.body.textContent;
    }

    handleEmailDropdown(e) {
        let index = e.target.selectedIndex;
        let element = e.target.childNodes[index]
        let selectedid = element.getAttribute('id');
        let selectedtype = element.getAttribute('type');
        let selectedtenant = element.getAttribute('tenantguid');

        if (e.target.selectedIndex === 0 || localStorage.getItem('useableGuid') !== selectedtenant) {
            this.setState({
                buttonDisable: true,
                disabledClass: "disableButton",
                selectedtemplateType: "",
                selectedDropdown: 0 // selected drop true in case of 1 , and false in case of 0
            })
        }
        if (e.target.selectedIndex > 0 && localStorage.getItem('useableGuid') === selectedtenant) {
            this.setState({
                buttonDisable: false,
                disabledClass: "enableButton",
                selectedDropdown: 1 // selected drop true in case of 1 , and false in case of 0
            })
        }
        
        if (selectedid) {
            this.setState({
                selectedtemplateID: selectedid,
                selectedtemplateType: selectedtype,
                selectedDropdown: 1, // selected drop true in case of one , and false in case of 0,
                isLoading: true,
                FileAttachments_urls: []
            }, () => {
                var selected_files_urls = this.state.FileAttachments_urls
                let template_data = this.state.emailTemplateList.find(el => el.Guid === selectedid);
                if(template_data){
                    this.setState({
                        singleTemplate: template_data,
                        body : template_data.BodyText,
                        isLoading: false
                    });
                    /*template_data.Attachments.forEach(attachment => {
                            Storage.get(attachment.url, { level: 'private' })
                                .then(result => {
                                    this.setState({
                                        attachmentPath: result
                                    })
                                    selected_files_urls.push(this.state.attachmentPath);
                                    this.setState({
                                        FileAttachments_urls: selected_files_urls
                                    })
                                }).catch(
                                    err => {
                                        console.error(err, err.stack);
                                    });
                    });*/
                }
            })
        }
    }

    render() {
        var placeholders;
        if (this.state.placeholderList !== 'undefined' && this.state.placeholderList.length > 0) {
            let placeholderlist = this.state.placeholderList;
            placeholders = placeholderlist.map((val, key) => {
                return (
                    <tr key={key}>
                        <td width="25%" align="left" valign="middle">{val.Name}</td>
                        <td width="25%" align="left" valign="middle">{val.DBValue}</td>
                        <td width="40%" align="left" valign="middle">{val.Url}</td>
                        <td width="20%" align="left" valign="middle">{val.Text}</td>
                        <td align="center">
                            <div className="col-sm-4  col-xs-6 p0 pull-right text-center timecard_dele pull-right">
                                <a href={null} onClick={(e) => this.delPlaceholderClick(e, val.Guid)} >
                                    <svg className="CursorPointer" xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
                                        <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"></path>
                                    </svg>
                                </a>
                            </div>
                            <div className="col-sm-4  col-xs-6 p0 text-center timecard_edit6 pull-right">
                                <a href={null} onClick={(e) => this.handleEdit(e, val.Guid)}>
                                    <svg className="CursorPointer" xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
                                        <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"></path>
                                    </svg>
                                </a>
                            </div>
                        </td>
                    </tr>
                )
            })
        } else {
            placeholders = <tr><td colSpan="2">Record not found</td><td></td></tr>;

        }


        return (
            
            <div className="CustomFields">

                <Header pageHeading="Email Templates" isLoading={this.state.isLoading} props={this.props} />
                <div className="clearfix"></div>
                <div className="col-sm-12 p0 top_mrg_general top_mrg_general_custom">
                    <div className="">
                        <div className="col-sm-12 col-md-12 col-lg-10 col-lg-offset-1 emailtemplate_general_setting_hd">
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <div className="clear10"></div>
                                <div className="clearfix"></div>
                                <div className="email_temp_box">
                                    <div className="email_temp_hdr">
                                        <div className="col-xs-12 col-sm-6 text-left mrg_top3">
                                            <label>Templates:</label>
                                            <div className="email_hdr_select">
                                                <select id="emailTemplateID" name="key" onChange={(e) => this.handleEmailDropdown(e)}>
                                                    <option>Select Email Template</option>
                                                    {this.state.emailTemplateList.map((val, key) => {
                                                        return (

                                                            <option id={val.Guid} key={key} tenantguid={val.TenantGUID} type={val.Type} >{val.TName}</option>
                                                        )
                                                    })}
                                                </select>


                                            </div>
                                            <label>Template Type: <span>{this.state.selectedtemplateType}</span></label>

                                        </div>
                                        <div className="col-xs-12 col-sm-6 text-right email_temp_right">
                                            <a href={null} className="pull-right">
                                                <button type="button" disabled={this.state.buttonDisable} className={"btn  pull-right plus_icon_table btn_plus_cusotm btn_custom_list " + this.state.disabledClass} onClick={() => this.btnClickDeleteTemplate()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
                                                        <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"></path>
                                                    </svg>
                                                </button>
                                            </a>
                                            <a href={null} className="pull-right">
                                                <button type="button" disabled={this.state.buttonDisable} className={"btn  pull-right plus_icon_table btn_plus_cusotm btn_custom_list " + this.state.disabledClass} onClick={(e) => this.editemailTemplate(e)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
                                                        <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"></path>
                                                    </svg>
                                                </button>
                                            </a>
                                            <LinkContainer to="add-email-templates">
                                                <a href={null} className="pull-right">
                                                    <button type="button" className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm btn_custom_list emailtemplates_btn_plus_cusotm">+</button>
                                                </a>
                                            </LinkContainer>

                                            <button type="button" className="pull-right emailtemplate_edit_place_email" data-toggle="modal" onClick={(e) => this.modalPlaceholderList(e)}>Edit Placeholders</button>
                                        </div>
                                        <div className="clearfix"></div>

                                        {this.state.selectedDropdown === 0 ?
                                            <div className="mrg_top_email_temp">Email Template Preview</div>
                                            : ""}
                                        {this.state.selectedDropdown > 0 ?
                                            <div className="mrg_top_email_temp preview_body">

                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <div className="emailadd_left_boxarea">
                                                            <div className="col-sm-3 mrg_top5">Template Name:</div>
                                                            <div className="col-sm-3 col-md-4">
                                                                <input name="templateName" className="name_w_email" value={this.state.singleTemplate.TName || ''} type="text" readOnly={true} />
                                                            </div>
                                                            <div className="clear20"></div>
                                                            <div className="col-sm-3 mrg_top5">Template Type:</div>
                                                            <div className="col-sm-3 col-md-4 mrg_top5">
                                                                <select className="email_add_file_btn" value={this.state.templateType} name="templateType" onChange={(e) => this.handleChange(e)}>
                                                                    <option>{this.state.singleTemplate.Type || ''}</option>
                                                                </select>
                                                            </div>
                                                            {this.state.singleTemplate.Default === 1 ?
                                                                <div className="mrg_top5_labels">
                                                                    <div className="col-xs-7 col-sm-2 col-md-1">Default:</div>
                                                                    <div className="col-xs-5 col-sm-1">
                                                                        <label className="check_box_pop">
                                                                            <input type="checkbox" name="default" readOnly={true} checked={this.state.singleTemplate.Default === 1 ? true : false || false} />
                                                                            <span className="checkmark left0_check"></span>
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                                : ""}
                                                            {this.state.singleTemplate.Private === 1 ?
                                                                <div className="mrg_top5_labels">
                                                                    <div className="col-xs-7 col-sm-2 col-md-1">Private:</div>
                                                                    <div className="col-xs-5 col-sm-1">
                                                                        <label className="check_box_pop">
                                                                            <input type="checkbox" name="private" readOnly={true} checked={this.state.singleTemplate.Private === 1 ? true : false || false} />
                                                                            <span className="checkmark left0_check"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                : ""}


                                                            <div className="clear20"></div>
                                                            <div className="col-sm-12 p0">
                                                                <div className="col-sm-3 mrg_top10">Attachment:</div>
                                                                <div className="col-sm-9 col-md-4 text-left">
                                                                    {(this.state.FileAttachments_urls.length > 0) ? this.state.FileAttachments_urls.map((val, key) => {
                                                                        return (
                                                                            <a href={val} key={key} download>
                                                                                <img src={val} key={key} className="images" alt="Img" />

                                                                            </a>
                                                                        )
                                                                    }) : <p className="mrg_top10">No Attachments</p>}
                                                                    <div>
                                                                    </div>
                                                                </div>
                                                                <div className="clear20"></div>
                                                            </div>
                                                            <div className="clear30"></div>                                                            
                                                            <div className="email_attach_table_desc_pad" width="95%" align="left">Description</div>
                                                            <div className="clear5"></div>
                                                            <hr />
                                                            <div className="clear5"></div>
                                                            <div className="email_temp_add_box3">
                                                                <div className="col-xs-5 col-sm-5 col-md-7 mrg_top5">Subject: <span className="email_temp_add_box3_spn">*</span></div>
                                                                <div className="col-xs-7 col-sm-7 col-md-5">

                                                                </div>
                                                                <div className="clear10"></div>
                                                                <div className="col-sm-12"><input name="subject" value={this.state.singleTemplate.Subject || ""} readOnly={true} type="text" />
                                                                </div>
                                                                <div className="clear20"></div>
                                                                <div className="col-sm-7">Name: </div>
                                                                <div className="clear10"></div>
                                                                <div className="col-sm-12"><input name="name" value={this.state.singleTemplate.Name || ""} readOnly={true} type="text" />
                                                                </div>
                                                                <div className="clear20"></div>
                                                                <div className="col-sm-7">Description: </div>
                                                                <div className="clear10"></div>
                                                                <div className="col-sm-12"><input name="description" value={this.state.singleTemplate.Desc || ""} readOnly={true} type="text" />
                                                                </div>
                                                                <div className="clear20"></div>
                                                                <div className="col-sm-7">Message: <span className="email_temp_add_box3_spn">*</span></div>
                                                                <div className="clear10"></div>
                                                                <div className="col-sm-12">
                                                                    <div className="emailtemplate_message" dangerouslySetInnerHTML={{__html: this.state.body}} ></div>
                                                                    
                                                                </div>
                                                                <div className="clear20"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            : ''}
                                    </div>
                                    <div className="clear10"></div>
                                </div>
                            </div>
                        </div>
                        <div className="clear10"></div>
                    </div>
                </div>
                <div className="clear10"></div>



                <Modal id="myModal_edit_placeholder" show={this.state.modalPlaceholderList} onHide={(event) => this.onModalPlaceholderClose(event)} className="modal modal2 fade modal-dialog_w_pckform1" role="dialog">

                    <div className="modal-header">
                        <button type="button" onClick={(event) => this.onModalPlaceholderClose(event)} className="close emailtemplates_OutlineNone emailtemplates_mt15" data-dismiss="modal"><img src={ic_clear_24px} className="emailtemplates_mt22px" width="18" height="18" alt="" /></button>
                        <h4 className="modal-title modal_customefield">Placeholders
			   </h4>
                    </div>
                    <div className="modal-body">
                        <div className="col-sm-12 p0">
                            <a href={null} className="pull-right">
                                <button type="button" className="btn btn-primary pull-right plus_icon_table emailtemplate_btn_plus_cusotm" onClick={this.modalOpenPlaceHolder.bind(this)}>+</button>
                            </a>
                            <div className="clear5"></div>
                        </div>
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="table_formpck_pop_1 company_pop emailtemplate_pop_overflow_email3">
                                <table width="100%" border="0" cellSpacing="2" cellPadding="2">
                                    <thead>
                                        <tr>
                                            <th width="25%" align="left" valign="middle">Name</th>
                                            <th width="25%" align="left" valign="middle">DB Value</th>
                                            <th width="40%" align="left" valign="middle">URL</th>
                                            <th width="20%" align="left" valign="middle">Text</th>
                                            <th width="10%" align="left" valign="middle"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            placeholders
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="clear20"></div>
                    </div>
                </Modal>
                <Modal show={this.state.modalOpenPlaceHolder} onHide={(event) => this.onModalClosePlaceHolder(event)} className="modal modal2 fade modal-dialog_w_pckform1" role="dialog">

                    <div className="modal-header">
                        <button onClick={this.onModalClosePlaceHolder.bind(this)} type="button" className="close emailtemplates_OutlineNone" data-dismiss="modal"><img src={ic_clear_24px} className="emailtemplates_mt22px" width="18" height="18" alt="" /></button>
                        <h4 className="modal-title modal_customefield">
                            Placeholders Form
			            </h4>
                    </div>
                    <div className="modal-body label_modal_custom emailtemplate_email_temp_pop_placeholder">
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-sm-3 col-md-3 label_csutom_pop">Name:</div>
                                <div className="col-sm-9 col-md-9"><input name="placeholderName" value={this.state.placeholderName} type="text" onChange={(e) => this.handleChange(e)} />
                                    <p className={"error validation " + (this.state.formErrors.placeholderName ? '' : 'displayNone')}>{this.state.formErrors.placeholderName}</p>

                                </div>

                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-sm-3 col-md-3 label_csutom_pop">DB Value:</div>
                                <div className="col-sm-9 col-md-9"><input name="placeholderDBvalue" value={this.state.placeholderDBvalue} type="text" onChange={(e) => this.handleChange(e)} />
                                    <p className={"error validation " + (this.state.formErrors.placeholderDBvalue ? '' : 'displayNone')}>{this.state.formErrors.placeholderDBvalue}</p>

                                </div>

                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-xs-3 col-sm-3 col-md-3 label_csutom_pop">Link::</div>
                                <div className="col-xs-9 col-sm-9 col-md-9 mrg_top5">
                                    <label className="check_box_pop" style={{ marginTop: "0px" }}>
                                        <input type="checkbox" id="placeholderLink" value={this.state.placeholderLink} defaultChecked={this.state.placeholderLink} onChange={(e) => this._toggleChange(e)} />
                                        <span className="checkmark left0_check emailtemplate_checkmark"></span>
                                        <input type="checkbox" /></label>
                                </div>
                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-sm-3 col-md-3 label_csutom_pop">URL:</div>
                                <div className="col-sm-9 col-md-9"><input name="placeholderURL" value={this.state.placeholderURL} type="text" onChange={(e) => this.handleChange(e)} />
                                </div>

                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-sm-3 col-md-3 label_csutom_pop">Text:</div>
                                <div className="col-sm-9 col-md-9"><input name="placeholderText" type="text" value={this.state.placeholderText} onChange={(e) => this.handleChange(e)} />
                            </div>

                            </div>
                            <div className="clear40"></div>
                            <div className="col-sm-6">
                                <div className="btn_cance_save">
                                    <button type="button" className="btn_save_pro" onClick={this.handleSubmit.bind(this)} data-dismiss="modal">Save</button>
                                    <button type="button" className="btn_cancel_pro" data-dismiss="modal" onClick={this.onModalClosePlaceHolder.bind(this)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        <div className="clear20"></div>
                    </div>

                </Modal>

                <Modal show={this.state.modalOpenEditPlaceHolder} onHide={(event) => this.onModalCloseEditPlaceHolder(event)} className="modal modal2 fade modal-dialog_w_pckform1" role="dialog">

                    <div className="modal-header">
                        <button onClick={this.onModalCloseEditPlaceHolder.bind(this)} type="button" className="close emailtemplates_OutlineNone" data-dismiss="modal"><img src={ic_clear_24px} className="emailtemplates_mt22px" width="18" height="18" alt="" /></button>
                        <h4 className="modal-title modal_customefield">
                            Edit Placeholder
	   </h4>
                    </div>
                    <div className="modal-body label_modal_custom emailtemplate_email_temp_pop_placeholder">
                        <div className="col-sm-12 p0 mrg_bot_custom10">
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-sm-3 col-md-3 label_csutom_pop">Name:</div>
                                <div className="col-sm-9 col-md-9"><input name="placeholderName" value={this.state.placeholderName} type="text" onChange={(e) => this.handleChange(e)} />
                                    <p className={"error validation " + (this.state.formErrors.placeholderName ? '' : 'displayNone')}>{this.state.formErrors.placeholderName}</p>

                                </div>

                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-sm-3 col-md-3 label_csutom_pop">DB Value:</div>
                                <div className="col-sm-9 col-md-9"><input name="placeholderDBvalue" value={this.state.placeholderDBvalue} type="text" onChange={(e) => this.handleChange(e)} />
                                    <p className={"error validation " + (this.state.formErrors.placeholderDBvalue ? '' : 'displayNone')}>{this.state.formErrors.placeholderDBvalue}</p>

                                </div>

                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-xs-3 col-sm-3 col-md-3 label_csutom_pop">Link::</div>
                                <div className="col-xs-9 col-sm-9 col-md-9 mrg_top5">
                                    <label className="check_box_pop">
                                        <input type="checkbox" id="placeholderLink" value={this.state.placeholderLink || false} defaultChecked={this.state.placeholderLink} onChange={(e) => this._toggleChange(e)} />
                                        <span className="checkmark left0_check emailtemplate_checkmark"></span>
                                        <input type="checkbox" /></label>
                                </div>
                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-sm-3 col-md-3 label_csutom_pop">URL:</div>
                                <div className="col-sm-9 col-md-9"><input name="placeholderURL" value={this.state.placeholderURL} type="text" onChange={(e) => this.handleChange(e)} />

                                </div>

                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="col-sm-3 col-md-3 label_csutom_pop">Text:</div>
                                <div className="col-sm-9 col-md-9"><input name="placeholderText" type="text" value={this.state.placeholderText} onChange={(e) => this.handleChange(e)} />
                                </div>

                            </div>
                            <div className="clear40"></div>
                            <div className="col-sm-6">
                                <div className="btn_cance_save">
                                    <button type="button" className="btn_save_pro" onClick={this.handleEditSubmit.bind(this)} data-dismiss="modal">Update</button>
                                    <button type="button" className="btn_cancel_pro" data-dismiss="modal" onClick={this.onModalCloseEditPlaceHolder.bind(this)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        <div className="clear20"></div>
                    </div>

                </Modal>


            </div>
        );
    }
};
