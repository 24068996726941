import React, { Component } from "react";
//======================================//
// ListItem AS A CHILD COMPONENT DEFINE //
// - - - - -  IN THIS SCOPE - - - - - - //
//--------------------------------------//

import down_arrow_amprvoe from "../../../assets/images/down_arrow_amprvoe.png";

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

export default class ApprovalListItem extends Component
{
	constructor(props)
	{
	    super(props);
      this.state = {
          usersList: props.usersList
      };
  	}

	componentWillReceiveProps(nextProps)
	{
	  	this.setState({ usersList: nextProps.usersList });
	}

	customToggle(event){
		var ID = event.currentTarget.id;
		ID = "#approval_setup_"+ID
		$(ID).toggle();
    //console.log(event.currentTarget);
    $(event.currentTarget).find(".cursorPointer").toggleClass('rotate180');
	}

	//=======================================//
	//  - - - RENDER LISTITEM COMPONENT - -  //
	//---------------------------------------//
	render()
	{

        if(typeof this.props.usersList !== 'undefined' && this.props.usersList.length > 0)
        {
            let currentUsersList = this.props.usersList;
            var usersList = currentUsersList.map((data, key) => {
                var approvals;
        				if (typeof(data.approvalData) !== "undefined" && data.approvalData.Approvers.length > 0){

										approvals = data.approvalData.Approvers.map((approvals, key) => {
												return(
													<tr key={"ux2o2"+key}>
														<td>{approvals.ApproverName}</td>
														<td>{data.FirstName}</td>
														<td align="center" className="textAlignCenter">{approvals.Sequence}</td>
														<td align="center" className="textAlignCenter">{approvals.SigPosition}</td>
														<td>
															<div className="col-sm-3  col-xs-6 p0 pull-right text-center timecard_dele pull-right cursor_pointer">
																<a data-index={key} data-guid={data.approvalData.Guid} data-tenantguid={data.approvalData.TenantGuid} href={null} onClick={(event) => this.props.deleteApproval(event)}>
																	<svg
																		xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
																		<path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"></path>
																	</svg>
																</a>
															</div>
															<div className="col-sm-3  col-xs-6 p0 text-center timecard_edit6 pull-right cursor_pointer">
																<a data-index={key} data-guid={data.approvalData.Guid} data-tenantguid={data.approvalData.TenantGuid}  href={null} onClick={(event) => this.props.editApproval(event)}>
																	<svg
																		xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
																		<path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"></path>
																	</svg>
																</a>
															</div>
														</td>
													</tr>
												)
										});
        				}
        				else
        				{
        					 approvals = <tr><td  colSpan="5" >Record not found</td></tr>;
        				}

        				return (
        					[
										<tr key={"ux1o1"+key}>
											<td width="15%" align="center">
												<a href={null} id={key} onClick={(event) => this.customToggle(event)}>
													<img className="cursorPointer" src={down_arrow_amprvoe} alt="" />
												</a>
											</td>
                      <td align="left">{ data.FirstName}</td>
                      <td width="20%" align="left">{ data.ApproverGroupUserName }</td>
                      <td width="25%" align="left">{ data.ApproverGroupinitials }</td>
                      <td align="left">{data.ApproverGroupDepartment}</td>
										</tr>,
                    <tr key={key} id={"approval_setup_"+key} className="approvel_new_open_table displayNone">
                      <td colSpan="5">
                        <div>
                          <a href={null}>
                            <button type="button" data-index={key} id={data.Guid} data-appgroup={data.ApproverGroupUserName}  className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm approval_setup_btn_plus_cusotm" onClick={(event) => this.props.onModalOpen(event)}>+</button>
                          </a>
                        </div>
                        <div className="clear5"></div>
                        <table width="100%" border="0" cellSpacing="2" cellPadding="2">
                          <thead>
                            <tr>
                              <th width="15%" align="left">Name</th>
                              <th width="20%" align="left">Approver Group</th>
                              <th width="20%" align="center" className="text-center">Sequence</th>
                              <th width="25%" align="center" className="text-center">Signature Position</th>
                              <th width="20%" align="left"></th>
                            </tr>
                          </thead>
                          <tbody>
															{approvals}
                          </tbody>
                        </table>
                      </td>
                    </tr>
        			   	]
        				)

            });

            if(usersList.length === 0)
            {

                 usersList = <tr><td  colSpan="4" >Record not found</td><td></td></tr>;
            }
        }
        else
        {
             usersList = <tr><td  colSpan="4">Record not found</td><td></td></tr>;
        }


        return(
            <tbody>
            		{
            			usersList
            		}
            </tbody>
        );
	}
};
