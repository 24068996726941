import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import "./CpStepThird.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/reset_logo.jpg";
import close_icon from "../../assets/images/close_icon.png";
import sucess_forgot from "../../assets/images/sucess_forgot.jpg";
import { Auth } from 'aws-amplify';
import { API} from "aws-amplify";
import { toast } from 'react-toastify';
import $ from 'jquery';
require('react-bootstrap-modal/lib/css/rbm-patch.css');

class CpStepThird extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            prevPassword : '',
            newPassword: '',
            confirmPassword:'',
            prevPasswordError:'',
            newPasswordError:'',
            confirmPasswordError:'',
            passwordMatchError:''
        }
    }

    componentDidMount(){
        $(".modal").css('display', 'none');
        //console.log(this.props.location.state);
        if(this.props.location.state == undefined){
            return this.props.history.push('/change-password-step-1');   
        }

        this.setState({
            user_code: this.props.location.state.user_code,
            reset_email: this.props.location.state.reset_email
        });
    }

    onInputChangeHandler = (e) =>{
        this.setState({[e.target.name]: e.target.value});
    }

    onPasswordChange = async () =>{
        /*if(this.state.prevPassword === ''){
            this.setState({prevPasswordError:'Previous password is required'});
            return false;
        }else
        {
            this.setState({prevPasswordError:''})
        }*/
        if(this.state.newPassword === ''){
            this.setState({newPasswordError : 'New password is required'});
            return false;
        }else{
            this.setState({newPasswordError:''})
        }
        if(this.state.confirmPassword === ''){
            this.setState({confirmPasswordError: 'Confirm password is required'});
            return false;
        }else{
            this.setState({confirmPasswordError:''})
        }

        if(this.state.newPassword === this.state.confirmPassword){
            this.setState({isLoading:true});

            await Auth.forgotPasswordSubmit(this.state.reset_email, this.state.user_code, this.state.newPassword).then(data => {
               //toast.success('Password updated successfully')
               API.post("dynamochangepassword", "/dynamochangepassword", {
                  body: {
                      guid: localStorage.getItem('Guid'),
                      Password: this.state.newPassword,
                      passwordChangedOnce: true
                  }
              }).then(result => {
                 toast.success('Password updated successfully.');
                  if(result.status){
                      this.props.history.push({
                          pathname: '/dashboard'
                        });
                  }
              }).catch(err => {
                toast.error(err.message, {autoClose: 5000});
              });
            }).catch(err => {
              console.log(err);
               toast.error(err.message, {autoClose: 5000});
            });

        }else{
            this.setState({passwordMatchError : 'Password not matched'});
            return false;
        }
        
    }

    render() {
        return (
            <div className="CpStepThird">
                <div className="col-sm-12">
                    <div className="container p0">
                        <div className="forgot_box1">
                            <div className="forgot_box bdr_change_pass">
                                <div className="col-sm-12 reset_password"> <img src={LOGO} alt="" /> Reset Password</div>
                                <div className="reset_under_box">
                                    <div className="clear50"></div>
                                    {/*}
                                    <input name="prevPassword" className="frogot_input" onChange={this.onInputChangeHandler} value={this.state.prevPassword} type="password" placeholder="Previous Password" />
                                    {(this.state.prevPasswordError !== '') ? <small className="errors">{this.state.prevPasswordError}</small>: null}
                                    <div className="clear10"></div>
                                    {*/}
                                    <input name="newPassword" className="frogot_input" value={this.state.newPassword} onChange={this.onInputChangeHandler} type="password" placeholder="New Password" />
                                    {(this.state.newPasswordError !== '') ? <small className="errors">{this.state.newPasswordError}</small>: null}
                                    <div className="clear10"></div>
                                    <input name="confirmPassword" onChange={this.onInputChangeHandler} value={this.state.confirmPassword} className="frogot_input" type="password" placeholder="Confirm Password" />
                                    {(this.state.confirmPasswordError !== '') ? <small className="errors">{this.state.confirmPasswordError}</small>: null}
                                    {(this.state.passwordMatchError !== '') ? <small className="errors">{this.state.passwordMatchError}</small>: null}
                                    <div className="clear20"></div>

                                    <LinkContainer to="/change-password-step-2"><a href={null} className="btn_frogot_back">Back</a></LinkContainer>
                                    <a href={null} onClick={this.onPasswordChange} className="btn_frogot_blue">Proceed</a>

                                    <div className="clearfix"></div>
                                </div>
                                <div className="clearfix"></div>

                            </div>
                        </div>
                        <div className="clearfix"></div>

                    </div>
                    <div className="clearfix"></div>
                </div>
                <div id="myModalSccess" className="modal fade" role="dialog">
                    <div className="modal-dialog">


                        <div className="modal-content modal_forgot_w">
                            <div className="modal-header forgot_modal_header">
                                <button type="button" className="close" data-dismiss="modal"><img src={close_icon} alt="" /></button>
                                <img src={sucess_forgot} width="80" height="80" alt="" />
                            </div>
                            <div className="modal-body forgot_modal_body">
                                <p>SUCCESS!</p>
                                <div className="clear60"></div>
                                <div className="clear10"></div>
                                <div className="col-sm-12">
                                    <a href="/dashboard" className="btn_frogot_ok">OK</a>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        );
    }
};
export default CpStepThird;
