import React from "react";
import { Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute/AppliedRoute";
import Login from "./containers/Login/Login";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import AddCompany from "./containers/AddCompany/AddCompany";
import AddEmailTemplates from "./containers/AddEmailTemplates/AddEmailTemplates";
import Dashboard from "./containers/Dashboard/Dashboard";
import FpStepFirst from "./containers/FpStepFirst/FpStepFirst";
import FpStepSecond from "./containers/FpStepSecond/FpStepSecond";
import FpStepThird from "./containers/FpStepThird/FpStepThird";
import Report from "./containers/Report/Report";
import Pdf from "./containers/Report/Pdf";
import PdfDetail from "./containers/Report/PdfDetail";
import FormTemplate from "./containers/FormTemplate/FormTemplate";
import FormPacks from "./containers/FormPacks/FormPacks";
import FormPacksPdf from "./containers/FormPacks/Pdf1";
import ApprovalSetup from "./containers/ApprovalSetup/ApprovalSetup";
import CustomFields from "./containers/CustomFields/CustomFields";
import CustomFieldsList from "./containers/CustomFieldsList/CustomFieldsList";
import FormTemplateDetail from "./containers/FormTemplateDetail/FormTemplateDetail";
import SignSettings from "./containers/SignSettings/SignSettings";
import GeneralSettings from "./containers/GeneralSettings/GeneralSettings";
import Help from "./containers/Help/Help";
import Users from "./containers/Users/Users";
import EmailTemplates from "./containers/EmailTemplates/EmailTemplates";
import CompanyDetail from "./containers/CompanyDetail/CompanyDetail";
import TermsOfUse from "./containers/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import CpStepFirst from "./containers/CpStepFirst/CpStepFirst";
import CpStepSecond from "./containers/CpStepSecond/CpStepSecond";
import CpStepThird from "./containers/CpStepThird/CpStepThird";
import InsertDocument from "./containers/InsertDocument/InsertDocument";
import Documents from "./containers/Documents/Documents";
import CompanyEdit from "./containers/CompanyEdit/CompanyEdit";
import CompanyView from "./containers/CompanyView/CompanyView";
import AddProject from "./containers/AddProject/AddProject";
import EditProject from "./containers/EditProject/EditProject";
import header from "./containers/Common/Header/Header";
import VerifyCode from "./containers/VerifyCode/VerifyCode";
import NotFound from "./containers/NotFound/NotFound";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute  path="/" exact component={Login} props={childProps} />
	<AppliedRoute  path="/add-company" exact component={AddCompany} props={childProps} />
    <AppliedRoute  path="/header" exact component={header} props={childProps} />
    <AppliedRoute  path="/dashboard" exact component={Dashboard} props={childProps} />
    <AppliedRoute  path="/change-password" exact component={ChangePassword} props={childProps} />
    <AppliedRoute  path="/VerifyCode" exact component={VerifyCode} props={childProps} />
    <AppliedRoute  path="/forget-password-step-1" exact component={FpStepFirst} props={childProps} />
    <AppliedRoute  path="/forget-password-step-2" exact component={FpStepSecond} props={childProps} />
    <AppliedRoute  path="/forget-password-step-3" exact component={FpStepThird} props={childProps} />
    <AppliedRoute  path="/report" exact component={Report} props={childProps} />
    <AppliedRoute  path="/report/print" exact component={Pdf} props={childProps} />
    <AppliedRoute  path="/report/print_detail" exact component={PdfDetail} props={childProps} />
    <AppliedRoute  path="/form-template" exact component={FormTemplate} props={childProps} />
    <AppliedRoute  path="/form-packs" exact component={FormPacks} props={childProps} />
    <AppliedRoute  path="/formpacks/print" exact component={FormPacksPdf} props={childProps} />
    <AppliedRoute  path="/approval-setup" exact component={ApprovalSetup} props={childProps} />
    <AppliedRoute  path="/custom-fields" exact component={CustomFields} props={childProps} />
    <AppliedRoute  path="/custom-fields-list" exact component={CustomFieldsList} props={childProps} />
    <AppliedRoute  path="/form-template-detail" exact component={FormTemplateDetail} props={childProps} />
    <AppliedRoute  path="/signature-settings" exact component={SignSettings} props={childProps} />
    <AppliedRoute  path="/general_settings" exact component={GeneralSettings} props={childProps} />
    <AppliedRoute  path="/help" exact component={Help} props={childProps} />
    <AppliedRoute  path="/users" exact component={Users} props={childProps} />
    <AppliedRoute  path="/email-templates" exact component={EmailTemplates} props={childProps} />
    <AppliedRoute  path="/company-detail" exact component={CompanyDetail} props={childProps} />
    <AppliedRoute  path="/terms-of-use" exact component={TermsOfUse} />
    <AppliedRoute  path="/privacy-policy" exact component={PrivacyPolicy} />
    <AppliedRoute  path="/change-password-step-1" exact component={CpStepFirst} props={childProps} />
    <AppliedRoute  path="/change-password-step-2" exact component={CpStepSecond} props={childProps} />
    <AppliedRoute  path="/change-password-step-3" exact component={CpStepThird} props={childProps} />
    <AppliedRoute  path="/insert-document" exact component={InsertDocument} props={childProps} />
    <AppliedRoute  path="/documents" exact component={Documents} props={childProps} />
    <AppliedRoute  path="/edit-company/:company_id" exact component={CompanyEdit} props={childProps} />
    <AppliedRoute  path="/view-company/:company_id" exact component={CompanyView} props={childProps} />
    <AppliedRoute  path="/add-project" exact component={AddProject} props={childProps} />
    <AppliedRoute  path="/edit-project/:id" exact component={EditProject} props={childProps} />
    <AppliedRoute  path="/add-email-templates" exact component={AddEmailTemplates} props={childProps} />
    <AppliedRoute  path="" exact component={NotFound} />
  </Switch>;
