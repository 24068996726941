import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Header from "../Common/Header/Header";
import { Storage , API } from 'aws-amplify';
import ListItem from "./Components/ListItem";
import ProjectListItem from "./Components/ProjectListItem";
import { toast } from 'react-toastify';
// - -   IMPORT STYLING   - -//
// - - - - - - - - - - - - - //
import "./Dashboard.css";
// - -  DEFINE CONSTANTS  - -//
// - - - - - - - - - - - - - //
import menutoggleimg from "../../assets/images/ic_dehaze_-19@2x.png";
import setting_icon_second from "../../assets/images/dashboard-setting-image2.png";
import draft_icon from "../../assets/images/draft_icon.png";
import aproved from "../../assets/images/aproved.png";
import pending from "../../assets/images/pending.png";
import decline from "../../assets/images/decline.png";
import approved from "../../assets/images/approved.png";
import all_icon from "../../assets/images/all_icon.png";
import img_queue from "../../assets/images/img_queue.jpg";
import edit_profile_image3 from "../../assets/images/edit_profile-image3.jpg";
import amproved_bg from "../../assets/images/amproved_bg.jpg";
import profile_pic from "../../assets/images/profile_pic.png";
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";
import default_profile_pic from "../../assets/images/default_profile_pic.png";
import loading_profile_pic from "../../assets/images/Preloader.gif";
import moment from 'moment';
import { Auth} from "aws-amplify";
import LineChart from 'react-svg-line-chart'
import Moment from 'react-moment';
import $ from 'jquery';
import Chart from 'react-apexcharts'

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
window.jQuery = $;
window.$ = $;
global.jQuery = $;
var Modal = require('react-bootstrap-modal');
require('react-bootstrap-modal/lib/css/rbm-patch.css');

//========================================//
// Dashboard AS A PARENT COMPONENT DEFINE //
// - - - - -  IN THIS SCOPE - - - - - - - //
//----------------------------------------//
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            passwordChanging: false,
            companiesList: [],
            digitalFormCompleteData: [],
            digitalFormsLatestApproved: [],
            inputValueUseremail:'',
            inputValueUserMobileNumber:'',
            projectList: [],
            deletedStatus:false,
            projectDeletedStatus:false,
            showproject:false,
            modalOpen:false,
            projectModalOpen:false,
            editProjectAction:false,
            Approve:"",
            Declined:"", 
            Approved:"",
            Draft:"",
            Pending :"",
            All: "",
            userTypeForRoleManagement:localStorage.getItem('userType'),
            allRecentActivities :[],
            activePoint: {
                  x:1,
                  y:10
            },
            data : [
               {
                  x: 0,
                  y:0
               }
            ],
            uploadS3:false,
            profile_pic:'',
            userData:false,
            old_password: "",
            change_password_view: false,
            new_password: "",
            confirm_password: "",
            options: {
              chart: {
                id: 'in-queue-chart',
                toolbar: {
                  show: false
                }
              },
              xaxis: {
                categories: ["Approved", "Draft", "Pending", "Approve", "Declined"]
              },
              fill: {
                colors: ['#29429d']
              }
            },
            series: [{
              name: 'Total',
              data: [0, 0, 0, 0, 0]
            }]
        };

        if (this.props.isAuthenticated === false) {
            this.props.history.push("/");
        }
        
    };
    
    handlePointHover = (activePoint, e) => {
      this.setState({activePoint})
    }
    
    handleUserInput = (e) =>{
     this.setState({
        [e.target.name]: e.target.value
     })
    }

    showChangePassword = async (event) =>{
      this.setState({change_password_view: !this.state.change_password_view})
    }

    changePassword = async (event) =>{
      
      if(this.state.old_password === ""){
        toast.error("Old password is required." , {autoClose: 5000});
        return false;
      }

      if(this.state.new_password === ""){
        toast.error("New password is required." , {autoClose: 5000});
        return false;
      }

      if(this.state.confirm_password === ""){
        toast.error("Confirm password is required." , {autoClose: 5000});
        return false;
      }

      if(this.state.confirm_password !== this.state.new_password){
        toast.error("Confirm passwords do not match." , {autoClose: 5000});
        return false;
      }

      this.setState({passwordChanging: true});
      Auth.currentAuthenticatedUser().then(user => {
          return Auth.changePassword(user, this.state.old_password, this.state.confirm_password);
        }).then(data => {
          $("#close_edit_modal_btn").click();
          toast.success("Password changed successfully." , {autoClose: 5000});
          this.setState({passwordChanging: false, showChangePassword: false});
        }).catch(err => {
          this.setState({passwordChanging: false});
          if(err.code === "NotAuthorizedException"){
            toast.error("Incorrect old password." , {autoClose: 5000});
          }else if(err.code === "InvalidPasswordException"){
            toast.error("password must be at least 8 characters long contain a number, an uppercase letter and a symbol." , {autoClose: 5000});
          }else{
            toast.error(err.message , {autoClose: 5000});
          }
      });
    }

    handleUserInputCheckbox(event){};
    
    onModalClose(){
      this.setState({ modalOpen: false });
    }

    onModalOpen = async () => {
      this.setState({ isLoading: true });
      try{
        var response = await this.props.postRequest("/companies/get-list", {});
        if(response.status){
            this.setState({ companiesList: response.result, modalOpen: true, isLoading: false });
        }
        console.log("/companies/get-list=>", response);
      }catch(err){
          console.log("/companies/get-list error=>", err);
          this.setState({ isLoading: false });
      }

    }


    onProjectModalOpen = async () => {
      this.setState({ isLoading: true });
      try{
        var response = await this.props.postRequest("/projects/get-list", {});
        if(response.status){
            this.setState({projectList: response.result, projectModalOpen: true, isLoading: false});
        }
        console.log("/projects/get-list=>", response);
      }catch(err){
          console.log("/projects/get-list error=>", err);
          this.setState({ isLoading: false });
      }

    }

    onProjectModalClose(){
      this.setState({ projectModalOpen: false });
    }
    

    handleUserInputMobileNumber = (event) =>{
         
         this.setState({
            [event.target.name]: event.target.value
         })
    }

    handleDelete = (Guid)=> async event => {
          var result = window.confirm('Are you sure you want to delete?');
          if (result) {
              this.setState({isLoading: true});
              this.onModalClose();
              try{
                var response = await this.props.postRequest("/companies/delete-company", {Guid: Guid});
                if(response.status){
                  toast.success("Success! Record has deleted successfully." , { autoClose: 5000 });
                  this.onModalOpen();
                }else{
                    this.onModalOpen();
                    toast.error(response.error , { autoClose: 5000 });
                    this.setState({ isLoading: false });
                }
              }catch(err){
                this.onModalOpen();
                toast.error(err.message , { autoClose: 5000 });
                this.setState({ isLoading: false });
              }    
          }
    }

    pickFile = () =>{
      document.getElementById("upload_profile").click();
    }

   
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    uploadimage = async (event) =>{
      const file = event.target.files[0];
      const mimeType = file.type;
      //console.log("mimeType=>", mimeType);
      if(mimeType !== "image/png"){
        toast.error("Only png file type allowed.", {autoClose: 5000});
        return false;
      }
      this.setState({ isLoading: true });
      try{
        const base64Data = await this.toBase64(file);
        var response = await this.props.postRequest("/attachments/upload-profile-pic", {base64Data: base64Data});
        console.log("/attachments/upload-profile-pic=>", response);
        toast.success("Profile image Updated.", { autoClose: 5000 });
        this.setState({ isLoading: false, profile_pic: base64Data });
      }catch(err){
        this.setState({ isLoading: false });
        console.log("upload profile image Error=>", err);
        toast.error(err.message, {autoClose: 5000});
      }
  
    }


    //// handle Project Delete ////
    handleProjectDelete = (Guid) => async event => {
        var result = window.confirm('Are you sure you want to delete this item?');
        if (result) {
            this.setState({isLoading: true});
            this.onProjectModalClose();
            try{
                var response = await this.props.postRequest("/projects/delete-project", {Guid: Guid});
                if(response.status){
                  toast.success("Success! Record has deleted successfully." , { autoClose: 5000 });
                  this.onProjectModalOpen();
                }else{
                    this.onProjectModalOpen();
                    toast.error(response.error , { autoClose: 5000 });
                    this.setState({ isLoading: false });
                }
              }catch(err){
                this.onProjectModalOpen();
                toast.error(err.message , { autoClose: 5000 });
                this.setState({ isLoading: false });
              }
        }
    }

    componentDidMount = async () => {
        $(".modal").css('display', 'none');
        try{
          var response = await this.props.postRequest("/attachments/get-profile-pic", {});
          if(response.status){
            this.setState({profile_pic: response.profile_pic});
          }
          console.log("/attachments/get-profile-pic=>", response);
        }catch(err){
          console.log("/attachments/get-profile-pic error=>", err);
        }
        await this.allDigitalForm();
        await this.getRecentActivities();
        await this.getLastThirtyDaysData();

        if(this.props.history.location.state){
          toast.success(this.props.history.location.state.msg, {
            autoClose: 5000
          });
        }
        if (typeof(this.props.location.state) !== "undefined"){
            if(this.props.location.state.modalOpen === true){
                this.onModalOpen();
                this.props.history.push({
                    state: { modalOpen: false }
                  });
                  toast.success("Success! Record has added successfully.", {
                        autoClose: 5000
                    });
            }
            if(this.props.location.state.projectModalOpen === true){
                this.onProjectModalOpen();
                this.props.history.push({
                    state: { projectModalOpen: false }
                  });
                  toast.success("Success! Record has added successfully.", {
                        autoClose: 5000
                    });
            }
            if(this.props.location.state.editAction === true){
                this.onModalOpen();
                this.props.history.push({
                    state: { editAction: false }
                  });
                  toast.success("Success! Record has updated successfully.", {
                    autoClose: 5000
                });
            }
            if(this.props.location.state.editProjectAction === true){
                this.onProjectModalOpen();
                this.props.history.push({
                    state: { editProjectAction: false }
                  });
                  toast.success("Success! Record has updated successfully.", {
                    autoClose: 5000
                });
            }
            if(this.props.location.state.showcompany === true){
                this.onModalOpen();
                this.props.history.push({
                    state: { showcompany: false }
                  });
            }
            if(this.props.location.state.showproject === true){
                this.onProjectModalOpen();
                this.props.history.push({
                    state: { showproject: false }
                  });
            }
        }


            var userName = localStorage.getItem('userName');
            var userEmail = localStorage.getItem('userEmail');
            var userLastLogin = moment(localStorage.getItem('userLastLogin')).format('DD/MM/YYYY');
            var MobileNumber = localStorage.getItem('MobileNumber');
            var countryCode = localStorage.getItem('countryCode');
            $("#userName").html(userName);
            $("#fullName").html(userName);
            $("#email").html(userEmail);
            $("#lastLogin").html(userLastLogin);
            
            this.setState({
                isLoading: false,
                inputValueUserName: userName,
                inputValueUseremail: userEmail,
                inputValueUserMobileNumber: MobileNumber,
                contrydropdown: countryCode? countryCode.replace("+", "") : ""
            });

            $(document).attr("title", "Dashboard");

            $("#menu-toggle").click(function(e) {
                e.preventDefault();
                $("#wrapper").toggleClass("toggled");
                $(".mrg_dashboard_right").css('width', '92%');

            });
            var toggle = true;
            $("#menu-toggle").click(function() {
                if (toggle) {
                    $(".mrg_dashboard_right").css('width', '92%');
                    $('#Group_123').find('image').first().hide(300);
                    $('#wrapper span').hide(300);
                    $(".sidebar-nav li a span").css('display', 'none');

                } else {
                    $('#Group_123').find('image').first().show(300);
                    $(".mrg_dashboard_right").css('width', '83.33%');
                    $(".sidebar-nav li a span").css('display', 'contents');
                    $('#wrapper span').show(300);
                }
                toggle = !toggle;
            });
            $("#menu-toggle_mobile").click(function() {
                $("#mobile_menu_sm").slideToggle();
            });

            function hideDiv() {
                if (window.matchMedia('(max-width: 767px)').matches) {

                    $(".display_none_full_menu").hide();
                    $(".dashboard_new").css('width', '100%');
                } else {
                    $("#mobile_menu_sm").hide();
                    $(".mrg_dashboard_right").css('width', '83.33%');
                    $(".display_none_full_menu").show();
                }
            }
            $("#music").click(function() {
                $("#musicinfo").toggle();
            });

            hideDiv();

            $(window).resize(function() {
                hideDiv();
                $("#menu-toggle_mobile").click(function() {
                    $("#mobile_menu_sm").slideToggle();
                });
            });

            
    }

    getLastThirtyDaysData = ()=>{
       var finalData = [{x:1,y:0},{x:2,y:0},{x:3,y:0},{x:4,y:0},{x:5,y:0},{x:6,y:0},{x:7,y:0},{x:8,y:0},{x:9,y:0},{x:10,y:0},{x:11,y:0},{x:12,y:0},{x:13,y:0},{x:14,y:0},{x:15,y:0},{x:16,y:0},{x:17,y:0},{x:18,y:0},{x:19,y:0},{x:20,y:0},{x:21,y:0},{x:22,y:0},{x:23,y:0},{x:24,y:0},{x:25,y:0},{x:26,y:0},{x:27,y:0},{x:28,y:0},{x:29,y:0},{x:30,y:0}];

       var LatestApproved = this.state.digitalFormsLatestApproved;

       if(localStorage.getItem('userType') === 'SysAdmin' || localStorage.getItem('userType') === 'Admin'){
         for (var i = 0; i < 30; i++) {
          var newDate = new moment().subtract(i, "days");
          LatestApproved.map(digitalForms =>{
            var approvedDate = moment(digitalForms.ApprovedDate);
            var counter = finalData[i].y;
            if(approvedDate.format('YYYY-MM-DD') === newDate.format('YYYY-MM-DD')){
              finalData[i] = {
                  x: i+1,
                  y: counter+1
              }
            }
          });
        }  
       }else if(localStorage.getItem('userType') === 'Operator'){
          for (var i = 0; i < 30; i++) {
            var newDate = new moment().subtract(i, "days");
            //console.log("newDate=>", newDate.format("DD-MM-YYYY"));
            LatestApproved.map(digitalForms =>{
              var approvedDate = moment(digitalForms.ApprovedDate);
              //console.log("newDate=>", newDate.format("DD-MM-YYYY"));
              var counter = finalData[i].y;
              if(approvedDate.format('YYYY-MM-DD') === newDate.format('YYYY-MM-DD')){
                if(localStorage.getItem('Guid') === digitalForms.EmpGuid){
                   finalData[i] = {
                    x: i+1,
                    y: counter+1
                  }
                }
              }
            });
          }
       
   }else if(localStorage.getItem('userType') === 'Operator/Approver'){
      
      for (var i = 0; i < 30; i++) {
        var newDate = new moment().subtract(i, "days");
        //console.log("newDate=>", newDate.format("DD-MM-YYYY"));
        LatestApproved.map(digitalForms =>{
          var approvedDate = moment(digitalForms.ApprovedDate);
          //console.log("newDate=>", newDate.format("DD-MM-YYYY"));
          var counter = finalData[i].y;
          if(approvedDate.format('YYYY-MM-DD') === newDate.format('YYYY-MM-DD')){
            if(localStorage.getItem('Guid') === digitalForms.EmpGuid){
               finalData[i] = {
                x: i+1,
                y: counter+1
              }
            }else{
               digitalForms.ApprovalChain.map(item =>{
                  if(item.userGuid === localStorage.getItem('Guid')){
                    finalData[i] = {
                      x: i+1,
                      y: counter+1
                    }       
                  }
               })
            }
          }
        });
      }

   }else if(localStorage.getItem('userType') === 'Approver'){
      for (var i = 0; i < 30; i++) {
        var newDate = new moment().subtract(i, "days");
        //console.log("newDate=>", newDate.format("DD-MM-YYYY"));
        LatestApproved.map(digitalForms =>{
          var approvedDate = moment(digitalForms.ApprovedDate);
          //console.log("newDate=>", newDate.format("DD-MM-YYYY"));
          var counter = finalData[i].y;
          if(approvedDate.format('YYYY-MM-DD') === newDate.format('YYYY-MM-DD')){
            
               digitalForms.ApprovalChain.map(item =>{
                  if(item.userGuid === localStorage.getItem('Guid')){
                    finalData[i] = {
                      x: i+1,
                      y: counter+1
                    }       
                  }
               })
          }
        });
      }

   } 

      this.setState({
         data:[...this.state.data , ...finalData] 
      })
    }

   
    allDigitalFormAPI = (event) => {
        return this.props.postRequest("/documents/get-list",{});
    }

    getRecentActivities = async () =>{
      var recentAcitivities = [];
      this.state.digitalFormCompleteData.map(document =>{
         document.Activity.map(activity =>{
            if(recentAcitivities.length <= 9){
               recentAcitivities.push(activity);
            }
         })
       })
       await this.setState({
          allRecentActivities: recentAcitivities
       })
    }
      
    allDigitalForm = async() =>{
       this.setState({
          isLoading: true
       });
        await this.allDigitalFormAPI().then(response => {
          if(!response.status){
            return false;
          }

            this.setState({
                digitalFormCompleteData : response.result,

            }, () =>{
                
                let approve=0, approved=0, declined =0,pending =0, draft= 0, all =0;
                var LatestApproved=[];
                var dateFrom = new moment().subtract(30, "days");
                this.state.digitalFormCompleteData.map((val) =>{
                    
                    if(val.ApprovedDate && val.Status === "Approved"){
                      if(moment(val.ApprovedDate) > dateFrom){
                        LatestApproved.push(val);
                      }
                    }

                    if(localStorage.getItem('userType')==='Operator/Approver'){

                      if(val.Status === 'Draft'){
                          if(val.EmpGuid === localStorage.getItem('Guid')){
                              draft++;
                              all++;
                          }
                      }
                      var counted = false;
                      if(val.Status === 'Pending'){
                        
                        if(val.EmpGuid === localStorage.getItem('Guid')){
                          pending++;
                          all++;
                          counted = true;
                          
                        }else{
                          var ApprovalChain = val.ApprovalChain;

                          for (var j = 0; j < ApprovalChain.length; j++) {
                            if(ApprovalChain[j].userGuid === localStorage.getItem('Guid')){
                              pending++;
                              all++;
                              counted = true;
                            }
                          }
                        }
                      }

                      if(val.Status === 'Pending'){
                        var isCurrentApprover = this.isCurrentApprover(val.CurrentApproval);
                        if(isCurrentApprover){
                          approve++;
                          if(!counted){
                            all++;
                          }
                        }
                      }

                      if(val.Status === 'Declined'){
                        
                        if(val.EmpGuid === localStorage.getItem('Guid')){
                          declined++;
                          all++;

                        }else{
                          var ApprovalChain = val.ApprovalChain;

                          for (var j = 0; j < ApprovalChain.length; j++) {
                            if(ApprovalChain[j].userGuid === localStorage.getItem('Guid')){
                              declined++;
                              all++;
                            }
                          }
                        }

                      }

                      if(val.Status === 'Approved'){
                        
                        if(val.EmpGuid === localStorage.getItem('Guid')){
                          approved++;
                          all++;

                        }else{
                          var ApprovalChain = val.ApprovalChain;

                          for (var j = 0; j < ApprovalChain.length; j++) {
                            if(ApprovalChain[j].userGuid === localStorage.getItem('Guid')){
                              approved++;
                              all++;
                            }
                          }
                        }

                      }


                    } //End Operator/Approver

                    if(localStorage.getItem('userType')==='Approver'){
                      
                      var counted = false;
                      if(val.Status === 'Pending'){
                        
                        var ApprovalChain = val.ApprovalChain;

                        for (var j = 0; j < ApprovalChain.length; j++) {
                          if(ApprovalChain[j].userGuid === localStorage.getItem('Guid')){
                            pending++;
                            all++;
                            counted = true;
                          }
                        }
                      }

                      if(val.Status === 'Pending'){
                        var isCurrentApprover = this.isCurrentApprover(val.CurrentApproval);
                        if(isCurrentApprover){
                          approve++;
                          if(!counted){
                            all++;
                          }
                        }
                      }

                      if(val.Status === 'Declined'){
                        
                        var ApprovalChain = val.ApprovalChain;

                        for (var j = 0; j < ApprovalChain.length; j++) {
                          if(ApprovalChain[j].userGuid === localStorage.getItem('Guid')){
                            declined++;
                            all++;
                          }
                        }

                      }

                      if(val.Status === 'Approved'){
                        
                        var ApprovalChain = val.ApprovalChain;

                        for (var j = 0; j < ApprovalChain.length; j++) {
                          if(ApprovalChain[j].userGuid === localStorage.getItem('Guid')){
                            approved++;
                            all++;
                          }
                        }

                      }

                    } //End Approver

                    if(localStorage.getItem('userType')==='Operator'){
                      
                      if(val.Status === 'Draft'){
                          if(val.EmpGuid === localStorage.getItem('Guid')){
                              draft++;
                              all++;
                          }
                      }

                      if(val.Status === 'Pending'){
                          if(val.EmpGuid === localStorage.getItem('Guid')){
                              pending++;
                              all++;
                          }
                      }
                        
                      if(val.Status === 'Declined'){
                        
                        if(val.EmpGuid === localStorage.getItem('Guid')){
                              declined++;
                              all++;
                        }
                      
                      }

                      if(val.Status === 'Approved'){
                          if(val.EmpGuid === localStorage.getItem('Guid')){
                            approved++;
                            all++;
                          }
                      }

                    } //End Operator

                    if(localStorage.getItem('userType')==='SysAdmin' || localStorage.getItem('userType')==='Admin'){ 
                        
                        if(val.Status === 'Draft'){
                          draft++;
                          all++;
                        }

                        if(val.Status === 'Pending'){
                          pending++;
                          all++;
                        }
                        
                        if(val.Status === 'Pending'){
                          approve++;
                        }
                        
                        if(val.Status === 'Declined'){
                          declined++;
                          all++;
                        }

                        if(val.Status === 'Approved'){
                          approved++;
                          all++;
                        }
                        
                    } //End SysAdmin and Admin
                })
               
               this.setState({
                    Approve : approve,
                    Declined : declined,
                    Approved: approved,
                    Draft: draft,
                    Pending : pending,
                    All: all,
                    digitalFormsLatestApproved: LatestApproved
                });
                
                this.setState({
                  series: [{
                    name: 'Total',
                    data: [approved, draft, pending, approve, declined]
                  }]
                })
            })
        })
    }

    handleCustomFieldsModalInputsCountry(event) {
      let name = event.target.name;
      let value = event.target.value;
      this.setState({
              inputValueUserMobileNumber: "+" + value,
              CountryCode : "+"+value,
              [name]: event.target.value
      })
   }


    isCurrentApprover(CurrentApprovel){
        var CurrentApprover = false;
        for (var i=0; i<CurrentApprovel.length; i++) {
          if(localStorage.getItem('Guid') === CurrentApprovel[i].userGuid) {
            return true;
          }
        }
        return CurrentApprover;
    }

  handleProfileImageError = async event => {
    this.setState({profile_pic: default_profile_pic});
  }

  render() {
   const {activePoint} = this.state;
      this.childPropsListItem={
      handleDelete: this.handleDelete
      };
     return (
        <div className="Dashboard">
            <Header pageHeading="" hideHomeButton="1" showMobileMenu="1" isLoading={this.state.isLoading} props={this.props}/>

            <div className="clearfix"></div>
            <div className="col-sm-12 p0 ">
               <div className="col-sm-2 col-md-3 p0">
                  <div id="wrapper" className="display_none_full_menu">
                     <div id="sidebar-wrapper">
                        <ul className="sidebar-nav">
                           <li className="sidebar-brand">
                              <span className="navi_hdg"> Navigation</span>
                              <a href={null} className="menu_toggle_mrg CursorPointer" id="menu-toggle">
                              <img src={menutoggleimg} width="30" height="18" alt="" />
                              </a>
                           </li>
                           <li className="sidebar_active">
                              <LinkContainer to="/dashboard">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 759 13.333 11.333">
                                       <path id="ic_home_24px" className="cls-1" d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z" transform="translate(-5380 756)" />
                                    </svg>
                                    <span> Home </span>
                                 </a>
                              </LinkContainer>
                           </li>
                           <li>
                              <LinkContainer to="/documents">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 23.158">
                                       <path id="ic_content_copy_24px" className="cls-1" d="M16.737,1H4.105A2.111,2.111,0,0,0,2,3.105V17.842H4.105V3.105H16.737Zm3.158,4.211H8.316A2.111,2.111,0,0,0,6.211,7.316V22.053a2.111,2.111,0,0,0,2.105,2.105H19.895A2.111,2.111,0,0,0,22,22.053V7.316A2.111,2.111,0,0,0,19.895,5.211Zm0,16.842H8.316V7.316H19.895Z" transform="translate(-2 -1)"/>
                                    </svg>
                                    <span> Documents </span>
                                 </a>
                              </LinkContainer>
                           </li>
                           <li>
                              <LinkContainer to="/report">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22.222">
                                       <path id="ic_receipt_24px" className="cls-1" d="M19.667,18.667H6.333V16.444H19.667Zm0-4.444H6.333V12H19.667Zm0-4.444H6.333V7.556H19.667ZM3,24.222l1.667-1.667,1.667,1.667L8,22.556l1.667,1.667,1.667-1.667L13,24.222l1.667-1.667,1.667,1.667L18,22.556l1.667,1.667,1.667-1.667L23,24.222V2L21.333,3.667,19.667,2,18,3.667,16.333,2,14.667,3.667,13,2,11.333,3.667,9.667,2,8,3.667,6.333,2,4.667,3.667,3,2Z" transform="translate(-3 -2)"/>
                                    </svg>
                                    <span> Report</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           <li>
                                 <a href={null}>
                                 <strong> Admin </strong> </a>
                           </li>
                           {(this.state.userTypeForRoleManagement !== 'Admin' && this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver' ? 
                           <li>
                              <LinkContainer to="/form-template">
                                 <a href={null} className="CursorPointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                       <path id="ic_description_24px" className="cls-1" d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z" transform="translate(-4 -2)"/>
                                    </svg>
                                    <span> Form Templates</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/form-packs">
                                 <a href={null} className="CursorPointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
                                       <path id="ic_folder_24px" className="cls-1" d="M10,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V8a2.006,2.006,0,0,0-2-2H12Z" transform="translate(-2 -4)"/>
                                    </svg>
                                    <span> Form Packs</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                            <a href={null} className="CursorPointer" data-toggle="modal" onClick={(event) => this.onModalOpen()}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18">
                                   <path id="ic_business_24px" className="cls-1" d="M12,7V3H2V21H22V7ZM6,19H4V17H6Zm0-4H4V13H6Zm0-4H4V9H6ZM6,7H4V5H6Zm4,12H8V17h2Zm0-4H8V13h2Zm0-4H8V9h2Zm0-4H8V5h2ZM20,19H12V17h2V15H12V13h2V11H12V9h8Zm-2-8H16v2h2Zm0,4H16v2h2Z" transform="translate(-2 -3)"/>
                                </svg>
                                <span> Company</span>
                            </a>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <a className="CursorPointer" href={null}  data-toggle="modal" onClick={(event) => this.onProjectModalOpen()}>
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 8.967">
                                    <path id="ic_all_inclusive_24px" className="cls-1" d="M15.5,6.62a4.443,4.443,0,0,0-3.142,1.275L10,9.987,8.733,11.1h.008L6.5,13.1a2.822,2.822,0,1,1-2-4.808,2.833,2.833,0,0,1,2.033.858l.942.833L8.733,8.862l-1.05-.925a4.483,4.483,0,1,0-.042,6.375L10,12.228l.008.008L11.267,11.1h-.008L13.5,9.112a2.822,2.822,0,1,1,2,4.808,2.847,2.847,0,0,1-2.033-.858l-.95-.842-1.258,1.117,1.058.933A4.491,4.491,0,1,0,15.5,6.62Z" transform="translate(0 -6.62)"/>
                                 </svg>
                                 <span> Project</span>
                              </a>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/users">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                       <path id="ic_person_24px" className="cls-1" d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z" transform="translate(-4 -4)"/>
                                    </svg>
                                    <span> Users</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')
                           }
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/approval-setup">
                                 <a href={null} className="CursorPointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14">
                                       <path id="ic_supervisor_account_24px" className="cls-1" d="M16.5,12A2.5,2.5,0,1,0,14,9.5,2.492,2.492,0,0,0,16.5,12ZM9,11A3,3,0,1,0,6,8,2.987,2.987,0,0,0,9,11Zm7.5,3c-1.83,0-5.5.92-5.5,2.75V19H22V16.75C22,14.92,18.33,14,16.5,14ZM9,13c-2.33,0-7,1.17-7,3.5V19H9V16.75a3.941,3.941,0,0,1,2.37-3.47A12.283,12.283,0,0,0,9,13Z" transform="translate(-2 -5)"/>
                                    </svg>
                                    <span> Approval Setup</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                           <li>
                              <LinkContainer to="/email-templates">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
                                       <path id="ic_markunread_24px" className="cls-1" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z" transform="translate(-2 -4)"/>
                                    </svg>
                                    <span> Email Templates</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/custom-fields">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21.818">
                                       <path id="ic_pan_tool_24px" className="cls-1" d="M21,5V18.182a3.647,3.647,0,0,1-3.636,3.636H10.727a3.63,3.63,0,0,1-2.591-1.082L1,13.482s1.145-1.118,1.182-1.136a1.077,1.077,0,0,1,.718-.264,1.111,1.111,0,0,1,.545.145c.036.009,3.918,2.236,3.918,2.236V3.636a1.364,1.364,0,1,1,2.727,0V10H11V1.364a1.364,1.364,0,0,1,2.727,0V10h.909V2.273a1.364,1.364,0,0,1,2.727,0V10h.909V5A1.364,1.364,0,0,1,21,5Z" transform="translate(-1)"/>
                                    </svg>
                                    <span> Custom Fields</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/custom-fields-list">
                                 <a href="{null}">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 13.333">
                                       <path id="ic_sort_24px" className="cls-1" d="M3,19.333H9.667V17.111H3ZM3,6V8.222H23V6Zm0,7.778H16.333V11.556H3Z" transform="translate(-3 -6)"/>
                                    </svg>
                                    <span> Custom List Fields</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                        </ul>
                     </div>
                  </div>
                  <div id="mobile_menu_sm" className="displayNoneimp">
                     <div id="sidebar-wrapper">
                        <ul className="sidebar-nav">
                           <li className="sidebar_active">
                              <LinkContainer to="/dashboard">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 759 13.333 11.333">
                                       <path id="ic_home_24px" className="cls-1" d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z" transform="translate(-5380 756)" />
                                    </svg>
                                    <span> Home </span>
                                 </a>
                              </LinkContainer>
                           </li>
                           <li>
                              <LinkContainer to="/documents">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 806 13.333 10.909">
                                       <defs>
                                       </defs>
                                       <path id="ic_picture_in_picture_alt_24px" className="cls-1" d="M11.909,7.848H7.061v3.636h4.848ZM14.333,12.7V4.2A1.205,1.205,0,0,0,13.121,3H2.212A1.205,1.205,0,0,0,1,4.2v8.5a1.216,1.216,0,0,0,1.212,1.212H13.121A1.216,1.216,0,0,0,14.333,12.7Zm-1.212.012H2.212V4.194H13.121Z" transform="translate(-5379 803)" />
                                    </svg>
                                    <span> Documents </span>
                                 </a>
                              </LinkContainer>
                           </li>
                           <li>
                              <LinkContainer to="/report">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                       <defs>
                                       </defs>
                                       <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Report</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           <li>
                                 <a href={null}>
                                 <strong> Admin </strong> </a>
                           </li>
                            {this.state.userTypeForRoleManagement !== 'Admin' && this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver' ? 
                           <li>
                              <LinkContainer to="/form-template">
                                 <a href={null} className="CursorPointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 945 13.333 13.707">
                                       <defs>
                                       </defs>
                                       <path id="ic_settings_24px" className="cls-1" d="M14.031,9.525a4.726,4.726,0,0,0,0-1.343l1.446-1.131a.346.346,0,0,0,.082-.439L14.189,4.241a.344.344,0,0,0-.418-.151l-1.707.685A5.007,5.007,0,0,0,10.906,4.1l-.26-1.816A.334.334,0,0,0,10.31,2H7.568a.334.334,0,0,0-.336.288L6.972,4.1a5.266,5.266,0,0,0-1.158.672L4.107,4.09a.334.334,0,0,0-.418.151L2.318,6.613a.338.338,0,0,0,.082.439L3.847,8.182a5.435,5.435,0,0,0-.048.672,5.435,5.435,0,0,0,.048.672L2.4,10.656a.346.346,0,0,0-.082.439l1.371,2.371a.344.344,0,0,0,.418.151l1.707-.685a5.007,5.007,0,0,0,1.158.672l.26,1.816a.334.334,0,0,0,.336.288H10.31a.334.334,0,0,0,.336-.288l.26-1.816a5.266,5.266,0,0,0,1.158-.672l1.707.685a.334.334,0,0,0,.418-.151l1.371-2.371a.346.346,0,0,0-.082-.439ZM8.939,11.252a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,8.939,11.252Z" transform="translate(-5380.271 943)" />
                                    </svg>
                                    <span> Form Templates</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :''}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/form-packs">
                                 <a href={null} className="CursorPointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4935 7662 20 20">
                                       <defs>
                                       </defs>
                                       <path id="ic_mode_edit_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166Z" transform="translate(-4938 7659.002)" />
                                    </svg>
                                    <span> Form Packs</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                                 <a href={null} className="CursorPointer" data-toggle="modal" onClick={(event) => this.onModalOpen()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10829 7335 20 20">
                                       <defs>
                                       </defs>
                                       <path id="ic_info_outline_24px" className="cls-1" d="M11,17h2V11H11ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM11,9h2V7H11Z" transform="translate(-10831 7333)" />
                                    </svg>
                                    <span> Company</span>
                                 </a>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>

                                 <a href={null} className="CursorPointer" onClick={(event) => this.onProjectModalOpen()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                       <defs>
                                       </defs>
                                       <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Project</span>
                                 </a>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/users">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                       <path id="ic_person_24px" className="cls-1" d="M14,14A5,5,0,1,0,9,9,5,5,0,0,0,14,14Zm0,2.5c-3.337,0-10,1.675-10,5V24H24V21.5C24,18.175,17.337,16.5,14,16.5Z" transform="translate(-4 -4)"/>
                                    </svg>
                                    <span> Users</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/approval-setup">
                                 <a href={null} className="CursorPointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                       <defs>
                                       </defs>
                                       <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Approval Setup</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                           <li>
                              <LinkContainer to="/email-templates">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                       <defs>
                                       </defs>
                                       <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Email Templates</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/custom-fields">
                                 <a href={null}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                       <defs>
                                       </defs>
                                       <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Custom Fields</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                           {(this.state.userTypeForRoleManagement !== 'Approver' && this.state.userTypeForRoleManagement !== 'Operator' && this.state.userTypeForRoleManagement !== 'Operator/Approver'?
                           <li>
                              <LinkContainer to="/custom-fields-list">
                                 <a href="{null}">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                                       <defs>
                                       </defs>
                                       <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                                    </svg>
                                    <span> Custom List Fields</span>
                                 </a>
                              </LinkContainer>
                           </li>
                           :'')}
                        </ul>
                     </div>
                  </div>
                  <div className="clearfix"></div>
               </div>
               <div className="col-xs-12  col-sm-9 col-md-10 pull-right mrg_dashboard_right dashboard_new">
                  <div className="clear30"></div>
                  <div className="col-sm-12 col-md-12">
                     <div className="col-sm-12 col-md-12 p0">
                        <div className="welcome_box">
                           <div className="col-xs-12 col-sm-12 col-md-5 dashboard_welcomebox">
                              <div className="col-xs-12 col-sm-4 p0">
                                 <div className="col-sm-12 p0 text-center">
                                 <img style={{width: '80px',height:'80px'}} src={this.state.profile_pic} className="profile_img" alt="" onError={this.handleProfileImageError} />
                                 </div>
                                 <div className="clear20"></div>
                                 <div className="col-sm-12 p0 text-center">
                                    <a href={null} className="btn_edit_profile CursorPointer" data-toggle="modal" data-target="#mymodal_edit_profile">Edit Profile</a>
                                 </div>
                                 <div className="clear20"></div>
                              </div>
                              <div className="col-xs-12 col-sm-8 dahsboard_gray_box_label">
                              <h2> Welcome, <b id="fullName"></b></h2>
                                 UserName: <b id="userName"></b><br />
                                 Email: <b id="email"></b><br />
                                 Last Login: <b id="lastLogin"></b>
                              </div>
                           </div>
                           <div className="col-xs-12 col-sm-12 col-md-7 draft_right_area">
                              <div className="col-xs-12 col-sm-12 p0 text-center">
                                 <div className="clear20"></div>
                                 {localStorage.getItem('userType')==='Approver'?'':
                                <LinkContainer to="/insert-document" >
                                 <a href={null} className="btn_document_Dash">
                                    <svg id="svgPlus" xmlns="http://www.w3.org/2000/svg" viewBox="-7104 7346 15 15">
                                       <path id="ic_add_24px" className="cls-1" d="M20,13.571H13.571V20H11.429V13.571H5V11.429h6.429V5h2.143v6.429H20Z" transform="translate(-7109 7341)"/>
                                    </svg>
                                    Insert Document
                                 </a>
                                </LinkContainer>
                              }
                              </div>
                              <div className="clear30"></div>
                              <div className="col-xs-6 col-sm-2 draft_label_dash draft_main_h">
                                 <div className="col-sm-12 p0"><img src={draft_icon} alt="" /><span> Draft</span></div>
                                 <h3>{this.state.Draft}</h3>
                              </div>
                              <div className="col-xs-6 col-sm-2 aproved_label_dash draft_main_h">
                                 <div className="col-sm-12 p0"><img src={aproved} alt="" /><span> Approve</span></div>
                                 <h3>{this.state.Approve}</h3>
                              </div>
                              <div className="col-xs-6 col-sm-2 pending_label_dash draft_main_h">
                                 <div className="col-sm-12 p0"><img src={pending} alt="" /><span> Pending</span></div>
                                 <h3>{this.state.Pending}</h3>
                              </div>
                              <div className="col-xs-6 col-sm-2 declined_label_dash draft_main_h">
                                 <div className="col-sm-12 p0"><img src={decline} alt="" /><span> Declined</span></div>
                                 <h3>{this.state.Declined}</h3>
                              </div>
                              <div className="col-xs-6 col-sm-2 amproved_label_dash draft_main_h">
                                 <div className="col-sm-12 p0"><img src={approved} alt="" /><span> Approved</span></div>
                                 <h3>{this.state.Approved}</h3>
                              </div>
                              <div className="col-xs-6 col-sm-2 all_label_dash draft_main_h">
                                 <div className="col-sm-12 p0"><img src={all_icon} alt="" /><span> All</span></div>
                                 <h3>{this.state.All}</h3>
                              </div>
                           </div>
                           <div className="clearfix"></div>
                        </div>
                     </div>
                     <div className="clear20"></div>
                     <div className="col-sm-6 p0 news_feedbox_box">
                        <div className="news_feedbox2">
                           <div className="news_heading_bg">In Queue</div>
                           
                           {/* <img src={img_queue} className="img-responsive img_wuesu_w" alt="" /> */}
                           <Chart options={this.state.options} series={this.state.series} type="bar" />
                           
                           <div className="clearfix"></div>
                        </div>
                     </div>
                     <div className="col-sm-6 p0">
                        <div className="news_feedbox2">
                           <div className="recent_activity">RECENT ACTIVITY</div>
                           <div className="clearfix"></div>
                           <div style={{maxHeight: '293px', overflowY: 'auto'}}>
                           {(this.state.allRecentActivities.map(activity =>{
                              return(
                                 <div className="col-sm-12 p0 revent_mrg_bot">
                              <div className="img_recent_act">
                                 <img src={(activity.profile_pic_url === 'undefined') ? default_profile_pic :activity.profile_pic_url  } alt="" />
                              </div>
                              <div className="recent_act_mid">
                                 <h2> {activity.User} </h2>
                                 {activity.Description}
                              </div>
                              <div className="recent_act_right"> {new Date(activity.Timestamp * 1000).toDateString()} </div>
                           </div>
                              )   
                           }))}
                           </div>
                           <div className="clearfix"></div>
                        </div>
                     </div>
                     <div className="clear20"></div>
                     <div className="col-sm-12 p0">
                        <div className="news_feedbox">
                           <div className="news_heading_bg">Approved Last 30 days</div>
                           <div className="clear20"></div>
                           {/* <img src={amproved_bg} className="img-responsive" alt="" /> */}
                           <LineChart
            data={this.state.data}
            pointsOnHover={this.handlePointHover}
            areaColor="#03599A"
  areaVisible={true}
  axisColor="#34495e"
  axisOpacity={0.3}
  axisVisible={true}
  axisWidth={1}
  gridColor="#34495e"
  gridOpacity={0.0}
  gridVisible={true}
  gridWidth={1}
  labelsColor="#bdc3c7"
  labelsCountY={5}
  labelsStepX={2}
  labelsVisible={true}
  pathColor="#03599A"
  pathVisible={true}
  pointsColor="#fff"
  pointsRadius={4}
  pointsStrokeColor="#03599A"
  pointsStrokeWidth={2}
  pointsVisible={true}
  viewBoxHeight={300}
  viewBoxWidth={800}
            />
                           <div className="clearfix"></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="clear40"></div>
               <div className="clearfix"></div>
            </div>
            <div className="clear40"></div>
            <div>
               <ul id="responsive_menu">
                  <li className="responsive_menu_active">
                     <LinkContainer to="/dashboard">
                        <a href={null}>
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 759 13.333 11.333">
                              <path id="ic_home_24px" className="cls-1" d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z" transform="translate(-5380 756)" />
                           </svg>
                           <span> Home </span>
                        </a>
                     </LinkContainer>
                  </li>
                  <li>
                     <a href="timecard.html">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 806 13.333 10.909">
                           <defs>
                           </defs>
                           <path id="ic_picture_in_picture_alt_24px" className="cls-1" d="M11.909,7.848H7.061v3.636h4.848ZM14.333,12.7V4.2A1.205,1.205,0,0,0,13.121,3H2.212A1.205,1.205,0,0,0,1,4.2v8.5a1.216,1.216,0,0,0,1.212,1.212H13.121A1.216,1.216,0,0,0,14.333,12.7Zm-1.212.012H2.212V4.194H13.121Z" transform="translate(-5379 803)" />
                        </svg>
                        <span> TimeCard</span>
                     </a>
                  </li>
                  <li>
                     <LinkContainer to="/company">
                        <a href={null} className="CursorPointer">
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 850 13.333 14.074">
                              <defs>
                              </defs>
                              <path id="ic_location_city_24px" className="cls-1" d="M11.889,8.667V4.222L9.667,2,7.444,4.222V5.7H3v10.37H16.333V8.667ZM5.963,14.593H4.481V13.111H5.963Zm0-2.963H4.481V10.148H5.963Zm0-2.963H4.481V7.185H5.963Zm4.444,5.926H8.926V13.111h1.481Zm0-2.963H8.926V10.148h1.481Zm0-2.963H8.926V7.185h1.481Zm0-2.963H8.926V4.222h1.481Zm4.444,8.889H13.37V13.111h1.481Zm0-2.963H13.37V10.148h1.481Z" transform="translate(-5381 848)" />
                           </svg>
                           <span> Company</span>
                        </a>
                     </LinkContainer>
                  </li>
                  <li>
                     <a href="document.html">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5377.333 896 12 15">
                           <defs>
                           </defs>
                           <path id="ic_description_24px" className="cls-1" d="M11.5,2h-6A1.5,1.5,0,0,0,4.008,3.5L4,15.5A1.5,1.5,0,0,0,5.492,17H14.5A1.5,1.5,0,0,0,16,15.5v-9ZM13,14H7V12.5h6Zm0-3H7V9.5h6ZM10.75,7.25V3.125L14.875,7.25Z" transform="translate(-5381.333 894)" />
                        </svg>
                        <span>Document </span>
                     </a>
                  </li>
                  <li>
                     <a href="setting.html">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 945 13.333 13.707">
                           <defs>
                           </defs>
                           <path id="ic_settings_24px" className="cls-1" d="M14.031,9.525a4.726,4.726,0,0,0,0-1.343l1.446-1.131a.346.346,0,0,0,.082-.439L14.189,4.241a.344.344,0,0,0-.418-.151l-1.707.685A5.007,5.007,0,0,0,10.906,4.1l-.26-1.816A.334.334,0,0,0,10.31,2H7.568a.334.334,0,0,0-.336.288L6.972,4.1a5.266,5.266,0,0,0-1.158.672L4.107,4.09a.334.334,0,0,0-.418.151L2.318,6.613a.338.338,0,0,0,.082.439L3.847,8.182a5.435,5.435,0,0,0-.048.672,5.435,5.435,0,0,0,.048.672L2.4,10.656a.346.346,0,0,0-.082.439l1.371,2.371a.344.344,0,0,0,.418.151l1.707-.685a5.007,5.007,0,0,0,1.158.672l.26,1.816a.334.334,0,0,0,.336.288H10.31a.334.334,0,0,0,.336-.288l.26-1.816a5.266,5.266,0,0,0,1.158-.672l1.707.685a.334.334,0,0,0,.418-.151l1.371-2.371a.346.346,0,0,0-.082-.439ZM8.939,11.252a2.4,2.4,0,1,1,2.4-2.4A2.4,2.4,0,0,1,8.939,11.252Z" transform="translate(-5380.271 943)" />
                        </svg>
                        <span> Setting</span>
                     </a>
                  </li>
                  <li>
                     <a href="sign_setting.html">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4935 7662 20 20">
                           <defs>
                           </defs>
                           <path id="ic_mode_edit_24px" className="cls-1" d="M3,18.831V23H7.166L19.453,10.71,15.287,6.544ZM22.675,7.489a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L16.476,5.356l4.166,4.166Z" transform="translate(-4938 7659.002)" />
                        </svg>
                        <span> Signature Setting</span>
                     </a>
                  </li>
                  <li>
                     <a href="help.html">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10829 7335 20 20">
                           <defs>
                           </defs>
                           <path id="ic_info_outline_24px" className="cls-1" d="M11,17h2V11H11ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM11,9h2V7H11Z" transform="translate(-10831 7333)" />
                        </svg>
                        <span> Help</span>
                     </a>
                  </li>
                  <li>
                     <a href="login.html">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5377.499 1091.267 13.573 11.709">
                           <defs>
                           </defs>
                           <path id="ic_keyboard_tab_24px" className="cls-1" d="M6.717,1.284l2.253,3.4-9,.078-.011,1.9,9-.078-2.3,3.439.894,1.335L11.4,5.614,7.62-.066ZM12.065-.105,12,11.322l1.27-.011L13.335-.116Z" transform="matrix(-1, 0.017, -0.017, -1, -5363.967, 1102.627)" />
                        </svg>
                        <span> Logout</span>
                     </a>
                  </li>
               </ul>
            </div>
            <div id="mymodal_edit_profile" className="modal fade" role="dialog">
              {this.state.passwordChanging ? <div className="se-pre-con"></div> : ''}
               <div className="modal-dialog modal_w_dashedit">

               <div className="modal-content" id="change_password" style={{display:this.state.change_password_view? "block" : "none"}}>
                  <div className="modal-header">
                    <button type="button" className="close OutlineNone" data-dismiss="modal" id="close_edit_modal_btn"><img src={ic_clear_24px} className="mt22" width="18" height="18" alt="" /></button>
                    <h4 className="modal-title modal_customefield">Change Password</h4>
                  </div>
                  <div className="modal-body label_modal_cp">
                    <div className="col-sm-12">
                     
                        <div className="col-sm-12 mrg_bot_custom10">
                          <div className="col-sm-4 col-md-4 label_csutom_pop">Old Password:</div>
                          <div className="col-sm-8 col-md-8">
                            <input onChange={(event) => this.handleUserInput(event)} value={(this.state.old_password)} name="old_password" type="password" placeholder="Old Password"/>
                          </div>
                        </div>

                        <div className="col-sm-12 mrg_bot_custom10">
                          <div className="col-sm-4 col-md-4 label_csutom_pop">New Password:</div>
                          <div className="col-sm-8 col-md-8">
                            <input onChange={(event) => this.handleUserInput(event)} value={(this.state.new_password)} name="new_password" type="password" placeholder="New Password"/>
                          </div>
                        </div>

                        <div className="col-sm-12 mrg_bot_custom10">
                          <div className="col-sm-4 col-md-4 label_csutom_pop">Confirm Password:</div>
                          <div className="col-sm-8 col-md-8">
                            <input onChange={(event) => this.handleUserInput(event)} value={(this.state.confirm_password)} name="confirm_password" type="password" placeholder="Confirm Password"/>
                          </div>
                        </div>

                        <div className="clear20"></div>
                        <div className="col-sm-12">
                          <div className="btn_cance_save">
                            <button type="button" className="btn_save_pro"  onClick={(event) => this.changePassword()}>Save</button>
                            <button type="button" className="btn_cancel_pro" onClick={(event) => this.showChangePassword()}>Cancel</button>
                          </div>
                        </div>
                        <div className="clear20"></div>

                      </div>
                    
                  </div>
                </div>


                  <div className="modal-content"  style={{display: !this.state.change_password_view? "block" : "none"}}>
                     <div className="modal-header">
                        <button type="button" className="close OutlineNone" data-dismiss="modal"><img src={ic_clear_24px} className="mt22" width="18" height="18" alt="" /></button>
                        <h4 className="modal-title modal_customefield">Edit Profile</h4>
                     </div>
                     <div className="modal-body p0 label_modal_custom">
                        <div className="col-sm-4 p0">
                           <div className="dash_proifle_edit_pop">
                              <div className="user_edit_white_box">
                                 <img style={{height:'100px'}} src={this.state.profile_pic} alt=""  onError={this.handleProfileImageError} />
                              </div>
                              <div className="clear20"></div>
                              <div className="col-sm-12 text-center"> <a href={null} className="btn_edit_avator_dash" onClick={this.pickFile}>Edit Avatar</a>
                              <input type='file' name='upload_profile' id='upload_profile' className='display_hidden' onChange={this.uploadimage} accept='image/*' />
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-8">
                           <div className="edit_dash_pop_rightarea">
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">User Name:</div>
                                 <div className="col-sm-8 col-md-8">
                                    <input onChange={(event) => this.handleUserInput(event)} value={(this.state.inputValueUserName ?this.state.inputValueUserName:'')} name="inputValueUserName" type="text" placeholder="TPHASMINX1"/>
                                 </div>
                              </div>
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">Email Address:</div>
                                 <div className="col-sm-8 col-md-8">
                                    <input onChange={(event) => this.handleUserInput(event)} value={(this.state.inputValueUseremail?this.state.inputValueUseremail:'')} name="inputValueUseremail" type="text" placeholder="exampleAdmin@tphglobal.com"/>
                                 </div>
                              </div>
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">Country:</div>
                                 <div className="col-sm-8 col-md-8">
                                 <select className="form-control pro_input_pop" name="contrydropdown" value={this.state.contrydropdown} onChange={(event) =>
                                    this.handleCustomFieldsModalInputsCountry(event)}>
                                    <option value="">Country</option>
                                    <option value="61">Australia (+61)</option>
                                    <option value="44">UK (+44)</option>
                                    <option value="1">USA (+1)</option>
                                    <option value="213">Algeria (+213)</option>
                                    <option value="379">Andorra (+376)</option>
                                    <option value="244">Angola (+244)</option>
                                    <option value="1264">Anguilla (+1264)</option>
                                    <option value="1268">Antigua &amp;amp; Barbuda (+1268)</option>
                                    <option value="54">Argentina (+54)</option>
                                    <option value="374">Armenia (+374)</option>
                                    <option value="297">Aruba (+297)</option>
                                    <option value="43">Austria (+43)</option>
                                    <option value="994">Azerbaijan (+994)</option>
                                    <option value="1242">Bahamas (+1242)</option>
                                    <option value="973">Bahrain (+973)</option>
                                    <option value="880">Bangladesh (+880)</option>
                                    <option value="1246">Barbados (+1246)</option>
                                    <option value="375">Belarus (+375)</option>
                                    <option value="32">Belgium (+32)</option>
                                    <option value="501">Belize (+501)</option>
                                    <option value="229">Benin (+229)</option>
                                    <option value="1441">Bermuda (+1441)</option>
                                    <option value="975">Bhutan (+975)</option>
                                    <option value="591">Bolivia (+591)</option>
                                    <option value="387">Bosnia Herzegovina (+387)</option>
                                    <option value="267">Botswana (+267)</option>
                                    <option value="55">Brazil (+55)</option>
                                    <option value="673">Brunei (+673)</option>
                                    <option value="359">Bulgaria (+359)</option>
                                    <option value="226">Burkina Faso (+226)</option>
                                    <option value="257">Burundi (+257)</option>
                                    <option value="855">Cambodia (+855)</option>
                                    <option value="237">Cameroon (+237)</option>
                                    <option value="1">Canada (+1)</option>
                                    <option value="238">Cape Verde Islands (+238)</option>
                                    <option value="1345">Cayman Islands (+1345)</option>
                                    <option value="236">Central African Republic (+236)</option>
                                    <option value="56">Chile (+56)</option>
                                    <option value="86">China (+86)</option>
                                    <option value="57">Colombia (+57)</option>
                                    <option value="269">Comoros (+269)</option>
                                    <option value="242">Congo (+242)</option>
                                    <option value="682">Cook Islands (+682)</option>
                                    <option value="506">Costa Rica (+506)</option>
                                    <option value="385">Croatia (+385)</option>
                                    <option value="53">Cuba (+53)</option>
                                    <option value="90392">Cyprus North (+90392)</option>
                                    <option value="357">Cyprus South (+357)</option>
                                    <option value="42">Czech Republic (+42)</option>
                                    <option value="45">Denmark (+45)</option>
                                    <option value="253">Djibouti (+253)</option>
                                    <option value="1809">Dominica (+1809)</option>
                                    <option value="1809">Dominican Republic (+1809)</option>
                                    <option value="593">Ecuador (+593)</option>
                                    <option value="20">Egypt (+20)</option>
                                    <option value="503">El Salvador (+503)</option>
                                    <option value="240">Equatorial Guinea (+240)</option>
                                    <option value="291">Eritrea (+291)</option>
                                    <option value="372">Estonia (+372)</option>
                                    <option value="251">Ethiopia (+251)</option>
                                    <option value="500">Falkland Islands (+500)</option>
                                    <option value="298">Faroe Islands (+298)</option>
                                    <option value="679">Fiji (+679)</option>
                                    <option value="358">Finland (+358)</option>
                                    <option value="33">France (+33)</option>
                                    <option value="594">French Guiana (+594)</option>
                                    <option value="689">French Polynesia (+689)</option>
                                    <option value="241">Gabon (+241)</option>
                                    <option value="220">Gambia (+220)</option>
                                    <option value="7880">Georgia (+7880)</option>
                                    <option value="49">Germany (+49)</option>
                                    <option value="233">Ghana (+233)</option>
                                    <option value="350">Gibraltar (+350)</option>
                                    <option value="30">Greece (+30)</option>
                                    <option value="299">Greenland (+299)</option>
                                    <option value="1473">Grenada (+1473)</option>
                                    <option value="590">Guadeloupe (+590)</option>
                                    <option value="671">Guam (+671)</option>
                                    <option value="502">Guatemala (+502)</option>
                                    <option value="224">Guinea (+224)</option>
                                    <option value="245">Guinea - Bissau (+245)</option>
                                    <option value="592">Guyana (+592)</option>
                                    <option value="509">Haiti (+509)</option>
                                    <option value="504">Honduras (+504)</option>
                                    <option value="852">Hong Kong (+852)</option>
                                    <option value="36">Hungary (+36)</option>
                                    <option value="354">Iceland (+354)</option>
                                    <option value="91">India (+91)</option>
                                    <option value="62">Indonesia (+62)</option>
                                    <option value="98">Iran (+98)</option>
                                    <option value="964">Iraq (+964)</option>
                                    <option value="353">Ireland (+353)</option>
                                    <option value="972">Israel (+972)</option>
                                    <option value="39">Italy (+39)</option>
                                    <option value="1876">Jamaica (+1876)</option>
                                    <option value="81">Japan (+81)</option>
                                    <option value="962">Jordan (+962)</option>
                                    <option value="7">Kazakhstan (+7)</option>
                                    <option value="254">Kenya (+254)</option>
                                    <option value="686">Kiribati (+686)</option>
                                    <option value="850">Korea North (+850)</option>
                                    <option value="82">Korea South (+82)</option>
                                    <option value="965">Kuwait (+965)</option>
                                    <option value="996">Kyrgyzstan (+996)</option>
                                    <option value="856">Laos (+856)</option>
                                    <option value="371">Latvia (+371)</option>
                                    <option value="961">Lebanon (+961)</option>
                                    <option value="266">Lesotho (+266)</option>
                                    <option value="231">Liberia (+231)</option>
                                    <option value="218">Libya (+218)</option>
                                    <option value="417">Liechtenstein (+417)</option>
                                    <option value="370">Lithuania (+370)</option>
                                    <option value="352">Luxembourg (+352)</option>
                                    <option value="853">Macao (+853)</option>
                                    <option value="389">Macedonia (+389)</option>
                                    <option value="261">Madagascar (+261)</option>
                                    <option value="265">Malawi (+265)</option>
                                    <option value="60">Malaysia (+60)</option>
                                    <option value="960">Maldives (+960)</option>
                                    <option value="223">Mali (+223)</option>
                                    <option value="356">Malta (+356)</option>
                                    <option value="692">Marshall Islands (+692)</option>
                                    <option value="596">Martinique (+596)</option>
                                    <option value="222">Mauritania (+222)</option>
                                    <option value="269">Mayotte (+269)</option>
                                    <option value="52">Mexico (+52)</option>
                                    <option value="691">Micronesia (+691)</option>
                                    <option value="373">Moldova (+373)</option>
                                    <option value="377">Monaco (+377)</option>
                                    <option value="976">Mongolia (+976)</option>
                                    <option value="1664">Montserrat (+1664)</option>
                                    <option value="212">Morocco (+212)</option>
                                    <option value="258">Mozambique (+258)</option>
                                    <option value="95">Myanmar (+95)</option>
                                    <option value="264">Namibia (+264)</option>
                                    <option value="674">Nauru (+674)</option>
                                    <option value="977">Nepal (+977)</option>
                                    <option value="31">Netherlands (+31)</option>
                                    <option value="687">New Caledonia (+687)</option>
                                    <option value="64">New Zealand (+64)</option>
                                    <option value="505">Nicaragua (+505)</option>
                                    <option value="227">Niger (+227)</option>
                                    <option value="234">Nigeria (+234)</option>
                                    <option value="683">Niue (+683)</option>
                                    <option value="672">Norfolk Islands (+672)</option>
                                    <option value="670">Northern Marianas (+670)</option>
                                    <option value="47">Norway (+47)</option>
                                    <option value="968">Oman (+968)</option>
                                    <option value="92">Pakistan (+92)</option>
                                    <option value="680">Palau (+680)</option>
                                    <option value="507">Panama (+507)</option>
                                    <option value="675">Papua New Guinea (+675)</option>
                                    <option value="595">Paraguay (+595)</option>
                                    <option value="51">Peru (+51)</option>
                                    <option value="63">Philippines (+63)</option>
                                    <option value="48">Poland (+48)</option>
                                    <option value="351">Portugal (+351)</option>
                                    <option value="1787">Puerto Rico (+1787)</option>
                                    <option value="974">Qatar (+974)</option>
                                    <option value="262">Reunion (+262)</option>
                                    <option value="40">Romania (+40)</option>
                                    <option value="7">Russia (+7)</option>
                                    <option value="250">Rwanda (+250)</option>
                                    <option value="378">San Marino (+378)</option>
                                    <option value="239">Sao Tome &amp;amp; Principe (+239)</option>
                                    <option value="966">Saudi Arabia (+966)</option>
                                    <option value="221">Senegal (+221)</option>
                                    <option value="381">Serbia (+381)</option>
                                    <option value="248">Seychelles (+248)</option>
                                    <option value="232">Sierra Leone (+232)</option>
                                    <option value="65">Singapore (+65)</option>
                                    <option value="421">Slovak Republic (+421)</option>
                                    <option value="386">Slovenia (+386)</option>
                                    <option value="677">Solomon Islands (+677)</option>
                                    <option value="252">Somalia (+252)</option>
                                    <option value="27">South Africa (+27)</option>
                                    <option value="34">Spain (+34)</option>
                                    <option value="94">Sri Lanka (+94)</option>
                                    <option value="290">St. Helena (+290)</option>
                                    <option value="1869">St. Kitts (+1869)</option>
                                    <option value="1758">St. Lucia (+1758)</option>
                                    <option value="249">Sudan (+249)</option>
                                    <option value="597">Suriname (+597)</option>
                                    <option value="268">Swaziland (+268)</option>
                                    <option value="46">Sweden (+46)</option>
                                    <option value="41">Switzerland (+41)</option>
                                    <option value="963">Syria (+963)</option>
                                    <option value="886">Taiwan (+886)</option>
                                    <option value="7">Tajikstan (+7)</option>
                                    <option value="66">Thailand (+66)</option>
                                    <option value="228">Togo (+228)</option>
                                    <option value="676">Tonga (+676)</option>
                                    <option value="1868">Trinidad &amp;amp; Tobago (+1868)</option>
                                    <option value="216">Tunisia (+216)</option>
                                    <option value="90">Turkey (+90)</option>
                                    <option value="7">Turkmenistan (+7)</option>
                                    <option value="993">Turkmenistan (+993)</option>
                                    <option value="1649">Turks &amp;amp; Caicos Islands (+1649)</option>
                                    <option value="688">Tuvalu (+688)</option>
                                    <option value="256">Uganda (+256)</option>
                                    <option value="44">UK (+44)</option>
                                    <option value="380">Ukraine (+380)</option>
                                    <option value="971">United Arab Emirates (+971)</option>
                                    <option value="598">Uruguay (+598)</option>
                                    <option value="1">USA (+1)</option>
                                    <option value="7">Uzbekistan (+7)</option>
                                    <option value="678">Vanuatu (+678)</option>
                                    <option value="379">Vatican City (+379)</option>
                                    <option value="58">Venezuela (+58)</option>
                                    <option value="84">Vietnam (+84)</option>
                                    <option value="1284">Virgin Islands - British (+1284)</option>
                                    <option value="1340">Virgin Islands - US (+1340)</option>
                                    <option value="681">Wallis &amp;amp; Futuna (+681)</option>
                                    <option value="969">Yemen (North)(+969)</option>
                                    <option value="967">Yemen (South)(+967)</option>
                                    <option value="260">Zambia (+260)</option>
                                    <option value="263">Zimbabwe (+263)</option>
                                 </select>
                                 </div>
                              </div>
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">Phone Number:</div>
                                 <div className="col-sm-8 col-md-8">
                                 <input onChange={(event) => this.handleUserInputMobileNumber(event)} value={this.state.inputValueUserMobileNumber} name="inputValueUserMobileNumber" type="text" placeholder="12345678"/>
                                 </div>
                              </div>
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">SMS Authentication:</div>
                                 <div className="col-sm-8 col-md-8">
                                    <label className="check_box_pop">
                                    <input type="checkbox"/>
                                    <span className="checkmark left0_check dashboard_checkmark"></span>
                                    </label>
                                 </div>
                              </div>
                              <div className="clear10"></div>
                              <div className="col-sm-12">
                                <a href={null} className="add_file_btn" onClick={(event) => this.showChangePassword()} style={{cursor: "pointer"}}>
                                  Change Password
                                </a>
                              </div>
                              <div className="clear20"></div>
                           </div>
                           <div className="col-sm-12">
                              <div className="btn_cance_save">
                                 <button type="button" className="btn_save_pro" data-dismiss="modal">Save</button>
                                 <button type="button" className="btn_cancel_pro" data-dismiss="modal">Cancel</button>
                              </div>
                           </div>
                           <div className="clear20"></div>
                        </div>
                     </div>
                     <div className="clearfix"></div>
                  </div>
               </div>
            </div>
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
               <div className="modal-dialog modal-dialog-centered modla_edit_profile" role="document">
                  <div className="modal-content">
                     <div className="modal-header modal_header_register">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <div className="modal-body register_suc register_popup">
                        <div className="user_edting_hd">
                           <svg xmlns="http://www.w3.org/2000/svg" viewBox="1585 5909 17.515 18.002">
                              <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1582 5906.002)"/>
                           </svg>
                           User Setting
                        </div>
                        <div className="clear50"></div>
                        <div className="col-sm-4 p0">
                           <div className="user_setting_box">
                              <div className="col-sm-12 p0 text-center"> 
                              
                              <img src={(this.state.profile_pic !== '' ? this.state.profile_pic : 'https://media1.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif')} className="profile_img" alt="" /></div>
                              <div className="clear20"></div>
                              <button type="button" href="/" className="btn_changephoto">
                                 <span>Change photo</span>
                              </button>
                           </div>
                           <div className="clear40"></div>
                           <div className="btn_cance_save">
                              <input name="" type="button" className="btn_save_pro" value="Save" />
                              <input name="" type="button" className="btn_cancel_pro" data-dismiss="modal" aria-label="Close" value="Cancel" />
                           </div>
                        </div>
                        <div className="col-sm-7 profile_setting_pop pull-right p0">
                           <form className="form-horizontal" action="/action_page.php">
                              <div className="form-group">
                                 <label className="control-label col-sm-4" htmlFor="First Name">First Name</label>
                                 <div className="col-sm-8">
                                    <input type="First Name" className="form-control pro_input_pop" id="First Name" placeholder="Jessie" />
                                 </div>
                              </div>
                              <div className="form-group">
                                 <label className="control-label col-sm-4" htmlFor="Last Name">Last Name</label>
                                 <div className="col-sm-8">
                                    <input type="Last Name" className="form-control pro_input_pop" id="Last Name" placeholder="He" />
                                 </div>
                              </div>
                              <div className="form-group">
                                 <label className="control-label col-sm-4" htmlFor="Email">Email</label>
                                 <div className="col-sm-8">
                                    <input type="Email" className="form-control pro_input_pop" id="Email" placeholder="example@gmail.com"/>
                                 </div>
                              </div>
                              <div className="form-group">
                                 <label className="control-label col-sm-4" htmlFor="Mobile">Mobile</label>
                                 <div className="col-sm-8">
                                    <input type="Mobile" className="form-control pro_input_pop" id="Mobile" placeholder="123456789"/>
                                 </div>
                              </div>
                              <div className="clear20"></div>
                              <div className="col-sm-8">
                                 <button type="button" href="/" className="btn_change_pass_pro">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-9022 -414 13 17.063">
                                       <path id="ic_lock_24px" className="cls-1" d="M15.375,6.688h-.812V5.063a4.063,4.063,0,0,0-8.125,0V6.688H5.625A1.63,1.63,0,0,0,4,8.313v8.125a1.63,1.63,0,0,0,1.625,1.625h9.75A1.63,1.63,0,0,0,17,16.438V8.313A1.63,1.63,0,0,0,15.375,6.688ZM10.5,14a1.625,1.625,0,1,1,1.625-1.625A1.63,1.63,0,0,1,10.5,14Zm2.519-7.312H7.981V5.063a2.519,2.519,0,0,1,5.038,0Z" transform="translate(-9026 -415)"/>
                                    </svg>
                                    <span>Change Password</span>
                                 </button>
                              </div>
                           </form>
                           <div className="clear30"></div>
                           <div className="btn_cance_save2">
                              <input name="" type="button" className="btn_save_pro" value="Save" />
                              <input name="" type="button" className="btn_cancel_pro" value="Cancel" />
                           </div>
                        </div>
                        <div className="clear10"></div>
                     </div>
                  </div>
               </div>
            </div>

            <div id="mymodal_edit_profile" className="modal fade" role="dialog">
               <div className="modal-dialog modal_w_dashedit">
                  <div className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="close OutlineNone" data-dismiss="modal" ><img className="mt22" src={ic_clear_24px} width="18" height="18" alt="" /></button>
                        <h4 className="modal-title modal_customefield">Edit Profile</h4>
                     </div>
                     <div className="modal-body p0 label_modal_custom">
                        <div className="col-sm-4 p0">
                           <div className="dash_proifle_edit_pop">
                              <div className="user_edit_white_box">
                                 <img src={this.state.profile_pic !== '' ? this.state.profile_pic: 'https://media1.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif'}/>
                              </div>
                              <div className="clear20"></div>
                              <div className="col-sm-12 text-center"> <a href={null} className="btn_edit_avator_dash">Edit Avatar</a></div>
                           </div>
                        </div>
                        <div className="col-sm-8">
                           <div className="edit_dash_pop_rightarea">
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">User Name:</div>
                                 <div className="col-sm-8 col-md-8"><input name="" type="text" placeholder="TPHASMINX1"/></div>
                              </div>
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">Email Address:</div>
                                 <div className="col-sm-8 col-md-8"><input name="" type="text" placeholder="exampleAdmin@tphglobal.com"/></div>
                              </div>
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">Country:</div>
                                 <div className="col-sm-8 col-md-8">
                                    <select name="">
                                       <option>Australia</option>
                                    </select>
                                 </div>
                              </div>
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">Phone Number:</div>
                                 <div className="col-sm-8 col-md-8"><input name="" type="text" placeholder="12345678"/></div>
                              </div>
                              <div className="col-sm-12 p0 mrg_bot_custom10">
                                 <div className="col-sm-4 col-md-4 label_csutom_pop">SMS Authentication:</div>
                                 <div className="col-sm-8 col-md-8">
                                    <label className="check_box_pop">
                                    <input type="checkbox"/>
                                    <span className="checkmark left0_check dashboard_checkmark"></span>
                                    </label>
                                 </div>
                              </div>
                              <div className="clear10"></div>
                              <div className="col-sm-12">
                                 <a href="change_password.html" className=" add_file_btn">
                                 Change Password
                                 </a>
                              </div>
                              <div className="clear20"></div>
                           </div>
                           <div className="col-sm-12">
                              <div className="btn_cance_save">
                                 <button type="button" className="btn_save_pro" data-dismiss="modal">Save</button>
                                 <button type="button" className="btn_cancel_pro" data-dismiss="modal">Cancel</button>
                              </div>
                           </div>
                           <div className="clear20"></div>
                        </div>
                     </div>
                     <div className="clearfix"></div>
                  </div>
               </div>
            </div>
            <Modal id="myModal_company" show={this.state.modalOpen} onHide={this.closeModal} className="modal modal2 fade" role="dialog">
                        <div className="modal-header">
                            <button type="button" onClick={(event) => this.onModalClose()} className="close OutlineNone" data-dismiss="modal" ><img className="mt22"  src={ic_clear_24px} width="18" height="18" alt="" /></button>
                            <h4 className="modal-title modal_customefield">Choose a Company</h4>
                        </div>
                        <div className="modal-body flexscroll">
                            <div className="col-sm-12 p0">
                                <LinkContainer to="company-detail">
                                    <a href={null} className="pull-right">
                                        <button type="button" className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm dashboard_plus_icon_table">+</button>
                                    </a>
                                </LinkContainer>
                                <div className="clear5"></div>
                            </div>
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                                <div className="table_formpck_pop_1 company_pop">
                                    <table width="100%" border="0" cellSpacing="2" cellPadding="2">
                                        <thead>
                                            <tr>
                                                <th colSpan="2" align="left" valign="middle">Company</th>
                                            </tr>
                                        </thead>
                                        <ListItem companiesList={this.state.companiesList} isLoading={this.state.isLoading} props={this.props} handleDelete={this.handleDelete}/>
                                    </table>
                                </div>
                            </div>
                            <div className="clear20"></div>
                        </div>
            </Modal>

            <Modal id="myModal_project" show={this.state.projectModalOpen} className="modal modal2 fade" role="dialog">
                 <div className="modal-header">
                    <button type="button" onClick={(event) => this.onProjectModalClose()} className="close OutlineNone" data-dismiss="modal"><img className="mt22" src={ic_clear_24px} width="18" height="18" alt="" /></button>
                    <h4 className="modal-title modal_customefield">Project</h4>
                 </div>
                 <div className="modal-body">
                    <div className="col-sm-12 p0">
                       <LinkContainer to="/add-project">
                          <a href={null} className="pull-right">
                          <button type="button" className="btn btn-primary pull-right plus_icon_table dashboard_btn_plus_cusotm">+</button>
                          </a>
                       </LinkContainer>
                       <div className="clear5"></div>
                    </div>
                    <div className="col-sm-12 p0 mrg_bot_custom10">
                       <div className="table_formpck_pop_1 company_pop">
                          <table width="100%" border="0" cellSpacing="2" cellPadding="2">
                             <thead>
                                <tr>
                                   <th colSpan="2" align="left" valign="middle">Description</th>
                                </tr>
                             </thead>
                             <ProjectListItem projectList={this.state.projectList} isLoading={this.state.isLoading} props={this.props} handleDelete={this.handleProjectDelete}/>
                          </table>
                       </div>
                    </div>
                    <div className="clear20"></div>
                 </div>
            </Modal>

        </div>
    );
  }
};
export default Dashboard;
