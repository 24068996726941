import React, { Component } from "react";
//======================================//
// ListItem AS A CHILD COMPONENT DEFINE //
// - - - - -  IN THIS SCOPE - - - - - - //
//--------------------------------------//

export default class ListItem extends Component
{
	constructor(props)
	{
	    super(props);
		this.state = {
            customFields: props.customFields
        };
  	}

	componentWillReceiveProps(nextProps)
	{
	  	this.setState({ customFields: nextProps.customFields });
	}


	//=======================================//
	//  - - - RENDER LISTITEM COMPONENT - -  //
	//---------------------------------------//
	render()
	{
        if(typeof this.props.customFields !== 'undefined' && this.props.customFields.length > 0)
        {
            let currentcustomFields = this.props.customFields;
            var customFields = currentcustomFields.map((field, key) => {
                return (

					<tr key={key}>
						<td align="left" valign="middle">{field.prompt}</td>
						<td align="left" valign="middle">{field.value}</td>
						<td align="center">
							<div className="col-sm-2  col-md-1 col-xs-4 p0 pull-right text-center timecard_dele pull-right">
								
							</div>
							<div className="col-sm-2  col-md-1 col-xs-4 p0 text-center timecard_edit6 pull-right">
								
							</div>
						</td>
					</tr>
                )
            });
            if(customFields.length === 0)
            {

                 customFields = <tr><td  colSpan="2">Record not found</td><td></td></tr>;
            }
        }
        else
        {
             customFields = <tr><td  colSpan="2">Record not found</td><td></td></tr>;
        }


        return(
            <tbody>
            		{
            			customFields
            		}
            </tbody>
        );
	}
};
