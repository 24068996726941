import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
// - -   Date Picker  - -//
//import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
const ListInput = (props) => {
  const showDateTo = () => {
    document.getElementById("showDateTo").click();
  };
  const showDateFrom = () => {
    document.getElementById("showDateFrom").click();
  };
  
  //console.log("Getting Prompt in ListInput => ",props.Prompt);
  if (props.Prompt === "startDate") {
    var startDate = moment(props.Value);
    return (
      <div className="col-sm-12 p0 mrg_bot_custom10" id="startDate">
        <div
          className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop cursorMove sort"
          key={props.Index}
          id={props.Prompt}
        >
          Date From:
        </div>
        <div className="col-xs-10 col-sm-7 col-md-7 ">
          <div className="col-xs-8 col-sm-8 p0">
            <DatePicker
              id="showDateFrom"
              dateFormat="DD/MM/YYYY"
              selected={props.Amount !== 0 ? startDate : null}
              onChange={(date)=>props.onChangeFromDate(date)}
              disabled={props.disabledFromDate}
            />
          </div>
          <div className="col-xs-2 col-sm-2 calendar_time2 ">
            <svg
              className="cursorPointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="2936.352 349.176 18.501 23.145"
              onClick={showDateFrom}
            >
              <a href={null}>
                <path
                  id="ic_date_range_24px"
                  className="cls-1"
                  d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                  transform="translate(2933.352 347.176)"
                ></path>
              </a>
            </svg>
          </div>
        </div>
      </div>
    );
  } else if (props.Prompt === "endDate") {

    var endDate = moment(props.Value);
    return (
      <div className="col-sm-12 p0 mrg_bot_custom10" id="endtDate">
        <div
          className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop cursorMove sort"
          key={props.Index}
          id={props.Prompt}
        >
          Date To:
        </div>
        {props.TBA === "checked" ? (
          <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop cursorMove tba-box">
            TBA
          </div>
        ) : (
          <div className="col-xs-10 col-sm-7 col-md-7">
            <div className="col-xs-8 col-sm-8 p0">
              <DatePicker
                id="showDateTo"
                dateFormat="DD/MM/YYYY"
                selected={props.Amount !== 0 ? endDate : null}
                onChange={(event)=>props.onChangeToDate(event)}
                disabled={props.disabledEndDate}
              />
            </div>
            <div className="col-xs-2 col-sm-2 calendar_time2 ">
              <svg
                className="cursorPointer"
                onClick={showDateTo}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2936.352 349.176 18.501 23.145"
              >
                <a href={null} dat>
                  <path
                    id="ic_date_range_24px"
                    className="cls-1"
                    d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                    transform="translate(2933.352 347.176)"
                  ></path>
                </a>
              </svg>
            </div>
          </div>
        )}{" "}
      </div>
    );
  } else if (
    props.Prompt === "days_in_week" ||
    props.Prompt === "unit" ||
    props.Prompt === "rate" ||
    props.Prompt === "factor"
  ) {
    return (
      <div
        className="col-sm-12 p0 mrg_bot_custom10 cursorMove sort"
        key={props.Index}
        id={props.Prompt}
      >
        <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop insert_document_capitalize">
          {props.Prompt.replace(/_/g, " ")}:
        </div>
        <div className="col-xs-12 col-sm-8 col-md-8">
          <input
            style={
              props.generalSettings !== ""
                ? { textTransform: props.generalSettings }
                : { textTransform: "capitalize" }
            }
            name={props.Prompt}
            type="number"
            min="0"
            value={props.Value || 0}
            onChange={(event) =>
              props.onChange(event, props.Index, null, null, props.Prompt)
            }
          />
        </div>
      </div>
    );
  } else if (props.Prompt === "amount") {
    return (
      <div
        className="col-sm-12 p0 mrg_bot_custom10 cursorMove sort"
        key={props.Index}
        id={props.Prompt}
      >
        <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop insert_document_capitalize">
          {props.Prompt.replace(/_/g, " ")}:
        </div>
        <div className="col-xs-12 col-sm-8 col-md-8">
          <input
            style={
              props.generalSettings !== ""
                ? { textTransform: props.generalSettings }
                : { textTransform: "capitalize" }
            }
            name={props.Prompt}
            type="text"
            value={props.Value || 0}
          />
        </div>
      </div>
    );
  } else if (props.Prompt === "calc_days" || props.Prompt === "calc_weeks") {
    
    return (
      <div
        className="col-sm-12 p0 mrg_bot_custom10 cursorMove sort"
        key={props.Index}
        id={props.Prompt}
      >
        <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop insert_document_capitalize">
          {props.Prompt.replace(/_/g, " ")}:
        </div>
        <div className="col-xs-12 col-sm-8 col-md-8">
          <input
            style={
              props.generalSettings !== ""
                ? { textTransform: props.generalSettings }
                : { textTransform: "capitalize" }
            }
            name={props.Prompt}
            type="text"
            value={props.Value || 0}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="col-sm-12 p0 mrg_bot_custom10 cursorMove sort"
        key={props.Index}
        id={props.Prompt}
      >
        <div className="col-xs-12 col-sm-4 col-md-4 label_csutom_pop insert_document_capitalize">
          {props.Prompt.replace(/_/g, " ")}:
        </div>
        <div className="col-xs-12 col-sm-8 col-md-8">
          <input
            style={
              props.generalSettings !== ""
                ? { textTransform: props.generalSettings }
                : { textTransform: "capitalize" }
            }
            name={props.Prompt}
            type="text"
            value={props.Value || ""}
            onChange={(event) =>
              props.onChange(event, props.Index, null, null, props.Prompt)
            }
          />
        </div>
      </div>
    );
  }

};
export default ListInput;
