import React, { Component } from "react";
//======================================//
// ListItem AS A CHILD COMPONENT DEFINE //
// - - - - -  IN THIS SCOPE - - - - - - //
//--------------------------------------//

import x_icon from "../../../assets/images/x_icon.png";
import down_arrow_amprvoe from "../../../assets/images/down_arrow_amprvoe.png";
//import user_down_icon from "../../../assets/images/user_down_icon.png";
import "../../../assets/css/stimulsoft/stimulsoft.viewer.office2013.whiteblue.css";
// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

export default class FormPackListItem extends Component
{
	constructor(props)
	{
	    super(props);
		this.state = {
            formPacks: props.formPacks
        };
  	}

	componentWillReceiveProps(nextProps)
	{
	  	this.setState({ formPacks: nextProps.formPacks });
	}

	customToggle(event){
		var ID = event.currentTarget.id;
		ID = "#formpack_info_"+ID
		$(ID).toggle();
	}


	//=======================================//
	//  - - - RENDER LISTITEM COMPONENT - -  //
	//---------------------------------------//
	render()
	{

        if(typeof this.props.formPacks !== 'undefined' && this.props.formPacks.length > 0)
        {
            let currentformPacks = this.props.formPacks;
						// console.log("currentformPacks");
						//console.log("currentformPacks=>",currentformPacks);
            var formPacks = currentformPacks.map((data, key) => {
                    var documents;
				// all documents return into documnets variable //
				if (typeof(data.Documents) !== "undefined"){
					
					 documents = data.Documents.map((document, key) => {
						return(
							<tr key={"ux2o2"+key}>
							   <td>{document.TemplateCode}</td>
							   <td>{document.Desc}</td>
							   <td align="center">{document.filename}</td>
							   <td align="center">{document.EmailDist}</td>
							</tr>
						)
					});
				}
				else
				{

					 documents = <tr><td  colSpan="5" >Record not found</td></tr>;

				}




				return (
					[
					<tr key={"ux1o1"+key}>
					   <td align="center">{data.Code}</td>
					   <td align="center"><a href={null} className="CursorPointer" id={key} onClick={(event) => this.customToggle(event)} ><img src={down_arrow_amprvoe} alt="" /></a></td>
					   <td align="center">{data.Desc}</td>
					   <td align="center">
						  <div className="col-sm-2  col-md-1 col-xs-4 p0 pull-right text-center timecard_dele pull-right">
							 <a className="CursorPointer" href={null} onClick={this.props.handleDelete(data.Guid)} data-toggle="modal" data-target="#exampleModalCenter">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="1700 296 15 19.286">
								   <path id="ic_delete_24px" className="cls-1" d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z" transform="translate(1695 293)"/>
								</svg>
							 </a>
						  </div>
						  <div className="col-sm-2  col-md-1 col-xs-4 p0 text-center timecard_edit6 pull-right">
							 <a className="CursorPointer" href={null} onClick={(event) => this.props.handleEdit(event, data.Guid)} data-toggle="modal" data-target="#myModal_1">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="1656.776 299 17.515 18.003">
								   <path id="ic_create_24px" className="cls-1" d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z" transform="translate(1653.776 296.002)"/>
								</svg>
							 </a>
						  </div>
						  <div className="col-sm-2  col-md-1 col-xs-4 p0 text-center timecard_edit6 pull-right">
							 <a onClick={(event) => this.props.ViewMrtFile(event, data.Guid)} className="CursorPointer" href={null}>
							 <img src={x_icon} className="formpack_x_img_pack" width="16" height="18" alt="" /> </a>
						  </div>
					   </td>
				   </tr>,
				   <tr key={key} id={"formpack_info_"+key} className="approvel_new_open_table displayNone">
					  <td colSpan="5">
						 <div className="clear5"></div>
						 <table width="100%" border="0" cellSpacing="2" cellPadding="2">
							<thead>
							   <tr>
								  <th width="15%" align="left">Form Code</th>
								  <th width="20%" align="left">Description</th>
								  <th width="20%" align="center" className="text-center">Document</th>
								  <th width="25%" align="center" className="text-center">Email Distribution</th>
							   </tr>
							</thead>
							<tbody>
								  {documents}
							</tbody>
						 </table>
					  </td>
				   </tr>
			   		]
				)

            });

            if(formPacks.length === 0)
            {

                 formPacks = <tr><td  colSpan="3" >Record not found</td><td></td></tr>;
            }
        }
        else
        {
             formPacks = <tr><td  colSpan="4">Record not found</td><td></td></tr>;
        }


        return(
            <tbody>
            		{
            			formPacks
            		}
            </tbody>
        );
	}
};
