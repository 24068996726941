import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import "./CpStepSecond.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/reset_logo.jpg";
import { toast } from 'react-toastify';

class CpStepSecond extends Component {
    constructor(props){
        super(props);
        this.state={
            user_code:'',
            errors:'',
            resend_code:''
        }
    }
    componentDidMount(){
        //console.log(this.props.location.state);
        if(this.props.location.state === undefined){
            return this.props.history.push('/change-password-step-1');   
        }

        this.setState({
            reset_email: this.props.location.state.reset_email
        });
    }

    inputChangeHandler = (e) =>{
        this.setState({[e.target.name]: e.target.value});
    }

    resendCode = (e) =>{
            Auth.forgotPassword(this.state.reset_email)
            .then(data => toast.success("Code resended to " + data.CodeDeliveryDetails.Destination))
            .catch(err => toast.error(err.message));
    }

    onProceed = () =>{
        if(this.state.user_code === ''){
            this.setState({errors:'PIN Code is required'})
            return false;
        }else{
            this.props.history.push({
                pathname: '/change-password-step-3',
                state: { user_code:this.state.user_code, reset_email: this.props.location.state.reset_email  }
            });
        }
    }

    render() {
        return (
                <div className="CpStepSecond">
                    <div className="col-sm-12">
                        <div className="container p0">
                            <div className="forgot_box1">
                                <div className="forgot_box bdr_change_pass">
                                    <div className="col-sm-12 reset_password"> <img src={LOGO} alt="" /> Reset Password</div>
                                    <div className="reset_under_box">
                                        <div className="clear50"></div>

                                        Enter the PIN Code

                                        <div className="clear20"></div>

                                        <input name="user_code" onChange={this.inputChangeHandler} className="frogot_input" value={this.state.user_code} type="text" placeholder="PIN Code" />
                                        {(this.state.errors !== '') ? <small className="errors">{this.state.errors}</small>: null}
                                        <div className="clearfix"></div>
                                        <div className="col-sm-12 resend_label p0"> <a className="resend-code" onClick={this.resendCode} href={null}>Resend code</a></div>

                                        <div className="clear20"></div>

                                        <LinkContainer to="/change-password-step-1"><a href={null} className="btn_frogot_back">Back</a></LinkContainer>
                                        <a href={null} onClick={this.onProceed} className="btn_frogot_blue">Proceed</a>

                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="clearfix"></div>

                                </div>
                            </div>
                            <div className="clearfix"></div>

                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
        );
    }
};
export default CpStepSecond;
