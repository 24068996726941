import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import "./FpStepThird.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/reset_logo.jpg";
import close_icon from "../../assets/images/close_icon.png";
import sucess_forgot from "../../assets/images/sucess_forgot.jpg";
import { API} from "aws-amplify";
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class FpStepThird extends Component {
       constructor(props) {
        super(props);
          console.log(this.props);
            this.state = {
                oldpass:'',
                newpass:'',
                confirmnewpass:'',
                fperror:'',
                fperror2:'',
                formvalid:false,
                confirmingcode:'',
                isLoading: false
         }
      
      }

      componentDidMount = async() =>{
        $(".modal").css('display', 'none')
        
      }

    async componentWillMount(){
        if(this.props.location.state===undefined){
                     this.props.history.push('/forget-password-step-1');
        }else{
          this.setState({
              confirmingcode:this.props.location.state.authenticatepin
          });
        }
    }
    ChangePassword= async ()=>{
      if(this.state.formvalid){
            this.setState({isLoading:true});
            await Auth.forgotPasswordSubmit(this.props.location.state.email, this.state.confirmingcode, this.state.newpass).then(data => {
              toast.success('Password updated successfully.', {autoClose: 5000});
              this.props.history.push({pathname: '/dashboard'});
            }).catch(err => {
              console.log(err);
              this.setState({isLoading:false});
              toast.error(err.message, {autoClose: 5000});
            });
      }else if(this.state.newpass!==this.state.confirmnewpass){
        this.setState({fperror2:'Password Not Matched'});
      }
    }
    
    getchange = async (event)=>{
      var name=event.target.name;
      var value=event.target.value;
      await this.setState({[name]:value});
      this.validateField(name,value);
      this.formValid();
    }

    formValid(){
        this.setState({
            formvalid:(this.state.newpass===this.state.confirmnewpass)&&this.state.newpass!==''&&this.state.confirmnewpass!==''
        });
    }

validateField=async (name,value)=>{
     switch (name) {
        
            case 'newpass':
          
            if(value.length<1){
              await this.setState({
                    fperror:'This Field is Required'
                });
            }else{
                 await this.setState({
                    fperror:''
                });
            }
                break;
         case 'confirmnewpass':
          
               if(value.length<1){
               await this.setState({
                    fperror2:'This Field is Required'
                });}else{
                 await this.setState({
                    fperror2:''
                });
            }
                break;
            default:
                break;
        }
}
    render() {
        return (
           <React.Fragment>
  <div className="FpStepFirst">
  {this.state.isLoading ? <div className="se-pre-con"></div> : ''}
   <div className="forgot_bg">
      <div className="col-sm-12">
         <div className="container p0">
            <div className="forgot_box1">
               <div className="forgot_box">
                  <div className="col-sm-12 reset_password">
                     <LinkContainer to="/">
                        <img src={LOGO} alt="LOGO" id="forgetpassword_marginRight10"/>
                     </LinkContainer>
                     Reset Password
                  </div>
                  <div className="reset_under_box">
                     <div className="clear50"></div>
                     Enter the password and confirm password
                     <div className="clear20"></div>
                     <input name="newpass" className="frogot_input" value={this.state.newpass}type="password" placeholder="Password" onChange={this.getchange}/>
              <div className="fp3_validation">{this.state.fperror!==''?this.state.fperror:''}</div>
                     <div className="clear10"></div>
                     <input name="confirmnewpass" className="frogot_input"value={this.state.confirmnewpass} type="password" placeholder="Confirm Password" onChange={this.getchange} />
              <div className="fp3_validation">{this.state.fperror2!==''?this.state.fperror2:''}</div>
                     <div className="clear20"></div>

                     <LinkContainer to="/forget-password-step-1">
                        <a href={null} className="btn_frogot_back">Back</a>
                     </LinkContainer>

                     <a href={null} className="btn_frogot_blue fpstepthird_CursorPointer" onClick={this.ChangePassword}>Proceed</a>
                     <div className="clearfix"></div>
                  </div>
                  <div className="clearfix"></div>
               </div>
            </div>
            <div className="clearfix"></div>
         </div>
         <div className="clearfix"></div>
         <div id="myModal" className="modal fade" role="dialog">
            <div className="modal-dialog">
               <div className="modal-content modal_forgot_w">
                  <div className="modal-header forgot_modal_header">
                     <button type="button" className="close" data-dismiss="modal"><img src={close_icon} alt="" /></button>
                     <img src={sucess_forgot} width="80" height="80" alt="" />
                  </div>
                  <div className="modal-body forgot_modal_body">
                     <p>SUCCESS!</p>
                     <div className="clear60"></div>
                     <div className="clear10"></div>
                     <div className="col-sm-12">
                        <a href="/dashboard" className="btn_frogot_ok">OK</a>
                     </div>
                     <div className="clearfix"></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</React.Fragment>
  );
};
}
export default FpStepThird;
