import React, { Component } from 'react';
import './App.css';
import "./assets/css/style.css";
import "./assets/css/bootstrap.css";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";
import { Auth} from "aws-amplify";
import { API} from "aws-amplify";
import moment from 'moment';
import 'moment-timezone';
import ip from 'ip';
//import { toast ,ToastContainer} from 'mdbreact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            isAuthenticating: true
        };
    }
    //=================== checking Session ==========================
    async componentDidMount() {

         try {
             var sesData = await Auth.currentSession();
             //console.log("sesData=>", sesData);
             if (localStorage.getItem('loggedIn') === 'true') {
                if(localStorage.getItem('useableGuid') !== null){
                    this.userHasAuthenticated(true);
                } 
                else{
                    await this.handleLogout();
                    this.userHasAuthenticated(false);
                }
             }
         } catch (e) {
             console.log('catch');
             if (e !== 'No current user') {
                 console.log(e);
             }
         }
        this.setState({isAuthenticating: false});
    }
    //===================checking Session==========================
    userHasAuthenticated = async (authenticated) => {
        this.setState({isAuthenticated: authenticated});
    }

    postRequest = async (path, body) => {
        try {
            var currentSession = await Auth.currentSession();
            const myInit = {
                headers: { 
                  Authorization: `Bearer ${(currentSession).getIdToken().getJwtToken()}`,
                },
                body: body
            }
            return API.post("digitalforms", path, myInit);
        }catch(e) {
          console.log("catch session errr=>", e);
          await this.handleLogout();
          return {status: false, error: "Session expired!", message: "Session expired!"}
        }
    }

    handleLogout = async (event) => {
        this.setState({isLoading:true});
        await Auth.signOut().then(data => {
            console.log('logout Success=>',data);
            
            if(localStorage.getItem('useableGuid')){
                localStorage.removeItem('useableGuid');
            }
        
            /*API.post("updateusermetadata", "/updateusermetadata", {
                body: {
                    "Guid": localStorage.getItem('Guid'),
                    "key" : "lastLogOff",
                    "value": moment.tz(moment(), "Australia/Canberra")
                }
            });*/
            //======================================================================
            /*try{
                API.post("updateuseractivity", "/updateuseractivity", {
                    body: {
                        "userEmail": localStorage.getItem('userEmail'),
                        "activity" : {
                            "Description": "logout",
                            "IPAddress": ip.address(),
                            "Time": moment.tz(moment(), "Australia/Canberra"),
                            "Type": "Logout"
                        }
                    }
                });
            }catch(e){
                toast.error("Error In Updating User Registers" , {autoClose: 5000});
            }*/
            //======================================================================
            localStorage.setItem('loggedIn', false);
            localStorage.removeItem('userName');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('userLastLogin');
            localStorage.removeItem('SignatureTyped');
            localStorage.removeItem('Guid');
            localStorage.removeItem('currentIpAddress');
            localStorage.removeItem('MobileNumber');
            localStorage.removeItem('countryCode');
            localStorage.removeItem('userType');
            localStorage.removeItem('ApproverGroup');
            localStorage.removeItem('TenantGuid');
            localStorage.removeItem('companyID');
            localStorage.removeItem('UserLogin');
            this.userHasAuthenticated(false);
            console.log('loggedOut');
            this.props.history.push("/");

        }).catch(err => {
            this.setState({isLoading:false});
            toast.error("Error In Logout" , {
                autoClose: 5000
            }); 
        });
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            postRequest: this.postRequest,
            handleLogout: this.handleLogout
        };
        return (
                !this.state.isAuthenticating &&
                <div className="App">
                    <Routes childProps={childProps} />
                    <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={5000}
                  />
                </div>
                );
    }
};
export default withRouter(App);
