import React from "react";
import moment from "moment";
import Dropzone from "react-dropzone";
const TextInput = (props) => {
  let Type = props.Type;
  let Promt = props.Promt;
  let SubHeading = props.SubHeading;
  let Name = props.Name;
  let DBField = props.DBField;
  let Index = props.Index;
  let innerIndex = props.innerIndex;
  let RequiredInternal = props.RequiredInternal;
  let HideInternal = props.HideInternal;
  let ReadOnlyInternal = props.ReadOnlyInternal;
  let State = props.generateState;
  let Comments = props.comments;
  let RequiredEle = props.Required ? (
    <p
      className={
        "error validation " + (props.customFormErrors ? "" : "displayNone")
      }
    >
      {props.customFormErrors}
    </p>
  ) : (
    ""
  );

  if (Type === "Drop List") {
    return (
      <div>
        {SubHeading ? (
          <div className="col-sm-12 col-md-12 label_attchment_form">
            {SubHeading}
          </div>
        ) : (
          ""
        )}

        <div className="col-sm-4 col-md-3 label_dataentry label_form_margin">
          {Promt}:
        </div>
        <div className="col-sm-7 col-md-8">
          <div className="col-xs-12 p0">
            <div className="col-xs-8 col-sm-6 p0">
              <select
                id={DBField}
                name={Name}
                value={props.DefaultValue || ""}
                onChange={(event) =>
                  props.onChange(event, Index, innerIndex, State)
                }
                onBlur={
                  props.Tag ? (event) => props.onBlur(event, props.Tag) : ""
                }
                className={"form_detail_iput form_margin_zero " + props.Tag}
                disabled={ReadOnlyInternal === "1" ? true : false}
              >
                <option value="">Select</option>
                {props.DataList.length > 0 ? (
                  <React.Fragment>
                    {props.DataList.map((data, key) => {
                      return (
                        <option key={key} value={data.value}>
                          {data.option}
                        </option>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </select>
              {RequiredEle}
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  } 
  else if(Type==="File")
  {
    return(
      <div>
        {SubHeading ? (
          <div className="col-sm-12 col-md-12 label_attchment_form">
            {SubHeading}
          </div>
        ) : (
          ""
        )}

        <div className="col-sm-4 col-md-3 label_dataentry label_form_margin">
          {Promt}:
        </div>
        <div className="col-sm-7 col-md-8">
          <div className="col-xs-12 p0">
            <div className="col-xs-8 col-sm-6 p0">
    
              {/* <input
                id={props.ID}
                style={
                  props.generalSettings !== ""
                    ? { textTransform: props.generalSettings }
                    : { textTransform: "capitalize" }
                }
                type={Type}
                name={Name}
                value={DefaultValue}
                onChange={(event) =>
                  props.onChange(event, Index, innerIndex, State)
                }
                onBlur={
                  props.Tag ? (event) => props.onBlur(event, props.Tag) : ""
                }
                required={
                  RequiredInternal === true || RequiredInternal === "1"
                    ? true
                    : false
                }
                readOnly={ReadOnlyInternal === "1" ? true : false}
                className={
                  "form_detail_iput form_margin_zero " +
                  props.Tag +
                  " " +
                  (HideInternal === "1" ? "displayNone" : "")
                }
              />*/}
              <div className="clear20"></div>
                            <div className="col-sm-12 p0">
                              <Dropzone
                                onDrop={props.onDropZone}
                                className="drag_drop_box dropzone-custom"
                              >
                                <div className="drag_drop_box dropzone-custom">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="8655 7398 52 34.667"
                                  >
                                    <path
                                      id="ic_backup_24px"
                                      className="cls-1"
                                      d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z"
                                      transform="translate(8655 7394)"
                                    />
                                  </svg> 
                                  <p>Drag files in or click to upload</p> 
                                  {/* <div className="clear10"></div> */}
                                 
                                </div>
                              </Dropzone>
                            </div>

              {RequiredEle} 
            </div>
            <div className="col-xs-8 col-sm-6 p0 fo comments-padding">
              <p style={{ marginTop: "16px", marginLeft: "15px" }}>
                {props.comments !== null ? <p>{props.comments}</p> : null}
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    )
  }
  
  else {
    if (Type === "Date") {
      var DefaultValue = props.DefaultValue
        ? moment(props.DefaultValue).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    }
    else if(Type==="File")
    {
      console.log(">>>>FIND IT")
    
    }
    else {
      var DefaultValue = props.DefaultValue || "";
    }
    return (
      <div>
        {SubHeading ? (
          <div className="col-sm-12 col-md-12 label_attchment_form">
            {SubHeading}
          </div>
        ) : (
          ""
        )}

        <div className="col-sm-4 col-md-3 label_dataentry label_form_margin">
          {Promt}:
        </div>
        <div className="col-sm-7 col-md-8">
          <div className="col-xs-12 p0">
            <div className="col-xs-8 col-sm-6 p0">
    
              <input
                id={props.ID}
                style={
                  props.generalSettings !== ""
                    ? { textTransform: props.generalSettings }
                    : { textTransform: "capitalize" }
                }
                type={Type}
                name={Name}
                value={DefaultValue}
                onChange={(event) =>
                  props.onChange(event, Index, innerIndex, State)
                }
                onBlur={
                  props.Tag ? (event) => props.onBlur(event, props.Tag) : ""
                }
                required={
                  RequiredInternal === true || RequiredInternal === "1"
                    ? true
                    : false
                }
                readOnly={ReadOnlyInternal === "1" ? true : false}
                className={
                  "form_detail_iput form_margin_zero " +
                  props.Tag +
                  " " +
                  (HideInternal === "1" ? "displayNone" : "")
                }
              />
              {RequiredEle}
            </div>
            <div className="col-xs-8 col-sm-6 p0 fo comments-padding">
              <p style={{ marginTop: "16px", marginLeft: "15px" }}>
                {props.comments !== null ? <p>{props.comments}</p> : null}
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
};
export default TextInput;
