import React, { Component } from "react";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import { Storage } from "aws-amplify";
import Header from "../Common/Header/Header";
import PayAsListItem from "./Components/PayAsListItem";
import TimeCardListItem from "./Components/TimeCardListItem";
import DailyTimesListItem from "./Components/DailyTimesListItem";
import Dropzone from "react-dropzone";
import "./AddProject.css";
// - -  DEFINE CONSTANTS  - -//
// - - - - - - - - - - - - - //
import ic_clear_24px from "../../assets/images/ic_clear_24px@2x.png";
import uploadLoader from "../../assets/images/Preloader.gif";
// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

var Modal = require("react-bootstrap-modal");
require("react-bootstrap-modal/lib/css/rbm-patch.css");

//=========================================//
// ADDPROJECT AS A PARENT COMPONENT DEFINE //
// - - - - - - IN THIS SCOPE - - - - - - - //
//-----------------------------------------//
export default class AddProject extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      isLoading: true,
      id: "",
      imageWorking: false,
      email: "",
      company: "",
      companyGuid: "",
      timecard: "",
      CountryCode:"",
      description: "",
      payfrequency: "Weekly",
      payending: "Saturday",
      payAsDescription: "",
      payAsFlag: "",
      timeCardDescription: "",
      timeCardFlag: "",
      dailyTimesDescription: "",
      FileAttachments: "",
      displayLogo: "",
      fakePath: uploadLoader,
      payAsIndex: null,
      timeCardIndex: null,
      dailyTimesIndex: null,

      isPayAsEditModal: false,
      isTimeCardEditModal: false,
      isDailyTimesEditModal: false,

      idValid: false,
      emailValid: false,
      companyValid: false,
      timecardValid: false,
      descriptionValid: false,
      payfrequencyValid: true,
      payendingValid: true,
      payAsDescriptionValid: false,
      payAsFlagValid: false,
      timeCardDescriptionValid: false,
      timeCardFlagValid: false,
      dailyTimesDescriptionValid: false,

      formErrors: {
        id: "",
        email: "",
        company: "",
        description: "",
        timecard: "",
        payfrequency: "",
        payending: "",
      },

      payAsFormErrors: {
        payAsDescription: "",
        payAsFlag: "",
      },

      timeCardFormErrors: {
        timeCardDescription: "",
        timeCardFlag: "",
      },

      dailyTimesFormErrors: {
        dailyTimesDescription: "",
      },

      payAsList: [],
      timeCardList: [],
      dailyTimesList: [],
      companiesList: [],

      formValid: false,
      payAsFormValid: false,
      timeCardFormValid: false,
      dailyTimesFormValid: false,

      payAsModalOpen: false,
      timeCardModalOpen: false,
      dailyTimesModalOpen: false,
      imageUploadModalOpen: false,
    };
    if (this.props.isAuthenticated === false) {
      this.props.history.push("/");
    }
  }

  // - -   LIFE CYCLE METHODS   - -//
  // - - - - - - - - - - - - - - - //
  componentDidMount = async () => {
    $(".modal-backdrop").hide();
    $(".modal-open").css("overflow", "scroll");

    try {
      var response = await this.props.postRequest("/companies/get-list", {});
      if (response.status) {
        this.setState({ companiesList: response.result, isLoading: false });
      }
      console.log("/companies/get-list=>", response);
    } catch (err) {
      console.log("/companies/get-list error=>", err);
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount() {
    $(".modal-open").css("overflow", "visible");
  }

  onDrop(files) {
    return false;
    var thisobj = this;
    setTimeout(function () {
      //$('#dropZoneImage').attr({'src':'static/media/Preloader.0b664b58.gif','height':'100px','width':'100px'});
      $("#dropZoneImage").show();
      $(".drag_drop_box svg").hide();
      thisobj.setState({
        imageWorking: true,
      });
    }, 300);

    //var selected_files = this.state.files;
    files.forEach((file) => {
      //selected_files.push(file);
      var file_name =
        "project-attachments/" + new Date().getTime() + "-" + file.name;
      Storage.vault
        .put(file_name, file)
        .then((result) => {
          var uploadedObj = {
            ContentType: "UTF-8",
            DocDesc: "Timecard document",
            DoumentType: file.type,
            Encrypted: "No",
            filename: file.name,
            PWHash: "a7e7ef%^%*&(7ke834",
            Salt: "HFHHGVHJBJB",
            Timestamp: new Date().getTime(),
            url: result.key,
            //"url": "https://s3.amazonaws.com/freelance-app-uploads/public/"+result.key
          };
          //console.log(uploadedObj.url);
          Storage.get(uploadedObj.url, { level: "private" })
            .then((result) => this.setState({ fakePath: result }))
            .catch((err) => console.log(err));
          // this.state.FileAttachments.push(uploadedObj);
          // console.log("Fileesssss attachment",this.state.FileAttachments);
          this.setState({
            FileAttachments: uploadedObj,
            imageUploadModalOpen: true,
            imageWorking: false,
          });
        })
        .catch((err) => console.log(err));
    });
  }
  // - - CLASS METHODS - -//
  // - - - - - - - - - -  //

  handleInputs(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleCompanySelect(event) {
    let name = event.target.name;
    var companyName = $('select[name="company"] :selected').attr("class");

    let value = event.target.value;
    this.setState(
      {
        company: companyName,
        companyGuid: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let idValid = this.state.idValid;
    let emailValid = this.state.emailValid;
    let companyValid = this.state.companyValid;
    let descriptionValid = this.state.descriptionValid;
    let timecardValid = this.state.timecardValid;
    let payfrequencyValid = this.state.payfrequencyValid;
    let payendingValid = this.state.payendingValid;

    switch (fieldName) {
      case "id":
        idValid = value.length > 0;
        fieldValidationErrors.id = idValid ? "" : "This field is required";
        break;
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid
          ? ""
          : "Email should be username@domain.com";
        break;
      case "company":
        companyValid = value.length > 0;
        fieldValidationErrors.company = companyValid
          ? ""
          : "This field is required";
        break;
      case "description":
        descriptionValid = value.length > 0;
        fieldValidationErrors.description = descriptionValid
          ? ""
          : "This field is required";
        break;
      case "timecard":
        timecardValid = value.length > 0;
        fieldValidationErrors.timecard = timecardValid
          ? ""
          : "This field is required";
        break;
      case "payfrequency":
        payfrequencyValid = value.length > 0;
        fieldValidationErrors.payfrequency = payfrequencyValid
          ? ""
          : "This field is required";
        break;
      case "payending":
        payendingValid = value.length > 0;
        fieldValidationErrors.payending = payendingValid
          ? ""
          : "This field is required";
        break;

      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        idValid: idValid,
        emailValid: emailValid,
        companyValid: companyValid,
        descriptionValid: descriptionValid,
        timecardValid: timecardValid,
        payfrequencyValid: payfrequencyValid,
        payendingValid: payendingValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.idValid &&
        this.state.emailValid &&
        this.state.companyValid &&
        this.state.descriptionValid &&
        this.state.payfrequencyValid &&
        this.state.payendingValid &&
        this.state.timecardValid,
    });
  }
  addImage(event) {
    event.preventDefault();
    if (this.state.FileAttachments) {
      this.onImageUploadModalClose();
    } else {
      alert("plese upload image to save.");
    }
  }

  addProjectRequest = async (event) => {
    event.preventDefault();
    let id = this.state.idValid;
    let email = this.state.emailValid;
    let company = this.state.companyValid;
    let description = this.state.descriptionValid;
    let timecard = this.state.timecardValid;
    // let CountryCode=this.state.CountryCode
    let formErrors = Object.assign({}, this.state.formErrors);

    if (id === false || id === null) {
      formErrors.id = "This field is required";
    }
    if (email === false || email === null) {
      formErrors.email = "Email should be username@domain.com";
    }
    if (company === false || company === null) {
      formErrors.company = "This field is required";
    }
    if (description === false || description === null) {
      formErrors.description = "This field is required";
    }
    if (timecard === false || timecard === null) {
      formErrors.timecard = "This field is required";
    }
    if (!$("#payfrequency").val()) {
      formErrors.payfrequency = "This field is required";
    }
    if (!$("#payending").val()) {
      formErrors.payending = "This field is required";
    }
    this.setState({ formErrors });
    this.setState({
      formValid:
        this.state.idValid &&
        this.state.emailValid &&
        this.state.companyValid &&
        this.state.descriptionValid &&
        this.state.payfrequencyValid &&
        this.state.payendingValid &&
        this.state.timecardValid,
    });
    if (this.state.formValid) {
      this.setState({
        isLoading: true,
        formValid: false,
        idValid: false,
        emailValid: false,
        companyValid: false,
        descriptionValid: false,
        timecardValid: false,
      });
      let project_data = {
        Desc: this.state.description,
        CompanyName: this.state.company,
        CoyGuid: this.state.companyGuid,
        CountryCode:this.state.CountryCode,
        Email: this.state.email,
        ShowID: this.state.id,
        PayFrequency: this.state.payfrequency,
        PayEnding: this.state.payending,
        Payas: this.state.payAsList,
        Timesheet: this.state.timecard,
        CustomFields: this.state.timeCardList,
        CustomTimeFields: this.state.dailyTimesList,
        Logo: this.state.FileAttachments ? this.state.FileAttachments : null,
        displayLogo:
          this.state.displayLogo === "checked" ? this.state.displayLogo : null,
      };
      console.log("project_data=>", project_data);
      try {
        var response = await this.props.postRequest(
          "/projects/create-project",
          project_data
        );
        if (response.status) {
          toast.success("Success! Record has added successfully.", {
            autoClose: 5000,
          });
          this.setState({
            description: "",
            company: "",
            companyGuid: "",
            email: "",
            id: "",
            payfrequency: "",
            payending: "",
            payAsList: [],
            timecard: "",
            timeCardList: [],
            dailyTimesList: [],
          });
          this.props.history.push({
            pathname: "/dashboard",
            state: { projectModalOpen: true },
          });
        } else {
          console.log("/projects/create-project error=>", response);
        }
      } catch (err) {
        console.log("/projects/create-project error=>", err);
        toast.error(err.message, { autoClose: 5000 });
      }
    }
  };

  // - - - Pay As Model Methods - - -//
  // - - - - - - - - - - - - - - - - //
  handlePayAsModalInputs(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.payAsFieldsValidate(name, value);
      }
    );
  }

  payAsFieldsValidate(fieldName, value) {
    let payAsFormErrors = this.state.payAsFormErrors;
    let payAsDescriptionValid = this.state.payAsDescription;
    let payAsFlagValid = this.state.payAsFlag;

    switch (fieldName) {
      case "payAsDescription":
        payAsDescriptionValid = value.length > 0;
        payAsFormErrors.payAsDescription = payAsDescriptionValid
          ? ""
          : "This field is required";
        break;
      case "payAsFlag":
        payAsFlagValid = value.length > 0;
        payAsFormErrors.payAsFlag = payAsFlagValid
          ? ""
          : "This field is required";
        break;
      default:
        break;
    }
    this.setState(
      {
        payAsFormErrors: payAsFormErrors,
        payAsDescriptionValid: payAsDescriptionValid,
        payAsFlagValid: payAsFlagValid,
      },
      this.payAsValidateForm
    );
  }

  payAsValidateForm() {
    this.setState({
      payAsFormValid:
        this.state.payAsDescriptionValid && this.state.payAsFlagValid,
      //isActive2: this.state.promptValid && this.state.valueValid
    });
  }

  addPayAsListInToArray(event) {
    event.preventDefault();
    let payAsDescription = this.state.payAsDescription;
    let payAsFlag = this.state.payAsFlag;

    let payAsFormErrors = Object.assign({}, this.state.payAsFormErrors);
    if (payAsDescription.length < 1) {
      payAsFormErrors.payAsDescription = "This field is required";
    }
    if (payAsFlag.length < 1) {
      payAsFormErrors.payAsFlag = "This field is required";
    }
    if (payAsDescription.length < 1 || payAsFlag.length < 1) {
      this.setState({ payAsFormErrors });
      return false;
    }
    var dataObj = {
      Value: this.state.payAsDescription,
      Flag: this.state.payAsFlag,
    };
    if (payAsDescription.length > 0 && payAsFlag.length > 0) {
      var isPayAsEditModal = this.state.isPayAsEditModal;
      var payAsList1 = this.state.payAsList;
      var thisobj = this;
      var payAsDuplicate = payAsList1.find(function (el) {
        var result;
        if (
          el.Value === thisobj.state.payAsDescription &&
          el.Flag === thisobj.state.payAsFlag
        ) {
          result = true;
        }
        return result;
      });
      if (payAsDuplicate) {
        alert("Record already exist!");
        return false;
      }

      if (isPayAsEditModal) {
        var payAsList = this.state.payAsList;
        var payAsIndex = this.state.payAsIndex;
        payAsList[payAsIndex] = dataObj;

        this.setState({
          payAsIndex: payAsIndex,
          isPayAsEditModal: false,
          payAsDescription: "",
          payAsFlag: "",
          // "isActive2":"",
        });
      } else {
        let payAsList = this.state.payAsList;
        payAsList.push(dataObj);
        this.setState({
          payAsIndex: payAsIndex,
          payAsDescription: "",
          payAsFlag: "",
          //"isActive2":"",
        });
      }

      this.setState({
        payAsDescriptionValid: false,
        payAsFlagValid: false,
      });

      this.onPayAsModalClose();
    }
  }
  editPayAsListFromArray(event, id) {
    this.setState({ payAsIndex: id });
    event.preventDefault();
    this.setState({
      isPayAsEditModal: true,
      //"isActive2": true
    });
    var payAsList = this.state.payAsList;
    payAsList = payAsList[id];
    this.setState({
      payAsDescription: payAsList.Value,
      payAsFlag: payAsList.Flag,
    });
    this.onPayAsModalOpen();
  }

  removePayAsFromArray(event, id) {
    event.preventDefault();
    var payAsList = this.state.payAsList;
    payAsList.splice(id, 1);
    this.setState({
      payAsList: payAsList,
    });
  }

  readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $("#dropZoneImage").attr("src", e.target.result).width(150).height(200);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  onPayAsModalOpen() {
    this.setState({ payAsModalOpen: true });
  }

  onImageUploadModalOpen() {
    this.setState({ imageUploadModalOpen: true });
  }

  onImageUploadModalClose() {
    this.setState({
      imageUploadModalOpen: false,
      // payAsFormErrors: {
      //     payAsDescription: '',
      //     payAsFlag:'',
      // }
    });
  }

  onPayAsModalClose() {
    this.setState({
      payAsModalOpen: false,
      payAsIndex: null,
      isPayAsEditModal: false,
      payAsDescription: "",
      payAsFlag: "",
      payAsFormErrors: {
        payAsDescription: "",
        payAsFlag: "",
      },
    });
  }

  // - - - Time Card Model Methods - - -//
  // - - - - - - - - - - - - - - - - -  //

  handleTimeCardModalInputs(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.timeCardFieldsValidate(name, value);
      }
    );
  }

  timeCardFieldsValidate(fieldName, value) {
    let timeCardFormErrors = this.state.timeCardFormErrors;
    let timeCardDescriptionValid = this.state.timeCardDescription;
    let timeCardFlagValid = this.state.timeCardFlag;

    switch (fieldName) {
      case "timeCardDescription":
        timeCardDescriptionValid = value.length > 0;
        timeCardFormErrors.timeCardDescription = timeCardDescriptionValid
          ? ""
          : "This field is required";
        break;
      case "timeCardFlag":
        timeCardFlagValid = value.length > 0;
        timeCardFormErrors.timeCardFlag = timeCardFlagValid
          ? ""
          : "This field is required";
        break;
      default:
        break;
    }
    this.setState(
      {
        timeCardFormErrors: timeCardFormErrors,
        timeCardDescriptionValid: timeCardDescriptionValid,
        timeCardFlagValid: timeCardFlagValid,
      },
      this.timeCardValidateForm
    );
  }

  timeCardValidateForm() {
    this.setState({
      timecardFormValid:
        this.state.timeCardDescriptionValid && this.state.timeCardFlagValid,
      //isActive2: this.state.promptValid && this.state.valueValid
    });
  }

  addTimeCardListInToArray(event) {
    event.preventDefault();
    let timeCardDescription = this.state.timeCardDescription;
    let timeCardFlag = this.state.timeCardFlag;

    let timeCardFormErrors = Object.assign({}, this.state.timeCardFormErrors);
    if (timeCardDescription.length < 1) {
      timeCardFormErrors.timeCardDescription = "This field is required";
    }
    if (timeCardFlag.length < 1) {
      timeCardFormErrors.timeCardFlag = "This field is required";
    }
    if (timeCardDescription.length < 1 || timeCardFlag.length < 1) {
      this.setState({ timeCardFormErrors });
      return false;
    }
    var dataObj = {
      Prompt: this.state.timeCardDescription,
      PaymentFlag: this.state.timeCardFlag,
    };
    if (timeCardDescription.length > 0 && timeCardFlag.length > 0) {
      var isTimeCardEditModal = this.state.isTimeCardEditModal;
      if (isTimeCardEditModal) {
        var timeCardList = this.state.timeCardList;
        var timeCardIndex = this.state.timeCardIndex;
        timeCardList[timeCardIndex] = dataObj;

        this.setState({
          timeCardIndex: null,
          isTimeCardEditModal: false,
          timeCardDescription: "",
          timeCardFlag: "",
          // "isActive2":"",
        });
      } else {
        let timeCardList = this.state.timeCardList;
        timeCardList.push(dataObj);
        this.setState({
          timeCardIndex: timeCardIndex,
          timeCardDescription: "",
          timeCardFlag: "",
          //"isActive2":"",
        });
      }

      this.setState({
        timeCardDescriptionValid: false,
        timeCardFlagValid: false,
      });

      this.onTimeCardModalClose();
    }
  }

  editTimeCardListFromArray(event, id) {
    this.setState({ timeCardIndex: id });
    event.preventDefault();
    this.setState({
      isTimeCardEditModal: true,
      //"isActive2": true
    });
    var timeCardList = this.state.timeCardList;
    timeCardList = timeCardList[id];
    this.setState({
      timeCardDescription: timeCardList.Prompt,
      timeCardFlag: timeCardList.PaymentFlag,
    });
    this.onTimeCardModalOpen();
  }

  removeTimeCardFromArray(event, id) {
    event.preventDefault();
    var timeCardList = this.state.timeCardList;
    timeCardList.splice(id, 1);
    this.setState({
      timeCardList: timeCardList,
    });
  }

  onTimeCardModalOpen() {
    this.setState({ timeCardModalOpen: true });
  }

  onTimeCardModalClose() {
    this.setState({
      timeCardModalOpen: false,
      timeCardIndex: null,
      isTimeCardEditModal: false,
      timeCardDescription: "",
      timeCardFlag: "",
      timeCardFormErrors: {
        timeCardDescription: "",
        timeCardFlag: "",
      },
    });
  }

  // - - - Daily Times Model Methods - - -//
  // - - - - - - - - - - - - - - - - - - -//

  handleInputCheckbox(event) {
    let name = event.target.name;
    var value = event.target.value;
    if (value === "") {
      this.setState({
        [name]: "checked",
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  }
  handleDailyTimesModalInputs(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.dailyTimesFieldsValidate(name, value);
      }
    );
  }

  dailyTimesFieldsValidate(fieldName, value) {
    let dailyTimesFormErrors = this.state.dailyTimesFormErrors;
    let dailyTimesDescriptionValid = this.state.dailyTimesDescription;

    switch (fieldName) {
      case "dailyTimesDescription":
        dailyTimesDescriptionValid = value.length > 0;
        dailyTimesFormErrors.dailyTimesDescription = dailyTimesDescriptionValid
          ? ""
          : "This field is required";
        break;
      default:
        break;
    }
    this.setState(
      {
        dailyTimesFormErrors: dailyTimesFormErrors,
        dailyTimesDescriptionValid: dailyTimesDescriptionValid,
      },
      this.dailyTimesValidateForm
    );
  }
  
  dailyTimesValidateForm() {
    this.setState({
      dailyTimesFormValid: this.state.dailyTimesDescriptionValid,
      //isActive2: this.state.promptValid && this.state.valueValid
    });
  }

  addDailyTimesListInToArray(event) {
    event.preventDefault();
    let dailyTimesDescription = this.state.dailyTimesDescription;

    let dailyTimesFormErrors = Object.assign(
      {},
      this.state.dailyTimesFormErrors
    );
    if (dailyTimesDescription.length < 1) {
      dailyTimesFormErrors.dailyTimesDescription = "This field is required";
      this.setState({ dailyTimesFormErrors });
      return false;
    }
    var dataObj = {
      Prompt: this.state.dailyTimesDescription,
    };
    if (dailyTimesDescription.length > 0) {
      var isDailyTimesEditModal = this.state.isDailyTimesEditModal;

      if (isDailyTimesEditModal) {
        var dailyTimesList = this.state.dailyTimesList;
        var dailyTimeIndex = this.state.dailyTimeIndex;

        dailyTimesList[dailyTimeIndex] = dataObj;

        this.setState({
          dailyTimeIndex: null,
          isDailyTimesEditModal: false,
          dailyTimesDescription: "",
          // "isActive2":"",
        });
      } else {
        let dailyTimesList = this.state.dailyTimesList;
        dailyTimesList.push(dataObj);
        this.setState({
          dailyTimeIndex: dailyTimeIndex,
          dailyTimesDescription: "",
        });
      }

      this.setState({
        dailyTimesDescriptionValid: false,
      });

      this.onDailyTimesModalClose();
    }
  }

  editDailyTimesListFromArray(event, id) {
    this.setState({ dailyTimeIndex: id });
    event.preventDefault();
    this.setState({
      isDailyTimesEditModal: true,
      //"isActive2": true
    });
    var dailyTimesList = this.state.dailyTimesList;
    dailyTimesList = dailyTimesList[id];
    this.setState({
      dailyTimesDescription: dailyTimesList.Prompt,
    });
    this.onDailyTimesModalOpen();
  }

  removeDailyTimesFromArray(event, id) {
    event.preventDefault();
    var dailyTimesList = this.state.dailyTimesList;
    dailyTimesList.splice(id, 1);
    this.setState({
      dailyTimesList: dailyTimesList,
    });
  }

  onDailyTimesModalOpen() {
    this.setState({ dailyTimesModalOpen: true });
  }

  onDailyTimesModalClose() {
    this.setState({
      dailyTimesModalOpen: false,
      dailyTimeIndex: null,
      isDailyTimesEditModal: false,
      dailyTimesDescription: "",
      dailyTimesFormErrors: {
        dailyTimesDescription: "",
      },
    });
  }

  createSelectItems() {
    let items = [];
    let currentCompaniesList = this.state.companiesList;
    currentCompaniesList.map((results, key) => {
      items.push(
        <option key={key} className={results.CompanyName} value={results.Guid}>
          {results.CompanyName}
        </option>
      );
      return null;
    });
    return items;
  }

  handleAccordianToggle(e) {
    if ($(e.target).attr("class") === "accordion-toggle") {
      $(e.target).html("&nbsp; Hide List");
    } else {
      $(e.target).html("&nbsp; Show List");
    }
  }
  backToProjects() {
    this.props.history.push({
      pathname: "/dashboard",
      state: { showproject: true },
    });
  }
  handleCustomFieldsModalInputsCountry(event) {
    let name = event.target.name;
    let value = event.target.value;
    console.log(name,value)
    this.setState({
        [name]: value
    });
    this.setState({
        CountryCode: value
    });
}
 

  render() {
    return (
      <div className="Dashboard">
        <Header
          pageHeading=""
          showMobileMenu="1"
          isLoading={this.state.isLoading}
          props={this.props}
        />
        <div className="clearfix"></div>
        <div className="col-sm-12 p0 top_mrg_general">
          <form onSubmit={this.addProjectRequest}>
            <div className="col-lg-9 col-lg-offset-2 general_setting_hd">
              <h2>Add Project</h2>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Description:
                </div>
                <div className="col-sm-9 col-lg-5">
                  <input
                    type="text"
                    name="description"
                    value={this.state.description}
                    onChange={(event) => this.handleInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.description ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.description}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">Company:</div>
                <div className="col-sm-9 col-lg-5">
                  <select
                    id="companyName"
                    name="company"
                    onChange={(event) => this.handleCompanySelect(event)}
                  >
                    <option value="">Select</option>
                    {this.createSelectItems()}
                  </select>
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.company ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.company}
                  </p>
                </div>
              </div>
              <div className="clear20"></div>
              <h2 className="svg_img_project">
                <svg
                  className="mr10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    id="ic_description_24px"
                    className="cls-1"
                    d="M16.5,2H6.5A2.287,2.287,0,0,0,4.013,4L4,20a2.287,2.287,0,0,0,2.487,2H21.5A2.3,2.3,0,0,0,24,20V8ZM19,18H9V16H19Zm0-4H9V12H19ZM15.25,9V3.5L22.125,9Z"
                    transform="translate(-4 -2)"
                  />
                </svg>
                Freelance Portal Timecard Settings
              </h2>
              <div className="clear20"></div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">Email:</div>
                <div className="col-sm-9 col-lg-5">
                  <input
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={(event) => this.handleInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.email ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.email}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">Show ID:</div>
                <div className="col-sm-9 col-lg-5">
                  <input
                    type="text"
                    name="id"
                    value={this.state.id}
                    onChange={(event) => this.handleInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.id ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.id}
                  </p>
                </div>
              </div>
               <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Country Code:
                </div>
                <div className="col-sm-9 col-lg-5">
                  <select
                    name="CountryCode"
                    value={this.state.CountryCode}
                    onChange={(event) => this.handleCustomFieldsModalInputsCountry(event)} 
                  >
                    <option value="">Country</option>
                          <option value="61">Australia (+61)</option>
                          <option value="44">UK (+44)</option>
                          <option value="1">USA (+1)</option>
                          <option value="213">Algeria (+213)</option>
                          <option value="379">Andorra (+376)</option>
                          <option value="244">Angola (+244)</option>
                          <option value="1264">Anguilla (+1264)</option>
                          <option value="1268">
                            Antigua &amp;&amp; Barbuda (+1268)
                          </option>
                          <option value="54">Argentina (+54)</option>
                          <option value="374">Armenia (+374)</option>
                          <option value="297">Aruba (+297)</option>
                          <option value="43">Austria (+43)</option>
                          <option value="994">Azerbaijan (+994)</option>
                          <option value="1242">Bahamas (+1242)</option>
                          <option value="973">Bahrain (+973)</option>
                          <option value="880">Bangladesh (+880)</option>
                          <option value="1246">Barbados (+1246)</option>
                          <option value="375">Belarus (+375)</option>
                          <option value="32">Belgium (+32)</option>
                          <option value="501">Belize (+501)</option>
                          <option value="229">Benin (+229)</option>
                          <option value="1441">Bermuda (+1441)</option>
                          <option value="975">Bhutan (+975)</option>
                          <option value="591">Bolivia (+591)</option>
                          <option value="387">Bosnia Herzegovina (+387)</option>
                          <option value="267">Botswana (+267)</option>
                          <option value="55">Brazil (+55)</option>
                          <option value="673">Brunei (+673)</option>
                          <option value="359">Bulgaria (+359)</option>
                          <option value="226">Burkina Faso (+226)</option>
                          <option value="257">Burundi (+257)</option>
                          <option value="855">Cambodia (+855)</option>
                          <option value="237">Cameroon (+237)</option>
                          <option value="1">Canada (+1)</option>
                          <option value="238">Cape Verde Islands (+238)</option>
                          <option value="1345">Cayman Islands (+1345)</option>
                          <option value="236">
                            Central African Republic (+236)
                          </option>
                          <option value="56">Chile (+56)</option>
                          <option value="86">China (+86)</option>
                          <option value="57">Colombia (+57)</option>
                          <option value="269">Comoros (+269)</option>
                          <option value="242">Congo (+242)</option>
                          <option value="682">Cook Islands (+682)</option>
                          <option value="506">Costa Rica (+506)</option>
                          <option value="385">Croatia (+385)</option>
                          <option value="53">Cuba (+53)</option>
                          <option value="90392">Cyprus North (+90392)</option>
                          <option value="357">Cyprus South (+357)</option>
                          <option value="42">Czech Republic (+42)</option>
                          <option value="45">Denmark (+45)</option>
                          <option value="253">Djibouti (+253)</option>
                          <option value="1809">Dominica (+1809)</option>
                          <option value="1809">
                            Dominican Republic (+1809)
                          </option>
                          <option value="593">Ecuador (+593)</option>
                          <option value="20">Egypt (+20)</option>
                          <option value="503">El Salvador (+503)</option>
                          <option value="240">Equatorial Guinea (+240)</option>
                          <option value="291">Eritrea (+291)</option>
                          <option value="372">Estonia (+372)</option>
                          <option value="251">Ethiopia (+251)</option>
                          <option value="500">Falkland Islands (+500)</option>
                          <option value="298">Faroe Islands (+298)</option>
                          <option value="679">Fiji (+679)</option>
                          <option value="358">Finland (+358)</option>
                          <option value="33">France (+33)</option>
                          <option value="594">French Guiana (+594)</option>
                          <option value="689">French Polynesia (+689)</option>
                          <option value="241">Gabon (+241)</option>
                          <option value="220">Gambia (+220)</option>
                          <option value="7880">Georgia (+7880)</option>
                          <option value="49">Germany (+49)</option>
                          <option value="233">Ghana (+233)</option>
                          <option value="350">Gibraltar (+350)</option>
                          <option value="30">Greece (+30)</option>
                          <option value="299">Greenland (+299)</option>
                          <option value="1473">Grenada (+1473)</option>
                          <option value="590">Guadeloupe (+590)</option>
                          <option value="671">Guam (+671)</option>
                          <option value="502">Guatemala (+502)</option>
                          <option value="224">Guinea (+224)</option>
                          <option value="245">Guinea - Bissau (+245)</option>
                          <option value="592">Guyana (+592)</option>
                          <option value="509">Haiti (+509)</option>
                          <option value="504">Honduras (+504)</option>
                          <option value="852">Hong Kong (+852)</option>
                          <option value="36">Hungary (+36)</option>
                          <option value="354">Iceland (+354)</option>
                          <option value="91">India (+91)</option>
                          <option value="62">Indonesia (+62)</option>
                          <option value="98">Iran (+98)</option>
                          <option value="964">Iraq (+964)</option>
                          <option value="353">Ireland (+353)</option>
                          <option value="972">Israel (+972)</option>
                          <option value="39">Italy (+39)</option>
                          <option value="1876">Jamaica (+1876)</option>
                          <option value="81">Japan (+81)</option>
                          <option value="962">Jordan (+962)</option>
                          <option value="7">Kazakhstan (+7)</option>
                          <option value="254">Kenya (+254)</option>
                          <option value="686">Kiribati (+686)</option>
                          <option value="850">Korea North (+850)</option>
                          <option value="82">Korea South (+82)</option>
                          <option value="965">Kuwait (+965)</option>
                          <option value="996">Kyrgyzstan (+996)</option>
                          <option value="856">Laos (+856)</option>
                          <option value="371">Latvia (+371)</option>
                          <option value="961">Lebanon (+961)</option>
                          <option value="266">Lesotho (+266)</option>
                          <option value="231">Liberia (+231)</option>
                          <option value="218">Libya (+218)</option>
                          <option value="417">Liechtenstein (+417)</option>
                          <option value="370">Lithuania (+370)</option>
                          <option value="352">Luxembourg (+352)</option>
                          <option value="853">Macao (+853)</option>
                          <option value="389">Macedonia (+389)</option>
                          <option value="261">Madagascar (+261)</option>
                          <option value="265">Malawi (+265)</option>
                          <option value="60">Malaysia (+60)</option>
                          <option value="960">Maldives (+960)</option>
                          <option value="223">Mali (+223)</option>
                          <option value="356">Malta (+356)</option>
                          <option value="692">Marshall Islands (+692)</option>
                          <option value="596">Martinique (+596)</option>
                          <option value="222">Mauritania (+222)</option>
                          <option value="269">Mayotte (+269)</option>
                          <option value="52">Mexico (+52)</option>
                          <option value="691">Micronesia (+691)</option>
                          <option value="373">Moldova (+373)</option>
                          <option value="377">Monaco (+377)</option>
                          <option value="976">Mongolia (+976)</option>
                          <option value="1664">Montserrat (+1664)</option>
                          <option value="212">Morocco (+212)</option>
                          <option value="258">Mozambique (+258)</option>
                          <option value="95">Myanmar (+95)</option>
                          <option value="264">Namibia (+264)</option>
                          <option value="674">Nauru (+674)</option>
                          <option value="977">Nepal (+977)</option>
                          <option value="31">Netherlands (+31)</option>
                          <option value="687">New Caledonia (+687)</option>
                          <option value="64">New Zealand (+64)</option>
                          <option value="505">Nicaragua (+505)</option>
                          <option value="227">Niger (+227)</option>
                          <option value="234">Nigeria (+234)</option>
                          <option value="683">Niue (+683)</option>
                          <option value="672">Norfolk Islands (+672)</option>
                          <option value="670">Northern Marianas (+670)</option>
                          <option value="47">Norway (+47)</option>
                          <option value="968">Oman (+968)</option>
                          <option value="92">Pakistan (+92)</option>
                          <option value="680">Palau (+680)</option>
                          <option value="507">Panama (+507)</option>
                          <option value="675">Papua New Guinea (+675)</option>
                          <option value="595">Paraguay (+595)</option>
                          <option value="51">Peru (+51)</option>
                          <option value="63">Philippines (+63)</option>
                          <option value="48">Poland (+48)</option>
                          <option value="351">Portugal (+351)</option>
                          <option value="1787">Puerto Rico (+1787)</option>
                          <option value="974">Qatar (+974)</option>
                          <option value="262">Reunion (+262)</option>
                          <option value="40">Romania (+40)</option>
                          <option value="7">Russia (+7)</option>
                          <option value="250">Rwanda (+250)</option>
                          <option value="378">San Marino (+378)</option>
                          <option value="239">
                            Sao Tome &amp;amp; Principe (+239)
                          </option>
                          <option value="966">Saudi Arabia (+966)</option>
                          <option value="221">Senegal (+221)</option>
                          <option value="381">Serbia (+381)</option>
                          <option value="248">Seychelles (+248)</option>
                          <option value="232">Sierra Leone (+232)</option>
                          <option value="65">Singapore (+65)</option>
                          <option value="421">Slovak Republic (+421)</option>
                          <option value="386">Slovenia (+386)</option>
                          <option value="677">Solomon Islands (+677)</option>
                          <option value="252">Somalia (+252)</option>
                          <option value="27">South Africa (+27)</option>
                          <option value="34">Spain (+34)</option>
                          <option value="94">Sri Lanka (+94)</option>
                          <option value="290">St. Helena (+290)</option>
                          <option value="1869">St. Kitts (+1869)</option>
                          <option value="1758">St. Lucia (+1758)</option>
                          <option value="249">Sudan (+249)</option>
                          <option value="597">Suriname (+597)</option>
                          <option value="268">Swaziland (+268)</option>
                          <option value="46">Sweden (+46)</option>
                          <option value="41">Switzerland (+41)</option>
                          <option value="963">Syria (+963)</option>
                          <option value="886">Taiwan (+886)</option>
                          <option value="7">Tajikstan (+7)</option>
                          <option value="66">Thailand (+66)</option>
                          <option value="228">Togo (+228)</option>
                          <option value="676">Tonga (+676)</option>
                          <option value="1868">
                            Trinidad &amp;amp; Tobago (+1868)
                          </option>
                          <option value="216">Tunisia (+216)</option>
                          <option value="90">Turkey (+90)</option>
                          <option value="7">Turkmenistan (+7)</option>
                          <option value="993">Turkmenistan (+993)</option>
                          <option value="1649">
                            Turks &amp;amp; Caicos Islands (+1649)
                          </option>
                          <option value="688">Tuvalu (+688)</option>
                          <option value="256">Uganda (+256)</option>
                          <option value="44">UK (+44)</option>
                          <option value="380">Ukraine (+380)</option>
                          <option value="971">
                            United Arab Emirates (+971)
                          </option>
                          <option value="598">Uruguay (+598)</option>
                          <option value="1">USA (+1)</option>
                          <option value="7">Uzbekistan (+7)</option>
                          <option value="678">Vanuatu (+678)</option>
                          <option value="379">Vatican City (+379)</option>
                          <option value="58">Venezuela (+58)</option>
                          <option value="84">Vietnam (+84)</option>
                          <option value="1284">
                            Virgin Islands - British (+1284)
                          </option>
                          <option value="1340">
                            Virgin Islands - US (+1340)
                          </option>
                          <option value="681">
                            Wallis &amp;amp; Futuna (+681)
                          </option>
                          <option value="969">Yemen (North)(+969)</option>
                          <option value="967">Yemen (South)(+967)</option>
                          <option value="260">Zambia (+260)</option>
                          <option value="263">Zimbabwe (+263)</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Pay Frequency:
                </div>
                <div className="col-sm-9 col-lg-5">
                  <select
                    id="payfrequency"
                    name="payfrequency"
                    value={this.state.payfrequency}
                    onChange={(event) => this.handleInputs(event)}
                  >
                    <option value="">Select</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Fortnightly">Fortnightly</option>
                    <option value="4 Weekly">4 Weekly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.payfrequency ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.payfrequency}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  Pay Ending:
                </div>
                <div className="col-sm-9 col-lg-5">
                  <select
                    id="payending"
                    name="payending"
                    value={this.state.payending}
                    onChange={(event) => this.handleInputs(event)}
                  >
                    <option value="">Select</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                    <option value="Friday">Friday</option>
                  </select>
                  <p
                    className={
                      "error validation " +
                      (this.state.formErrors.payending ? "" : "displayNone")
                    }
                  >
                    {this.state.formErrors.payending}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 company_setting">
                <div className="col-sm-3 col-md-3 col-lg-3 label">
                  PayAs List:
                </div>
                <div className="col-sm-9 col-lg-5">
                  <div
                    className="panel-group projectedit_panelgroup"
                    id="accordion"
                  >
                    <div className="panel panel-default help_panel_group ProjectEdit_panel">
                      <div className="panel-heading help_panel_heading ProjectEdit_panel_heading">
                        <h4 className="panel-title ProjectEdit_panel_title widh100percent pull-left">
                          <a
                            className="accordion-toggle collapsed"
                            onClick={(event) =>
                              this.handleAccordianToggle(event)
                            }
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="false"
                          >
                            &nbsp; Show List
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        className="panel-collapse collapse noHeightProject"
                        aria-expanded="false"
                      >
                        <div className="panel-body help_body_box">
                          <div className="col-sm-12 p0 mrg_bot_custom10">
                            <a href={null} className="pull-right">
                              <button
                                type="button"
                                className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm projectedit_btn_plus_cusotm btn_custom_list"
                                data-toggle="modal"
                                onClick={(event) => this.onPayAsModalOpen()}
                              >
                                +
                              </button>
                            </a>
                            <div className="table_formpck_pop_1">
                              <table
                                width="100%"
                                border="0"
                                cellSpacing="2"
                                cellPadding="2"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      width="40%"
                                      align="left"
                                      valign="middle"
                                    >
                                      Description
                                    </th>
                                    <th
                                      width="35%"
                                      align="left"
                                      valign="middle"
                                    >
                                      Flag
                                    </th>
                                    <th
                                      width="25%"
                                      align="left"
                                      valign="middle"
                                    >
                                      &nbsp;
                                    </th>
                                  </tr>
                                </thead>

                                <PayAsListItem
                                  payAsList={this.state.payAsList}
                                  removeField={this.removePayAsFromArray.bind(
                                    this
                                  )}
                                  editField={this.editPayAsListFromArray.bind(
                                    this
                                  )}
                                />
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0 company_setting">
                  <div className="col-sm-3 col-md-3 col-lg-3 label">
                    TimeCard Template:
                  </div>
                  <div className="col-sm-9 col-lg-5">
                    <input
                      type="text"
                      name="timecard"
                      value={this.state.timecard}
                      onChange={(event) => this.handleInputs(event)}
                    />
                    <p
                      className={
                        "error validation " +
                        (this.state.formErrors.timecard ? "" : "displayNone")
                      }
                    >
                      {this.state.formErrors.timecard}
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 p0 company_setting">
                  <div className="col-sm-3 col-md-3 col-lg-3 label">
                    Timecard Custom Fields List:
                  </div>
                  <div className="col-sm-9 col-lg-5">
                    <div className="panel-group" id="accordion">
                      <div className="panel panel-default help_panel_group ProjectEdit_panel">
                        <div className="panel-heading help_panel_heading ProjectEdit_panel_heading">
                          <h4 className="panel-title ProjectEdit_panel_title widh100percent pull-left">
                            <a
                              className="accordion-toggle collapsed"
                              onClick={(event) =>
                                this.handleAccordianToggle(event)
                              }
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse2"
                              aria-expanded="false"
                            >
                              &nbsp; Show List
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse2"
                          className="panel-collapse collapse noHeightProject"
                          aria-expanded="false"
                        >
                          <div className="panel-body help_body_box">
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                              <a href={null} className="pull-right">
                                <button
                                  type="button"
                                  className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm projectedit_btn_plus_cusotm btn_custom_list"
                                  data-toggle="modal"
                                  onClick={(event) =>
                                    this.onTimeCardModalOpen()
                                  }
                                >
                                  +
                                </button>
                              </a>
                              <div className="table_formpck_pop_1">
                                <table
                                  width="100%"
                                  border="0"
                                  cellSpacing="2"
                                  cellPadding="2"
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        width="40%"
                                        align="left"
                                        valign="middle"
                                      >
                                        Description
                                      </th>
                                      <th
                                        width="35%"
                                        align="left"
                                        valign="middle"
                                      >
                                        Payment Flag
                                      </th>
                                      <th
                                        width="25%"
                                        align="left"
                                        valign="middle"
                                      >
                                        &nbsp;
                                      </th>
                                    </tr>
                                  </thead>
                                  <TimeCardListItem
                                    timeCardList={this.state.timeCardList}
                                    removeField={this.removeTimeCardFromArray.bind(
                                      this
                                    )}
                                    editField={this.editTimeCardListFromArray.bind(
                                      this
                                    )}
                                  />
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0 company_setting">
                  <div className="col-sm-3 col-md-3 col-lg-3 label">
                    Daily Times Custom Fields List:
                  </div>
                  <div className="col-sm-9 col-lg-5">
                    <div className="panel-group" id="accordion">
                      <div className="panel panel-default help_panel_group ProjectEdit_panel">
                        <div className="panel-heading help_panel_heading ProjectEdit_panel_heading">
                          <h4 className="panel-title ProjectEdit_panel_title widh100percent pull-left">
                            <a
                              className="accordion-toggle collapsed"
                              onClick={(event) =>
                                this.handleAccordianToggle(event)
                              }
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse3"
                              aria-expanded="false"
                            >
                              &nbsp; Show List
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse3"
                          className="panel-collapse collapse noHeightProject"
                          aria-expanded="false"
                        >
                          <div className="panel-body help_body_box">
                            <div className="col-sm-12 p0 mrg_bot_custom10">
                              <a href={null} className="pull-right">
                                <button
                                  type="button"
                                  className="btn btn-primary pull-right plus_icon_table btn_plus_cusotm projectedit_btn_plus_cusotm btn_custom_list"
                                  data-toggle="modal"
                                  onClick={(event) =>
                                    this.onDailyTimesModalOpen()
                                  }
                                >
                                  +
                                </button>
                              </a>
                              <div className="table_formpck_pop_1">
                                <table
                                  width="100%"
                                  border="0"
                                  cellSpacing="2"
                                  cellPadding="2"
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        width="70%"
                                        align="left"
                                        valign="middle"
                                      >
                                        Description
                                      </th>
                                      <th
                                        width="30%"
                                        align="left"
                                        valign="middle"
                                      >
                                        &nbsp;
                                      </th>
                                    </tr>
                                  </thead>
                                  <DailyTimesListItem
                                    dailyTimesList={this.state.dailyTimesList}
                                    removeField={this.removeDailyTimesFromArray.bind(
                                      this
                                    )}
                                    editField={this.editDailyTimesListFromArray.bind(
                                      this
                                    )}
                                  />
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 p0 company_setting">
                  <div className="col-sm-3 col-md-3 col-lg-3 label">Logo:</div>
                  <div className="col-sm-9 col-lg-5">
                    <a
                      href={null}
                      className="cursorPointer add_file_btn"
                      data-toggle="modal"
                      onClick={(event) => this.onImageUploadModalOpen()}
                    >
                      Add File
                    </a>
                  </div>
                </div>
              </div>
              <div className="clear20"></div>
              <div className="clear40"></div>
              <div className="col-sm-6 p0">
                <div className="btn_cance_save">
                  <button type="submit" className="btn_save_pro">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn_cancel_pro"
                    onClick={(event) => this.backToProjects()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="clear40"></div>
          </form>
        </div>
        <Modal
          id="imageUploadModal"
          show={this.state.imageUploadModalOpen}
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header hdr_border_none">
            <button
              type="button"
              className="close OutlineNone"
              data-dismiss="modal"
            >
              <img
                className="mt22"
                onClick={(event) => this.onImageUploadModalClose()}
                src={ic_clear_24px}
                width="14"
                height="14"
                alt=""
              />
            </button>
          </div>
          <form onSubmit={(event) => this.addImage(event)}>
            <div className="modal-body label_modal_custom overflow_inherti_custom pdg_30">
              <Dropzone
                onDrop={this.onDrop.bind(this)}
                className={"cursorPointer positionRelative"}
              >
                <div className="drag_drop_box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={
                      this.state.FileAttachments !== "" ? "displayNone" : ""
                    }
                    viewBox="8655 7398 52 34.667"
                  >
                    <path
                      id="ic_backup_24px"
                      className="cls-1"
                      d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z"
                      transform="translate(8655 7394)"
                    />
                  </svg>
                  <img
                    id="dropZoneImage"
                    width="100"
                    height="100"
                    className={
                      this.state.FileAttachments !== "" ? "" : "displayNone"
                    }
                    src={this.state.fakePath}
                    alt="Broken Logo"
                  />
                  <div className="clear10"></div>
                  {this.state.imageWorking === false
                    ? "Click to upload"
                    : "Uploading , Please Wait . . ."}
                </div>
                <div className="clear20"></div>
              </Dropzone>
              <div className="clear20"></div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-xs-6 col-sm-3 col-md-3 label_csutom_pop">
                  Display logo:
                </div>
                <div className="col-xs-6 col-sm-9 col-md-9 mrg_top5">
                  <label className="check_box_pop">
                    <input
                      type="checkbox"
                      name="displayLogo"
                      value={this.state.displayLogo}
                      checked={this.state.displayLogo}
                      onChange={(event) => this.handleInputCheckbox(event)}
                    />
                    <span className="checkmark left0_check"></span>
                  </label>
                </div>
              </div>
              <div className="clear20"></div>
              <div className="col-sm-6">
                <div className="btn_cance_save">
                  <button type="submit" className="btn_save_pro">
                    Save
                  </button>
                  {this.state.FileAttachments === "" ? (
                    <button
                      type="button"
                      className="btn_cancel_pro"
                      onClick={(event) => this.onImageUploadModalClose()}
                    >
                      Cancel
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="clear20"></div>
            </div>
          </form>
        </Modal>
        <Modal
          id="payAsModal"
          show={this.state.payAsModalOpen}
          onHide={this.closeModal}
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close OutlineNone"
              onClick={(event) => this.onPayAsModalClose()}
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">Pay As List</h4>
          </div>
          <form onSubmit={(event) => this.addPayAsListInToArray(event)}>
            <div className="modal-body label_modal_custom overflow_inherti_custom">
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-3 label_csutom_pop">
                  Description:
                </div>
                <div className="col-sm-9 col-md-9">
                  <input
                    type="text"
                    value={this.state.payAsDescription}
                    name="payAsDescription"
                    onChange={(event) => this.handlePayAsModalInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.payAsFormErrors.payAsDescription
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.payAsFormErrors.payAsDescription}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-3 label_csutom_pop">Flag:</div>
                <div className="col-sm-9 col-md-9">
                  <input
                    type="text"
                    value={this.state.payAsFlag}
                    name="payAsFlag"
                    onChange={(event) => this.handlePayAsModalInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.payAsFormErrors.payAsFlag
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.payAsFormErrors.payAsFlag}
                  </p>
                </div>
              </div>
              <div className="clear20"></div>
              <div className="col-sm-6">
                <div className="btn_cance_save">
                  <button type="submit" className="btn_save_pro">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn_cancel_pro"
                    onClick={(event) => this.onPayAsModalClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="clear20"></div>
            </div>
          </form>
        </Modal>

        <Modal
          id="timeCardModal"
          show={this.state.timeCardModalOpen}
          onHide={this.closeModal}
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close OutlineNone"
              onClick={(event) => this.onTimeCardModalClose()}
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Time Card Custom Fields List
            </h4>
          </div>
          <form onSubmit={(event) => this.addTimeCardListInToArray(event)}>
            <div className="modal-body label_modal_custom overflow_inherti_custom">
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-3 label_csutom_pop">
                  Description:
                </div>
                <div className="col-sm-9 col-md-9">
                  <input
                    type="text"
                    value={this.state.timeCardDescription}
                    name="timeCardDescription"
                    onChange={(event) => this.handleTimeCardModalInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.timeCardFormErrors.timeCardDescription
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.timeCardFormErrors.timeCardDescription}
                  </p>
                </div>
              </div>
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-3 label_csutom_pop">
                  Payment Flag:
                </div>
                <div className="col-sm-9 col-md-9">
                  <input
                    type="text"
                    value={this.state.timeCardFlag}
                    name="timeCardFlag"
                    onChange={(event) => this.handleTimeCardModalInputs(event)}
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.timeCardFormErrors.timeCardFlag
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.timeCardFormErrors.timeCardFlag}
                  </p>
                </div>
              </div>
              <div className="clear20"></div>
              <div className="col-sm-6">
                <div className="btn_cance_save">
                  <button type="submit" className="btn_save_pro">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn_cancel_pro"
                    onClick={(event) => this.onTimeCardModalClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="clear20"></div>
            </div>
          </form>
        </Modal>

        <Modal
          id="dailyTimesModal"
          show={this.state.dailyTimesModalOpen}
          onHide={this.closeModal}
          className="modal modal2 fade"
          role="dialog"
        >
          <div className="modal-header">
            <button
              type="button"
              className="close OutlineNone"
              onClick={(event) => this.onDailyTimesModalClose()}
            >
              <img
                className="mt22"
                src={ic_clear_24px}
                width="18"
                height="18"
                alt=""
              />
            </button>
            <h4 className="modal-title modal_customefield">
              Daily Times Custom Fields List
            </h4>
          </div>
          <form onSubmit={(event) => this.addDailyTimesListInToArray(event)}>
            <div className="modal-body label_modal_custom overflow_inherti_custom">
              <div className="col-sm-12 p0 mrg_bot_custom10">
                <div className="col-sm-3 col-md-3 label_csutom_pop">
                  Description:
                </div>
                <div className="col-sm-9 col-md-9">
                  <input
                    type="text"
                    value={this.state.dailyTimesDescription}
                    name="dailyTimesDescription"
                    onChange={(event) =>
                      this.handleDailyTimesModalInputs(event)
                    }
                  />
                  <p
                    className={
                      "error validation " +
                      (this.state.dailyTimesFormErrors.dailyTimesDescription
                        ? ""
                        : "displayNone")
                    }
                  >
                    {this.state.dailyTimesFormErrors.dailyTimesDescription}
                  </p>
                </div>
              </div>
              <div className="clear20"></div>
              <div className="col-sm-6">
                <div className="btn_cance_save">
                  <button type="submit" className="btn_save_pro">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn_cancel_pro"
                    onClick={(event) => this.onDailyTimesModalClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="clear20"></div>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}
