import React, { Component }
from "react";
import Header from "../Common/Header/Header";
import "./GeneralSettings.css";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';

class GeneralSettings extends Component {
   constructor(props){
      super(props);
      this.state={
         settings: "",
         isLoading: true
      }
   }
   
   componentDidMount =async () =>{
      try{
         var settings = await this.props.postRequest("/settings/get-list", {});
         if (settings.status) {
            if(settings.result.length >= 1){
               this.setState({isLoading:false, settings: settings.result[0].Value})
            }else{
               this.setState({isLoading:false, settings: ''})
            }
         }else{
            this.setState({isLoading:false, settings: ''});
         }
      }catch(err){
         this.setState({isLoading:false, settings: ''});
         console.log("/settings/get-list error=>", err);
      }
   }

   onSettingChange = async (e) => {
      this.setState({isLoading: true});
      this.setState({
         [e.target.name]: e.target.value
      });

      try{
         var settings = await this.props.postRequest("/settings/create-setting", {
            "Key": "DataEntry",
            "Value": e.target.value,
         });

         if (settings.status) {
            this.setState({isLoading:false});
            toast.success('Settings saved successfully.');
         }else{
            this.setState({isLoading:false});
            toast.error(settings.error);
         }
      }catch(err){
         this.setState({isLoading:false});
         toast.error(err.message);
      }
   }

    render() {
        return (
           <React.Fragment>
           {this.state.isLoading ? <div className="se-pre-con"></div> : 
                <div className="GeneralSettings">
   <Header pageHeading="" props={this.props}/>
   <div className="clearfix"></div>
   <div className="col-sm-12 p0 top_mrg_general_custom_g_s">
      <div className="">
         <div className="col-sm-9 col-sm-offset-2 general_setting_hd">
            <h2>General setting</h2>
            <div className="col-sm-3 col-md-2 label_dataentry">Data Entry:</div>
            <div className="col-sm-6 col-md-5">
               <div className="select-style">
                  <select name="settings" value={this.state.settings} onChange={this.onSettingChange}>
                     <option value="capitalize">Capitalise</option>
                     <option value="uppercase">Upper Case</option>
                     <option value="lowercase">Lower Case</option>
                  </select>
               </div>
            </div>
         </div>
         <div className="clear40"></div>
      </div>
   </div>
   <div className="clear40"></div>
</div>}
</React.Fragment>
                );
    }
}
;
export default GeneralSettings;
