import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import "./ChangePassword.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/reset_logo.jpg";
import { toast } from 'react-toastify';

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_password: "",
            confirm_password: "",
            phone_number:'',
            code:''
        };

        if (this.props.isAuthenticated === true) {
          this.props.history.push("/dashboard");
        }
     }
     
    componentDidMount(){
        $('.modal-backdrop').hide();
    }

    inputChangeHandler = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changePassword = async () =>{
        const email = this.props.location.state.state.email;
        const password = this.props.location.state.state.password;
        
        if(this.state.new_password === ""){
            toast.error("New password is required." , {autoClose: 5000});
            return false;
        }

        if(this.state.confirm_password === ""){
            toast.error("Confirm password is required." , {autoClose: 5000});
            return false;
        }

        if(this.state.confirm_password !== this.state.new_password){
            toast.error("Confirm passwords do not match." , {autoClose: 5000});
            return false;
        }
        this.setState({isLoading: true});
        try {
            var user = await Auth.signIn(email, password);
            console.log("login user=>", user);
            
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log("change password");
                const loggedUser = await Auth.completeNewPassword(
                    user,              // the Cognito User Object
                    this.state.confirm_password     // the new password
                );
                toast.success("Password changes Successfully, please login with new password.", { autoClose: 5000 });
                await this.props.postRequest("/users/update-login-count", {email: email});
                this.props.history.push({
                    pathname: '/'
                });
            }
        }catch(err){
          console.log("user signin error=>", err);
          this.setState({isLoading: false});
          if(err.code === "InvalidPasswordException"){
            toast.error("password must be at least 8 characters long contain a number, an uppercase letter and a symbol." , {autoClose: 5000});
          }else{
            toast.error(err.message , {autoClose: 5000});
          }
          //toast.error("Unable to change pasword, please try again.", { autoClose: 5000 });
        }
    }
    

    render() {
        return (
                <div className="CpStepFirst">
                {this.state.isLoading ? <div className="se-pre-con"></div> : ''}
                    <div className="col-sm-12">
                        <div className="container p0">
                            <div className="forgot_box1">
                                <div className="forgot_box bdr_change_pass">

                                    <div className="col-sm-12 reset_password"> <img src={LOGO} alt="" /> Reset Password</div>

                                    <div className="reset_under_box">
                                        <div className="clear50"></div>

                                        <div className="clear20"></div>

                                        <input name="new_password" onChange={this.inputChangeHandler}  value={this.state.new_password} className="frogot_input" type="password" placeholder="New Password" />

                                        <div className="clear20"></div>

                                        <input name="confirm_password" onChange={this.inputChangeHandler}  value={this.state.confirm_password} className="frogot_input" type="password" placeholder="Confirm Password" />

                                        <div className="clear50"></div>

                                        <LinkContainer to="/"><a href={null} className="btn_frogot_back">Back</a></LinkContainer>
                                        <a href={null} onClick={this.changePassword} className="btn_frogot_blue">Proceed</a>

                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="clearfix"></div>

                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
                );
    }
};
export default ChangePassword;
