import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import "./FpStepFirst.css";
import "../../assets/css/bootstrap.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import LOGO from "../../assets/images/reset_logo.jpg";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
class FpStepFirst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_fp: "",
      guid_user: "",
      cognitoid: "",
      acc_err: "",
      isLoading: false,
      codeStatus: false
    };
  }

  componentDidMount() {
    console.log("Props are here => ", this.props.history.location.sendedEmail);
    if (this.props.history.location !== undefined) {
      this.setState({
        email_fp: this.props.history.location.sendedEmail
      });
    }
  }

  checkAccount = async () => {
    this.setState({isLoading: true });
    var email = this.state.email_fp;

    await API.post("users", "/users/getsingleuser", {
      body: {
        userEmail: email
      }
    })
      .then(data => {
        console.log("User Data : ", data);
        this.setState({
          guid_user: data.guid,
          cognitoid: data.usercognito
        });
        this.sendsmsverification();
        if (typeof Storage !== "undefined") {
          localStorage.setItem("fpmail", this.state.email_fp);
          localStorage.setItem("cognitoid", this.state.cognitoid);
          localStorage.setItem("Guid", this.state.guid_user);
        }

        this.props.history.push({
          pathname: "/forget-password-step-2",
          state: { authenticateacc: true, email: this.state.email_fp },
          codeStatus: this.state.codeStatus
        });
      })
      .catch(error => {
        this.setState({
          acc_err: "Account not found"
        });
        return false;
      });
    this.setState({
      isLoading: false
    });
  };

  sendsmsverification = async () => {
    //event.preventDefault();
    if(this.state.email_fp.length>0){
      this.setState({codeStatus: true});
      try{
        const response = await Auth.forgotPassword(this.state.email_fp);
        this.setState({codeStatus: false});
        //console.log("forgotPassword data", response);
        toast.success("Sms Sended Successfully to " + response.CodeDeliveryDetails.Destination, {autoClose: 5000});
        this.props.history.push({
          pathname: "/forget-password-step-2",
          state: { authenticateacc: true, email: this.state.email_fp },
          codeStatus: true
        });
      }catch(err){
        this.setState({codeStatus: false});
        console.log("forgotPassword error", err);
        toast.error(err.message, {autoClose: 5000});
      }
    }else{
      toast.error("Valid email adreess is required.", {autoClose: 5000});
    }
  };

  getchange = async event => {
    var name = event.target.name;
    var value = event.target.value;
    await this.setState({[name]: value});
  };

  render() {
    return (
      <React.Fragment>
          <div className="FpStepFirst">
            {this.state.isLoading ? <div className="se-pre-con"></div> : ''}
            <div className="forgetpassword_forgot_bg">
              <div className="col-sm-12">
                <div className="container p0">
                  <div className="forgot_box1">
                    <div className="forgot_box">
                      <div className="col-sm-12 reset_password">
                        <LinkContainer to="/">
                          <img
                            src={LOGO}
                            alt="LOGO"
                            id="forgetpassword_marginRight10"
                          />
                        </LinkContainer>
                        Reset Password
                      </div>

                      <div className="reset_under_box">
                        <div className="clear50" />
                        Enter your Email Address below to reset your password
                        <div className="clear20" />
                        <input
                          name="email_fp"
                          className="frogot_input"
                          value={this.state.email_fp}
                          type="text"
                          placeholder="Email"
                          onChange={this.getchange}
                        />
                        <div className="error_fp">
                          {this.state.acc_err !== "" ? this.state.acc_err : ""}
                        </div>
                        <div className="clear50" />
                        <LinkContainer to="/">
                          <a href={null} className="btn_frogot_back">
                            Back
                          </a>
                        </LinkContainer>
                        <a
                          href={null}
                          onClick={this.sendsmsverification}
                          className="btn_frogot_blue"
                        >
                          Proceed
                        </a>
                        <div className="clearfix" />
                      </div>

                      <div className="clearfix" />
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="clearfix" />
              </div>
            </div>
            <div />
          </div>
        )}
      </React.Fragment>
 );   
};
}
export default FpStepFirst;
