import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
//  __  DEFINE COMMON STYLE  __ //
//------------------------------//
import "../../../assets/css/bootstrap.css";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/css/home.css";
import "../../../assets/css/ie_only.css";
import "./Header.css";
//  ____  DEFINE IMAGES  ____ //
//----------------------------//
import LOGO from "../../../assets/images/logo.png";
import LOGOLOGOUT from "../../../assets/images/logout_icon.png";
import helpIcon from "../../../assets/images/help_icon.png";
import settingIcon from "../../../assets/images/setting_icon.png";
import ic_dehaze from "../../../assets/images/ic_dehaze_-19@2x.png";
import ip from 'ip';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

//=======================================//
//      HEADER AS A COMMON COMPONENT     //
//---------------------------------------//
export default class HeaderForUnauthenticated extends Component {
    constructor(props) {
         super(props);
         this.state = {
            isLoading: true
        };
        if (typeof(Storage) !== "undefined")
                        {
        if(localStorage.getItem('loggedIn') !== null && localStorage.getItem('loggedIn') !== ''&& localStorage.getItem('loggedIn') !== true){
        if(localStorage.getItem('currentIpAddress') === ip.address()){
            console.log('same Ip address');
        }
        else{
            console.log('Changed Ip address');
        }
    }    
                        }
    };
    componentDidMount() {
        if (this.props.props.isAuthenticated === false) {
            this.props.props.history.push("/");
        }
        
    }
    render() {
        let hideHomeButton = this.props.hideHomeButton;
        let showMobileMenu = this.props.showMobileMenu;
            const homeButton = hideHomeButton === "1" ? (
            null
        ) : (
            <LinkContainer to="/dashboard">
                <a href={null} className="btn_homer_hdr">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5378 759 13.333 11.333">
                        <path id="ic_home_24px" className="cls-1" d="M7.333,14.333v-4H10v4h3.333V9h2L8.667,3,2,9H4v5.333Z" transform="translate(-5380 756)"/>
                    </svg>
                    Home
                </a>
            </LinkContainer>
        );
        const mobileMenuButton = showMobileMenu === "1" ? (
            <a href={null} className="menu_toggle_mrg display_none_sm_menu header_mobileMenuStylingRe" id="menu-toggle_mobile">
                        <img src={ic_dehaze} className="header_mobileMenuStyling" alt="" />
                    </a>
        ) : (
            null
        );
        return (
            <div className="col-sm-12 dashboard_main_shadow">
                {this.props.isLoading ? <div className="se-pre-con"></div> : ''}
                
                <div className="col-sm-5 col-md-5 col-xs-12 p0">
                    <a href={null} className="pull-left">
                            <img src={LOGO} className="header_CursorPointer" width="200" height="85" alt="" /> </a>
                    
                    
                </div>
                <div className="col-xs-12 col-sm-2 col-md-2 text-center custome_header_label">
                    { this.props.pageHeading }
                </div>
                <div className="col-sm-4 col-xs-12 p0 header_top_right">
               
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
};
