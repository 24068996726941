import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//======================================//
// ListItem AS A CHILD COMPONENT DEFINE //
// - - - - -  IN THIS SCOPE - - - - - - //
//--------------------------------------//

// - -   jQUARY INSTANCE  - -//
// - - - - - - - - - - - - - //
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  display:isDragging?"table":"",
  cursor:"all-scroll",
  cursor: isDragging ? "all-scroll" : "all-scroll",
margin:isDragging?"-80px 5px 10px -190px":"",
// marginTop:isDragging?"-80px":"",

...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  cursor:"all-scroll",
  cursor: isDraggingOver ? "all-scroll" : "all-scroll",
 
});

export default class FormSettingsListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsList: props.settingsList,
      checkboxArray: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ settingsList: nextProps.settingsList });
  }

  handleInputCheckbox(event) {
    var array = this.state.checkboxArray;
    var obj = {};
    var emailAddress = $(event.target).data("emailaddress");
    var userType = $(event.target).data("emailaddress");
    var index = event.target.id;
    obj.emailAddress = emailAddress;
    obj.userType = userType;

    if (array[index]) {
      array.splice(index, 1);
    } else {
      array[index] = obj;
    }
    this.setState({
      checkboxArray: array,
    });
  }
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const settingsList = reorder(
      this.props.settingsList,
      result.source.index,
      result.destination.index
    );

    //console.log(settingsList);
    this.props.setSettingsList(settingsList);
  }

  //=======================================//
  //  - - - RENDER LISTITEM COMPONENT - -  //
  //---------------------------------------//
  render() {
    return (
      <React.Fragment>

              <div
                className="scroll_form_pcktable"
                style={{border:"1px solid #EEEEEE"}}
              >
          <DragDropContext onDragEnd={this.onDragEnd} >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <table width="100%" border="0" cellSpacing="2" cellPadding="2"  {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                  <thead>
                    <tr>
                      <th width="18%" align="left" valign="middle">
                        Form Code
                      </th>
                      <th width="18%" align="left" valign="middle">
                        Description
                      </th>
                      <th width="22%" align="left" valign="middle">
                        Document
                      </th>
                      <th width="18%" align="left" valign="middle">
                        Email Distribution
                      </th>
                      <th width="20%" align="center">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    {this.props.settingsList !== "undefined" &&
                    this.props.settingsList.length > 0 ? (
                      this.props.settingsList.map((field, key) => {
                        return (
                          <Draggable
                            key={field.Guid}
                            draggableId={field.Guid}
                            index={key}
                          >
                            {(provided, snapshot) => (
                              <tr
                                key={key}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              
                              >
                                <td align="left" valign="middle">
                                  {field.TemplateCode}
                                </td>
                                <td align="left" valign="middle">
                                  {field.Desc}
                                </td>
                                <td align="left" valign="middle">
                                  {field.filename}
                                </td>
                                <td align="left" valign="middle">
                                  {field.EmailDist.length > 0
                                    ? field.EmailDist.toString()
                                    : "N/A"}
                                </td>
                                <td align="center">
                                  <div className="col-sm-2  col-md-1 col-xs-4 p0 pull-right text-center timecard_dele pull-right">
                                    <a
                                      href={null}
                                      className="CursorPointer"
                                      onClick={(event) =>
                                        this.props.removeField(event, key)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="1700 296 15 19.286"
                                      >
                                        <path
                                          id="ic_delete_24px"
                                          className="cls-1"
                                          d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                                          transform="translate(1695 293)"
                                        ></path>
                                      </svg>
                                    </a>
                                  </div>
                                  <div className="col-sm-2  col-md-1 col-xs-4 p0 text-center timecard_edit6 pull-right">
                                    <a
                                      href={null}
                                      className="CursorPointer"
                                      data-toggle="modal"
                                      onClick={(event) =>
                                        this.props.editField(event, key)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="1656.776 299 17.515 18.003"
                                      >
                                        <path
                                          id="ic_create_24px"
                                          className="cls-1"
                                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                                          transform="translate(1653.776 296.002)"
                                        ></path>
                                      </svg>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">Record not found</td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                  {provided.placeholder}
                </table>
            )}
            </Droppable>
          </DragDropContext>
</div>

      </React.Fragment>
    );
  }
}
